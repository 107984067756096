import React, { FC } from 'react';
import { Dropdown, Menu, Typography } from 'antd';
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { useAppContext } from '@lib/providers/app';

const DocsDropdown: FC = () => {
  const appContext = useAppContext();

  const docsItems = [];
  if (appContext?.docs?.userGuidePath) {
    docsItems.push({
      text: 'User Guide',
      href: appContext?.docs?.userGuidePath,
    });
  }
  if (appContext?.docs?.devGuidePath) {
    docsItems.push({
      text: 'Development Guide',
      href: appContext?.docs?.devGuidePath,
    });
  }

  const menu = (
    <Menu>
      {docsItems.map((item) => (
        <Menu.Item key={item.href}>
          <a href={item.href} rel="noopener noreferrer" target="_blank" className="mr-4">
            {item.text}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  return docsItems.length ? (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <Typography.Link className="ant-dropdown-link">
        <QuestionCircleOutlined /> Docs <DownOutlined />
      </Typography.Link>
    </Dropdown>
  ) : null;
};

export default DocsDropdown;
