import React, { FC, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Pagination, Row } from 'antd';
import { FormListFieldData } from 'antd/es/form/FormList';
import { IEnum, IField } from 'protobufjs';
import { blue, red } from '@ant-design/colors';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { formatLabel } from '@lib/utils/blueprint-data';

import EnumFieldFormItem from '../enum-field';
import SingleFieldFormItem, { RenderOptions } from '../single-field';

type SimpleArrayFormItemInnerProps = {
  options: RenderOptions<IField>;
};

export type SimpleArrayFormItemProps = SimpleArrayFormItemInnerProps & {
  fields: FormListFieldData[];
  remove: (index: number | number[]) => void;
  add: (_defaultValue: number | undefined, index: number) => void;
};

const SimpleArrayFormItemInner: FC<SimpleArrayFormItemInnerProps> = ({ options }) => {
  const { paths, types, type, key, fieldSettings, field } = options;

  const fieldName = [...paths, key];
  const dataKey = fieldName.join('.');
  const fieldSetting = fieldSettings[dataKey];
  const refType = types[type.type] as IEnum;

  const grid = 2;

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  if (fieldSetting?.reference || refType?.values) {
    if (key !== 'MainFlowIds') {
      return (
        <EnumFieldFormItem options={{ ...options, type: fieldSetting?.reference ?? refType }} />
      );
    }
    return (
      <Draggable
        key={field?.name.toString()}
        draggableId={field?.name.toString() ?? '1'}
        index={field?.name || 0}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <EnumFieldFormItem options={{ ...options, type: fieldSetting?.reference ?? refType }} />
          </div>
        )}
      </Draggable>
    );
  }

  return <SingleFieldFormItem options={{ ...options }} />;
};

const SimpleArrayFormItem = forwardRef<any, SimpleArrayFormItemProps>(
  ({ options, fields, remove, add }, ref) => {
    const { paths, key, fieldSettings } = options;
    const fieldName = [...paths, key];
    const dataIndex = fieldName.join('.');
    const fieldSetting = fieldSettings[dataIndex];
    const formatedKey = fieldSetting?.displayName ?? formatLabel(key);

    const [pagingData, setPagingData] = useState<FormListFieldData[][]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(50);
    const [pageCount, setPageCount] = useState<number>(0);

    const getPagingData = (data: FormListFieldData[]): FormListFieldData[][] => {
      const returnData: FormListFieldData[][] = [];
      let count = 0;
      for (let start = 0; start < data.length; ) {
        let end = start + pageSize;
        if (end > data.length) {
          const leftCount = data.length - pageSize * count;
          end = start + leftCount;
        }

        count += 1;
        const pageData = data.slice(start, end);
        if (pageData.length === 0) break;
        returnData.push(pageData);

        start += pageSize;
      }
      setPageCount(count);
      return returnData;
    };

    const handlePageChange = (page: number, pSize?: number) => {
      setCurrentPage(page);
      setPageSize(pSize ?? pageSize);
    };

    useEffect(() => {
      setPagingData(getPagingData(fields));
    }, [fields, pageSize, pageCount]);

    useImperativeHandle(ref, () => ({
      goToLastPage: () => {
        if (fields.length % pageSize === 0) {
          setCurrentPage(pageCount + 1);
        } else {
          setCurrentPage(pageCount);
        }
      },
    }));

    return (
      <>
        {key === 'MainFlowIds' && (
          <div>
            <Row gutter={8} key={dataIndex}>
              {fields.map((field) => {
                const header = (
                  <div className="flex items-center">
                    <span>id:{field.name}</span>
                    <DeleteOutlined
                      className="ml-2"
                      style={{ color: red.primary }}
                      onClick={() => remove(field.name)}
                    />
                    <PlusOutlined
                      className="ml-2"
                      style={{ color: blue.primary }}
                      onClick={() => add(undefined, field.name + 1)}
                    />
                  </div>
                );

                return (
                  <SimpleArrayFormItemInner
                    key={field.key}
                    options={{
                      ...options,
                      field,
                      label: header,
                    }}
                  />
                );
              })}
            </Row>
          </div>
        )}
        {key !== 'MainFlowIds' && (
          <div>
            <Row gutter={8} key={dataIndex}>
              {pagingData[currentPage - 1] &&
                pagingData[currentPage - 1].map((field) => {
                  const header = (
                    <div className="flex items-center">
                      <span>
                        {formatedKey} [{field.name}]
                      </span>
                      <DeleteOutlined
                        className="ml-2"
                        style={{ color: red.primary }}
                        onClick={() => remove(field.name)}
                      />
                      <PlusOutlined
                        className="ml-2"
                        style={{ color: blue.primary }}
                        onClick={() => add(undefined, field.name + 1)}
                      />
                    </div>
                  );

                  return (
                    <SimpleArrayFormItemInner
                      key={field.key}
                      options={{
                        ...options,
                        field,
                        label: header,
                      }}
                    />
                  );
                })}
            </Row>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={fields.length}
                onChange={handlePageChange}
                showSizeChanger
                pageSizeOptions={['50', '100']}
              />
            </div>
          </div>
        )}
      </>
    );
  },
);

export default SimpleArrayFormItem;
