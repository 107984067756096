import React, { FC, useState } from 'react';
import { Checkbox, DatePicker, Form, Input, InputNumber, message, Select, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import BlueprintDataVersionSelectGlobal from '@lib/components/blueprint-data-version-select';
import DabSelect from '@lib/components/dab-select';
import EnvironmentSelect from '@lib/components/env-select';
import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';
import { useAvaConfigContext } from '@lib/providers/ava-config';
import taskQueueService from '@lib/services/task-queue';

import { useSimulatorContext } from '@pages/simulator/context';
import { SimulatorV2 as Simulator } from '@services/_api';

import { SIMULATOR_CONFIG_KEY } from './create-runtest';

const MainForm: FC<{ isViewDetail?: boolean }> = ({ isViewDetail = false }) => {
  const [disableMEM, setDisableMEM] = useState(true);
  const { formCreate, fetch } = useSimulatorContext();
  const { loading, setLoading } = useAvaConfigContext();
  const { dab, setConfig, setDab, setBucketIds } = useSimulatorContext();
  const { environment, setEnvironment } = useBlueprintDataContext();
  const { avaConfig } = useAvaConfigContext();

  const fetchTaskStatus = async (taskId: number | undefined, _dab: string) => {
    if (taskId) {
      const res = await taskQueueService.findTaskById(taskId);
      if (res.ok) {
        if (res.data?.status === 'DEPLOYING') {
          setLoading(true);
          setTimeout(() => {
            fetchTaskStatus(taskId, _dab);
          }, 5000);
        } else if (res.data?.status === 'SUCCESS') {
          const data = res.data.returnValue as Simulator[];
          if (data) {
            setLoading(false);
            if (data) {
              message.success('Load config successfully !');
              setConfig(data);
              localStorage.setItem(
                SIMULATOR_CONFIG_KEY,
                JSON.stringify({
                  env: environment,
                  dab: _dab,
                  config: data,
                }),
              );
            }
          }
        } else if (res.data?.status === 'FAIL') {
          setLoading(false);
          return message.error(res.data.returnValue?.toString(), 15);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const handleOnChangeDab = async (value: string) => {
    try {
      // if (!value) {
      //   return localStorage.removeItem(SIMULATOR_CONFIG_KEY);
      // }
      if (!avaConfig) message.error('Ava Config need to be loaded!');

      if (!dab || dab !== value) {
        setLoading(true);
        // message.info('Please wait for the config to load from dab!', 6);
        const _bucketIds =
          avaConfig[(environment ?? 'trunk').toLowerCase()]?.dabs?.find(
            (y: any) => y.inAppId === value,
          )?.applicationIds ?? [];

        setBucketIds(_bucketIds);
        setLoading(false);

        // _configs = await simulatorService.getConfigsDab(_bucketId);
        // const res = await simulatorService.getConfigsDab(_bucketId);
        // if (res.ok && res.data) {
        //   const task = await res.data;
        //   if (task) {
        //     fetchTaskStatus(Number(task.id), value);
        //   }
        // }
      }
    } catch (error) {
      message.error('Get config error. Please check again');
      setLoading(false);
    } finally {
      // eslint-disable-next-line no-console
      console.log(loading);

      setDab(value);
    }
  };

  const handleExtraMoves = (value: any) => {
    if (value?.target?.checked) {
      setDisableMEM(false);
      formCreate.setFieldsValue({ maxExtraMoves: 1 });
    } else {
      setDisableMEM(true);
      formCreate.setFieldsValue({ maxExtraMoves: 0 });
    }
  };

  const onChangeUsePGB = (value: any, name: string) => {
    const _value = value.target.checked;
    if (name !== 'usePGB') {
      if (_value) {
        formCreate.setFieldsValue({
          usePGB: true,
        });
      } else {
        const fields = formCreate.getFieldsValue();
        if (!fields.missiles && !fields.bomb && !fields.cube) {
          formCreate.setFieldsValue({
            usePGB: false,
          });
        }
      }
    } else if (name === 'usePGB' && _value) {
      formCreate.setFieldsValue({
        missiles: true,
        bomb: true,
        cube: true,
      });
    } else if (name === 'usePGB' && !_value) {
      formCreate.setFieldsValue({
        missiles: false,
        bomb: false,
        cube: false,
      });
    }
  };

  return (
    <>
      <div className="flex gap-x-6" style={{ marginLeft: 42 }}>
        <Form.Item name="runtestId" label="Runtest ID">
          <Input disabled style={{ minWidth: 200 }} />
        </Form.Item>
        <Form.Item name="performer" label="Performer">
          <Input disabled />
        </Form.Item>
        <Form.Item name="dateRuntest" label="Datetime">
          <DatePicker disabled={isViewDetail} showTime />
        </Form.Item>
      </div>

      <div style={{ marginLeft: 79 }}>
        <Form.Item name="tags" label="Tags">
          <Select
            disabled={isViewDetail}
            mode="tags"
            placeholder="Enter custom tags"
            style={{ width: 220 }}
          />
        </Form.Item>
      </div>
      <div style={{ marginLeft: 49 }}>
        <Form.Item name="attempts" label="Attempts">
          <InputNumber disabled={isViewDetail} min={1} max={10000} defaultValue={0} />
        </Form.Item>
      </div>
      <div className="flex gap-x-6">
        <Form.Item valuePropName="checked" name="useExtraMoves" label="Use Extra Moves">
          <Checkbox onChange={(e: any) => handleExtraMoves(e)} disabled={isViewDetail} />
        </Form.Item>
        <Form.Item name="maxExtraMoves" label="Max Extra Moves">
          <InputNumber disabled={isViewDetail || disableMEM} min={0} />
        </Form.Item>
      </div>
      <div className="flex gap-x-6" style={{ marginLeft: 50 }}>
        <Form.Item valuePropName="checked" name="usePGB" label="Use PGB">
          <Checkbox disabled={isViewDetail} onChange={(e: any) => onChangeUsePGB(e, 'usePGB')} />
        </Form.Item>
        <Form.Item valuePropName="checked" name="missiles" label="Missiles">
          <Checkbox disabled={isViewDetail} onChange={(e: any) => onChangeUsePGB(e, 'missiles')} />
        </Form.Item>
        <Form.Item valuePropName="checked" name="bomb" label="Bomb">
          <Checkbox disabled={isViewDetail} onChange={(e: any) => onChangeUsePGB(e, 'bomb')} />
        </Form.Item>
        <Form.Item valuePropName="checked" name="cube" label="Cube">
          <Checkbox disabled={isViewDetail} onChange={(e: any) => onChangeUsePGB(e, 'cube')} />
        </Form.Item>
      </div>
      <div style={{ marginLeft: 55 }}>
        <Form.Item valuePropName="checked" name="useIGB" label="Use IGB">
          <Checkbox disabled={isViewDetail} />
        </Form.Item>
      </div>
      <div style={{ marginLeft: 16 }}>
        <Form.Item name="botSkillLevel" label="Bot Skill Level">
          <InputNumber disabled={isViewDetail} min={1} max={5} defaultValue={1} />
        </Form.Item>
      </div>
      <div className="flex gap-x-5">
        <Form.Item label="ENV">
          <EnvironmentSelect
            disabled={isViewDetail || loading}
            onChange={setEnvironment}
            value={environment}
            placeholder="Select environment"
            style={{ minWidth: 150 }}
          />
        </Form.Item>
        <Form.Item label="Dab">
          <DabSelect
            value={dab}
            allowClear
            showSearch
            loading={loading}
            disabled={isViewDetail || loading}
            onChange={handleOnChangeDab}
            placeholder="Select dab"
            style={{ minWidth: 250 }}
          />
        </Form.Item>
        <Form.Item label="Version">
          <BlueprintDataVersionSelectGlobal style={{ minWidth: 200 }} />
        </Form.Item>
        <Form.Item>
          <Tooltip title="Reload">
            <ReloadOutlined onClick={fetch} />
          </Tooltip>
        </Form.Item>
      </div>
    </>
  );
};

export default MainForm;
