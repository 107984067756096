import React, { FC, ReactNode } from 'react';
import { Checkbox, Form, InputNumber } from 'antd';
import { FormItemProps, Rule } from 'antd/es/form';
import { IField } from 'protobufjs';
import { CloseCircleFilled } from '@ant-design/icons';

import OdlFileSelect from '@lib/components/odl-file/file-select';
import { TimestampDatePicker, TimestampTimePicker } from '@lib/components/ui/date-picker';
import InputWithValueAsTitle from '@lib/components/ui/input-with-value-as-title';
import { FieldDataType } from '@lib/services/_api';

import { RefFieldReference, useBlueprintDataContext } from '../context';
import EnumSelect from '../enum-select';
import JsonInput from '../form/form-generator/any/json-input';
import { RenderOptions } from '../form/form-generator/single-field';

const { Item: FormItem } = Form;

export type EditableCellOptions = {
  options: RenderOptions<IField>;
  editable: boolean;
  inputType: string | RefFieldReference;
  dataIndex: Array<string | number>;
};
export interface EditableCellProps extends EditableCellOptions, React.HTMLAttributes<HTMLElement> {
  children?: ReactNode;
}

const EditableCell: FC<EditableCellProps> = ({
  options,
  editable,
  inputType,
  dataIndex,
  title,
  children,
  ...restProps
}) => {
  const { anyModal } = useBlueprintDataContext();

  if (!editable) {
    return <td {...restProps}>{children}</td>;
  }

  const { key, paths, form, fieldSettings } = options;
  const dataKey = dataIndex.filter((item) => typeof item !== 'number').join('.');
  const fieldSetting = fieldSettings[dataKey];
  const disabled = options?.editing && fieldSetting?.editable === false;

  const renderFormItem = (
    inputNode: ReactNode,
    additionalProps?: FormItemProps,
    rules?: Rule[],
  ) => {
    const validators: Rule[] = [];
    if (rules) {
      validators.push(...rules);
    }
    if (key === '$key') {
      validators.push({ required: true, message: 'Key is required' });
    }

    return (
      <td {...restProps} key={dataIndex.join('.')}>
        <FormItem
          name={dataIndex}
          fieldKey={dataIndex}
          style={{ margin: 0 }}
          rules={validators}
          className="table-form-item"
          {...additionalProps}
        >
          {inputNode}
        </FormItem>
      </td>
    );
  };

  if (typeof inputType !== 'string') {
    return renderFormItem(
      <EnumSelect
        enum={inputType}
        placeholder={`Select ${title}`}
        style={{ width: '100%', textAlign: 'left' }}
        showSearch
        allowClear
        disabled={disabled}
      />,
    );
  }

  if (
    fieldSetting?.dataType === FieldDataType.Date ||
    fieldSetting?.dataType === FieldDataType.DateTime
  ) {
    return renderFormItem(
      <TimestampDatePicker
        showTime={fieldSetting?.dataType === FieldDataType.DateTime}
        placeholder={`Select ${title}`}
        style={{ width: '100%' }}
        useSeconds
        utcTime
        disabled={disabled}
      />,
    );
  }
  if (fieldSetting?.dataType === FieldDataType.Time) {
    return renderFormItem(
      <TimestampTimePicker
        placeholder={`Select ${title}`}
        style={{ width: '100%' }}
        useSeconds
        utcTime
        disabled={disabled}
      />,
    );
  }
  if (['int', 'int32', 'int64', 'float', 'uint32'].indexOf(inputType) > -1) {
    return renderFormItem(
      <InputNumber placeholder={`Input ${title}`} style={{ width: '100%' }} disabled={disabled} />,
      {},
      [{ type: inputType === 'float' ? 'float' : 'integer' }],
    );
  }
  if (inputType === 'bool') {
    return renderFormItem(<Checkbox disabled={disabled} />, { valuePropName: 'checked' });
  }
  if (inputType === 'json') {
    const handleClick = () => {
      const type = form.getFieldValue([...paths, ...dataIndex.slice(0, -1), 'type_url']);
      if (!type) return;

      anyModal.setSelected({ ...options, paths: [...paths, ...dataIndex], type: { type, id: 1 } });
      anyModal.toggleModal(true);
    };

    const handleClear = () => {
      form.setFields([
        {
          name: [...paths, ...dataIndex],
          value: undefined,
        },
      ]);
    };

    return renderFormItem(
      <JsonInput
        readOnly
        placeholder="Click to edit"
        suffix={<CloseCircleFilled className="hover:text-red-400" onClick={handleClear} />}
        onClick={handleClick}
        disabled={disabled}
      />,
    );
  }
  if (fieldSetting?.dataType === FieldDataType.OdlFile) {
    return renderFormItem(
      <OdlFileSelect
        placeholder={`Select ${title}`}
        style={{ width: '100%' }}
        className="text-left"
        disabled={disabled}
      />,
    );
  }

  return renderFormItem(
    <InputWithValueAsTitle placeholder={`Input ${title}`} disabled={disabled} />,
  );
};

export default EditableCell;
