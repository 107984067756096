import React, { FC } from 'react';
import { Col, Form } from 'antd';
import { Rule } from 'antd/es/form';
import { IEnum } from 'protobufjs';

import { formatLabel } from '@lib/utils/blueprint-data';

import { RefFieldReference } from '../../context';
import EnumSelect from '../../enum-select';

import { RenderOptions } from './single-field';

export type EnumFieldFormItemProps = {
  options: RenderOptions<IEnum>;
  repeated?: boolean;
};

const EnumFieldFormItem: FC<EnumFieldFormItemProps> = ({ options, repeated }) => {
  const { paths, type, key, label, field, fieldSettings, editing } = options;

  const fieldName = field ? [field.name] : [...paths];
  const fieldKey = field ? [field.fieldKey] : [...paths];
  if (!field) {
    fieldName.push(key);
    fieldKey.push(key);
  }
  const dataKey = fieldName.join('.');
  const truthDataKey = field ? key : dataKey;
  const fieldSetting = fieldSettings[truthDataKey];
  const formatedKey = fieldSetting?.displayName ?? formatLabel(key);

  const width = Math.max(350, formatedKey.length * 7.5);

  const required = key === '$key' || fieldSetting?.required;
  const editable = fieldSetting?.editable ?? true;
  const rules: Rule[] = [];
  if (required) {
    rules.push({ required: true, message: `${formatedKey} is required` });
  }

  const refEnumType: RefFieldReference = {
    ...fieldSetting?.reference,
    ...type,
  };

  return (
    <Col span={8} key={dataKey}>
      <Form.Item
        name={fieldName}
        fieldKey={fieldKey}
        label={label ?? formatedKey}
        rules={rules}
        style={{ marginBottom: '0px' }}
      >
        <EnumSelect
          enum={refEnumType}
          mode={repeated ? 'multiple' : undefined}
          placeholder={`Select ${formatedKey}`}
          style={{ width }}
          allowClear
          showSearch
          disabled={editing && !editable}
        />
      </Form.Item>
    </Col>
  );
};

export default EnumFieldFormItem;
