import React, { FC, MouseEventHandler, useEffect, useState } from 'react';
import { Input, InputProps, Tooltip } from 'antd';
import { TextAreaProps } from 'antd/es/input/TextArea';

type JsonProps<T = Element> = {
  value?: any;
  onClick: MouseEventHandler<T>;
};

export type JsonInputProps = Omit<InputProps, 'value' | 'onChange' | 'onClick' | 'title'> &
  JsonProps<HTMLInputElement>;

export type JsonTextAreaProps = Omit<TextAreaProps, 'value' | 'onChange' | 'onClick' | 'title'> &
  JsonProps<HTMLTextAreaElement>;

const JsonInput: FC<JsonInputProps> = ({ value, ...props }) => {
  const [val, setVal] = useState<string>(
    typeof value === 'string' ? value : value && JSON.stringify(value),
  );

  useEffect(() => {
    setVal(typeof value === 'string' ? value : value && JSON.stringify(value));
  }, [value]);

  return (
    <Tooltip title={val}>
      <Input {...props} value={val} />
    </Tooltip>
  );
};

export const JsonTextArea: FC<JsonTextAreaProps> = ({ value, ...props }) => {
  const [val, setVal] = useState(
    typeof value === 'string' ? value : value && JSON.stringify(value),
  );

  useEffect(() => {
    setVal(typeof value === 'string' ? value : value && JSON.stringify(value));
  }, [value]);

  return <Input.TextArea rows={3} {...props} value={val} />;
};

export default JsonInput;
