import React, { FC } from 'react';
import { Collapse } from 'antd';

import { useLevelDataConfigContext } from '@pages/map-level/context';

import GeneralInformation from './general-information/general-information';
import TileForm from './layout/tile-form';
import ChargeBarConfigs from './charge-bar-config';
import TargetAndRewards from './target-rewards';
import TetrominoSpawn from './tetromino-spawn';

type MenuMapsType = {
  title: string;
  children: React.ReactNode;
};

const { Panel } = Collapse;

const LevelData: FC<{ viewOnly: boolean; props: any }> = ({ viewOnly, props }) => {
  const { formTetromino, formTargetAndRewards, formChargeBar } = useLevelDataConfigContext();

  const { width } = props;

  const MENU_MAPS = [
    {
      title: 'General Information',
      children: <GeneralInformation viewOnly={viewOnly} />,
    },
    {
      title: 'Layout',
      children: <TileForm />,
    },
    {
      title: 'Target and Rewards',
      children: <TargetAndRewards form={formTargetAndRewards} viewOnly={viewOnly} />,
    },
    {
      title: 'Tetrimino Spawn',
      children: <TetrominoSpawn form={formTetromino} viewOnly={viewOnly} />,
    },
    {
      title: 'Charge Bar Config',
      children: <ChargeBarConfigs form={formChargeBar} width={width} />,
    },
  ];

  return (
    <div className="overflow-y-scroll" style={{ height: `calc(100% - 1px)` }}>
      <Collapse bordered defaultActiveKey={[0, 1]}>
        {MENU_MAPS.map((menu: MenuMapsType, index: React.Key) => {
          if (viewOnly && menu.title === 'Layout') {
            return null;
          }
          return (
            <Panel header={menu.title} key={index}>
              {menu.children}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default LevelData;
