import React, { FC } from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { PlayerMilestoneProgressionDto, UserRole } from '@lib/services/models';
import { useAuthorized } from '@lib/utils/authorize';

import { useCsPlayerContext } from '../context';

const MilestoneListAction: FC<{
  data: PlayerMilestoneProgressionDto;
}> = ({ data: milestone }) => {
  const authorized = useAuthorized(UserRole.CsEditor);

  const { player, milestoneModal } = useCsPlayerContext();
  const { toggleModal, setSelected } = milestoneModal;

  const handleEdit = () => {
    toggleModal(true);

    setSelected(milestone);
  };

  return (
    <Button.Group>
      <Button
        type="primary"
        icon={<EditOutlined />}
        disabled={!authorized || !player /*  || player.source !== PlayerDataSource.Redis */}
        onClick={handleEdit}
      />
    </Button.Group>
  );
};

export default MilestoneListAction;
