import React, { FC, useCallback, useRef } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Col, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import AgGridReact, { ColDef, GridApi, GridReadyEvent } from '@lib/components/ui/ag-grid';
import { UserRole } from '@lib/services/models';
import { useAuthorized } from '@lib/utils/authorize';

import { useCsPlayerContext } from '../context';

import MilestoneListAction from './list-action';
import MilestoneModal from './modal';

const columnDefs: ColDef[] = [
  {
    headerName: 'ProgressionId',
    field: 'ProgressionId',
  },
  {
    headerName: 'CurrentLevelId',
    field: 'CurrentLevelId',
  },
  {
    headerName: 'CurrentLevelPoints',
    field: 'CurrentLevelPoints',
  },
  // {
  //   headerName: 'Value',
  //   field: 'value',
  //   minWidth: 200,
  //   maxWidth: 200,
  //   valueFormatter: ({ value }) => value?.toLocaleString() ?? 0,
  // },
  {
    headerName: '',
    field: '',
    colId: 'action',
    minWidth: 85,
    maxWidth: 85,
    cellRendererFramework: MilestoneListAction,
  },
];

const MilestoneTab: FC = () => {
  const authorized = useAuthorized(UserRole.CsEditor);

  const { player, milestoneModal } = useCsPlayerContext();
  const { setSelected, toggleModal } = milestoneModal;

  // const [resources, setResources] = useState<T3CurrencyDto[]>([]);
  const { milestones } = useCsPlayerContext();
  const gridApiRef = useRef<GridApi>();

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    gridApiRef.current = e.api;
    e.api.sizeColumnsToFit();
  }, []);

  const handleSearch = useCallback((term?: string) => {
    if (gridApiRef.current) {
      gridApiRef.current.setQuickFilter(term);
    }
  }, []);

  return (
    <>
      <Row className="flex mb-4">
        <Col flex="0 0 220px">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Type to search..."
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
      </Row>
      <ReactResizeDetector
        handleHeight
        render={({ height }) => (
          <div className="flex-auto ag-theme-alpine bordered" style={{ height }}>
            <AgGridReact
              columnDefs={columnDefs}
              rowData={milestones}
              onGridReady={handleGridReady}
            />
          </div>
        )}
      />

      <MilestoneModal />
    </>
  );
};

export default MilestoneTab;
