import React, { FC, useRef } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Button, Col, Form } from 'antd';
import { IField, IType } from 'protobufjs';
import { OrderedListOutlined, PlusOutlined } from '@ant-design/icons';

import { formatLabel } from '@lib/utils/blueprint-data';

import { RefFieldSettings } from '../../../context';
import { RenderOptions } from '../single-field';

import NestedArrayTable from './nested-array';
import SimpleArrayFormItem from './simple-array';

export type ArrayFormItemProps = {
  options: RenderOptions<IField>;
};

const ArrayFormItem: FC<ArrayFormItemProps> = ({ options }) => {
  const { types, paths, type, key, fieldSettings, onFieldsChange } = options;
  const simpleArrayRef = useRef<any>();
  const nestedArrayRef = useRef<any>();

  const fieldName = [...paths, key];
  const dataIndex = fieldName.join('.');
  const fieldSetting = fieldSettings[dataIndex];
  const formatedKey = fieldSetting?.displayName ?? formatLabel(key);

  const newType: IField = { ...type };
  newType.rule = undefined;

  const grid = 4;

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250,
  });

  const nestedFieldSettings = Object.keys(fieldSettings).reduce<Record<string, RefFieldSettings>>(
    (all, field) => {
      if (field.indexOf(dataIndex) === 0) {
        all[field.substr(dataIndex.length + 1)] = fieldSettings[field];
      }
      return all;
    },
    {},
  );

  return (
    <Col flex="0 0 100%" className="mb-3" key={dataIndex}>
      <Form.List name={fieldName}>
        {(fields, { add, remove, move }) => {
          const handleAdd = () => {
            onFieldsChange([{ name: fieldName }], []);
            add();
            if (simpleArrayRef.current) {
              simpleArrayRef.current.goToLastPage();
            }
            if (nestedArrayRef.current) {
              nestedArrayRef.current.goToLastPage();
            }
          };
          const handleRemove = (index: number | number[]) => {
            onFieldsChange([{ name: fieldName }], []);
            remove(index);
          };

          const onDragEnd = (result: any) => {
            // dropped outside the list
            if (!result.destination) {
              return null;
            }

            move(result.source.index, result.destination.index);
          };

          return (
            <>
              <div className="ant-form-item-label">
                <OrderedListOutlined className="mr-2" />
                {formatedKey}
                <Button
                  size="small"
                  className="ml-2"
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={handleAdd}
                >
                  Add
                </Button>
              </div>

              {key === 'MainFlowIds' && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        <SimpleArrayFormItem
                          ref={simpleArrayRef}
                          options={{ ...options, type: newType }}
                          fields={fields}
                          remove={remove}
                          add={add}
                        />
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}

              {(types[type.type] as IType)?.fields ? (
                <NestedArrayTable
                  ref={nestedArrayRef}
                  options={{
                    ...options,
                    type: newType,
                    fieldSettings: nestedFieldSettings,
                  }}
                  fields={fields}
                  onRemoveField={handleRemove}
                />
              ) : (
                key !== 'MainFlowIds' && (
                  <SimpleArrayFormItem
                    ref={simpleArrayRef}
                    options={{ ...options, type: newType }}
                    fields={fields}
                    remove={remove}
                    add={add}
                  />
                )
              )}
            </>
          );
        }}
      </Form.List>
    </Col>
  );
};

export default ArrayFormItem;
