import React, { FC, useEffect, useState } from 'react';
import { Form, InputNumber, Select } from 'antd';
import { FormInstance } from 'antd/es/form';

import { Currency, T3CurrencyDto } from '@lib/services/models';

import { useCsPlayerContext } from '../context';

const CurrencyForm: FC<{ form: FormInstance; obj?: Currency }> = ({ form, obj }) => {
  const { currencies } = useCsPlayerContext();

  const [currency, setCurrency] = useState<T3CurrencyDto>();

  const handleCurrencyChange = (id?: string) => {
    setCurrency(id ? currencies.find((item) => item.type === id) : undefined);
  };

  useEffect(() => {
    if (obj) {
      form.setFieldsValue(obj);
    } else {
      form.resetFields();
    }

    handleCurrencyChange(obj?.currency);
  }, [obj]);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item
        label="Currency"
        name="currency"
        rules={[{ required: true, message: 'Please select currency!' }]}
      >
        <Select<string>
          placeholder="Select currency"
          showSearch
          disabled={!!obj?.currency}
          onChange={handleCurrencyChange}
        >
          {currencies.map((item) => (
            <Select.Option key={item.type} value={item.type}>
              {item.name}
              {item.max && (
                <span className="ml-1">(Max capacity: {item.max.toLocaleString()})</span>
              )}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Amount"
        name="amount"
        rules={[{ required: true, message: 'Please input amount!' }]}
      >
        <InputNumber
          type="number"
          min={0}
          style={{ width: '100%' }}
          placeholder="Input amount"
          max={(currency?.max ?? 0) < 1 ? undefined : currency?.max}
        />
      </Form.Item>
    </Form>
  );
};

export default CurrencyForm;
