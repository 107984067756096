import { useEffect, useState } from 'react';

export const useDocumentTitle = (title: string) => {
  let oldTitle: string;
  const [current, setTitle] = useState(title);

  useEffect(() => {
    oldTitle = document.title;
    return () => {
      document.title = oldTitle;
    };
  }, []);

  useEffect(() => {
    document.title = current;
  }, [current]);

  return { setTitle };
};
