enum SegmentTransitionType {
  Vertical = 1,
  Horizontal = 2,
}

enum TargetType {
  None = 0,
  ClearLines = 1,
  ClearObjects = 2,
  BonusLevel = 3,
}

enum GridType {
  Segment = 1,
  Scrolling = 2,
}

export enum BlockType {
  TileI = 0,
  TileT = 1,
  TileO = 2,
  TileL = 3,
  TileJ = 4,
  TileS = 5,
  TileZ = 6,
}

export enum SmartTetriminoMode {
  // Smart Tetrimino entirely off.
  NONE,
  // will be the main mode for most of the levels
  REGULAR,
  // will give the user the best tetrimino with the consideration of not repeat same tetrimino x number of times.
  HIGH,
  // will give the user the best tetrimino every new spawn
  MAX,
}

enum TileBlock {
  TileI = 52,
  TileT = 53,
  TileO = 54,
  TileL = 55,
  TileJ = 56,
  TileS = 57,
  TileZ = 58,
}

enum Square {
  Blue = 'Blue',
  LightBlue = 'LightBlue',
  Green = 'Green',
  Orange = 'Orange',
  Purple = 'Purple',
  Red = 'Red',
  Yellow = 'Yellow',
}

enum RotationDegree {
  Deg90 = 1,
  Deg180 = 2,
  Deg270 = 3,
}

enum AVAEnviroment {
  QA = 1,
  STG = 2,
  PROD = 3,
}

enum TileType {
  Normal = 0,
  Backpack = 1,
  Seeker = 2,
  Rocket = 3,
  Bomb = 4,
  ColorCube = 5,
  SuitCase = 6,
  Rope2 = 7,
  Pigeon = 8,
  PostCard = 9,
  ComboRocketSeeker = 10,
  ComboBombSeeker = 11,
  ComboBombBomb = 12,
  ComboCubeBomb = 13,
  ComboRocketRocket = 14,
  ComboBombRocket = 15,
  ComboCubeRocket = 16,
  ComboSeekerSeeker = 17,
  ComboCubeSeeker = 18,
  Box = 19,
  Camera = 20,
  Photo = 21,
  BarrelBomb = 22,
  Watermelon = 23,
  WatermelonPiece = 24,
  MiniCube = 25,
  UltraTetrisCube = 26,
  CrossBomb = 27,
  ComboCubeCube = 28,
  ComboHorizontalRocket = 29,
  SeekerCrossBlast = 30,
  HorizontalRocket = 31,
  CrossAOESeeker = 32,
  ComboBarrelSeeker = 33,
  Stone = 34,
  ComboChargedRocket = 35,
  ComboCubeBooster = 36,
  ComboHorizontalRocketSeeker = 37,
  ComboBarrelHorizontalRocket = 38,
  ComboDoubleSeekerRocket = 39,
  ComboCubeHorizontalRocket = 40,
  ComboBarrelBarrel = 41,
  ComboDoubleSeekerCrossBlast = 42,
  ComboMultiRowSeekerRocker = 43,
  ComboDoubleCrossAOESeeker = 44,
  Missile = 45,
  ComboBombSeekerLine = 46,
  Coin = 47,
  BigSuitcase = 48,
  Pigeon22 = 49,
  ClearXLines = 50,
  Rope = 51,
  TileI = 52,
  TileT = 53,
  TileO = 54,
  TileL = 55,
  TileJ = 56,
  TileS = 57,
  TileZ = 58,
  Dynamite = 59,
  ComboDynamiteDynamite = 60,
  ComboBombDynamite = 61,
  ComboCubeDynamite = 62,
  ComboMultiRowSeekerDynamite = 63,
  Box2 = 64,
  PaintBottle = 70,
  PainTile = 71,
  Ice2 = 73,
  CoinMultiHit = 74,
  TicketMachine = 75,
  Ticket = 76,
  CoinGenerator = 77,
  Ice1 = 78,
  Bubble = 113,
}

export {
  SegmentTransitionType,
  AVAEnviroment,
  TileType,
  RotationDegree,
  TargetType,
  GridType,
  TileBlock,
  Square,
};
