import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { IEnum } from 'protobufjs';

import { ICellEditorParams } from '@lib/components/ui/ag-grid';
import EnumSelect from '@lib/pages/blueprint-data/components/enum-select';

export type EnumCellEditorProps = ICellEditorParams & {
  enum: IEnum;
};

const EnumCellEditor = forwardRef<any, EnumCellEditorProps>((props, ref) => {
  const [value, setValue] = useState(props.value);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  return (
    <EnumSelect
      enum={props.enum}
      bordered={false}
      value={value}
      onChange={setValue}
      style={{ width: '100%' }}
    />
  );
});

export default EnumCellEditor;
