import React, { createContext, FC, useContext, useRef, useState } from 'react';

import { PagingAgGridRef } from '@lib/components/paging-grid';
import { useModal } from '@lib/utils/use-modal';

import { T3LeaderBoard } from '@services/_api';

const useLeaderBoardContextValue = () => {
  const [type, setType] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [rangeTime, setRangetime] = useState<any>();
  const [leaderboardId, setLeaderboardId] = useState<string>('');

  const gridRef = useRef<PagingAgGridRef>(null);

  // const list = useMailTemplateList({ id: OrderDirection.DESC });
  const modal = useModal<T3LeaderBoard>();

  return {
    type,
    setType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    rangeTime,
    setRangetime,
    leaderboardId,
    setLeaderboardId,
    // list,
    modal,
  };
};

export type LeaderBoardContext = ReturnType<typeof useLeaderBoardContextValue>;

const leaderBoardContext = createContext<LeaderBoardContext | null>(null);

export const LeaderBoardProvider: FC = ({ children }) => {
  const value = useLeaderBoardContextValue();

  return <leaderBoardContext.Provider value={value}>{children}</leaderBoardContext.Provider>;
};

export const useLeaderBoardContext = () => {
  const ctx = useContext(leaderBoardContext);
  if (!ctx) {
    throw new Error('useLeaderBoardList must be used inside LeaderBoardProvider');
  }

  return ctx;
};
