import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/es/form';

import { Bot } from '@lib/services/models';

const BotForm: FC<{ form: FormInstance; obj?: Bot }> = ({ form, obj }) => {
  const [setEnvironment] = useState<string>();

  useEffect(() => {
    form.resetFields();
    if (obj) {
      setEnvironment(obj.environment);
      form.setFieldsValue({ ...obj, userId: undefined });
    }
  }, [obj]);

  return (
    <Form form={form} layout="vertical" style={{ width: '550px' }}>
      <Form.Item noStyle name="id">
        <Input type="hidden" />
      </Form.Item>

      {/* <LeaderBoardSelectProvider environment={environment}>
        <Row gutter={16}>
          <Col flex="1">
            <Form.Item
              label="Environment"
              name="environment"
              rules={[{ required: true, message: 'Please select Environment!' }]}
            >
              <EnvironmentSelect
                onChange={setEnvironment}
                style={{ width: '100%' }}
                disabled={!!obj}
              />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item
              label="Client Version"
              name="clientVersion"
              rules={[{ required: true, message: 'Please select Client version!' }]}
            >
              <ClientVersionSelect
                style={{ width: '100%' }}
                environment={environment}
                disabled={!!obj}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex="1 1 50%">
            <Form.Item
              label="Event"
              name="eventId"
              rules={[{ required: !obj, message: 'Event is required' }]}
            >
              <LeaderBoardSelect
                style={{ width: '100%' }}
                disabled={!environment}
                onChange={setEventId}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex="1">
            <Form.Item label="Get Segment from Player" name="userId">
              <Input placeholder="Input User Id" disabled={!eventId} />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item label="Or select Segment" name="segment">
              <LeaderBoardSegmentSelect disabled={!eventId} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </LeaderBoardSelectProvider> */}

      <Row gutter={16}>
        <Col flex="1">
          <Form.Item
            label="Update All Bot"
            // rules={[{ required: true, message: 'Please input Score!' }]}
          >
            <Checkbox style={{ width: '100%' }} defaultChecked />
          </Form.Item>
        </Col>
        {/* <Col flex="1">
          {!obj && (
            <Form.Item
              label="Number of Bot"
              name="numberBot"
              rules={[{ required: true, message: 'Please input Number of Bot!' }]}
            >
              <InputNumber placeholder="Input Number of Bot" style={{ width: '100%' }} />
            </Form.Item>
          )}
        </Col> */}
      </Row>
    </Form>
  );
};

export default BotForm;
