import { useState } from 'react';

import { useVisible } from './use-visible';

export type UseModalState<T> = {
  selected: T | undefined;
  modalVisible: boolean;
};

export type UseModalAction<T> = {
  setSelected: (selected?: T) => void;
  toggleModal: (show: boolean) => void;
};

export type UseModal<T> = UseModalState<T> & UseModalAction<T>;

export const useModal = <T>(): UseModal<T> => {
  const [selected, setSelected] = useState<T | undefined>();
  const { visible: modalVisible, toggleVisible: toggleModal } = useVisible();

  return {
    selected,
    setSelected,
    modalVisible,
    toggleModal,
  };
};
