import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { IEnum, IType } from 'protobufjs';

import client from '@lib/services/client';
import { BlueprintDataVersion } from '@lib/services/models';

import { useAuth } from './auth';

export type BlueprintDataVersionContext = {
  versions: BlueprintDataVersion[];
  loading: boolean;
  selected?: BlueprintDataVersion;
  selectedKey?: string;
  setSelected: (key?: string) => BlueprintDataVersion | undefined;
  fetch: () => Promise<BlueprintDataVersion[]>;
};

export type ProtoData = {
  [key: string]: IType | IEnum;
};

export type BlueprintDataVersionTreeNode = BlueprintDataVersion & {
  children?: BlueprintDataVersionTreeNode[];
};

const blueprintDataVersionContext = createContext<BlueprintDataVersionContext | null>(null);
const { Provider } = blueprintDataVersionContext;

export const buildTreeData = (
  blueprintVersions: BlueprintDataVersion[],
): BlueprintDataVersionTreeNode[] => {
  const blueprintMap = blueprintVersions.reduce<Record<string, BlueprintDataVersionTreeNode>>(
    (all, version) => {
      all[version.name] = version;
      return all;
    },
    {},
  );

  blueprintVersions.forEach((version) => {
    if (version.baseVersion && blueprintMap[version.baseVersion]) {
      const base = blueprintMap[version.baseVersion];
      base.children = (base.children ?? []).concat(blueprintMap[version.name]);
    }
  });

  return blueprintVersions
    .filter(({ isBaseVersion }) => isBaseVersion)
    .map(({ name }) => blueprintMap[name]);
};

export const BlueprintDataVersionProvider: FC = ({ children }) => {
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<BlueprintDataVersion[]>([]);
  const [selected, _setSelected] = useState<BlueprintDataVersion>({
    name: 'base',
    isBaseVersion: true,
  });

  const fetch = async () => {
    const res = await client.api.blueprintDataVersionControllerGetAll({
      order: { createdAt: 'DESC' },
    });
    setData(res.data ?? []);
    setLoading(false);
    return res.data ?? [];
  };

  useEffect(() => {
    if (!currentUser) return;
    fetch();
  }, [currentUser]);

  const setSelected = (name?: string) => {
    const obj = data.find((item) => item.name === name);
    if (obj) _setSelected(obj);
    return obj;
  };

  return (
    <Provider
      value={{
        versions: data,
        loading,
        selected,
        selectedKey: selected?.name,
        setSelected,
        fetch,
      }}
    >
      {children}
    </Provider>
  );
};

export const useGlobalBlueprintDataVersionContext = () => {
  const ctx = useContext(blueprintDataVersionContext);
  if (!ctx) {
    throw new Error(
      'useBlueprintDataVersionContext must be used inside BlueprintDataVersionProvider',
    );
  }

  return ctx;
};

export default BlueprintDataVersionProvider;
