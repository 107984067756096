import React, { createContext, FC, useContext, useEffect, useRef, useState } from 'react';

import { PagingAgGridRef } from '@lib/components/paging-grid';
import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';
import { useGlobalBlueprintDataVersionContext } from '@lib/providers/blueprint-version';
import { BlueprintData, GearUser } from '@lib/services/models';
import { useModal } from '@lib/utils/use-modal';

import { blueprintDataService } from '@services/blueprint';

export interface EventDataProto {
  title?: string;
  start: Date;
  end: Date;
  color: string;
}

const useEventCalendarContextValue = () => {
  const modal = useModal<GearUser>();
  const [user, setUser] = useState<string>();
  const {
    environment,
    setEnvironment,
    bucketName,
    setBucketName,
    bucketId,
  } = useBlueprintDataContext();

  const gridRef = useRef<PagingAgGridRef>(null);
  const [eventData, setEventData] = useState<EventDataProto[]>([]);

  const [dab, setDab] = useState<string>('');
  const [variant, setVariant] = useState<string>();

  const {
    selectedKey: version,
    setSelected: setVersion,
    loading: versionLoading,
  } = useGlobalBlueprintDataVersionContext();

  const [loading, setLoading] = useState<boolean>(versionLoading ?? false);

  const randomColor = (): string => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  const fetch = async () => {
    if (version) {
      setLoading(true);

      const eventProtoNames = [
        'com.alleylabs.legendblast.blueprint.Template_SsPassEventBlueprint',
        'com.alleylabs.legendblast.blueprint.Template_MilestonEventBlueprint',
      ];

      eventProtoNames.map(async (name) => {
        const res = await blueprintDataService.getData(version, name);
        const { ok, data } = res;
        if (ok && data?.length) {
          const event = data.reduce((acc: EventDataProto[], item: BlueprintData) => {
            acc.push({
              title: item.data.ProgressionId,
              start: new Date(item.data.Schedule.TimePeriod?.Start),
              end: new Date(item.data.Schedule.TimePeriod?.End),
              color: randomColor(),
            });

            return acc;
          }, []);

          setEventData([...eventData, ...event]);
        } else {
          setEventData([...eventData]);
        }
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    fetch();
  }, [variant]);

  useEffect(() => {
    setVariant(undefined);
    fetch();
  }, [version]);

  return {
    gridRef,
    modal,
    user,
    setUser,
    environment,
    setEnvironment,
    dab,
    setDab,
    version,
    eventData,
    loading,
    setLoading,
    bucketId,
    setBucketName,
    bucketName,
    fetch,
  };
};

export type EventCalendarContext = ReturnType<typeof useEventCalendarContextValue>;

const eventCalendarContext = createContext<EventCalendarContext | null>(null);

export const EventCalendarProvider: FC = ({ children }) => {
  const value = useEventCalendarContextValue();

  return <eventCalendarContext.Provider value={value}>{children}</eventCalendarContext.Provider>;
};

export const useEventCalendarContext = () => {
  const ctx = useContext(eventCalendarContext);
  if (!ctx) {
    throw new Error('useUserList must be used inside UserProvider');
  }

  return ctx;
};
