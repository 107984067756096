import React, { FC, useEffect } from 'react';
import { Col, Divider, Row } from 'antd';

import ChargeBarCollectionProvider from '@providers/charge-bar-collection';
import MapLevelCollectionProvider from '@providers/map-level-collection';
import ObjectCollectionProvider from '@providers/object-collection';

import LayoutGrid from './components/layout-grid';
import LevelData from './components/level-data';
import { DEFAULT_TILE } from './components/level-data/layout/tile-select';
import { useLevelDataConfigContext } from './context';

import './tile-select.css';

const MapLevelScreen: FC<{ props?: any; viewOnly?: boolean }> = ({ viewOnly }) => {
  const { setSelectedTile } = useLevelDataConfigContext();

  useEffect(() => {
    if (viewOnly) {
      setSelectedTile(undefined);
    } else {
      setSelectedTile(DEFAULT_TILE);
    }
  }, [viewOnly]);

  return (
    <MapLevelCollectionProvider>
      <ObjectCollectionProvider>
        <ChargeBarCollectionProvider>
          <Row className="relative h-full" gutter={32}>
            <Col span={8}>
              <div className="flex flex-col">
                <Divider plain orientation="left">
                  Layout Grid
                </Divider>
                <LayoutGrid viewOnly={viewOnly ?? false} />
              </div>
            </Col>
            <hr
              className="absolute w-screen"
              style={{
                height: '2px',
                borderWidth: 1.5,
                top: -9,
              }}
            />
            <Col
              span={16}
              className="overflow-y-scroll"
              style={{
                height: `100%`,
                borderLeft: 'solid 0.5px',
                borderLeftColor: 'gray',
              }}
            >
              {/* <Divider plain orientation="left">
              Level Data
            </Divider> */}
              <LevelData viewOnly={viewOnly ?? false} props />
            </Col>
          </Row>
        </ChargeBarCollectionProvider>
      </ObjectCollectionProvider>
    </MapLevelCollectionProvider>
  );
};

export default MapLevelScreen;
