import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { message } from 'antd';

import { getDataAVA } from '@lib/services/blueprint/ava/ava-connect.service';

import { useAuth } from './auth';

export type AvaConfigContext = {
  avaConfig: AvaInfos;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetch: () => Promise<void>;
};

const avaConfigContext = createContext<AvaConfigContext | null>(null);
const { Provider } = avaConfigContext;

export interface BucketInfo {
  inAppId: string;
  id: number;
}

export interface DabInfo {
  inAppId: string;
  applicationIds: number[];
}

export interface AvaConfigData {
  dabs: DabInfo[];
  buckets: BucketInfo[];
}

export type AvaInfos = {
  [key: string]: AvaConfigData;
};

export const AvaConfigProvider: FC = ({ children }) => {
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(true);
  const [avaConfig, setAvaConfig] = useState<AvaInfos>({});

  const fetch = async () => {
    let res;
    try {
      res = await getDataAVA();
    } catch (error) {
      message.error('Connect Ava Fail!');
      return;
    }
    if (!res.ok) {
      message.error('Loading Ava Failured.Check your Ava Token!');
      return;
      // throw new Error('Loading Ava Failured.Check your Ava Token');
    }

    let datas: any = [];
    if (res && res.ok && res?.data && Array.isArray(res.data)) {
      datas = res?.data?.reduce((acc: AvaInfos, item: any) => {
        const key = item.name.slice(3).toLowerCase();

        // get dab info from ava config
        const dabInfos = item.dabs.reduce((subAcc: DabInfo[], dab: any) => {
          subAcc.push({
            inAppId: dab.inAppId,
            applicationIds: dab.dabApplicationMappings.map((app: any) => Number(app.applicationId)),
          });

          return subAcc;
        }, []);

        acc[key] = {
          dabs: dabInfos,
          buckets: item.applications.map(
            // eslint-disable-next-line array-callback-return
            (app: { id: number; archived: boolean; inAppId: string }) => {
              return { inAppId: app.inAppId, id: app.id };
            },
          ),
        };

        return acc;
      }, {});
      setAvaConfig(datas);
      setLoading(false);
    }
    // else {
    //   message.warning('Loading Ava Failured.Check your Ava Token');
    // }
  };

  useEffect(() => {
    if (!currentUser) return;
    fetch();
  }, [currentUser]);

  return (
    <Provider
      value={{
        avaConfig,
        loading,
        setLoading,
        fetch,
      }}
    >
      {children}
    </Provider>
  );
};

export const useAvaConfigContext = () => {
  const ctx = useContext(avaConfigContext);
  if (!ctx) {
    throw new Error('useAvaConfigContext must be used inside AvaConfigProvider');
  }

  return ctx;
};

export default AvaConfigProvider;
