import React, { FC } from 'react';
import { Button, Col, Form } from 'antd';
import { IField } from 'protobufjs';
import { CloseCircleFilled, EditOutlined } from '@ant-design/icons';

import { useBlueprintDataContext } from '../../../context';
import { RenderOptions } from '../single-field';

import { JsonTextArea } from './json-input';

export type JsonFormFieldProps = {
  options: RenderOptions<IField>;
  width?: number;
};

const JsonFormField: FC<JsonFormFieldProps> = ({ options, width = 440 }) => {
  const { anyModal } = useBlueprintDataContext();
  const { toggleModal, setSelected } = anyModal;

  const { paths, field, key, form } = options;

  const fieldName = field ? [field.name] : [...paths];
  const fieldKey = field ? [field.fieldKey] : [...paths];

  const handleClick = () => {
    const type = form.getFieldValue([...fieldName, 'type_url']);
    if (!type) return;

    setSelected({ ...options, paths: [...paths, key], type: { type, id: 1 } });
    toggleModal(true);
  };

  const handleClear = () => {
    form.setFields([
      {
        name: [...paths, key],
        value: undefined,
      },
    ]);
  };

  const label = (
    <div style={{ width }} className="flex">
      <Button type="link" size="small" style={{ height: 22, border: 'none' }} onClick={handleClick}>
        Value <EditOutlined className="ml-2" />
      </Button>
      <Button
        type="link"
        danger
        size="small"
        className="ml-auto"
        style={{ height: 22, border: 'none' }}
        icon={<CloseCircleFilled />}
        onClick={handleClear}
      >
        Clear
      </Button>
    </div>
  );

  return (
    <Col flex={`0 0 ${width}px`} key={[...fieldName, key].join('.')}>
      <Form.Item label={label} name={[...fieldName, key]} fieldKey={[...fieldKey, key]}>
        <JsonTextArea readOnly onClick={handleClick} placeholder="Click to edit" />
      </Form.Item>
    </Col>
  );
};

export default JsonFormField;
