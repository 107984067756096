import React, { FC, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Col, Drawer, Form, message, Modal, Row, Tabs, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';
import SubmitAvaForm from '@lib/pages/blueprint-data/components/submit-ava';

import MapLevelScreen from '@pages/map-level';
import { useLevelDataConfigContext } from '@pages/map-level/context';
import { useSimulatorContext } from '@pages/simulator/context';
import { blueprintDataService } from '@services/blueprint';
import { submitAVA } from '@services/blueprint/ava-connect.service';
import { simulatorService } from '@services/simulator';

import { SimulatorComponent } from '../simulator';

import Result from './result';

export const MAPLEVEL_COLLECTION = 'com.alleylabs.legendblast.blueprint.MapLevelCollection';

export enum TabId {
  ResultTab = 'result',
  LevelConfigTab = 'levelConfig',
}

const VERSION = 'base';
const TYPE = 'com.alleylabs.legendblast.blueprint.MapLevelCollection';

const LevelRuntestResult: FC = () => {
  const [form] = Form.useForm();

  const [confirm] = useState(false);

  const [open, setOpen] = useState(false);
  const [openRawData, setOpenRawData] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);
  const [openModalSubmit, setOpenModalSubmit] = useState<boolean>(false);
  const [exporting, setExporting] = useState(false);
  const { compare, setActive, backPageLevelRuntestResult, dataSelected } = useSimulatorContext();
  const { levelData } = useLevelDataConfigContext();
  const [loading, setLoading] = useState(false);
  const [inAppId, setInAppId] = useState('');

  const {
    environment,
    bucketId,
    bucketName,
  } = useBlueprintDataContext();

  const {
    resultSeleted,
    isBackToListRuntestByLevel,
    setIsBackToListRuntestByLevel,
    setCompare,
    viewDetailSelected,
  } = useSimulatorContext();

  const LevelConfig: FC = () => {
    return (
      <div className="flex flex-col flex-1 max-h-full overflow-hidden">
        <MapLevelScreen viewOnly />
      </div>
    );
  };

  const tabs = [
    {
      key: TabId.ResultTab,
      label: 'Result',
      component: Result,
    },
    {
      key: TabId.LevelConfigTab,
      label: 'Level Config',
      component: LevelConfig,
    },
  ];

  const exit = () => {
    if (isBackToListRuntestByLevel) {
      setActive(SimulatorComponent.ListRuntestResultByLevelComponent);
      setIsBackToListRuntestByLevel(false);
    } else setActive(backPageLevelRuntestResult);
  };

  const handleClick = async () => {
    setOpenRawData(true);

    setLoading(true);
    // const levelResult = props.data as LevelsTestResult & { levelId: string };

    const res = await simulatorService.getRawData(viewDetailSelected?.requestId ?? '');
    let rawData = 'Can not found. Please check again';
    let config = 'Can not found. Please check again';

    if (res.ok && res.data) {
      const { data, configs } = res.data as any;
      rawData = JSON.stringify(data, null, 2);
      config = JSON.stringify(configs, null, 2);
    }

    setCompare((cur: any) => {
      cur.rawData = rawData;
      cur.config = config;

      return { ...cur };
    });
    setLoading(false);
  };

  const handlePushAVA = async (values: any) => {
    try {
      setExporting(true);
      if (levelData) {
        const res = await submitAVA(
          VERSION,
          TYPE,
          values.inAppId,
          values.description,
          bucketId,
          environment as string,
          JSON.stringify([levelData?.id]),
          bucketName as string,
        );

        if (res.ok) {
          message.success(`Push data to AVA successfully`);
        }
      }
    } catch (error) {
      message.error('Push error please check again');
    } finally {
      setExporting(false);
      setOpenModalSubmit(false);
    }
  };

  const saveConfig = async () => {
    try {
      const res = await blueprintDataService.storeBatchData(VERSION, [
        {
          type: TYPE,
          version: VERSION,
          data: levelData,
        },
      ]);
      if (res.ok && res.data) {
        message.success('Replace LevelConfig successfully');
      }
      setOpen(false);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    if (resultSeleted) {
      form.setFieldsValue({ levelId: compare.levelId });
      form.setFieldsValue(resultSeleted);
    }
  }, [resultSeleted]);

  useEffect(() => {
    if (levelData) {
      setInAppId(`LevelConfig_${levelData.id}`);
    }
  }, [levelData]);

  const TitleRawData = () => {
    return (
      <>
        {'Raw Data   '}
        <CopyToClipboard
          text={compare?.rawData ?? ''}
          onCopy={(val, result) => {
            message.success('Copied');
            // eslint-disable-next-line no-console
            console.log(val);
            // eslint-disable-next-line no-console
            console.log(result);
          }}
        >
          <Button icon={<CopyOutlined />} />
        </CopyToClipboard>
      </>
    );
  };

  const TitleConfig = () => {
    return (
      <>
        {'Config   '}
        <CopyToClipboard
          text={compare?.config ?? ''}
          onCopy={(val, result) => {
            message.success('Copied');
            // eslint-disable-next-line no-console
            console.log(val);
            // eslint-disable-next-line no-console
            console.log(result);
          }}
        >
          <Button icon={<CopyOutlined />} />
        </CopyToClipboard>
      </>
    );
  };

  return (
    <>
      {inAppId && (
        <SubmitAvaForm
          bluePrintData={{
            version: VERSION,
            type: TYPE,
            data: levelData,
          }}
          open={openModalSubmit}
          title="Submit Level Design to AVA"
          inAppId={inAppId}
          // inAppId="LevelConfig_1997_Dung_Test"
          description="Level Design Pushed by GearTool"
          configGroupSearch="Game -"
          loading={exporting}
          onCreate={handlePushAVA}
          onCancel={() => {
            setOpenModalSubmit(false);
          }}
        />
      )}
      <Modal
        title="Confirm"
        visible={open}
        onOk={saveConfig}
        okText="Confirm"
        cancelText="Cancel"
        onCancel={() => setOpen(false)}
      >
        <div>
          <p>This action will replace current LevelConfig !!!</p>
        </div>
      </Modal>

      <Drawer
        title={<TitleRawData />}
        onClose={() => setOpenRawData(false)}
        width="50%"
        visible={openRawData}
        footer={null}
      >
        <pre>{compare?.rawData ?? ''}</pre>
      </Drawer>

      <Drawer
        title={<TitleConfig />}
        onClose={() => setOpenConfig(false)}
        visible={openConfig}
        width={640}
        footer={null}
      >
        <pre>{compare?.config ?? ''}</pre>
      </Drawer>
      <div className="flex">
        <Typography.Title level={3}>Level Runtest Result</Typography.Title>
        <div className="ml-auto flex gap-x-3">
          <Button
            type="primary"
            onClick={() => {
              if (confirm && dataSelected && dataSelected?.length > 0) {
                setActive(SimulatorComponent.BoardComponent);
                // setBoardTabActive(TabId.OngoingTab);
              } else {
                setOpen(true);
              }
            }}
          >
            Save Config
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setOpenModalSubmit(true);
            }}
          >
            Push to AVA
          </Button>
          <Button
            type="primary"
            ghost
            onClick={() => {
              handleClick();
            }}
          >
            View Raw Data
          </Button>
          <Button
            type="primary"
            ghost
            onClick={() => {
              setOpenConfig(true);
            }}
          >
            View Config
          </Button>
          <Button onClick={exit}>Exit</Button>
        </div>
      </div>
      <Tabs className="h-full" defaultActiveKey={TabId.ResultTab} size="middle">
        {tabs.map((tab) => {
          return (
            <Tabs.TabPane tab={tab.label} key={tab.key}>
              <Row className="level-runtest-result" style={{ minHeight: 400 }}>
                <Col span={24}>
                  <tab.component
                    form={form}
                    winRate={compare.winRate}
                    winRate2={compare.winRate2}
                    movesAndExtra={compare.movesAndExtra}
                    percentileMoveRemain={compare.percentileMoveRemain}
                    moveWin={compare.moveWin}
                    clearLines={compare.clearLines}
                    percentileMoveUsed={compare.percentileMoveUsed}
                    target={compare.target}
                    igb={compare.igb}
                    powerUps={compare.powerUps}
                    powerUps2={compare.powerUps2}
                    lineClear={compare.lineClear}
                    isShowResult
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </>
  );
};

export default LevelRuntestResult;
