import React, { FC, useState } from 'react';
import { Button, message, Popover } from 'antd';

import { useLevelDataConfigContext } from '../context';

export type ButtonStagesProps = {
  stageNumber: number;
  curStageNumber: number;
  onClickStageButton?: (stageNumber: number) => void;
};

const ButtonStage: FC<ButtonStagesProps> = ({
  stageNumber,
  onClickStageButton,
  curStageNumber,
}) => {
  const [open, setOpen] = useState(false);
  const { copyStage, stageCopy, pasteStage } = useLevelDataConfigContext();

  const handleClick = (stage: number) => {
    setOpen(true);
    if (onClickStageButton) {
      onClickStageButton(stage);
    }
  };

  const handleCopy = () => {
    copyStage(stageNumber);
    setOpen(false);
    message.success('Copied');
  };

  const handlePaste = () => {
    pasteStage(stageNumber);
    setOpen(false);
    message.success('Paste Successfully');
  };

  // const handleMouseDown = (e: any) => {
  //   // eslint-disable-next-line no-debugger
  //   debugger;
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  const content = () => {
    return (
      <div className="text-center justify-center items-center gap-x-1">
        <Button onClick={handleCopy}>Copy</Button>
        {stageCopy && <Button onClick={handlePaste}>Paste</Button>}
      </div>
    );
  };

  return (
    <>
      <Popover visible={open} content={content} title="Trigger" trigger="Click">
        <Button
          style={{ width: 44 }}
          onClick={() => handleClick(stageNumber)}
          type={curStageNumber === stageNumber ? 'primary' : 'default'}
          // onMouseDown={(e) => {
          //   handleMouseDown(e);
          // }}
        >
          {stageNumber}
        </Button>
      </Popover>
    </>
  );
};

export default ButtonStage;
