import client from '@lib/services/client';

const {
  blueprintDataChangeLogControllerGetChangeLogsFromLastDeploy,
  blueprintDataChangeLogControllerGetChangeLogsBetweenDeploys,
} = client.api;

export const getChangeLogs = (version: string, type: string) =>
  client.api.blueprintDataChangeLogControllerGetAll({
    where: { version, type },
  });

export const getObjectChangeLogs = (version: string, type: string, objId: string) =>
  client.api.blueprintDataChangeLogControllerGetAll({
    where: { version, type, objId },
  });

export const getChangeLogsBetweenDeploys = (
  params: Parameters<typeof blueprintDataChangeLogControllerGetChangeLogsBetweenDeploys>[0],
) => client.api.blueprintDataChangeLogControllerGetChangeLogsBetweenDeploys(params);

export const getChangeLogsFromLastDeploy = (
  params: Parameters<typeof blueprintDataChangeLogControllerGetChangeLogsFromLastDeploy>[0],
) => blueprintDataChangeLogControllerGetChangeLogsFromLastDeploy(params);
