import React, { FC } from 'react';

import { useAuth } from '@lib/providers/auth';
import { UserRole, UserRoles } from '@lib/services/models';

export const authorized = (userRole: UserRoles, requiredRole: UserRole, environment?: string) => {
  const envs = userRole[requiredRole];
  return envs && (environment ? envs.includes(environment) : true);
};

export const useAuthorized = (requiredRole: UserRole, environment?: string) => {
  const { userRoles } = useAuth();
  return !!userRoles && authorized(userRoles, requiredRole, environment);
};

export const Authorized: FC<{ role: UserRole; environment?: string }> = ({
  role,
  environment,
  children,
}) => {
  const { userRoles } = useAuth();
  if (!userRoles || !authorized(userRoles, role, environment)) return null;

  return <>{children}</>;
};
