import React, { FC, useState } from 'react';
import { Button, Tabs } from 'antd';
import moment from 'moment';

import { useAuth } from '@lib/providers/auth';

import { RuntestStatusClient, useSimulatorContext } from '@pages/simulator/context';

import Draft from '../component/draft';
import Ongoing from '../component/runtest-ongoing/ongoing';
import Result from '../component/runtest-result';
import { CompareForm } from '../component/runtest-result/list-runtest-result-by-level';
import Stopped from '../component/runtest-stopped';
import { SimulatorComponent } from '../simulator';

const { TabPane } = Tabs;

export function generatePrefixedID() {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = String(now.getFullYear()).slice(-2);
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  const prefixedID = `Runtest_${day}${month}${year}_${hours}${minutes}${seconds}`;
  return prefixedID;
}

const Board: FC = () => {
  const {
    setActive,
    boardTabActive,
    setBoardTabActive,
    setDataSelected,
    formCreate,
    onCompare,
    setIsBackToBoard,
  } = useSimulatorContext();

  const [openCompare, setOpenCompare] = useState(false);

  const { currentUser } = useAuth();

  const tabs = [
    {
      key: RuntestStatusClient.RUNTEST_RESULT,
      label: 'Runtest Result',
      component: Result,
    },
    {
      key: RuntestStatusClient.ONGOING,
      label: 'Runtest Ongoing',
      component: Ongoing,
    },
    {
      key: RuntestStatusClient.STOP,
      label: 'Runtest Stopped',
      component: Stopped,
    },
    {
      key: RuntestStatusClient.DRAFT,
      label: 'Draft Runtest',
      component: Draft,
    },
  ];
  const onCreate = async () => {
    formCreate.setFieldsValue({
      runtestId: generatePrefixedID(),
      performer: currentUser?.email,
      dateRuntest: moment(),
      tags: undefined,
      attempts: 1,
      botSkillLevel: 1,
      maxExtraMoves: 0,
      useExtraMoves: false,
      usePGB: false,
      missiles: false,
      bomb: false,
      cube: false,
      useIGB: false,
    });
    setDataSelected([]);
    setActive(SimulatorComponent.CreateComponent);
  };

  const onChange = (e: number) => {
    setBoardTabActive(e);
  };

  const compare = async (values: any) => {
    setIsBackToBoard(true);
    await onCompare(values);
    setOpenCompare(false);
  };

  return (
    <>
      <CompareForm
        open={openCompare}
        onCompare={compare}
        onCancel={() => {
          setOpenCompare(false);
        }}
      />
      <div className="flex bg-white">
        <div className="ml-auto">
          <Button
            className="mr-3"
            onClick={() => {
              setOpenCompare(true);
            }}
          >
            Compare Result
          </Button>
          <Button type="primary" onClick={onCreate}>
            Create
          </Button>
        </div>
      </div>
      <Tabs
        defaultActiveKey={boardTabActive.toString()}
        onChange={(e: any) => onChange(e)}
        size="middle"
      >
        {tabs.map((tab) => {
          return (
            <TabPane tab={tab.label} key={tab.key}>
              <tab.component />
            </TabPane>
          );
        })}
      </Tabs>
    </>
  );
};

export default Board;
