import React, { FC, useState } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input/TextArea';

export type InputWithValueAsTitle = Omit<TextAreaProps, 'title'>;

const InputWithValueAsTitle: FC<InputWithValueAsTitle> = (props) => {
  const { value: _value, onChange } = props;

  const value = typeof _value === 'object' ? JSON.stringify(_value) : _value;

  const [title, setTitle] = useState<any>(_value);
  const [focus, setFocus] = useState(false);

  const handleChange: TextAreaProps['onChange'] = (e) => {
    setTitle(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Input.TextArea
      {...props}
      value={value}
      title={title?.toString()}
      rows={focus ? 3 : 1}
      onChange={handleChange as any}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    />
  );
};

export default InputWithValueAsTitle;
