import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Checkbox, Divider, Form, message, Modal, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Rule } from 'antd/lib/form';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import DabSelect from '@lib/components/dab-select';
import EnvironmentSelect from '@lib/components/env-select';
import { ColDef } from '@lib/components/ui/ag-grid';
import { PagingOptions } from '@lib/services/base/paginate';
import { botService } from '@lib/services/bot-manage';
import { Bot, UserRole } from '@lib/services/models';
import { useAuthorized } from '@lib/utils/authorize';
import { useQuery } from '@lib/utils/use-query';

import { useBotContext } from './context';
import BotListAction from './list-action';

const colDefs: ColDef[] = [
  {
    headerName: '#',
    field: 'id',
    minWidth: 60,
    maxWidth: 60,
  },
  {
    headerName: 'Environment',
    field: 'environment',
    minWidth: 120,
    maxWidth: 120,
    valueFormatter: ({ value }: { value: string }) => value?.toUpperCase(),
  },
  {
    headerName: 'User Id',
    field: 'userId',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    headerName: 'Player Id',
    field: 'playerId',
    minWidth: 200,
    maxWidth: 200,
  },
  {
    headerName: 'Client Version',
    field: 'clientVersion',
    minWidth: 120,
    maxWidth: 120,
  },

  {
    headerName: 'Leader Board',
    field: 'leaderBoard',
    minWidth: 200,
  },
  {
    headerName: 'Score',
    field: 'score',
    minWidth: 80,
    maxWidth: 80,
  },
  {
    colId: 'action',
    headerName: '',
    minWidth: 80,
    maxWidth: 80,
    cellRendererFramework: BotListAction,
  },
];

const BotPageBody: FC = () => {
  const history = useHistory();
  const query = useQuery();

  const authorized = useAuthorized(UserRole.CsEditor);

  const { gridRef, modal } = useBotContext();
  const { setSelected, toggleModal } = modal;

  const [environment, setEnvironment] = useState<string>();
  const [dabName, setDabName] = useState<string>();
  const [version, setVersion] = useState<string>();
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const pagingOptionsRef = useRef<PagingOptions<Bot>['where']>({});

  const updateQuery = () => {
    const queries = [environment && `environment=${environment}`, version && `version=${version}`]
      .filter(Boolean)
      .join('&');

    history.push({
      pathname: history.location.pathname,
      search: `?${queries}`,
    });
  };

  const handleUpdateAll = () => {
    // setSelected();
    // toggleModal(true);
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to update all bot in ${environment}`,
      okButtonProps: { danger: true },
      onOk: async () => {
        if (!environment || !dabName) return;
        const res = await botService.updateAllBot(environment, dabName, forceUpdate);
        if (res) {
          message.success(`Update All Bot successfully`);
        }
      },
    });
  };

  useEffect(() => {
    if (query.get('version')) {
      setVersion(query.get('version') ?? undefined);
    }
    if (query.get('environment')) {
      setEnvironment(query.get('environment') ?? undefined);
    }
    if (query.get('dabName')) {
      setDabName(query.get('dabName') ?? undefined);
    }
  }, []);

  const onChangeForceUpdate = (e: CheckboxChangeEvent) => {
    setForceUpdate(e.target.checked);
  };

  useEffect(() => {
    if (pagingOptionsRef.current) {
      pagingOptionsRef.current.environment = environment;
      pagingOptionsRef.current.clientVersion = version;
    }
    if (gridRef.current) {
      gridRef.current.fetch();
    }
    updateQuery();
  }, [environment, version]);

  const validationSchema: Record<string, Rule[]> = {
    env: [{ required: true, message: 'Please select environment!' }],
    dabName: [{ required: true, message: 'Please select dab name!' }],
    // bucket: [{ required: true, message: 'Please input bucket!' }],
  };

  const [form] = Form.useForm();

  return (
    <Card
      className="flex flex-col flex-1 min-h-full"
      bordered={false}
      bodyStyle={{ padding: 16, display: 'flex', flexDirection: 'column' }}
    >
      <div className="flex ">
        <Form layout="vertical" className="mr-auto" form={form}>
          <Space>
            <Form.Item label="Environment" name="environment" rules={validationSchema.env}>
              <EnvironmentSelect
                onChange={setEnvironment}
                value={environment}
                placeholder="Select environment"
                style={{ minWidth: 250 }}
              />
            </Form.Item>
            <Form.Item
              label="Dab name"
              name="dabName"
              rules={validationSchema.dabName}
              style={{ minWidth: 250 }}
            >
              <DabSelect value={dabName} allowClear onChange={setDabName} />
            </Form.Item>

            <Form.Item label="Force Update" style={{ minWidth: 250 }}>
              <Checkbox defaultChecked onChange={onChangeForceUpdate} />
            </Form.Item>

            {/* <ClientVersionSelect
              environment={environment}
              disabled={!environment}
              value={pagingOptionsRef.current?.clientVersion as string}
              onChange={setVersion}
              allowClear
              style={{ minWidth: 200 }}
            /> */}
            {/* <Button danger icon={<DeleteOutlined />} disabled={!authorized}>
              Remove
            </Button> */}
            <Button
              type="primary"
              // icon={<PlusOutlined />}
              disabled={!authorized}
              onClick={handleUpdateAll}
            >
              Update All
            </Button>
          </Space>
          <Divider type="vertical" />
          {/* <ReloadOutlined onClick={() => gridRef.current?.fetch()} /> */}
        </Form>
      </div>

      {/* <ReactResizeDetector
        handleHeight
        render={({ height }) => (
          <div className="flex-auto ag-theme-alpine bordered" style={{ height }}>
            <PagingAgGrid<Bot>
              request={botService.paging}
              requestParams={{ where: pagingOptionsRef.current }}
              actionRef={gridRef}
              defaultColDef={{
                sortable: true,
                tooltipValueGetter: ({ valueFormatted }) => valueFormatted,
              }}
              columnDefs={colDefs}
              enableBrowserTooltips
              pagination
              paginationPageSize={25}
            />
          </div>
        )}
      /> */}
    </Card>
  );
};

export default BotPageBody;
