export interface ClientConfigResponseDto {
  googleClientId: string;
  appIcon?: string;
}

export enum ConfigDataType {
  String = "string",
  Url = "url",
  Number = "number",
  ListString = "list-string",
  Boolean = "boolean",
  Image = "image",
  Select = "select",
}

export interface SystemConfig {
  key: string;
  title: string;
  description?: string;
  group?: string;
  dataType?: ConfigDataType;
  configurable?: boolean;
  required?: boolean;
  value?: string;
  options?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface SystemConfigDto {
  key: string;
  value?: string;
}

export interface StringOperators {
  in?: string[];
  nin?: string[];
}

export interface NumberOperators {
  in?: number[];
  nin?: number[];
  gt?: number;
  gte?: number;
  lt?: number;
  lte?: number;
}

export interface DateOperators {
  gt?: string;
  gte?: string;
  lt?: string;
  lte?: string;
}

export interface BlueprintProtoUpdateHistoryWhereOptions {
  id?: number | NumberOperators;
  sha?: string | StringOperators;
  note?: string | StringOperators;
  status?: "UPDATING" | "SUCCESS" | "FAILED" | "TIMEOUT" | StringOperators;
  source?: "GITLAB" | "GITHUB" | StringOperators;
  proto?: string | StringOperators;
  userEmail?: string | StringOperators;
  userAvatar?: string | StringOperators;
}

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export interface BlueprintProtoUpdateHistoryOrderOptions {
  id?: OrderDirection;
  sha?: OrderDirection;
  note?: OrderDirection;
  status?: OrderDirection;
  source?: OrderDirection;
  proto?: OrderDirection;
  userEmail?: OrderDirection;
  userAvatar?: OrderDirection;
}

export interface BlueprintProtoUpdateHistoryPagingFilterOptions {
  select?: ("id" | "sha" | "note" | "status" | "source" | "proto" | "userEmail" | "userAvatar")[];
  where?: BlueprintProtoUpdateHistoryWhereOptions;
  order?: BlueprintProtoUpdateHistoryOrderOptions;
  skip?: number;
  take?: number;
}

export interface BlueprintProtoUpdateHistory {
  id?: number;
  sha: string;
  note?: string;
  status: "UPDATING" | "SUCCESS" | "FAILED" | "TIMEOUT";
  source: "GITLAB" | "GITHUB";

  /** Raw proto structure in JSON */
  proto?: string;
  userEmail: string;
  userAvatar?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface BlueprintProtoUpdateHistoryPagingResult {
  total: number;
  skip: number;
  take: number;
  data: BlueprintProtoUpdateHistory[];
}

export interface VariantSetting {
  name?: string;
  key: string;
}

export interface FieldReference {
  type: string;
  valueField: string;
  displayField?: string;
  allowInput?: boolean;
}

export enum FieldDataType {
  Date = "date",
  Time = "time",
  DateTime = "date-time",
  Textarea = "textarea",
  OdlFile = "odl-file",
  Url = "url",
  Rrule = "rrule",
}

export interface FieldSettings {
  field: string;
  reference?: FieldReference;
  required?: boolean;
  editable?: boolean;
  dataType?: FieldDataType;
  anyTypes?: string[];
  displayName?: string;
  ienumerableCase?: string;
  isRSC?: boolean;
  allowedRSC?: string;
  optional?: boolean;
}

export interface BlueprintProtoWhereOptions {
  /** Indicate Blueprint has multiple variants */
  isVariant?: boolean;
  variants?: VariantSetting[];
  isSingle?: boolean;
  idFields?: string[];
  settings?: FieldSettings[];

  /** Indicate a protobuf message is disabled */
  enabled?: object;
  name?: string | StringOperators;
  type?: string | StringOperators;
  shortName?: string | StringOperators;
  groupName?: string | StringOperators;

  /** Raw proto structure in JSON */
  proto?: object;

  /** Indicate a protobuf message represent an Enum */
  isEnum?: boolean;

  /** Indicate a protobuf message represent a Blueprint */
  isBlueprint?: boolean;
}

export interface BlueprintProtoOrderOptions {
  isVariant?: OrderDirection;
  variants?: OrderDirection;
  isSingle?: OrderDirection;
  idFields?: OrderDirection;
  settings?: OrderDirection;
  enabled?: OrderDirection;
  name?: OrderDirection;
  type?: OrderDirection;
  shortName?: OrderDirection;
  groupName?: OrderDirection;
  proto?: OrderDirection;
  isEnum?: OrderDirection;
  isBlueprint?: OrderDirection;
}

export interface BlueprintProtoPagingFilterOptions {
  select?: (
    | "isVariant"
    | "variants"
    | "isSingle"
    | "idFields"
    | "settings"
    | "enabled"
    | "name"
    | "type"
    | "shortName"
    | "groupName"
    | "proto"
    | "isEnum"
    | "isBlueprint"
  )[];
  where?: BlueprintProtoWhereOptions;
  order?: BlueprintProtoOrderOptions;
  skip?: number;
  take?: number;
}

export interface BlueprintProto {
  /** Indicate Blueprint has multiple variants */
  isVariant?: boolean;
  variants?: VariantSetting[];
  isSingle?: boolean;
  idFields?: string[];
  settings?: FieldSettings[];

  /** Indicate a protobuf message is disabled */
  enabled?: object;

  /** Protobuf message type name */
  name: string;

  /** Protobuf message type */
  type: string;
  shortName: string;
  groupName?: string;

  /** Raw proto structure in JSON */
  proto: object;

  /** Indicate a protobuf message represent an Enum */
  isEnum?: boolean;

  /** Indicate a protobuf message represent a Blueprint */
  isBlueprint?: boolean;
  protoUpdateId?: number;
  syncVariant?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface CloneBlueprintProtoDto {
  name: string;
  shortName: string;
  oldName: string;
}

export interface BlueprintDataVersionWhereOptions {
  name?: string | StringOperators;
  isBaseVersion?: boolean;
  baseVersion?: string | StringOperators;

  /** Indicate this version is not editable */
  isReadOnly?: boolean;
  protoUpdateId?: number | NumberOperators;
  isCloning?: boolean;
  cloneVersion?: string | StringOperators;
}

export interface BlueprintDataVersionOrderOptions {
  name?: OrderDirection;
  isBaseVersion?: OrderDirection;
  baseVersion?: OrderDirection;
  isReadOnly?: OrderDirection;
  protoUpdateId?: OrderDirection;
  isCloning?: OrderDirection;
  cloneVersion?: OrderDirection;
}

export interface BlueprintDataVersionPagingFilterOptions {
  select?: ("name" | "isBaseVersion" | "baseVersion" | "isReadOnly" | "protoUpdateId" | "isCloning" | "cloneVersion")[];
  where?: BlueprintDataVersionWhereOptions;
  order?: BlueprintDataVersionOrderOptions;
  skip?: number;
  take?: number;
}

export interface BlueprintDataVersion {
  name: string;
  isBaseVersion: boolean;
  baseVersion?: string;

  /** Indicate this version is not editable */
  isReadOnly?: boolean;

  /** Lock this data version with a proto version */
  protoUpdateId?: number;
  isCloning?: boolean;
  cloneVersion?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum TaskStatus {
  PENDING = "PENDING",
  DEPLOYING = "DEPLOYING",
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  TIMEOUT = "TIMEOUT",
  UNKNOWN = "UNKNOWN",
}

export interface TemplateDto {
  name: string;
  state: string;
  type: string;
}

export interface BlueprintDeployHistory {
  note?: string;

  /** Task status */
  status: TaskStatus;
  message?: string;

  /** Schedule a task */
  scheduledAt?: string;
  startTime?: string;
  endTime?: string;
  id?: number;

  /** Deploy environment */
  environment: string;

  /** Blueprint proto type name */
  types: string[];
  version: string;

  /** Timestamp of last change log record */
  lastChangedAt: string;

  /** ID of last proto update record */
  lastProtoUpdateId: number;
  forceUpdate?: boolean;

  /** Who made the change */
  userEmail: string;
  userAvatar?: string;

  /** ava client */
  avaClient: string;

  /** ava secret */
  avaSecret: string;
  bucketName: number;

  /** Ava Config names */
  avaConfigNames: string[];
  avaBucketName: string;

  /** For Template Bucket */
  templateNames?: TemplateDto[];
  createdAt?: string;
  updatedAt?: string;
}

export interface BlueprintDataVersionDetailDto {
  version: BlueprintDataVersion;
  protoUpdate?: BlueprintProtoUpdateHistory;
  deployments: BlueprintDeployHistory[];
}

export interface BlueprintDataVersionPagingResult {
  total: number;
  skip: number;
  take: number;
  data: BlueprintDataVersion[];
}

export interface BlueprintDeployHistoryWhereOptions {
  note?: string | StringOperators;
  status?: "PENDING" | "DEPLOYING" | "SUCCESS" | "FAIL" | "TIMEOUT" | "UNKNOWN" | StringOperators;
  message?: string | StringOperators;
  scheduledAt?: NumberOperators;
  startTime?: NumberOperators;
  endTime?: NumberOperators;
  id?: number | NumberOperators;
  environment?: string | StringOperators;

  /** Blueprint proto type name */
  types?: string[];
  version?: string | StringOperators;
  lastChangedAt?: string | StringOperators;
  lastProtoUpdateId?: number | NumberOperators;
  forceUpdate?: boolean;
  userEmail?: string | StringOperators;
  userAvatar?: string | StringOperators;
  avaClient?: string | StringOperators;
  avaSecret?: string | StringOperators;
  bucketName?: number | NumberOperators;

  /** Ava Config names */
  avaConfigNames?: string[];
  avaBucketName?: string | StringOperators;

  /** For Template Bucket */
  templateNames?: TemplateDto[];
}

export interface BlueprintDeployHistoryOrderOptions {
  note?: OrderDirection;
  status?: OrderDirection;
  message?: OrderDirection;
  scheduledAt?: OrderDirection;
  startTime?: OrderDirection;
  endTime?: OrderDirection;
  id?: OrderDirection;
  environment?: OrderDirection;
  types?: OrderDirection;
  version?: OrderDirection;
  lastChangedAt?: OrderDirection;
  lastProtoUpdateId?: OrderDirection;
  forceUpdate?: OrderDirection;
  userEmail?: OrderDirection;
  userAvatar?: OrderDirection;
  avaClient?: OrderDirection;
  avaSecret?: OrderDirection;
  bucketName?: OrderDirection;
  avaConfigNames?: OrderDirection;
  avaBucketName?: OrderDirection;
  templateNames?: OrderDirection;
}

export interface BlueprintDeployHistoryPagingFilterOptions {
  select?: (
    | "note"
    | "status"
    | "message"
    | "scheduledAt"
    | "startTime"
    | "endTime"
    | "id"
    | "environment"
    | "types"
    | "version"
    | "lastChangedAt"
    | "lastProtoUpdateId"
    | "forceUpdate"
    | "userEmail"
    | "userAvatar"
    | "avaClient"
    | "avaSecret"
    | "bucketName"
    | "avaConfigNames"
    | "avaBucketName"
    | "templateNames"
  )[];
  where?: BlueprintDeployHistoryWhereOptions;
  order?: BlueprintDeployHistoryOrderOptions;
  skip?: number;
  take?: number;
}

export interface BlueprintDeployHistoryPagingResult {
  total: number;
  skip: number;
  take: number;
  data: BlueprintDeployHistory[];
}

export enum HealthStatus {
  Error = "error",
  Ok = "ok",
  ShuttingDown = "shutting_down",
}

export enum ServiceHealthStatus {
  Up = "up",
  Down = "down",
}

export interface ServiceHealthStatusDto {
  status: ServiceHealthStatus;
  message?: string;
}

export interface ServiceHealthDto {
  database: ServiceHealthStatusDto;
}

export interface HealthDto {
  status: HealthStatus;

  /** Uptime in seconds */
  uptime: number;

  /** Is in maintenance mode? */
  maintenance: boolean;
  info: ServiceHealthDto;
  error: ServiceHealthDto;
}

export interface LoginRequestDto {
  googleTokenId: string;
}

export interface LoginResponseDto {
  token: string;
}

export interface GearUser {
  email: string;
  name: string;
  locked: number;
  avatar?: string;
  avaClientId?: string;
  avaSecretId?: string;
  roles?: RoleMapping[];
}

export interface RoleMapping {
  id: number;
  userEmail: string;
  role: string;
  environment?: string;
  user?: GearUser;
}

export interface RegisterRequestDto {
  email: string;
  name?: string;
  roles: RoleMapping[];
}

export enum UserRole {
  Authenticated = "authenticated",
  Member = "member",
  Ci = "ci",
  System = "system",
  DataEditor = "data-editor",
  DataAdmin = "data-admin",
  ProtoAdmin = "proto-admin",
  Admin = "admin",
  Deployer = "deployer",
  CsViewer = "cs-viewer",
  CsEditor = "cs-editor",
  OdlEditor = "odl-editor",
  OdlAdmin = "odl-admin",
}

export interface UserRoleMapping {
  role: UserRole;
  environments?: string[];
  isBaseRole?: string;
}

export interface MeDto {
  user: GearUser;
  roles: UserRoleMapping[];
}

export interface Role {
  name: string;
  title: string;
  description: string;
  isDefault?: boolean;
  environmentSpecific?: boolean;
  includes?: string[];
}

export interface GearUserWhereOptions {
  email?: string | StringOperators;
  name?: string | StringOperators;
  locked?: number | NumberOperators;
  avatar?: string | StringOperators;
  avaClientId?: string | StringOperators;
  avaSecretId?: string | StringOperators;
}

export interface GearUserOrderOptions {
  email?: OrderDirection;
  name?: OrderDirection;
  locked?: OrderDirection;
  avatar?: OrderDirection;
  avaClientId?: OrderDirection;
  avaSecretId?: OrderDirection;
}

export interface GearUserPagingFilterOptions {
  select?: ("email" | "name" | "locked" | "avatar" | "avaClientId" | "avaSecretId")[];
  where?: GearUserWhereOptions;
  order?: GearUserOrderOptions;
  skip?: number;
  take?: number;
}

export interface GearUserPagingResult {
  total: number;
  skip: number;
  take: number;
  data: GearUser[];
}

export interface LogWhereOptions {
  id?: number | NumberOperators;
  action?: string | StringOperators;
  avatar?: string | StringOperators;
  name?: string | StringOperators;
  note?: string | StringOperators;
  pageName?: string | StringOperators;
  updateBy?: string | StringOperators;
  updateOn?: NumberOperators;
}

export interface LogOrderOptions {
  id?: OrderDirection;
  action?: OrderDirection;
  avatar?: OrderDirection;
  name?: OrderDirection;
  note?: OrderDirection;
  pageName?: OrderDirection;
  updateBy?: OrderDirection;
  updateOn?: OrderDirection;
}

export interface LogPagingFilterOptions {
  select?: ("id" | "action" | "avatar" | "name" | "note" | "pageName" | "updateBy" | "updateOn")[];
  where?: LogWhereOptions;
  order?: LogOrderOptions;
  skip?: number;
  take?: number;
}

export interface Log {
  id?: number;
  action: string;
  avatar: string;
  name?: string;
  note?: string;
  pageName: string;
  updateBy: string;
  updateOn: string;
}

export interface LogPagingResult {
  total: number;
  skip: number;
  take: number;
  data: Log[];
}

export interface GitlabBodyDto {
  branch?: string;
  note?: string;
}

export enum BlueprintDataState {
  A = "A",
  D = "D",
  U = "U",
}

export interface BlueprintData {
  objId?: string;

  /** Blueprint proto type name */
  type: string;

  /** Blueprint version */
  version: string;

  /** Blueprint data variant */
  variant?: string;
  data: Record<string, any>;

  /** Indicate state of the data in current version is (A)dded, (D)eleted or (U)pdated. Not set when untouched */
  state?: BlueprintDataState;
  tags?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface BlueprintDataDiffNew {
  kind: string;
  path?: (string | number)[];
  rhs: any;
}

export interface BlueprintDataDiffDeleted {
  kind: string;
  path?: (string | number)[];
  lhs: any;
}

export interface BlueprintDataDiffEdit {
  kind: string;
  path?: (string | number)[];
  lhs: any;
  rhs: any;
}

export interface BlueprintDataDiffArray {
  kind: string;
  path?: (string | number)[];
  index: number;
  item: BlueprintDataDiffNew | BlueprintDataDiffDeleted | BlueprintDataDiffEdit | BlueprintDataDiffArray;
}

export interface BlueprintDataChangeLog {
  id?: number;

  /** Modified blueprint object ID */
  objId: string;

  /** Protobuf type name */
  type: string;

  /** Blueprint version */
  version: string;

  /** Blueprint data variant */
  variant?: string;
  data: (BlueprintDataDiffNew | BlueprintDataDiffDeleted | BlueprintDataDiffEdit | BlueprintDataDiffArray)[];

  /** Who made the change */
  userEmail: string;
  userAvatar?: string;
  deletedAt?: string;
  tags?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface BlueprintDataWithChangeLogsDto {
  data: BlueprintData[];
  changeLogs?: BlueprintDataChangeLog[];
  deletedData?: BlueprintData[];
  messageError?: string;
}

export interface BlueprintDataStoreResultDto {
  data: BlueprintData;
  change?: BlueprintDataChangeLog;
}

export interface BlueprintDataStoreBatchDto {
  data: BlueprintData[];
}

export interface DeleteMultipleBlueprintDataDto {
  objIds: string[];
}

export interface TaskQueue {
  note?: string;

  /** Task status */
  status: TaskStatus;
  message?: string;

  /** Schedule a task */
  scheduledAt?: string;
  startTime?: string;
  endTime?: string;
  id?: number;
  name?:
    | "import"
    | "MoveChanges"
    | "DeleteBlueprintData"
    | "GetDataAVA"
    | "GetSimulator"
    | "CalculateData"
    | "GetConfigDab";
  state?: string;
  returnValue?: object;
  createdAt?: string;
  updatedAt?: string;
}

export interface BlueprintDataMoveChangesDto {
  version: string;
  toVersion: string;
  type: string;
  objIds?: string[];
  overrideExistingData?: boolean;
}

export interface BlueprintDataMoveTypeChangesDto {
  type: string;
  objIds?: string[];
}

export interface BlueprintDataMoveMultipleChangesDto {
  version: string;
  toVersion: string;
  changes: BlueprintDataMoveTypeChangesDto[];
  overrideExistingData?: boolean;
}

export interface BlueprintDataCloneVariantDto {
  version: string;
  type: string;
  from: string;
  to: string;
  overrideAllData?: boolean;
}

export interface BlueprintDataChangeLogWhereOptions {
  id?: number | NumberOperators;
  objId?: string | StringOperators;
  type?: string | StringOperators;
  version?: string | StringOperators;
  variant?: string | StringOperators;
  data?: (BlueprintDataDiffNew | BlueprintDataDiffDeleted | BlueprintDataDiffEdit | BlueprintDataDiffArray)[];
  userEmail?: string | StringOperators;
  userAvatar?: string | StringOperators;
}

export interface BlueprintDataChangeLogOrderOptions {
  id?: OrderDirection;
  objId?: OrderDirection;
  type?: OrderDirection;
  version?: OrderDirection;
  variant?: OrderDirection;
  data?: OrderDirection;
  userEmail?: OrderDirection;
  userAvatar?: OrderDirection;
}

export interface BlueprintDataChangeLogPagingFilterOptions {
  select?: ("id" | "objId" | "type" | "version" | "variant" | "data" | "userEmail" | "userAvatar")[];
  where?: BlueprintDataChangeLogWhereOptions;
  order?: BlueprintDataChangeLogOrderOptions;
  skip?: number;
  take?: number;
}

export interface BlueprintDataChangeLogPagingResult {
  total: number;
  skip: number;
  take: number;
  data: BlueprintDataChangeLog[];
}

export enum BlueprintDataFormat {
  Xlsx = "xlsx",
  Csv = "csv",
  Json = "json",
}

export interface BlueprintDataImportOptions {
  preview?: boolean;
  upsert?: boolean;
  reserveOrder?: boolean;
  overrideExistingData?: boolean;
  overrideAllData?: boolean;
  changes?: BlueprintDataMoveTypeChangesDto[];
}

export interface BlueprintDataImportDto {
  file: File;
  version: string;
  options?: BlueprintDataImportOptions;
  optionsBin?: File;
}

export interface BlueprintImportResultDto {
  data: BlueprintData[];
  changeLogs?: BlueprintDataChangeLog[];
  deletedData?: BlueprintData[];
  messageError?: string;
  type: string;
  total: number;
}

export interface TaskQueueResponseDto {
  id: number;
  status: string;
  name: string;
  returnValue?: object;
}

export interface TaskQueueWhereOptions {
  note?: string | StringOperators;
  status?: "PENDING" | "DEPLOYING" | "SUCCESS" | "FAIL" | "TIMEOUT" | "UNKNOWN" | StringOperators;
  message?: string | StringOperators;
  scheduledAt?: NumberOperators;
  startTime?: NumberOperators;
  endTime?: NumberOperators;
  id?: number | NumberOperators;
  name?: string | StringOperators;
  state?: string | StringOperators;
  returnValue?: object;
}

export interface TaskQueueOrderOptions {
  note?: OrderDirection;
  status?: OrderDirection;
  message?: OrderDirection;
  scheduledAt?: OrderDirection;
  startTime?: OrderDirection;
  endTime?: OrderDirection;
  id?: OrderDirection;
  name?: OrderDirection;
  state?: OrderDirection;
  returnValue?: OrderDirection;
}

export interface TaskQueuePagingFilterOptions {
  select?: (
    | "note"
    | "status"
    | "message"
    | "scheduledAt"
    | "startTime"
    | "endTime"
    | "id"
    | "name"
    | "state"
    | "returnValue"
  )[];
  where?: TaskQueueWhereOptions;
  order?: TaskQueueOrderOptions;
  skip?: number;
  take?: number;
}

export interface TaskQueuePagingResult {
  total: number;
  skip: number;
  take: number;
  data: TaskQueue[];
}

export interface BlueprintDeployDto {
  environment: string;
  version: string;
  types?: string[];
  note?: string;
  forceUpdate?: boolean;
  bucketName: number;
  avaBucketName: string;
  avaConfigNames?: string[];
  templateNames?: TemplateDto[];
}

export interface EnvironmentWhereOptions {
  name?: string | StringOperators;
  config?: object;
}

export interface EnvironmentOrderOptions {
  name?: OrderDirection;
  config?: OrderDirection;
}

export interface EnvironmentPagingFilterOptions {
  select?: ("name" | "config")[];
  where?: EnvironmentWhereOptions;
  order?: EnvironmentOrderOptions;
  skip?: number;
  take?: number;
}

export interface EnvironmentConfigField {
  name: string;
  type: "number" | "string" | "boolean" | "credentials-url";
  required?: boolean;
  dataType: string;
}

export interface Environment {
  name: string;
  config: object;
  createdAt?: string;
  updatedAt?: string;
}

export interface EnvironmentPagingResult {
  total: number;
  skip: number;
  take: number;
  data: Environment[];
}

export interface AssetConfigWhereOptions {
  id?: number | NumberOperators;
  clientVersion?: string | StringOperators;
  odl?: string | StringOperators;
  odb?: string | StringOperators;
  assetBundle?: string | StringOperators;
  platform?: "Android" | "iOS" | StringOperators;
  environment?: string | StringOperators;
}

export interface AssetConfigOrderOptions {
  id?: OrderDirection;
  clientVersion?: OrderDirection;
  odl?: OrderDirection;
  odb?: OrderDirection;
  assetBundle?: OrderDirection;
  platform?: OrderDirection;
  environment?: OrderDirection;
}

export interface AssetConfigPagingFilterOptions {
  select?: ("id" | "clientVersion" | "odl" | "odb" | "assetBundle" | "platform" | "environment")[];
  where?: AssetConfigWhereOptions;
  order?: AssetConfigOrderOptions;
  skip?: number;
  take?: number;
}

export enum AssetPlatform {
  Android = "Android",
  IOS = "iOS",
}

export interface AssetConfig {
  id?: number;
  clientVersion: string;
  odl: string;
  odb: string;
  assetBundle: string;
  platform: AssetPlatform;
  environment: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface AssetConfigPagingResult {
  total: number;
  skip: number;
  take: number;
  data: AssetConfig[];
}

export interface GameConfigWhereOptions {
  id?: number | NumberOperators;
  blueprintVersion?: string | StringOperators;
  environment?: string | StringOperators;
  clientVersion?: string | StringOperators;
  score?: number | NumberOperators;
  keyRedis?: string | StringOperators;
  forcedUpdate?: boolean;
}

export interface GameConfigOrderOptions {
  id?: OrderDirection;
  blueprintVersion?: OrderDirection;
  environment?: OrderDirection;
  clientVersion?: OrderDirection;
  score?: OrderDirection;
  keyRedis?: OrderDirection;
  forcedUpdate?: OrderDirection;
}

export interface GameConfigPagingFilterOptions {
  select?: ("id" | "blueprintVersion" | "environment" | "clientVersion" | "score" | "keyRedis" | "forcedUpdate")[];
  where?: GameConfigWhereOptions;
  order?: GameConfigOrderOptions;
  skip?: number;
  take?: number;
}

export interface GameConfig {
  id?: number;
  blueprintVersion: string;
  environment: string;
  clientVersion: string;
  score?: number;
  keyRedis?: string;
  forcedUpdate?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface GameConfigPagingResult {
  total: number;
  skip: number;
  take: number;
  data: GameConfig[];
}

export interface GenerateTokenDto {
  expireDate?: string;
}

export interface GenerateTokenResponseDto {
  token: string;
}

export interface BaseCurrencyBlueprint {
  id: string;
  capacity: number;
}

export interface BaseRbeTaskSegmentBlueprint {
  score: number;
}

export interface BaseRbeTaskBlueprint {
  id: string;
  reward: { $key?: string; $val?: number }[];
  segment: BaseRbeTaskSegmentBlueprint[];
}

export interface BaseRbeCompetitiveBlueprint {
  reward: string;
  segmentSize: number;
}

export interface BaseRbeEventBlueprint {
  id: string;
  task: string[];
  competitive?: BaseRbeCompetitiveBlueprint;
}

export interface Currency {
  currency: string;
  amount: number;
  update: number;
}

export interface RbeTaskDataDto {
  id: string;
  progress: number;
  completed: number;
  reward: number;
}

export interface RbeCompetitiveData {
  score: number;
  rank: number;
  leaderboard: string;
  reward: number;
}

export interface RbeEventDataDto {
  id: string;
  tasks: RbeTaskDataDto[];
  completed: number;
  start: number;
  end: number;
  competitive: RbeCompetitiveData;
  joinedAt: number;
}

export interface RbeDto {
  achievements: object;
  events: RbeEventDataDto[];
}

export enum PlayerDataSource {
  Redis = "redis",
  Dynamo = "dynamo",
}

export interface PlayerInfoDto {
  level: number;
  avatar: string;
  signUpDate: string;
}

export interface FinanceDto {
  totalSpent: number;
}

export interface GeoDto {
  lastIPAddress: string;
  lastCountry: string;
  lastCity: string;
}

export interface BanStateDto {
  soloLeaderboardIds: string[];
}

export interface RestrictionsDto {
  accountDisable: boolean;
  banState: BanStateDto;
}

export interface T3CurrencyDto {
  max: number;
  min: number;
  value: number;
  name: string;
  type: string;
}

export interface MilestoneLevelDto {
  LevelId: string;
  Order: number;
  PointToComplete: number;
}

export enum EventType {
  SeasonPass = "season_pass",
  SoloMilestone = "solo_milestone",
}

export interface PlayerMilestoneProgressionDto {
  ProgressionId: string;
  CurrentLevelPoints: number;
  CurrentLevelId: string;
  MilestoneLevels: MilestoneLevelDto[];
  Type: EventType;
}

export interface Player {
  id: string;
  playerId: string;
  name: string;
  version: string;
  xp: number;
  ban: boolean;
  resources: Currency[];
  created: number;
  update: number;
  rbe?: RbeDto;
  source: PlayerDataSource;
  playerInfo: PlayerInfoDto;
  finance: FinanceDto;
  geo: GeoDto;
  restrictions: RestrictionsDto;
  balances: T3CurrencyDto[];
  milestoneProgressions: PlayerMilestoneProgressionDto[];
}

export interface LBMemberDto {
  Score: number;
  PsaId: string;
  UpdateTime: number;
}

export interface T3LeaderBoardDto {
  LeaderboardId: string;
  environment: string;
  RoomId: string;
  RoomSize: number;
  CurrentJoinedPlayerCount: number;
  RoomEndDate: string;
  RoomStartDate: string;
  RoomDetailInfo?: LBMemberDto[];
  Type: string;
  IsBanned: boolean;
}

export interface UpdatePlayerDto {
  name: string;
}

export interface MassInventoryDto {
  playersId: string[];
  currency: string[];
}

export interface CurrencyDto {
  currency: string;
  amount: number;
  update: number;
}

export interface AuditLogWhereOptions {
  id?: number | NumberOperators;
  environment?: string | StringOperators;
  userEmail?: string | StringOperators;
  action?: string | StringOperators;
  playerId?: string | StringOperators;
  category?: string | StringOperators;
}

export interface AuditLogOrderOptions {
  id?: OrderDirection;
  environment?: OrderDirection;
  userEmail?: OrderDirection;
  action?: OrderDirection;
  playerId?: OrderDirection;
  category?: OrderDirection;
}

export interface AuditLogPagingFilterOptions {
  select?: ("id" | "environment" | "userEmail" | "action" | "playerId" | "category")[];
  where?: AuditLogWhereOptions;
  order?: AuditLogOrderOptions;
  skip?: number;
  take?: number;
}

export interface T3LeaderBoardWhereOptions {
  LeaderboardId?: string | StringOperators;
  environment?: string | StringOperators;
  RoomId?: string | StringOperators;
  RoomSize?: number | NumberOperators;
  CurrentJoinedPlayerCount?: number | NumberOperators;
  RoomEndDate?: NumberOperators;
  RoomStartDate?: NumberOperators;
  RoomDetailInfo?: LBMemberDto[];
  Type?: string | StringOperators;
  IsBanned?: boolean;
}

export interface T3LeaderBoardOrderOptions {
  LeaderboardId?: OrderDirection;
  environment?: OrderDirection;
  RoomId?: OrderDirection;
  RoomSize?: OrderDirection;
  CurrentJoinedPlayerCount?: OrderDirection;
  RoomEndDate?: OrderDirection;
  RoomStartDate?: OrderDirection;
  RoomDetailInfo?: OrderDirection;
  Type?: OrderDirection;
  IsBanned?: OrderDirection;
}

export interface T3LeaderBoardPagingFilterOptions {
  select?: (
    | "LeaderboardId"
    | "environment"
    | "RoomId"
    | "RoomSize"
    | "CurrentJoinedPlayerCount"
    | "RoomEndDate"
    | "RoomStartDate"
    | "RoomDetailInfo"
    | "Type"
    | "IsBanned"
  )[];
  where?: T3LeaderBoardWhereOptions;
  order?: T3LeaderBoardOrderOptions;
  skip?: number;
  take?: number;
}

export interface T3LeaderBoard {
  LeaderboardId: string;
  environment: string;
  RoomId: string;
  RoomSize: number;
  CurrentJoinedPlayerCount: number;
  RoomEndDate?: string;
  RoomStartDate?: string;
  RoomDetailInfo?: LBMemberDto[];
  Type?: string;
  IsBanned?: boolean;
}

export interface T3LeaderBoardPagingResult {
  total: number;
  skip: number;
  take: number;
  data: T3LeaderBoard[];
}

export interface MailTemplateReward {
  reward: string;
  amount: number;
}

export interface MailTemplateWhereOptions {
  id?: number | NumberOperators;
  templateId?: string | StringOperators;
  name?: string | StringOperators;
  title?: string | StringOperators;
  content?: string | StringOperators;
  deepLink?: string | StringOperators;
  resources?: MailTemplateReward[];
  expiry?: NumberOperators;
  environment?: string | StringOperators;
}

export interface MailTemplateOrderOptions {
  id?: OrderDirection;
  templateId?: OrderDirection;
  name?: OrderDirection;
  title?: OrderDirection;
  content?: OrderDirection;
  deepLink?: OrderDirection;
  resources?: OrderDirection;
  expiry?: OrderDirection;
  environment?: OrderDirection;
}

export interface MailTemplatePagingFilterOptions {
  select?: ("id" | "templateId" | "name" | "title" | "content" | "deepLink" | "resources" | "expiry" | "environment")[];
  where?: MailTemplateWhereOptions;
  order?: MailTemplateOrderOptions;
  skip?: number;
  take?: number;
}

export interface MailTemplate {
  id?: number;
  templateId: string;
  name: string;
  title: string;
  content: string;
  deepLink?: string;
  resources?: MailTemplateReward[];
  expiry: string;
  environment: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface MailTemplatePagingResult {
  total: number;
  skip: number;
  take: number;
  data: MailTemplate[];
}

export interface MailTemplateDeployWhereOptions {
  id?: number | NumberOperators;
  templateId?: string | StringOperators;
  environment?: string | StringOperators;
}

export interface MailTemplateDeployOrderOptions {
  id?: OrderDirection;
  templateId?: OrderDirection;
  environment?: OrderDirection;
}

export interface MailTemplateDeployPagingFilterOptions {
  select?: ("id" | "templateId" | "environment")[];
  where?: MailTemplateDeployWhereOptions;
  order?: MailTemplateDeployOrderOptions;
  skip?: number;
  take?: number;
}

export interface MailTemplateDeploy {
  id?: number;
  templateId: string;
  environment: string;
}

export interface MailTemplateDeployPagingResult {
  total: number;
  skip: number;
  take: number;
  data: MailTemplateDeploy[];
}

export enum InboxVersion {
  V1 = "v1",
  V2 = "v2",
}

export interface InboxMessageExtraProto {
  name: string;
  proto: object;
}

export interface InboxMessageProtoDto {
  messageBlueprint: BlueprintProto;
  extraProtos?: InboxMessageExtraProto[];
}

export interface InboxDto {
  file: File;
  environment: string;
  data: string;
  playerId?: string;
  note?: string;
  scheduledAt?: string;
}

export interface InboxHistory {
  note?: string;

  /** Task status */
  status: TaskStatus;
  message?: string;

  /** Schedule a task */
  scheduledAt?: string;
  startTime?: string;
  endTime?: string;
  id?: number;
  environment: string;
  version: "v1" | "v2";
  data: object;
  playerIds: string[];
  userEmail: string;
  userAvatar?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface InboxHistoryWhereOptions {
  note?: string | StringOperators;
  status?: "PENDING" | "DEPLOYING" | "SUCCESS" | "FAIL" | "TIMEOUT" | "UNKNOWN" | StringOperators;
  message?: string | StringOperators;
  scheduledAt?: NumberOperators;
  startTime?: NumberOperators;
  endTime?: NumberOperators;
  id?: number | NumberOperators;
  environment?: string | StringOperators;
  version?: string | StringOperators;
  data?: object;
  playerIds?: string[];
  userEmail?: string | StringOperators;
  userAvatar?: string | StringOperators;
}

export interface InboxHistoryOrderOptions {
  note?: OrderDirection;
  status?: OrderDirection;
  message?: OrderDirection;
  scheduledAt?: OrderDirection;
  startTime?: OrderDirection;
  endTime?: OrderDirection;
  id?: OrderDirection;
  environment?: OrderDirection;
  version?: OrderDirection;
  data?: OrderDirection;
  playerIds?: OrderDirection;
  userEmail?: OrderDirection;
  userAvatar?: OrderDirection;
}

export interface InboxHistoryPagingFilterOptions {
  select?: (
    | "note"
    | "status"
    | "message"
    | "scheduledAt"
    | "startTime"
    | "endTime"
    | "id"
    | "environment"
    | "version"
    | "data"
    | "playerIds"
    | "userEmail"
    | "userAvatar"
  )[];
  where?: InboxHistoryWhereOptions;
  order?: InboxHistoryOrderOptions;
  skip?: number;
  take?: number;
}

export interface InboxHistoryPagingResult {
  total: number;
  skip: number;
  take: number;
  data: InboxHistory[];
}

export interface OdlFileWhereOptions {
  id?: string | StringOperators;
  name?: string | StringOperators;
  folder?: string | StringOperators;
  contentType?: string | StringOperators;
  isDir?: boolean;
  hasThumbnail?: boolean;
  url?: string | StringOperators;
  thumbnailUrl?: string | StringOperators;
  hash?: string | StringOperators;
  lastBuiltAt?: NumberOperators;
  userEmail?: string | StringOperators;
}

export interface OdlFileOrderOptions {
  id?: OrderDirection;
  name?: OrderDirection;
  folder?: OrderDirection;
  contentType?: OrderDirection;
  isDir?: OrderDirection;
  hasThumbnail?: OrderDirection;
  url?: OrderDirection;
  thumbnailUrl?: OrderDirection;
  hash?: OrderDirection;
  lastBuiltAt?: OrderDirection;
  userEmail?: OrderDirection;
}

export interface OdlFilePagingFilterOptions {
  select?: (
    | "id"
    | "name"
    | "folder"
    | "contentType"
    | "isDir"
    | "hasThumbnail"
    | "url"
    | "thumbnailUrl"
    | "hash"
    | "lastBuiltAt"
    | "userEmail"
  )[];
  where?: OdlFileWhereOptions;
  order?: OdlFileOrderOptions;
  skip?: number;
  take?: number;
}

export interface OdlFile {
  id: string;
  name: string;
  folder?: string;
  contentType?: string;
  isDir: boolean;
  hasThumbnail?: boolean;
  url?: string;
  thumbnailUrl?: string;
  hash?: string;
  lastBuiltAt?: string;
  userEmail?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface FileUploadDto {
  file: File;
}

export interface DeleteFileDto {
  ids: string[];
}

export interface UrlDto {
  id: string;
  url: string;
}

export interface OdlFilePagingResult {
  total: number;
  skip: number;
  take: number;
  data: OdlFile[];
}

export interface OdlBuildHistoryWhereOptions {
  note?: string | StringOperators;
  status?: "PENDING" | "DEPLOYING" | "SUCCESS" | "FAIL" | "TIMEOUT" | "UNKNOWN" | StringOperators;
  message?: string | StringOperators;
  scheduledAt?: NumberOperators;
  startTime?: NumberOperators;
  endTime?: NumberOperators;
  id?: number | NumberOperators;

  /** File IDs list to build */
  fileIds?: string[];
  buildUrl?: string | StringOperators;
  buildStatus?: "QUEUING" | "RUNNING" | "ABORTED" | "SUCCESS" | "FAILURE" | StringOperators;
  userEmail?: string | StringOperators;
  userAvatar?: string | StringOperators;
}

export interface OdlBuildHistoryOrderOptions {
  note?: OrderDirection;
  status?: OrderDirection;
  message?: OrderDirection;
  scheduledAt?: OrderDirection;
  startTime?: OrderDirection;
  endTime?: OrderDirection;
  id?: OrderDirection;
  fileIds?: OrderDirection;
  buildUrl?: OrderDirection;
  buildStatus?: OrderDirection;
  userEmail?: OrderDirection;
  userAvatar?: OrderDirection;
}

export interface OdlBuildHistoryPagingFilterOptions {
  select?: (
    | "note"
    | "status"
    | "message"
    | "scheduledAt"
    | "startTime"
    | "endTime"
    | "id"
    | "fileIds"
    | "buildUrl"
    | "buildStatus"
    | "userEmail"
    | "userAvatar"
  )[];
  where?: OdlBuildHistoryWhereOptions;
  order?: OdlBuildHistoryOrderOptions;
  skip?: number;
  take?: number;
}

export enum OdlBuildStatus {
  QUEUING = "QUEUING",
  RUNNING = "RUNNING",
  ABORTED = "ABORTED",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export interface OdlBuildHistory {
  note?: string;

  /** Task status */
  status: TaskStatus;
  message?: string;

  /** Schedule a task */
  scheduledAt?: string;
  startTime?: string;
  endTime?: string;
  id?: number;

  /** File IDs list to build */
  fileIds: string[];

  /** Jenkins build URL */
  buildUrl?: string;
  buildStatus?: OdlBuildStatus;
  userEmail: string;
  userAvatar?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface OdlBuildHistoryPagingResult {
  total: number;
  skip: number;
  take: number;
  data: OdlBuildHistory[];
}

export interface OdlBuildInputDto {
  fileIds: string[];
}

export interface BotWhereOptions {
  id?: number | NumberOperators;
  userId?: string | StringOperators;
  playerId?: string | StringOperators;
  leaderBoard?: string | StringOperators;
  score?: number | NumberOperators;
  clientVersion?: string | StringOperators;
  environment?: string | StringOperators;
}

export interface BotOrderOptions {
  id?: OrderDirection;
  userId?: OrderDirection;
  playerId?: OrderDirection;
  leaderBoard?: OrderDirection;
  score?: OrderDirection;
  clientVersion?: OrderDirection;
  environment?: OrderDirection;
}

export interface BotPagingFilterOptions {
  select?: ("id" | "userId" | "playerId" | "leaderBoard" | "score" | "clientVersion" | "environment")[];
  where?: BotWhereOptions;
  order?: BotOrderOptions;
  skip?: number;
  take?: number;
}

export interface BotBulkCreateDto {
  environment: string;
  numberBot: number;
  score: number;
  clientVersion: string;
  eventId: string;
  segment?: string;
  userId?: string;
}

export interface Bot {
  id?: number;
  userId: string;
  playerId: string;
  leaderBoard: string;
  score: number;
  clientVersion: string;
  environment: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface BotUpdateDto {
  score?: number;
  eventId?: string;
  segment?: string;
  userId?: string;
}

export interface LeaderboardDto {
  eventId: string;
  segment: string;
  timestamp?: number;
}

export interface BotPagingResult {
  total: number;
  skip: number;
  take: number;
  data: Bot[];
}

export interface ConfigWhereOptions {
  configId?: number | NumberOperators;
  configName?: string | StringOperators;
  groupConfigId?: number | NumberOperators;
  groupConfigName?: string | StringOperators;
  bucketName?: string | StringOperators;
  bucketId?: number | NumberOperators;
  dabName?: string | StringOperators;
  dabId?: number | NumberOperators;
  schemaName?: string | StringOperators;
  schemaId?: number | NumberOperators;
  environment?: string | StringOperators;
  jsonHash?: string | StringOperators;
  updatedBy?: string | StringOperators;
  isPushing?: boolean;
}

export interface ConfigOrderOptions {
  configId?: OrderDirection;
  configName?: OrderDirection;
  groupConfigId?: OrderDirection;
  groupConfigName?: OrderDirection;
  bucketName?: OrderDirection;
  bucketId?: OrderDirection;
  dabName?: OrderDirection;
  dabId?: OrderDirection;
  schemaName?: OrderDirection;
  schemaId?: OrderDirection;
  environment?: OrderDirection;
  jsonHash?: OrderDirection;
  updatedBy?: OrderDirection;
  isPushing?: OrderDirection;
}

export interface ConfigPagingFilterOptions {
  select?: (
    | "configId"
    | "configName"
    | "groupConfigId"
    | "groupConfigName"
    | "bucketName"
    | "bucketId"
    | "dabName"
    | "dabId"
    | "schemaName"
    | "schemaId"
    | "environment"
    | "jsonHash"
    | "updatedBy"
    | "isPushing"
  )[];
  where?: ConfigWhereOptions;
  order?: ConfigOrderOptions;
  skip?: number;
  take?: number;
}

export interface Config {
  configId: number;
  configName: string;
  groupConfigId: number;
  groupConfigName?: string;
  bucketName?: string;
  bucketId: number;
  dabName?: string;
  dabId?: number;
  schemaName?: string;
  schemaId?: number;
  environment?: string;
  jsonHash?: string;
  updatedBy?: string;
  isPushing?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface ConfigPagingResult {
  total: number;
  skip: number;
  take: number;
  data: Config[];
}

export type RequestParams = Omit<RequestInit, "body" | "method"> & {
  secure?: boolean;
};

export type Interceptors = {
  request?: ({ options }: { options: RequestParams }) => Promise<RequestParams> | RequestParams;
  response?: <D extends unknown = unknown, E extends unknown = unknown>({
    response,
  }: {
    response: HttpResponse<D, E>;
  }) => Promise<HttpResponse<D, E>>;
};

export type RequestQueryParamsType = Record<string | number, any>;

interface ApiConfig<SecurityDataType> {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
  interceptors?: Interceptors;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D | null;
  error: E | null;
}

enum BodyType {
  Json,
  FormData,
}

class HttpClient<SecurityDataType> {
  public baseUrl: string = "";
  private securityData: SecurityDataType = null as any;
  private securityWorker: null | ApiConfig<SecurityDataType>["securityWorker"] = null;
  private interceptors: Interceptors = {};

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string): string {
    let value: any = query[key];
    if (Array.isArray(value)) {
      value = value.join(",");
    } else if (typeof value === "object") {
      value = JSON.stringify(value);
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }

  protected addQueryParams = (rawQuery?: RequestQueryParamsType): string => {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => typeof query[key] !== "undefined");
    return keys.length ? `?${keys.map((key) => this.addQueryParam(query, key)).join("&")}` : "";
  };

  private bodyFormatters: Record<BodyType, (input: any) => any> = {
    [BodyType.Json]: JSON.stringify,
    [BodyType.FormData]: (input: any) =>
      Object.keys(input).reduce((data, key) => {
        data.append(key, input[key]);
        return data;
      }, new FormData()),
  };

  private mergeRequestOptions(params: RequestParams, securityParams?: RequestParams): RequestParams {
    const mergedParams = {
      ...this.baseApiParams,
      ...params,
      ...(securityParams || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params.headers || {}),
        ...((securityParams && securityParams.headers) || {}),
      },
    };

    if (!(mergedParams.headers as any)["Content-Type"]) {
      delete (mergedParams.headers as any)["Content-Type"];
    }
    return mergedParams;
  }

  private safeParseResponse = <T = any, E = any>(response: Response): Promise<HttpResponse<T, E>> => {
    const r = response as HttpResponse<T, E>;
    r.data = null;
    r.error = null;

    if (!response.headers.get("content-type")?.includes("application/json")) {
      return Promise.resolve(r);
    }

    return response
      .json()
      .then((data) => {
        if (r.ok) {
          r.data = data;
        } else {
          r.error = data;
        }
        return r;
      })
      .catch((e) => {
        r.error = e;
        return r;
      });
  };

  public request = async <T = any, E = any>(
    path: string,
    method: string,
    { secure, ...params }: RequestParams = {},
    body?: any,
    bodyType?: BodyType,
    secureByDefault?: boolean,
  ): Promise<HttpResponse<T, E>> => {
    const requestUrl = `${this.baseUrl}${path}`;
    const secureOptions =
      (secureByDefault || secure) && this.securityWorker ? this.securityWorker(this.securityData) : {};
    let options = this.mergeRequestOptions(params, secureOptions);
    if (this.interceptors.request) {
      options = await Promise.resolve(this.interceptors.request({ options }));
    }
    const requestOptions = {
      ...options,
      method,
      body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
    };

    return fetch(requestUrl, requestOptions).then(async (response) => {
      let data = await this.safeParseResponse<T, E>(response);
      if (this.interceptors.response) {
        data = await this.interceptors.response<T, E>({ response: data });
      }
      // if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title In-House Tool API
 * @version 1.0.0
 * Powered by GearInc
 */
export class Api<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @tags system-config
     * @name SystemConfigController_getPublicConfig
     * @request GET:/api/config/public.json
     */
    systemConfigControllerGetPublicConfig: (params?: RequestParams) =>
      this.request<ClientConfigResponseDto, any>(`/api/config/public.json`, "GET", params),

    /**
     * @tags system-config
     * @name SystemConfigController_all
     * @request GET:/api/config/all
     * @secure
     */
    systemConfigControllerAll: (params?: RequestParams) =>
      this.request<SystemConfig[], any>(`/api/config/all`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags system-config
     * @name SystemConfigController_update
     * @request PUT:/api/config
     * @secure
     */
    systemConfigControllerUpdate: (data: SystemConfigDto, params?: RequestParams) =>
      this.request<SystemConfig, any>(`/api/config`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags system-config
     * @name SystemConfigController_upsert
     * @request POST:/api/config
     * @secure
     */
    systemConfigControllerUpsert: (data: SystemConfigDto, params?: RequestParams) =>
      this.request<SystemConfig, any>(`/api/config`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags system-config
     * @name SystemConfigController_bulkUpdate
     * @request PUT:/api/config/bulk
     * @secure
     */
    systemConfigControllerBulkUpdate: (data: SystemConfigDto[], params?: RequestParams) =>
      this.request<SystemConfig[], any>(`/api/config/bulk`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags blueprint-proto-update-history
     * @name BlueprintProtoUpdateHistoryController_list
     * @request GET:/api/blueprint-proto-update-history
     * @secure
     */
    blueprintProtoUpdateHistoryControllerList: (
      query?: {
        select?: ("id" | "sha" | "note" | "status" | "source" | "proto" | "userEmail" | "userAvatar")[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintProtoUpdateHistoryPagingResult, any>(
        `/api/blueprint-proto-update-history${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-proto-update-history
     * @name BlueprintProtoUpdateHistoryController_getAll
     * @request GET:/api/blueprint-proto-update-history/all
     * @secure
     */
    blueprintProtoUpdateHistoryControllerGetAll: (
      query?: {
        select?: ("id" | "sha" | "note" | "status" | "source" | "proto" | "userEmail" | "userAvatar")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintProtoUpdateHistory[], any>(
        `/api/blueprint-proto-update-history/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-proto-update-history
     * @name BlueprintProtoUpdateHistoryController_findOne
     * @request GET:/api/blueprint-proto-update-history/findOne
     * @secure
     */
    blueprintProtoUpdateHistoryControllerFindOne: (
      query?: {
        select?: ("id" | "sha" | "note" | "status" | "source" | "proto" | "userEmail" | "userAvatar")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintProtoUpdateHistory, any>(
        `/api/blueprint-proto-update-history/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-proto-update-history
     * @name BlueprintProtoUpdateHistoryController_findById
     * @request GET:/api/blueprint-proto-update-history/{id}
     * @secure
     */
    blueprintProtoUpdateHistoryControllerFindById: (id: string, params?: RequestParams) =>
      this.request<BlueprintProtoUpdateHistory, any>(
        `/api/blueprint-proto-update-history/${id}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-proto
     * @name BlueprintProtoController_getAll
     * @request GET:/api/blueprint-proto/all
     * @secure
     */
    blueprintProtoControllerGetAll: (query?: { updateId?: number }, params?: RequestParams) =>
      this.request<BlueprintProto[], any>(
        `/api/blueprint-proto/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-proto
     * @name BlueprintProtoController_update
     * @request PUT:/api/blueprint-proto/{id}
     * @secure
     */
    blueprintProtoControllerUpdate: (
      id: string,
      data: BlueprintProto,
      query?: { updateId?: number },
      params?: RequestParams,
    ) =>
      this.request<BlueprintProto, any>(
        `/api/blueprint-proto/${id}${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-proto
     * @name BlueprintProtoController_deleteById
     * @request DELETE:/api/blueprint-proto/{id}
     * @secure
     */
    blueprintProtoControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/blueprint-proto/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags blueprint-proto
     * @name BlueprintProtoController_cloneBlueprint
     * @request POST:/api/blueprint-proto/clone
     * @secure
     */
    blueprintProtoControllerCloneBlueprint: (
      data: CloneBlueprintProtoDto,
      query?: { updateId?: number },
      params?: RequestParams,
    ) =>
      this.request<BlueprintProto, any>(
        `/api/blueprint-proto/clone${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-proto
     * @name BlueprintProtoController_create
     * @request POST:/api/blueprint-proto
     * @secure
     */
    blueprintProtoControllerCreate: (data: BlueprintProto, params?: RequestParams) =>
      this.request<BlueprintProto, any>(`/api/blueprint-proto`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags blueprint-data-version
     * @name BlueprintDataVersionController_lock
     * @request PUT:/api/blueprint-data-version/{version}/lock
     * @secure
     */
    blueprintDataVersionControllerLock: (version: string, params?: RequestParams) =>
      this.request<BlueprintDataVersion, any>(
        `/api/blueprint-data-version/${version}/lock`,
        "PUT",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-version
     * @name BlueprintDataVersionController_unLock
     * @request PUT:/api/blueprint-data-version/{version}/unlock
     * @secure
     */
    blueprintDataVersionControllerUnLock: (version: string, params?: RequestParams) =>
      this.request<BlueprintDataVersion, any>(
        `/api/blueprint-data-version/${version}/unlock`,
        "PUT",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-version
     * @name BlueprintDataVersionController_detail
     * @request GET:/api/blueprint-data-version/{version}/detail
     * @secure
     */
    blueprintDataVersionControllerDetail: (version: string, params?: RequestParams) =>
      this.request<BlueprintDataVersionDetailDto, any>(
        `/api/blueprint-data-version/${version}/detail`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-version
     * @name BlueprintDataVersionController_list
     * @request GET:/api/blueprint-data-version
     * @secure
     */
    blueprintDataVersionControllerList: (
      query?: {
        select?: (
          | "name"
          | "isBaseVersion"
          | "baseVersion"
          | "isReadOnly"
          | "protoUpdateId"
          | "isCloning"
          | "cloneVersion"
        )[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataVersionPagingResult, any>(
        `/api/blueprint-data-version${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-version
     * @name BlueprintDataVersionController_create
     * @request POST:/api/blueprint-data-version
     * @secure
     */
    blueprintDataVersionControllerCreate: (data: BlueprintDataVersion, params?: RequestParams) =>
      this.request<BlueprintDataVersion, any>(`/api/blueprint-data-version`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags blueprint-data-version
     * @name BlueprintDataVersionController_getAll
     * @request GET:/api/blueprint-data-version/all
     * @secure
     */
    blueprintDataVersionControllerGetAll: (
      query?: {
        select?: (
          | "name"
          | "isBaseVersion"
          | "baseVersion"
          | "isReadOnly"
          | "protoUpdateId"
          | "isCloning"
          | "cloneVersion"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataVersion[], any>(
        `/api/blueprint-data-version/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-version
     * @name BlueprintDataVersionController_findOne
     * @request GET:/api/blueprint-data-version/findOne
     * @secure
     */
    blueprintDataVersionControllerFindOne: (
      query?: {
        select?: (
          | "name"
          | "isBaseVersion"
          | "baseVersion"
          | "isReadOnly"
          | "protoUpdateId"
          | "isCloning"
          | "cloneVersion"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataVersion, any>(
        `/api/blueprint-data-version/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-version
     * @name BlueprintDataVersionController_findById
     * @request GET:/api/blueprint-data-version/{id}
     * @secure
     */
    blueprintDataVersionControllerFindById: (id: string, params?: RequestParams) =>
      this.request<BlueprintDataVersion, any>(
        `/api/blueprint-data-version/${id}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-version
     * @name BlueprintDataVersionController_update
     * @request PUT:/api/blueprint-data-version/{id}
     * @secure
     */
    blueprintDataVersionControllerUpdate: (id: string, data: BlueprintDataVersion, params?: RequestParams) =>
      this.request<BlueprintDataVersion, any>(
        `/api/blueprint-data-version/${id}`,
        "PUT",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-version
     * @name BlueprintDataVersionController_deleteById
     * @request DELETE:/api/blueprint-data-version/{id}
     * @secure
     */
    blueprintDataVersionControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/blueprint-data-version/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags blueprint-deploy-history
     * @name BlueprintDeployHistoryController_getLastSucessfulDeploy
     * @request GET:/api/blueprint-deploy-history/last-success-deploy
     * @secure
     */
    blueprintDeployHistoryControllerGetLastSucessfulDeploy: (
      query?: { environment?: string; version?: string },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDeployHistory, any>(
        `/api/blueprint-deploy-history/last-success-deploy${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-deploy-history
     * @name BlueprintDeployHistoryController_list
     * @request GET:/api/blueprint-deploy-history
     * @secure
     */
    blueprintDeployHistoryControllerList: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "environment"
          | "types"
          | "version"
          | "lastChangedAt"
          | "lastProtoUpdateId"
          | "forceUpdate"
          | "userEmail"
          | "userAvatar"
          | "avaClient"
          | "avaSecret"
          | "bucketName"
          | "avaConfigNames"
          | "avaBucketName"
          | "templateNames"
        )[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDeployHistoryPagingResult, any>(
        `/api/blueprint-deploy-history${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-deploy-history
     * @name BlueprintDeployHistoryController_getAll
     * @request GET:/api/blueprint-deploy-history/all
     * @secure
     */
    blueprintDeployHistoryControllerGetAll: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "environment"
          | "types"
          | "version"
          | "lastChangedAt"
          | "lastProtoUpdateId"
          | "forceUpdate"
          | "userEmail"
          | "userAvatar"
          | "avaClient"
          | "avaSecret"
          | "bucketName"
          | "avaConfigNames"
          | "avaBucketName"
          | "templateNames"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDeployHistory[], any>(
        `/api/blueprint-deploy-history/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-deploy-history
     * @name BlueprintDeployHistoryController_findOne
     * @request GET:/api/blueprint-deploy-history/findOne
     * @secure
     */
    blueprintDeployHistoryControllerFindOne: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "environment"
          | "types"
          | "version"
          | "lastChangedAt"
          | "lastProtoUpdateId"
          | "forceUpdate"
          | "userEmail"
          | "userAvatar"
          | "avaClient"
          | "avaSecret"
          | "bucketName"
          | "avaConfigNames"
          | "avaBucketName"
          | "templateNames"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDeployHistory, any>(
        `/api/blueprint-deploy-history/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-deploy-history
     * @name BlueprintDeployHistoryController_findById
     * @request GET:/api/blueprint-deploy-history/{id}
     * @secure
     */
    blueprintDeployHistoryControllerFindById: (id: string, params?: RequestParams) =>
      this.request<BlueprintDeployHistory, any>(
        `/api/blueprint-deploy-history/${id}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags health
     * @name HealthController_check
     * @request GET:/api/health
     */
    healthControllerCheck: (params?: RequestParams) =>
      this.request<
        HealthDto,
        {
          status?: string;
          info?: Record<string, { status?: string }>;
          error?: Record<string, { status?: string }>;
          details?: Record<string, { status?: string }>;
        }
      >(`/api/health`, "GET", params),

    /**
     * @tags health
     * @name HealthController_startMaintenance
     * @request PUT:/api/health/maintenance/start
     * @secure
     */
    healthControllerStartMaintenance: (params?: RequestParams) =>
      this.request<any, any>(`/api/health/maintenance/start`, "PUT", params, null, BodyType.Json, true),

    /**
     * @tags health
     * @name HealthController_stopMaintenance
     * @request PUT:/api/health/maintenance/stop
     * @secure
     */
    healthControllerStopMaintenance: (params?: RequestParams) =>
      this.request<any, any>(`/api/health/maintenance/stop`, "PUT", params, null, BodyType.Json, true),

    /**
     * @tags auth
     * @name AuthController_login
     * @request POST:/api/auth/login
     */
    authControllerLogin: (data: LoginRequestDto, params?: RequestParams) =>
      this.request<LoginResponseDto, any>(`/api/auth/login`, "POST", params, data),

    /**
     * @tags auth
     * @name AuthController_logout
     * @request POST:/api/auth/logout
     */
    authControllerLogout: (params?: RequestParams) => this.request<string, any>(`/api/auth/logout`, "POST", params),

    /**
     * @tags auth
     * @name AuthController_register
     * @request POST:/api/auth
     */
    authControllerRegister: (data: RegisterRequestDto, params?: RequestParams) =>
      this.request<GearUser, any>(`/api/auth`, "POST", params, data),

    /**
     * @tags auth
     * @name AuthController_updateUser
     * @request PUT:/api/auth
     */
    authControllerUpdateUser: (data: GearUser, params?: RequestParams) =>
      this.request<GearUser, any>(`/api/auth`, "PUT", params, data),

    /**
     * @tags auth
     * @name AuthController_me
     * @request GET:/api/auth/me
     * @secure
     */
    authControllerMe: (params?: RequestParams) =>
      this.request<MeDto, any>(`/api/auth/me`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags auth
     * @name AuthController_allRoles
     * @request GET:/api/auth/roles
     * @secure
     */
    authControllerAllRoles: (params?: RequestParams) =>
      this.request<Role[], any>(`/api/auth/roles`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags auth
     * @name AuthController_getRoles
     * @request GET:/api/auth/{email}/roles
     * @secure
     */
    authControllerGetRoles: (email: string, params?: RequestParams) =>
      this.request<UserRoleMapping[], any>(`/api/auth/${email}/roles`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags auth
     * @name AuthController_setRoles
     * @request PUT:/api/auth/{email}/roles
     * @secure
     */
    authControllerSetRoles: (email: string, data: UserRoleMapping[], params?: RequestParams) =>
      this.request<UserRoleMapping[], any>(`/api/auth/${email}/roles`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags users
     * @name GearUserController_list
     * @request GET:/api/users
     * @secure
     */
    gearUserControllerList: (
      query?: {
        select?: ("email" | "name" | "locked" | "avatar" | "avaClientId" | "avaSecretId")[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<GearUserPagingResult, any>(
        `/api/users${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags users
     * @name GearUserController_create
     * @request POST:/api/users
     * @secure
     */
    gearUserControllerCreate: (data: GearUser, params?: RequestParams) =>
      this.request<GearUser, any>(`/api/users`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags users
     * @name GearUserController_getAll
     * @request GET:/api/users/all
     * @secure
     */
    gearUserControllerGetAll: (
      query?: {
        select?: ("email" | "name" | "locked" | "avatar" | "avaClientId" | "avaSecretId")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<GearUser[], any>(
        `/api/users/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags users
     * @name GearUserController_findOne
     * @request GET:/api/users/findOne
     * @secure
     */
    gearUserControllerFindOne: (
      query?: {
        select?: ("email" | "name" | "locked" | "avatar" | "avaClientId" | "avaSecretId")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<GearUser, any>(
        `/api/users/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags users
     * @name GearUserController_findById
     * @request GET:/api/users/{id}
     * @secure
     */
    gearUserControllerFindById: (id: string, params?: RequestParams) =>
      this.request<GearUser, any>(`/api/users/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags users
     * @name GearUserController_update
     * @request PUT:/api/users/{id}
     * @secure
     */
    gearUserControllerUpdate: (id: string, data: GearUser, params?: RequestParams) =>
      this.request<GearUser, any>(`/api/users/${id}`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags users
     * @name GearUserController_deleteById
     * @request DELETE:/api/users/{id}
     * @secure
     */
    gearUserControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/users/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags log
     * @name LogController_getByAction
     * @request GET:/api/log/get-by-action
     * @secure
     */
    logControllerGetByAction: (query: { action: string }, params?: RequestParams) =>
      this.request<Log[], any>(
        `/api/log/get-by-action${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags log
     * @name LogController_list
     * @request GET:/api/log
     * @secure
     */
    logControllerList: (
      query?: {
        select?: ("id" | "action" | "avatar" | "name" | "note" | "pageName" | "updateBy" | "updateOn")[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<LogPagingResult, any>(
        `/api/log${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags log
     * @name LogController_create
     * @request POST:/api/log
     * @secure
     */
    logControllerCreate: (data: Log, params?: RequestParams) =>
      this.request<Log, any>(`/api/log`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags log
     * @name LogController_getAll
     * @request GET:/api/log/all
     * @secure
     */
    logControllerGetAll: (
      query?: {
        select?: ("id" | "action" | "avatar" | "name" | "note" | "pageName" | "updateBy" | "updateOn")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<Log[], any>(`/api/log/all${this.addQueryParams(query)}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags log
     * @name LogController_findOne
     * @request GET:/api/log/findOne
     * @secure
     */
    logControllerFindOne: (
      query?: {
        select?: ("id" | "action" | "avatar" | "name" | "note" | "pageName" | "updateBy" | "updateOn")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<Log, any>(`/api/log/findOne${this.addQueryParams(query)}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags log
     * @name LogController_findById
     * @request GET:/api/log/{id}
     * @secure
     */
    logControllerFindById: (id: string, params?: RequestParams) =>
      this.request<Log, any>(`/api/log/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags log
     * @name LogController_update
     * @request PUT:/api/log/{id}
     * @secure
     */
    logControllerUpdate: (id: string, data: Log, params?: RequestParams) =>
      this.request<Log, any>(`/api/log/${id}`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags log
     * @name LogController_deleteById
     * @request DELETE:/api/log/{id}
     * @secure
     */
    logControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/log/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags proto/import
     * @name ProtoImportController_pull
     * @request POST:/api/proto/import/pull
     * @secure
     */
    protoImportControllerPull: (data: GitlabBodyDto, params?: RequestParams) =>
      this.request<BlueprintProtoUpdateHistory, any>(
        `/api/proto/import/pull`,
        "POST",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_getAllData
     * @request GET:/api/blueprint-data/version/{version}/type/{type}
     * @secure
     */
    blueprintDataControllerGetAllData: (
      version: string,
      type: string,
      query?: { variant?: string; deployId?: number },
      params?: RequestParams,
    ) =>
      this.request<BlueprintData[], any>(
        `/api/blueprint-data/version/${version}/type/${type}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_deleteMultipleData
     * @request DELETE:/api/blueprint-data/version/{version}/type/{type}
     * @secure
     */
    blueprintDataControllerDeleteMultipleData: (
      version: string,
      type: string,
      data: DeleteMultipleBlueprintDataDto,
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataChangeLog[], any>(
        `/api/blueprint-data/version/${version}/type/${type}`,
        "DELETE",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_getChangedTypesOfVersions
     * @request GET:/api/blueprint-data/compare/version/{version}
     * @secure
     */
    blueprintDataControllerGetChangedTypesOfVersions: (
      version: string,
      query?: { variant?: string; deployId?: number; compareVersion?: string; compareDeployId?: number },
      params?: RequestParams,
    ) =>
      this.request<string[], any>(
        `/api/blueprint-data/compare/version/${version}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_getDataAndCompare
     * @request GET:/api/blueprint-data/compare/version/{version}/type/{type}
     * @secure
     */
    blueprintDataControllerGetDataAndCompare: (
      version: string,
      type: string,
      query?: { variant?: string; deployId?: number; compareVersion?: string; compareDeployId?: number },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataWithChangeLogsDto, any>(
        `/api/blueprint-data/compare/version/${version}/type/${type}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_getAllDataAndCompareWithLastDeploy
     * @request GET:/api/blueprint-data/last-deploy/version/{version}/type/{type}
     * @secure
     */
    blueprintDataControllerGetAllDataAndCompareWithLastDeploy: (
      version: string,
      type: string,
      query: { environment: string; variant?: string },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataWithChangeLogsDto, any>(
        `/api/blueprint-data/last-deploy/version/${version}/type/${type}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_getData
     * @request GET:/api/blueprint-data/version/{version}/type/{type}/{objId}
     * @secure
     */
    blueprintDataControllerGetData: (
      version: string,
      type: string,
      objId: string,
      query?: { variant?: string; deployId?: number },
      params?: RequestParams,
    ) =>
      this.request<BlueprintData, any>(
        `/api/blueprint-data/version/${version}/type/${type}/${objId}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_deleteData
     * @request DELETE:/api/blueprint-data/version/{version}/type/{type}/{objId}
     * @secure
     */
    blueprintDataControllerDeleteData: (version: string, type: string, objId: string, params?: RequestParams) =>
      this.request<BlueprintDataChangeLog, any>(
        `/api/blueprint-data/version/${version}/type/${type}/${objId}`,
        "DELETE",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_storeData
     * @request POST:/api/blueprint-data
     * @secure
     */
    blueprintDataControllerStoreData: (data: BlueprintData, params?: RequestParams) =>
      this.request<BlueprintDataStoreResultDto[], any>(
        `/api/blueprint-data`,
        "POST",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_storeBatchData
     * @request POST:/api/blueprint-data/version/{version}/batch
     * @secure
     */
    blueprintDataControllerStoreBatchData: (
      version: string,
      data: BlueprintDataStoreBatchDto,
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataStoreResultDto[], any>(
        `/api/blueprint-data/version/${version}/batch`,
        "POST",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_deleteMultipleDataAsync
     * @request DELETE:/api/blueprint-data/async/version/{version}/type/{type}
     * @secure
     */
    blueprintDataControllerDeleteMultipleDataAsync: (
      version: string,
      type: string,
      data: DeleteMultipleBlueprintDataDto,
      params?: RequestParams,
    ) =>
      this.request<TaskQueue, any>(
        `/api/blueprint-data/async/version/${version}/type/${type}`,
        "DELETE",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_moveChangesToVersion
     * @request PUT:/api/blueprint-data/move
     * @secure
     */
    blueprintDataControllerMoveChangesToVersion: (data: BlueprintDataMoveChangesDto, params?: RequestParams) =>
      this.request<any, any>(`/api/blueprint-data/move`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_moveMultipleChangesToVersion
     * @request PUT:/api/blueprint-data/move/multiple
     * @secure
     */
    blueprintDataControllerMoveMultipleChangesToVersion: (
      data: BlueprintDataMoveMultipleChangesDto,
      params?: RequestParams,
    ) => this.request<any, any>(`/api/blueprint-data/move/multiple`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_moveMultipleChangesToVersionAsync
     * @request PUT:/api/blueprint-data/move/multiple_async
     * @secure
     */
    blueprintDataControllerMoveMultipleChangesToVersionAsync: (
      data: BlueprintDataMoveMultipleChangesDto,
      params?: RequestParams,
    ) =>
      this.request<TaskQueue, any>(`/api/blueprint-data/move/multiple_async`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_revertData
     * @request PUT:/api/blueprint-data/revert/version/{version}/type/{type}/{objId}
     * @secure
     */
    blueprintDataControllerRevertData: (
      version: string,
      type: string,
      objId: string,
      query: { lastChangedAt: string },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataStoreResultDto, any>(
        `/api/blueprint-data/revert/version/${version}/type/${type}/${objId}${this.addQueryParams(query)}`,
        "PUT",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_cloneData
     * @request POST:/api/blueprint-data/clone/version/{version}/to/{toVersion}
     * @secure
     */
    blueprintDataControllerCloneData: (version: string, toVersion: string, params?: RequestParams) =>
      this.request<BlueprintDataVersion, any>(
        `/api/blueprint-data/clone/version/${version}/to/${toVersion}`,
        "POST",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data
     * @name BlueprintDataController_cloneVariant
     * @request POST:/api/blueprint-data/clone/variant
     * @secure
     */
    blueprintDataControllerCloneVariant: (data: BlueprintDataCloneVariantDto, params?: RequestParams) =>
      this.request<BlueprintDataWithChangeLogsDto, any>(
        `/api/blueprint-data/clone/variant`,
        "POST",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-change-log
     * @name BlueprintDataChangeLogController_getChangeLogsFromLastDeploy
     * @request GET:/api/blueprint-data-change-log/last-deploy
     * @secure
     */
    blueprintDataChangeLogControllerGetChangeLogsFromLastDeploy: (
      query: { environment: string; version: string; type: string; objId: string },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataChangeLog[], any>(
        `/api/blueprint-data-change-log/last-deploy${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-change-log
     * @name BlueprintDataChangeLogController_getChangeLogsBetweenDeploys
     * @request GET:/api/blueprint-data-change-log/between-deploys
     * @secure
     */
    blueprintDataChangeLogControllerGetChangeLogsBetweenDeploys: (
      query: { version: string; type: string; objId: string; fromDeployId?: number; toDeployId?: number },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataChangeLog[], any>(
        `/api/blueprint-data-change-log/between-deploys${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-change-log
     * @name BlueprintDataChangeLogController_list
     * @request GET:/api/blueprint-data-change-log
     * @secure
     */
    blueprintDataChangeLogControllerList: (
      query?: {
        select?: ("id" | "objId" | "type" | "version" | "variant" | "data" | "userEmail" | "userAvatar")[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataChangeLogPagingResult, any>(
        `/api/blueprint-data-change-log${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-change-log
     * @name BlueprintDataChangeLogController_create
     * @request POST:/api/blueprint-data-change-log
     * @secure
     */
    blueprintDataChangeLogControllerCreate: (data: BlueprintDataChangeLog, params?: RequestParams) =>
      this.request<BlueprintDataChangeLog, any>(
        `/api/blueprint-data-change-log`,
        "POST",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-change-log
     * @name BlueprintDataChangeLogController_getAll
     * @request GET:/api/blueprint-data-change-log/all
     * @secure
     */
    blueprintDataChangeLogControllerGetAll: (
      query?: {
        select?: ("id" | "objId" | "type" | "version" | "variant" | "data" | "userEmail" | "userAvatar")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataChangeLog[], any>(
        `/api/blueprint-data-change-log/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-change-log
     * @name BlueprintDataChangeLogController_findOne
     * @request GET:/api/blueprint-data-change-log/findOne
     * @secure
     */
    blueprintDataChangeLogControllerFindOne: (
      query?: {
        select?: ("id" | "objId" | "type" | "version" | "variant" | "data" | "userEmail" | "userAvatar")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<BlueprintDataChangeLog, any>(
        `/api/blueprint-data-change-log/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-change-log
     * @name BlueprintDataChangeLogController_findById
     * @request GET:/api/blueprint-data-change-log/{id}
     * @secure
     */
    blueprintDataChangeLogControllerFindById: (id: string, params?: RequestParams) =>
      this.request<BlueprintDataChangeLog, any>(
        `/api/blueprint-data-change-log/${id}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-change-log
     * @name BlueprintDataChangeLogController_update
     * @request PUT:/api/blueprint-data-change-log/{id}
     * @secure
     */
    blueprintDataChangeLogControllerUpdate: (id: string, data: BlueprintDataChangeLog, params?: RequestParams) =>
      this.request<BlueprintDataChangeLog, any>(
        `/api/blueprint-data-change-log/${id}`,
        "PUT",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-change-log
     * @name BlueprintDataChangeLogController_deleteById
     * @request DELETE:/api/blueprint-data-change-log/{id}
     * @secure
     */
    blueprintDataChangeLogControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/blueprint-data-change-log/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags blueprint-data-import-export
     * @name BlueprintDataImportController_exportToExcel
     * @request GET:/api/blueprint-data/export/version/{version}
     * @secure
     */
    blueprintDataImportControllerExportToExcel: (
      version: string,
      query?: { type?: string; deployId?: number; selectedIds?: string[]; format?: BlueprintDataFormat },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/blueprint-data/export/version/${version}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-import-export
     * @name BlueprintDataImportController_exportToJSON
     * @request GET:/api/blueprint-data/json/version/{version}
     * @secure
     */
    blueprintDataImportControllerExportToJson: (
      version: string,
      query?: { type?: string; deployId?: number; ids?: string },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/blueprint-data/json/version/${version}${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-data-import-export
     * @name BlueprintDataImportController_importFromExcel
     * @request POST:/api/blueprint-data/import
     * @secure
     */
    blueprintDataImportControllerImportFromExcel: (data: BlueprintDataImportDto, params?: RequestParams) =>
      this.request<BlueprintImportResultDto[], any>(
        `/api/blueprint-data/import`,
        "POST",
        params,
        data,
        BodyType.FormData,
        true,
      ),

    /**
     * @tags blueprint-data-import-export
     * @name BlueprintDataImportController_importFromExcelAsync
     * @request POST:/api/blueprint-data/import_async
     * @secure
     */
    blueprintDataImportControllerImportFromExcelAsync: (data: BlueprintDataImportDto, params?: RequestParams) =>
      this.request<TaskQueueResponseDto, any>(
        `/api/blueprint-data/import_async`,
        "POST",
        params,
        data,
        BodyType.FormData,
        true,
      ),

    /**
     * @tags task-queue
     * @name TaskQueueController_list
     * @request GET:/api/task-queue
     * @secure
     */
    taskQueueControllerList: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "name"
          | "state"
          | "returnValue"
        )[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<TaskQueuePagingResult, any>(
        `/api/task-queue${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags task-queue
     * @name TaskQueueController_getAll
     * @request GET:/api/task-queue/all
     * @secure
     */
    taskQueueControllerGetAll: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "name"
          | "state"
          | "returnValue"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<TaskQueue[], any>(
        `/api/task-queue/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags task-queue
     * @name TaskQueueController_findOne
     * @request GET:/api/task-queue/findOne
     * @secure
     */
    taskQueueControllerFindOne: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "name"
          | "state"
          | "returnValue"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<TaskQueue, any>(
        `/api/task-queue/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags task-queue
     * @name TaskQueueController_findById
     * @request GET:/api/task-queue/{id}
     * @secure
     */
    taskQueueControllerFindById: (id: string, params?: RequestParams) =>
      this.request<TaskQueue, any>(`/api/task-queue/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags blueprint-deploy
     * @name BlueprintDeployController_register
     * @request POST:/api/blueprint-deploy/register
     * @secure
     */
    blueprintDeployControllerRegister: (data: BlueprintDeployDto, params?: RequestParams) =>
      this.request<BlueprintDeployHistory, any>(
        `/api/blueprint-deploy/register`,
        "POST",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-deploy
     * @name BlueprintDeployController_registerRedeploy
     * @request POST:/api/blueprint-deploy/{id}/redeploy
     * @secure
     */
    blueprintDeployControllerRegisterRedeploy: (id: number, params?: RequestParams) =>
      this.request<BlueprintDeployHistory, any>(
        `/api/blueprint-deploy/${id}/redeploy`,
        "POST",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-deploy
     * @name BlueprintDeployController_getChangedTypesFromLastDeploy
     * @request GET:/api/blueprint-deploy/changed-types
     * @secure
     */
    blueprintDeployControllerGetChangedTypesFromLastDeploy: (
      query: { environment: string; version: string },
      params?: RequestParams,
    ) =>
      this.request<string[], any>(
        `/api/blueprint-deploy/changed-types${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-deploy
     * @name BlueprintDeployController_getLeaderBoards
     * @request GET:/api/blueprint-deploy/get-leader-boards
     * @secure
     */
    blueprintDeployControllerGetLeaderBoards: (query: { bucketId: string }, params?: RequestParams) =>
      this.request<object, any>(
        `/api/blueprint-deploy/get-leader-boards${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags blueprint-deploy
     * @name BlueprintDeployController_getBlueprintDeployed
     * @request POST:/api/blueprint-deploy/{id}/data
     * @secure
     */
    blueprintDeployControllerGetBlueprintDeployed: (id: number, params?: RequestParams) =>
      this.request<object, any>(`/api/blueprint-deploy/${id}/data`, "POST", params, null, BodyType.Json, true),

    /**
     * @tags blueprint-deploy
     * @name BlueprintDeployController_getBinaryBlueprintDeployed
     * @request POST:/api/blueprint-deploy/{id}/data/binary
     * @secure
     */
    blueprintDeployControllerGetBinaryBlueprintDeployed: (id: number, params?: RequestParams) =>
      this.request<object, any>(`/api/blueprint-deploy/${id}/data/binary`, "POST", params, null, BodyType.Json, true),

    /**
     * @tags environment
     * @name EnvironmentController_getConfigFields
     * @request GET:/api/environment/config-fields
     * @secure
     */
    environmentControllerGetConfigFields: (params?: RequestParams) =>
      this.request<EnvironmentConfigField[], any>(
        `/api/environment/config-fields`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags environment
     * @name EnvironmentController_list
     * @request GET:/api/environment
     * @secure
     */
    environmentControllerList: (
      query?: { select?: ("name" | "config")[]; order?: any; where?: any; skip?: number; take?: number },
      params?: RequestParams,
    ) =>
      this.request<EnvironmentPagingResult, any>(
        `/api/environment${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags environment
     * @name EnvironmentController_create
     * @request POST:/api/environment
     * @secure
     */
    environmentControllerCreate: (data: Environment, params?: RequestParams) =>
      this.request<Environment, any>(`/api/environment`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags environment
     * @name EnvironmentController_getAll
     * @request GET:/api/environment/all
     * @secure
     */
    environmentControllerGetAll: (
      query?: { select?: ("name" | "config")[]; order?: any; where?: any },
      params?: RequestParams,
    ) =>
      this.request<Environment[], any>(
        `/api/environment/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags environment
     * @name EnvironmentController_findOne
     * @request GET:/api/environment/findOne
     * @secure
     */
    environmentControllerFindOne: (
      query?: { select?: ("name" | "config")[]; order?: any; where?: any },
      params?: RequestParams,
    ) =>
      this.request<Environment, any>(
        `/api/environment/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags environment
     * @name EnvironmentController_findById
     * @request GET:/api/environment/{id}
     * @secure
     */
    environmentControllerFindById: (id: string, params?: RequestParams) =>
      this.request<Environment, any>(`/api/environment/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags environment
     * @name EnvironmentController_update
     * @request PUT:/api/environment/{id}
     * @secure
     */
    environmentControllerUpdate: (id: string, data: Environment, params?: RequestParams) =>
      this.request<Environment, any>(`/api/environment/${id}`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags environment
     * @name EnvironmentController_deleteById
     * @request DELETE:/api/environment/{id}
     * @secure
     */
    environmentControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/environment/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags asset-config
     * @name AssetConfigController_upsert
     * @request POST:/api/asset-config/upsert
     * @secure
     */
    assetConfigControllerUpsert: (data: AssetConfig, params?: RequestParams) =>
      this.request<AssetConfig, any>(`/api/asset-config/upsert`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags asset-config
     * @name AssetConfigController_list
     * @request GET:/api/asset-config
     * @secure
     */
    assetConfigControllerList: (
      query?: {
        select?: ("id" | "clientVersion" | "odl" | "odb" | "assetBundle" | "platform" | "environment")[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<AssetConfigPagingResult, any>(
        `/api/asset-config${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags asset-config
     * @name AssetConfigController_getAll
     * @request GET:/api/asset-config/all
     * @secure
     */
    assetConfigControllerGetAll: (
      query?: {
        select?: ("id" | "clientVersion" | "odl" | "odb" | "assetBundle" | "platform" | "environment")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<AssetConfig[], any>(
        `/api/asset-config/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags asset-config
     * @name AssetConfigController_findOne
     * @request GET:/api/asset-config/findOne
     * @secure
     */
    assetConfigControllerFindOne: (
      query?: {
        select?: ("id" | "clientVersion" | "odl" | "odb" | "assetBundle" | "platform" | "environment")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<AssetConfig, any>(
        `/api/asset-config/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags asset-config
     * @name AssetConfigController_findById
     * @request GET:/api/asset-config/{id}
     * @secure
     */
    assetConfigControllerFindById: (id: string, params?: RequestParams) =>
      this.request<AssetConfig, any>(`/api/asset-config/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags asset-config
     * @name AssetConfigController_update
     * @request PUT:/api/asset-config/{id}
     * @secure
     */
    assetConfigControllerUpdate: (id: string, data: AssetConfig, params?: RequestParams) =>
      this.request<AssetConfig, any>(`/api/asset-config/${id}`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags asset-config
     * @name AssetConfigController_deleteById
     * @request DELETE:/api/asset-config/{id}
     * @secure
     */
    assetConfigControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/asset-config/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags game-config
     * @name GameConfigController_upsert
     * @request POST:/api/game-config/upsert
     * @secure
     */
    gameConfigControllerUpsert: (data: GameConfig, params?: RequestParams) =>
      this.request<GameConfig, any>(`/api/game-config/upsert`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags game-config
     * @name GameConfigController_forceUpdate
     * @request PUT:/api/game-config/{id}/force
     * @secure
     */
    gameConfigControllerForceUpdate: (id: string, params?: RequestParams) =>
      this.request<GameConfig, any>(`/api/game-config/${id}/force`, "PUT", params, null, BodyType.Json, true),

    /**
     * @tags game-config
     * @name GameConfigController_unforceUpdate
     * @request PUT:/api/game-config/{id}/unforce
     * @secure
     */
    gameConfigControllerUnforceUpdate: (id: string, params?: RequestParams) =>
      this.request<GameConfig, any>(`/api/game-config/${id}/unforce`, "PUT", params, null, BodyType.Json, true),

    /**
     * @tags game-config
     * @name GameConfigController_list
     * @request GET:/api/game-config
     * @secure
     */
    gameConfigControllerList: (
      query?: {
        select?: (
          | "id"
          | "blueprintVersion"
          | "environment"
          | "clientVersion"
          | "score"
          | "keyRedis"
          | "forcedUpdate"
        )[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<GameConfigPagingResult, any>(
        `/api/game-config${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags game-config
     * @name GameConfigController_getAll
     * @request GET:/api/game-config/all
     * @secure
     */
    gameConfigControllerGetAll: (
      query?: {
        select?: (
          | "id"
          | "blueprintVersion"
          | "environment"
          | "clientVersion"
          | "score"
          | "keyRedis"
          | "forcedUpdate"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<GameConfig[], any>(
        `/api/game-config/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags game-config
     * @name GameConfigController_findOne
     * @request GET:/api/game-config/findOne
     * @secure
     */
    gameConfigControllerFindOne: (
      query?: {
        select?: (
          | "id"
          | "blueprintVersion"
          | "environment"
          | "clientVersion"
          | "score"
          | "keyRedis"
          | "forcedUpdate"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<GameConfig, any>(
        `/api/game-config/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags game-config
     * @name GameConfigController_findById
     * @request GET:/api/game-config/{id}
     * @secure
     */
    gameConfigControllerFindById: (id: string, params?: RequestParams) =>
      this.request<GameConfig, any>(`/api/game-config/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags game-config
     * @name GameConfigController_deleteById
     * @request DELETE:/api/game-config/{id}
     * @secure
     */
    gameConfigControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/game-config/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags token
     * @name ApiTokenController_generate
     * @request POST:/api/token
     * @secure
     */
    apiTokenControllerGenerate: (data: GenerateTokenDto, params?: RequestParams) =>
      this.request<GenerateTokenResponseDto, any>(`/api/token`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags cstool/user
     * @name CsUserController_getUser
     * @request GET:/api/cstool/user/{userId}
     */
    csUserControllerGetUser: (userId: string, query: { environment: string }, params?: RequestParams) =>
      this.request<Player, any>(`/api/cstool/user/${userId}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags cstool/user
     * @name CsUserController_updateUser
     * @request PUT:/api/cstool/user/{userId}
     */
    csUserControllerUpdateUser: (
      userId: string,
      query: { environment: string },
      data: UpdatePlayerDto,
      params?: RequestParams,
    ) => this.request<Player, any>(`/api/cstool/user/${userId}${this.addQueryParams(query)}`, "PUT", params, data),

    /**
     * @tags cstool/user
     * @name CsUserController_getCurrencies
     * @request GET:/api/cstool/user/{userId}/currencies
     */
    csUserControllerGetCurrencies: (userId: string, query: { environment: string }, params?: RequestParams) =>
      this.request<BaseCurrencyBlueprint[], any>(
        `/api/cstool/user/${userId}/currencies${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_getAllLeaderBoard
     * @request GET:/api/cstool/user/{userId}/leaderboards
     */
    csUserControllerGetAllLeaderBoard: (userId: string, query: { environment: string }, params?: RequestParams) =>
      this.request<T3LeaderBoardDto[], any>(
        `/api/cstool/user/${userId}/leaderboards${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_banUser
     * @request POST:/api/cstool/user/ban/{id}
     */
    csUserControllerBanUser: (
      id: string,
      query: { environment: string; leaderBoardId: string },
      params?: RequestParams,
    ) => this.request<Player, any>(`/api/cstool/user/ban/${id}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags cstool/user
     * @name CsUserController_massInventory
     * @request POST:/api/cstool/user/mass-inventory
     */
    csUserControllerMassInventory: (query: { environment: string }, data: MassInventoryDto, params?: RequestParams) =>
      this.request<Log, any>(`/api/cstool/user/mass-inventory${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags cstool/user
     * @name CsUserController_getRoomDetail
     * @request GET:/api/cstool/user/get-room-detail/{psaId}/{leaderBoardId}/{roomId}
     */
    csUserControllerGetRoomDetail: (
      psaId: number,
      leaderBoardId: string,
      roomId: string,
      query: { environment: string },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/cstool/user/get-room-detail/${psaId}/${leaderBoardId}/${roomId}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_updateNickname
     * @request POST:/api/cstool/user/update-nickname/{psaId}
     */
    csUserControllerUpdateNickname: (
      psaId: number,
      query: { name: string; environment: string },
      params?: RequestParams,
    ) =>
      this.request<any, any>(`/api/cstool/user/update-nickname/${psaId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags cstool/user
     * @name CsUserController_cheatScore
     * @request GET:/api/cstool/user/cheat-score/{psaId}/{leaderBoardId}/{roomId}/{score}
     */
    csUserControllerCheatScore: (
      psaId: number,
      leaderBoardId: string,
      roomId: string,
      score: number,
      query: { environment: string },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/cstool/user/cheat-score/${psaId}/${leaderBoardId}/${roomId}/${score}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_unbanUser
     * @request POST:/api/cstool/user/unban/{id}
     */
    csUserControllerUnbanUser: (
      id: string,
      query: { environment: string; leaderBoardId: string },
      params?: RequestParams,
    ) => this.request<Player, any>(`/api/cstool/user/unban/${id}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags cstool/user
     * @name CsUserController_scheduleLeaderboard
     * @request POST:/api/cstool/user/schedule-leaderboard/{leaderBoardId}/{currentDab}
     */
    csUserControllerScheduleLeaderboard: (
      leaderBoardId: string,
      currentDab: string,
      query: { environment: string },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/cstool/user/schedule-leaderboard/${leaderBoardId}/${currentDab}${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_updateMilestioneProgress
     * @request POST:/api/cstool/user/milestone/progress/{id}
     */
    csUserControllerUpdateMilestioneProgress: (
      id: string,
      query: {
        environment: string;
        ProgressionId: string;
        CurrentLevelId: string;
        CurrentLevelPoints: number;
        Type: string;
      },
      params?: RequestParams,
    ) =>
      this.request<Player, any>(
        `/api/cstool/user/milestone/progress/${id}${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_resetAccount
     * @request POST:/api/cstool/user/reset/account/{id}
     */
    csUserControllerResetAccount: (id: string, query: { environment: string }, params?: RequestParams) =>
      this.request<Player, any>(`/api/cstool/user/reset/account/${id}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags cstool/user
     * @name CsUserController_unlinkSocial
     * @request POST:/api/cstool/user/unlink/social/{id}
     */
    csUserControllerUnlinkSocial: (id: string, query: { environment: string }, params?: RequestParams) =>
      this.request<Player, any>(`/api/cstool/user/unlink/social/${id}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags cstool/user
     * @name CsUserController_deleteAccount
     * @request POST:/api/cstool/user/delete/account/{id}
     */
    csUserControllerDeleteAccount: (id: string, query: { environment: string }, params?: RequestParams) =>
      this.request<Player, any>(`/api/cstool/user/delete/account/${id}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags cstool/user
     * @name CsUserController_addCurrency
     * @request POST:/api/cstool/user/{userId}/currency
     */
    csUserControllerAddCurrency: (
      userId: string,
      query: { environment: string },
      data: CurrencyDto,
      params?: RequestParams,
    ) =>
      this.request<CurrencyDto, any>(
        `/api/cstool/user/${userId}/currency${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_updateCurrency
     * @request PUT:/api/cstool/user/{userId}/currency
     */
    csUserControllerUpdateCurrency: (
      userId: string,
      query: { environment: string },
      data: CurrencyDto,
      params?: RequestParams,
    ) =>
      this.request<CurrencyDto, any>(
        `/api/cstool/user/${userId}/currency${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_removeCurrency
     * @request DELETE:/api/cstool/user/{userId}/currency
     */
    csUserControllerRemoveCurrency: (
      userId: string,
      query: { environment: string; currency: string },
      params?: RequestParams,
    ) => this.request<any, any>(`/api/cstool/user/${userId}/currency${this.addQueryParams(query)}`, "DELETE", params),

    /**
     * @tags cstool/user
     * @name CsUserController_cheatPlayerLevel
     * @request PUT:/api/cstool/user/{userId}/cheat-player-level
     */
    csUserControllerCheatPlayerLevel: (
      userId: string,
      query: { environment: string; level: number },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/cstool/user/${userId}/cheat-player-level${this.addQueryParams(query)}`,
        "PUT",
        params,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_updateT3Currency
     * @request PUT:/api/cstool/user/{userId}/t3Currency
     */
    csUserControllerUpdateT3Currency: (
      userId: string,
      query: { environment: string },
      data: CurrencyDto,
      params?: RequestParams,
    ) =>
      this.request<T3CurrencyDto, any>(
        `/api/cstool/user/${userId}/t3Currency${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_updateCurrencyV2
     * @request PUT:/api/cstool/user/{userId}/currencyV2
     */
    csUserControllerUpdateCurrencyV2: (
      userId: string,
      query: { environment: string },
      data: CurrencyDto,
      params?: RequestParams,
    ) =>
      this.request<CurrencyDto, any>(
        `/api/cstool/user/${userId}/currencyV2${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_getT3Leaderboards
     * @request GET:/api/cstool/user/get-leaderboard
     */
    csUserControllerGetT3Leaderboards: (
      query: { order?: any; where?: any; skip?: number; take?: number; environment: any },
      params?: RequestParams,
    ) =>
      this.request<any, T3LeaderBoardDto>(
        `/api/cstool/user/get-leaderboard${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_getBlueprintData
     * @request GET:/api/cstool/user/{userId}/blueprint/{type}
     */
    csUserControllerGetBlueprintData: (
      userId: string,
      type: string,
      query: { environment: string; variant?: string },
      params?: RequestParams,
    ) =>
      this.request<BlueprintData[], any>(
        `/api/cstool/user/${userId}/blueprint/${type}${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags cstool/user
     * @name CsUserController_cheatGamePod
     * @request POST:/api/cstool/user/game-pod/{psaId}
     */
    csUserControllerCheatGamePod: (
      psaId: string,
      query: { gamePod: string; environment: string },
      params?: RequestParams,
    ) => this.request<object, any>(`/api/cstool/user/game-pod/${psaId}${this.addQueryParams(query)}`, "POST", params),

    /**
     * @tags audit-log
     * @name AuditLogController_findByEnvironmentAndCreatedAtBetween
     * @request GET:/api/audit-log/find-between
     * @secure
     */
    auditLogControllerFindByEnvironmentAndCreatedAtBetween: (
      query?: { select?: ("id" | "environment" | "action")[]; order?: any; where?: any; skip?: number; take?: number },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/audit-log/find-between${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags leader-board
     * @name LeaderBoardController_getRoomDetail
     * @request GET:/api/leader-board/room-detail
     * @secure
     */
    leaderBoardControllerGetRoomDetail: (params?: RequestParams) =>
      this.request<any, any>(`/api/leader-board/room-detail`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags leader-board
     * @name LeaderBoardController_list
     * @request GET:/api/leader-board
     * @secure
     */
    leaderBoardControllerList: (
      query?: {
        select?: (
          | "LeaderboardId"
          | "environment"
          | "RoomId"
          | "RoomSize"
          | "CurrentJoinedPlayerCount"
          | "RoomEndDate"
          | "RoomStartDate"
          | "RoomDetailInfo"
          | "Type"
          | "IsBanned"
        )[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<T3LeaderBoardPagingResult, any>(
        `/api/leader-board${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags leader-board
     * @name LeaderBoardController_create
     * @request POST:/api/leader-board
     * @secure
     */
    leaderBoardControllerCreate: (data: T3LeaderBoard, params?: RequestParams) =>
      this.request<T3LeaderBoard, any>(`/api/leader-board`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags leader-board
     * @name LeaderBoardController_getAll
     * @request GET:/api/leader-board/all
     * @secure
     */
    leaderBoardControllerGetAll: (
      query?: {
        select?: (
          | "LeaderboardId"
          | "environment"
          | "RoomId"
          | "RoomSize"
          | "CurrentJoinedPlayerCount"
          | "RoomEndDate"
          | "RoomStartDate"
          | "RoomDetailInfo"
          | "Type"
          | "IsBanned"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<T3LeaderBoard[], any>(
        `/api/leader-board/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags leader-board
     * @name LeaderBoardController_findOne
     * @request GET:/api/leader-board/findOne
     * @secure
     */
    leaderBoardControllerFindOne: (
      query?: {
        select?: (
          | "LeaderboardId"
          | "environment"
          | "RoomId"
          | "RoomSize"
          | "CurrentJoinedPlayerCount"
          | "RoomEndDate"
          | "RoomStartDate"
          | "RoomDetailInfo"
          | "Type"
          | "IsBanned"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<T3LeaderBoard, any>(
        `/api/leader-board/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags leader-board
     * @name LeaderBoardController_findById
     * @request GET:/api/leader-board/{id}
     * @secure
     */
    leaderBoardControllerFindById: (id: string, params?: RequestParams) =>
      this.request<T3LeaderBoard, any>(`/api/leader-board/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags leader-board
     * @name LeaderBoardController_update
     * @request PUT:/api/leader-board/{id}
     * @secure
     */
    leaderBoardControllerUpdate: (id: string, data: T3LeaderBoard, params?: RequestParams) =>
      this.request<T3LeaderBoard, any>(`/api/leader-board/${id}`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags leader-board
     * @name LeaderBoardController_deleteById
     * @request DELETE:/api/leader-board/{id}
     * @secure
     */
    leaderBoardControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/leader-board/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags cstool/mail-template
     * @name CsMailTemplateController_list
     * @request GET:/api/cstool/mail-template
     * @secure
     */
    csMailTemplateControllerList: (
      query?: {
        select?: (
          | "id"
          | "templateId"
          | "name"
          | "title"
          | "content"
          | "deepLink"
          | "resources"
          | "expiry"
          | "environment"
        )[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<MailTemplatePagingResult, any>(
        `/api/cstool/mail-template${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/mail-template
     * @name CsMailTemplateController_create
     * @request POST:/api/cstool/mail-template
     * @secure
     */
    csMailTemplateControllerCreate: (data: MailTemplate, params?: RequestParams) =>
      this.request<MailTemplate, any>(`/api/cstool/mail-template`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags cstool/mail-template
     * @name CsMailTemplateController_getAll
     * @request GET:/api/cstool/mail-template/all
     * @secure
     */
    csMailTemplateControllerGetAll: (
      query?: {
        select?: (
          | "id"
          | "templateId"
          | "name"
          | "title"
          | "content"
          | "deepLink"
          | "resources"
          | "expiry"
          | "environment"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<MailTemplate[], any>(
        `/api/cstool/mail-template/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/mail-template
     * @name CsMailTemplateController_findOne
     * @request GET:/api/cstool/mail-template/findOne
     * @secure
     */
    csMailTemplateControllerFindOne: (
      query?: {
        select?: (
          | "id"
          | "templateId"
          | "name"
          | "title"
          | "content"
          | "deepLink"
          | "resources"
          | "expiry"
          | "environment"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<MailTemplate, any>(
        `/api/cstool/mail-template/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/mail-template
     * @name CsMailTemplateController_findById
     * @request GET:/api/cstool/mail-template/{id}
     * @secure
     */
    csMailTemplateControllerFindById: (id: string, params?: RequestParams) =>
      this.request<MailTemplate, any>(`/api/cstool/mail-template/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags cstool/mail-template
     * @name CsMailTemplateController_update
     * @request PUT:/api/cstool/mail-template/{id}
     * @secure
     */
    csMailTemplateControllerUpdate: (id: string, data: MailTemplate, params?: RequestParams) =>
      this.request<MailTemplate, any>(`/api/cstool/mail-template/${id}`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags cstool/mail-template
     * @name CsMailTemplateController_deleteById
     * @request DELETE:/api/cstool/mail-template/{id}
     * @secure
     */
    csMailTemplateControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/cstool/mail-template/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags cstool/mail-template-deploy
     * @name CsMailTemplateDeployController_list
     * @request GET:/api/cstool/mail-template-deploy
     * @secure
     */
    csMailTemplateDeployControllerList: (
      query?: {
        select?: ("id" | "templateId" | "environment")[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<MailTemplateDeployPagingResult, any>(
        `/api/cstool/mail-template-deploy${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/mail-template-deploy
     * @name CsMailTemplateDeployController_create
     * @request POST:/api/cstool/mail-template-deploy
     * @secure
     */
    csMailTemplateDeployControllerCreate: (data: MailTemplateDeploy, params?: RequestParams) =>
      this.request<MailTemplateDeploy, any>(
        `/api/cstool/mail-template-deploy`,
        "POST",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/mail-template-deploy
     * @name CsMailTemplateDeployController_getAll
     * @request GET:/api/cstool/mail-template-deploy/all
     * @secure
     */
    csMailTemplateDeployControllerGetAll: (
      query?: { select?: ("id" | "templateId" | "environment")[]; order?: any; where?: any },
      params?: RequestParams,
    ) =>
      this.request<MailTemplateDeploy[], any>(
        `/api/cstool/mail-template-deploy/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/mail-template-deploy
     * @name CsMailTemplateDeployController_findOne
     * @request GET:/api/cstool/mail-template-deploy/findOne
     * @secure
     */
    csMailTemplateDeployControllerFindOne: (
      query?: { select?: ("id" | "templateId" | "environment")[]; order?: any; where?: any },
      params?: RequestParams,
    ) =>
      this.request<MailTemplateDeploy, any>(
        `/api/cstool/mail-template-deploy/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/mail-template-deploy
     * @name CsMailTemplateDeployController_findById
     * @request GET:/api/cstool/mail-template-deploy/{id}
     * @secure
     */
    csMailTemplateDeployControllerFindById: (id: string, params?: RequestParams) =>
      this.request<MailTemplateDeploy, any>(
        `/api/cstool/mail-template-deploy/${id}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/mail-template-deploy
     * @name CsMailTemplateDeployController_update
     * @request PUT:/api/cstool/mail-template-deploy/{id}
     * @secure
     */
    csMailTemplateDeployControllerUpdate: (id: string, data: MailTemplateDeploy, params?: RequestParams) =>
      this.request<MailTemplateDeploy, any>(
        `/api/cstool/mail-template-deploy/${id}`,
        "PUT",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/mail-template-deploy
     * @name CsMailTemplateDeployController_deleteById
     * @request DELETE:/api/cstool/mail-template-deploy/{id}
     * @secure
     */
    csMailTemplateDeployControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/cstool/mail-template-deploy/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags cstool/inbox
     * @name CsInboxController_getMessageProto
     * @request GET:/api/cstool/inbox/proto/{version}
     * @secure
     */
    csInboxControllerGetMessageProto: (version: InboxVersion, params?: RequestParams) =>
      this.request<InboxMessageProtoDto, any>(
        `/api/cstool/inbox/proto/${version}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/inbox
     * @name CsInboxController_register
     * @request POST:/api/cstool/inbox/register/{version}
     * @secure
     */
    csInboxControllerRegister: (version: InboxVersion, data: InboxDto, params?: RequestParams) =>
      this.request<InboxHistory, any>(
        `/api/cstool/inbox/register/${version}`,
        "POST",
        params,
        data,
        BodyType.FormData,
        true,
      ),

    /**
     * @tags cstool/inbox
     * @name CsInboxController_redeploy
     * @request POST:/api/cstool/inbox/{id}/redeploy
     * @secure
     */
    csInboxControllerRedeploy: (id: number, params?: RequestParams) =>
      this.request<InboxHistory, any>(`/api/cstool/inbox/${id}/redeploy`, "POST", params, null, BodyType.Json, true),

    /**
     * @tags cstool/inbox/history
     * @name CsInboxHistoryController_list
     * @request GET:/api/cstool/inbox/history
     * @secure
     */
    csInboxHistoryControllerList: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "environment"
          | "version"
          | "data"
          | "playerIds"
          | "userEmail"
          | "userAvatar"
        )[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<InboxHistoryPagingResult, any>(
        `/api/cstool/inbox/history${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/inbox/history
     * @name CsInboxHistoryController_getAll
     * @request GET:/api/cstool/inbox/history/all
     * @secure
     */
    csInboxHistoryControllerGetAll: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "environment"
          | "version"
          | "data"
          | "playerIds"
          | "userEmail"
          | "userAvatar"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<InboxHistory[], any>(
        `/api/cstool/inbox/history/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/inbox/history
     * @name CsInboxHistoryController_findOne
     * @request GET:/api/cstool/inbox/history/findOne
     * @secure
     */
    csInboxHistoryControllerFindOne: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "environment"
          | "version"
          | "data"
          | "playerIds"
          | "userEmail"
          | "userAvatar"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<InboxHistory, any>(
        `/api/cstool/inbox/history/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags cstool/inbox/history
     * @name CsInboxHistoryController_findById
     * @request GET:/api/cstool/inbox/history/{id}
     * @secure
     */
    csInboxHistoryControllerFindById: (id: string, params?: RequestParams) =>
      this.request<InboxHistory, any>(`/api/cstool/inbox/history/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags odl
     * @name OdlController_getAllFilesByFolder
     * @request GET:/api/odl/file/scan-dir
     * @secure
     */
    odlControllerGetAllFilesByFolder: (query?: { prefix?: string }, params?: RequestParams) =>
      this.request<OdlFile[], any>(
        `/api/odl/file/scan-dir${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags odl
     * @name OdlController_getAllUnbuildFiles
     * @request GET:/api/odl/file/unbuild
     * @secure
     */
    odlControllerGetAllUnbuildFiles: (query?: { prefix?: string }, params?: RequestParams) =>
      this.request<OdlFile[], any>(
        `/api/odl/file/unbuild${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags odl
     * @name OdlController_getAllFilesOfBuild
     * @request GET:/api/odl/file/build/{buildId}
     * @secure
     */
    odlControllerGetAllFilesOfBuild: (buildId: number, params?: RequestParams) =>
      this.request<OdlFile[], any>(`/api/odl/file/build/${buildId}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags odl
     * @name OdlController_createFolder
     * @request PUT:/api/odl/file/put/folder
     * @secure
     */
    odlControllerCreateFolder: (query: { filename: string; prefix?: string }, params?: RequestParams) =>
      this.request<OdlFile, any>(
        `/api/odl/file/put/folder${this.addQueryParams(query)}`,
        "PUT",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags odl
     * @name OdlController_uploadFile
     * @request PUT:/api/odl/file/put
     * @secure
     */
    odlControllerUploadFile: (data: FileUploadDto, query?: { prefix?: string }, params?: RequestParams) =>
      this.request<OdlFile, any>(
        `/api/odl/file/put${this.addQueryParams(query)}`,
        "PUT",
        params,
        data,
        BodyType.FormData,
        true,
      ),

    /**
     * @tags odl
     * @name OdlController_deleteFile
     * @request DELETE:/api/odl/file/s3
     * @secure
     */
    odlControllerDeleteFile: (data: DeleteFileDto, params?: RequestParams) =>
      this.request<any, any>(`/api/odl/file/s3`, "DELETE", params, data, BodyType.Json, true),

    /**
     * @tags odl
     * @name OdlController_signGetUrl
     * @request GET:/api/odl/file/url
     * @secure
     */
    odlControllerSignGetUrl: (query: { filename: string; prefix?: string }, params?: RequestParams) =>
      this.request<UrlDto, any>(
        `/api/odl/file/url${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags odl
     * @name OdlController_getSupportImageSize
     * @request GET:/api/odl/file/supportImageSize
     * @secure
     */
    odlControllerGetSupportImageSize: (params?: RequestParams) =>
      this.request<any, any>(`/api/odl/file/supportImageSize`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags odl
     * @name OdlController_list
     * @request GET:/api/odl/file
     * @secure
     */
    odlControllerList: (
      query?: {
        select?: (
          | "id"
          | "name"
          | "folder"
          | "contentType"
          | "isDir"
          | "hasThumbnail"
          | "url"
          | "thumbnailUrl"
          | "hash"
          | "lastBuiltAt"
          | "userEmail"
        )[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<OdlFilePagingResult, any>(
        `/api/odl/file${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags odl
     * @name OdlController_getAll
     * @request GET:/api/odl/file/all
     * @secure
     */
    odlControllerGetAll: (
      query?: {
        select?: (
          | "id"
          | "name"
          | "folder"
          | "contentType"
          | "isDir"
          | "hasThumbnail"
          | "url"
          | "thumbnailUrl"
          | "hash"
          | "lastBuiltAt"
          | "userEmail"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<OdlFile[], any>(
        `/api/odl/file/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags odl
     * @name OdlController_findOne
     * @request GET:/api/odl/file/findOne
     * @secure
     */
    odlControllerFindOne: (
      query?: {
        select?: (
          | "id"
          | "name"
          | "folder"
          | "contentType"
          | "isDir"
          | "hasThumbnail"
          | "url"
          | "thumbnailUrl"
          | "hash"
          | "lastBuiltAt"
          | "userEmail"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<OdlFile, any>(
        `/api/odl/file/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags odl
     * @name OdlController_findById
     * @request GET:/api/odl/file/{id}
     * @secure
     */
    odlControllerFindById: (id: string, params?: RequestParams) =>
      this.request<OdlFile, any>(`/api/odl/file/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags odl-build-history
     * @name OdlBuildHistoryController_list
     * @request GET:/api/odl/build/history
     * @secure
     */
    odlBuildHistoryControllerList: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "fileIds"
          | "buildUrl"
          | "buildStatus"
          | "userEmail"
          | "userAvatar"
        )[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<OdlBuildHistoryPagingResult, any>(
        `/api/odl/build/history${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags odl-build-history
     * @name OdlBuildHistoryController_getAll
     * @request GET:/api/odl/build/history/all
     * @secure
     */
    odlBuildHistoryControllerGetAll: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "fileIds"
          | "buildUrl"
          | "buildStatus"
          | "userEmail"
          | "userAvatar"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<OdlBuildHistory[], any>(
        `/api/odl/build/history/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags odl-build-history
     * @name OdlBuildHistoryController_findOne
     * @request GET:/api/odl/build/history/findOne
     * @secure
     */
    odlBuildHistoryControllerFindOne: (
      query?: {
        select?: (
          | "note"
          | "status"
          | "message"
          | "scheduledAt"
          | "startTime"
          | "endTime"
          | "id"
          | "fileIds"
          | "buildUrl"
          | "buildStatus"
          | "userEmail"
          | "userAvatar"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<OdlBuildHistory, any>(
        `/api/odl/build/history/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags odl-build-history
     * @name OdlBuildHistoryController_findById
     * @request GET:/api/odl/build/history/{id}
     * @secure
     */
    odlBuildHistoryControllerFindById: (id: string, params?: RequestParams) =>
      this.request<OdlBuildHistory, any>(`/api/odl/build/history/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags odl-build
     * @name OdlBuildController_register
     * @request POST:/api/odl/build/register
     * @secure
     */
    odlBuildControllerRegister: (data: OdlBuildInputDto, params?: RequestParams) =>
      this.request<OdlBuildHistory, any>(`/api/odl/build/register`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags odl-build
     * @name OdlBuildController_registerRedeploy
     * @request POST:/api/odl/build/{id}/redeploy
     * @secure
     */
    odlBuildControllerRegisterRedeploy: (id: number, params?: RequestParams) =>
      this.request<OdlBuildHistory, any>(`/api/odl/build/${id}/redeploy`, "POST", params, null, BodyType.Json, true),

    /**
     * @tags bot-manager/bot
     * @name BotController_addBots
     * @request POST:/api/bot-manager/bot
     * @secure
     */
    botControllerAddBots: (data: BotBulkCreateDto, params?: RequestParams) =>
      this.request<Bot[], any>(`/api/bot-manager/bot`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags bot-manager/bot
     * @name BotController_list
     * @request GET:/api/bot-manager/bot
     * @secure
     */
    botControllerList: (
      query?: {
        select?: ("id" | "userId" | "playerId" | "leaderBoard" | "score" | "clientVersion" | "environment")[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<BotPagingResult, any>(
        `/api/bot-manager/bot${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags bot-manager/bot
     * @name BotController_updateBot
     * @request PUT:/api/bot-manager/bot/{id}
     * @secure
     */
    botControllerUpdateBot: (id: number, data: BotUpdateDto, params?: RequestParams) =>
      this.request<Bot[], any>(`/api/bot-manager/bot/${id}`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags bot-manager/bot
     * @name BotController_findById
     * @request GET:/api/bot-manager/bot/{id}
     * @secure
     */
    botControllerFindById: (id: string, params?: RequestParams) =>
      this.request<Bot, any>(`/api/bot-manager/bot/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags bot-manager/bot
     * @name BotController_deleteById
     * @request DELETE:/api/bot-manager/bot/{id}
     * @secure
     */
    botControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/bot-manager/bot/${id}`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags bot-manager/bot
     * @name BotController_getLeaderBoards
     * @request GET:/api/bot-manager/bot/leader-board/{environment}
     * @secure
     */
    botControllerGetLeaderBoards: (environment: string, params?: RequestParams) =>
      this.request<LeaderboardDto[], any>(
        `/api/bot-manager/bot/leader-board/${environment}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags bot-manager/bot
     * @name BotController_deleteAll
     * @request DELETE:/api/bot-manager/bot/delete-all
     * @secure
     */
    botControllerDeleteAll: (params?: RequestParams) =>
      this.request<any, any>(`/api/bot-manager/bot/delete-all`, "DELETE", params, null, BodyType.Json, true),

    /**
     * @tags bot-manager/bot
     * @name BotController_updateAllBot
     * @request POST:/api/bot-manager/bot/update-all/{dabName}
     * @secure
     */
    botControllerUpdateAllBot: (
      dabName: string,
      query: { environment: string; forceUpdate: boolean },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/bot-manager/bot/update-all/${dabName}${this.addQueryParams(query)}`,
        "POST",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags bot-manager/bot
     * @name BotController_getAll
     * @request GET:/api/bot-manager/bot/all
     * @secure
     */
    botControllerGetAll: (
      query?: {
        select?: ("id" | "userId" | "playerId" | "leaderBoard" | "score" | "clientVersion" | "environment")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<Bot[], any>(
        `/api/bot-manager/bot/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags bot-manager/bot
     * @name BotController_findOne
     * @request GET:/api/bot-manager/bot/findOne
     * @secure
     */
    botControllerFindOne: (
      query?: {
        select?: ("id" | "userId" | "playerId" | "leaderBoard" | "score" | "clientVersion" | "environment")[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<Bot, any>(
        `/api/bot-manager/bot/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags ava-connect
     * @name AvaConnectController_submitAVA
     * @request GET:/api/ava-connect/submit
     * @secure
     */
    avaConnectControllerSubmitAva: (
      query: {
        version: string;
        type: string;
        inAppId?: string;
        description?: string;
        bucketId?: number;
        environment: string;
        ids?: string;
        bucketName?: string;
        levelConfig?: string;
      },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/ava-connect/submit${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags ava-connect
     * @name AvaConnectController_getENV
     * @request GET:/api/ava-connect/env
     * @secure
     */
    avaConnectControllerGetEnv: (params?: RequestParams) =>
      this.request<any, any>(`/api/ava-connect/env`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags ava-connect
     * @name AvaConnectController_getBucketEventData
     * @request GET:/api/ava-connect/event-data
     * @secure
     */
    avaConnectControllerGetBucketEventData: (
      query: { bucketId: number; version: string; upsert: boolean },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/ava-connect/event-data${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags ava-connect
     * @name AvaConnectController_getConfigByBucketSearch
     * @request GET:/api/ava-connect/config-search
     * @secure
     */
    avaConnectControllerGetConfigByBucketSearch: (
      query: { bucketId: number; searchType?: string },
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/ava-connect/config-search${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags ava-connect
     * @name AvaConnectController_pullDataFromAVA
     * @request POST:/api/ava-connect/pull
     * @secure
     */
    avaConnectControllerPullDataFromAva: (
      query: {
        inAppIds: string;
        bucketId: number;
        version: string;
        environment: string;
        type: string;
        upsert: boolean;
        bucketName: string;
      },
      data: string[],
      params?: RequestParams,
    ) =>
      this.request<any, any>(
        `/api/ava-connect/pull${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags ava-connect
     * @name AvaConnectController_list
     * @request GET:/api/ava-connect
     * @secure
     */
    avaConnectControllerList: (
      query?: {
        select?: (
          | "configId"
          | "configName"
          | "groupConfigId"
          | "groupConfigName"
          | "bucketName"
          | "bucketId"
          | "dabName"
          | "dabId"
          | "schemaName"
          | "schemaId"
          | "environment"
          | "jsonHash"
          | "updatedBy"
          | "isPushing"
        )[];
        order?: any;
        where?: any;
        skip?: number;
        take?: number;
      },
      params?: RequestParams,
    ) =>
      this.request<ConfigPagingResult, any>(
        `/api/ava-connect${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags ava-connect
     * @name AvaConnectController_create
     * @request POST:/api/ava-connect
     * @secure
     */
    avaConnectControllerCreate: (data: Config, params?: RequestParams) =>
      this.request<Config, any>(`/api/ava-connect`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags ava-connect
     * @name AvaConnectController_getAll
     * @request GET:/api/ava-connect/all
     * @secure
     */
    avaConnectControllerGetAll: (
      query?: {
        select?: (
          | "configId"
          | "configName"
          | "groupConfigId"
          | "groupConfigName"
          | "bucketName"
          | "bucketId"
          | "dabName"
          | "dabId"
          | "schemaName"
          | "schemaId"
          | "environment"
          | "jsonHash"
          | "updatedBy"
          | "isPushing"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<Config[], any>(
        `/api/ava-connect/all${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags ava-connect
     * @name AvaConnectController_findOne
     * @request GET:/api/ava-connect/findOne
     * @secure
     */
    avaConnectControllerFindOne: (
      query?: {
        select?: (
          | "configId"
          | "configName"
          | "groupConfigId"
          | "groupConfigName"
          | "bucketName"
          | "bucketId"
          | "dabName"
          | "dabId"
          | "schemaName"
          | "schemaId"
          | "environment"
          | "jsonHash"
          | "updatedBy"
          | "isPushing"
        )[];
        order?: any;
        where?: any;
      },
      params?: RequestParams,
    ) =>
      this.request<Config, any>(
        `/api/ava-connect/findOne${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags ava-connect
     * @name AvaConnectController_findById
     * @request GET:/api/ava-connect/{id}
     * @secure
     */
    avaConnectControllerFindById: (id: string, params?: RequestParams) =>
      this.request<Config, any>(`/api/ava-connect/${id}`, "GET", params, null, BodyType.Json, true),

    /**
     * @tags ava-connect
     * @name AvaConnectController_update
     * @request PUT:/api/ava-connect/{id}
     * @secure
     */
    avaConnectControllerUpdate: (id: string, data: Config, params?: RequestParams) =>
      this.request<Config, any>(`/api/ava-connect/${id}`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags ava-connect
     * @name AvaConnectController_deleteById
     * @request DELETE:/api/ava-connect/{id}
     * @secure
     */
    avaConnectControllerDeleteById: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/api/ava-connect/${id}`, "DELETE", params, null, BodyType.Json, true),
  };
  undefined = {
    /**
     * @tags bot-manager/bot
     * @name undefinedUndefined
     * @request UNDEFINED:undefined
     * @secure
     */
    undefinedUndefined: (params?: RequestParams) =>
      this.request<any, any>(`undefined`, "UNDEFINED", params, null, BodyType.Json, true),
  };
}
