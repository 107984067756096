import React, { createContext, FC, useContext } from 'react';
import { IType } from 'protobufjs';

import { AgColDef } from '@lib/components/ui/ag-grid';
import {
  BlueprintDataFormProps,
  BlueprintDataFormRef,
} from '@lib/pages/blueprint-data/components/form';
import { RouteItem } from '@lib/routes/routes';
import { BlueprintProto } from '@lib/services/models';

export type GridColumnUpdateCallback = (type: string, columnDefs: AgColDef[]) => AgColDef[];

export type TransformBlueprintCallback = (type: string, blueprint: BlueprintProto) => IType;

export type AppBlueprintContext = {
  formComponents?: Record<
    string,
    FC<BlueprintDataFormProps & React.RefAttributes<BlueprintDataFormRef>>
  >;
  onGridColumnUpdate?: GridColumnUpdateCallback;
  transformProto?: TransformBlueprintCallback;
};

export type DocsContext = {
  userGuidePath?: string;
  devGuidePath?: string;
};

export type AppContext = {
  version?: string;
  docs?: DocsContext;
  routes?: RouteItem[];
  blueprint?: AppBlueprintContext;
};

const appContext = createContext<AppContext | null>(null);

export const AppProvider: FC<{ value: AppContext }> = ({ value, children }) => {
  return <appContext.Provider value={value}>{children}</appContext.Provider>;
};

export const useAppContext = () => {
  return useContext(appContext);
};
