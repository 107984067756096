import React, { useEffect } from 'react';
import { Form, Input, Modal } from 'antd';

import BucketSelect from '@lib/components/bucket-select';
import EnvironmentSelect from '@lib/components/env-select';
import { BlueprintData } from '@lib/services/_api';

import { useBlueprintDataContext } from './context';

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface SubmitAvaFormProps {
  bluePrintData?: BlueprintData | null;
  open?: boolean;
  title: string;
  inAppId?: string | string[] | undefined;
  description?: string;
  loading: boolean;
  configGroupSearch?: string;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

const { Item } = Form;

const SubmitAvaForm: React.FC<SubmitAvaFormProps> = ({
  bluePrintData,
  open,
  title,
  inAppId,
  description,
  loading,
  configGroupSearch,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const { environment, setEnvironment } = useBlueprintDataContext();

  useEffect(() => {
    if (inAppId) {
      form.setFieldsValue({
        inAppId,
      });
    } else {
      form.setFieldsValue({
        inAppId: `LevelConfig_${bluePrintData?.objId}`,
      });
    }
  }, [bluePrintData, inAppId]);

  return (
    <Modal
      visible={open}
      title={title}
      okButtonProps={{
        loading,
        // disabled: !unDisableSubmit,
      }}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            await onCreate(values);
            // form.resetFields();
          })
          .catch((info) => {
            // eslint-disable-next-line no-console
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        initialValues={{
          inAppId,
          configGroupSearch,
          description,
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
      >
        <Item name="inAppId" label="inAppId" rules={[{ required: true }]}>
          <Input placeholder="LevelConfig111" />
        </Item>
        <Item name="description" label="Description" rules={[{ required: true }]}>
          <Input placeholder="level design for something ..." />
        </Item>
        <Item label="Environment" rules={[{ required: true }]}>
          <EnvironmentSelect
            onChange={setEnvironment}
            value={environment}
            placeholder="Select environment"
            style={{ minWidth: 250 }}
          />
        </Item>
        <Item label="Bucket" rules={[{ required: true }]}>
          <BucketSelect />
        </Item>
      </Form>
    </Modal>
  );
};

export default SubmitAvaForm;
