import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Button, Col, Divider, Form, Row, Typography } from 'antd';
import moment from 'moment';
import { CopyOutlined, EyeOutlined } from '@ant-design/icons';

import AgGridReact, { ColDef, GridApi, GridReadyEvent } from '@lib/components/ui/ag-grid';
import Loading from '@lib/components/ui/loading';
import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';
import { useAuth } from '@lib/providers/auth';

import { useLevelDataConfigContext } from '@pages/map-level/context';
import { MapLevelDataContext } from '@pages/map-level/utils/models';
import { RuntestStatusClient, useSimulatorContext } from '@pages/simulator/context';
import { LevelsTestResult } from '@services/_api';
import { simulatorService } from '@services/simulator';

import { generatePrefixedID } from '../board/board';
import MainForm from '../create-runtest/main-form';
import { SimulatorComponent } from '../simulator';
import { IResultTable } from '../utils/models';

export const MAPLEVEL_COLLECTION = 'com.alleylabs.legendblast.blueprint.MapLevelCollection';

const ViewDetail: FC = () => {
  const gridApiRef = useRef<GridApi>();
  const { resultSeleted, setCompare } = useSimulatorContext();
  const { setLevelSelect } = useLevelDataConfigContext();
  const { setEnvironment } = useBlueprintDataContext();

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (resultSeleted) {
      if (!resultSeleted.tags || (Array.isArray(resultSeleted.tags) && !resultSeleted.tags?.length))
        resultSeleted.tags = undefined;
      resultSeleted.dateRuntest = moment(resultSeleted?.dateRuntest) as any;
      form.setFieldsValue({ dateRuntest: resultSeleted.dateRuntest });
      form.setFieldsValue(resultSeleted);
    }
  }, [resultSeleted]);

  const {
    setActive,
    setBackPageLevelRuntestResult,
    rowDataViewDetail,
    isBackToListRuntestByLevel,
    setIsBackToListRuntestByLevel,
    setViewDetailSelected,
    boardTabActive,
    formCreate,
    setDataSelected,
    setDab,
    setConfig,
  } = useSimulatorContext();

  const { currentUser } = useAuth();

  const buttonViewRenderer = (props: any) => {
    const handleClick = async () => {
      setLoading(true);
      const levelResult = props.data as LevelsTestResult & { levelId: string };
      const compare: IResultTable = {
        ...(levelResult.resultTable as IResultTable),
        levelId: levelResult.levelId,
      };

      const data = levelResult.levelConfig as MapLevelDataContext;

      if (data) {
        setLevelSelect(data);
      }
      setCompare(compare);
      setViewDetailSelected(levelResult);
      setBackPageLevelRuntestResult(SimulatorComponent.ViewDetailComponent);
      setActive(SimulatorComponent.LevelRuntestResultComponent);
      setLoading(false);
    };

    return <Button icon={<EyeOutlined />} onClick={handleClick} />;
  };

  const initColumn = [
    {
      field: 'requestId',
      minWidth: 100,
    },
    {
      field: 'levelId',
      minWidth: 120,
    },
    { field: 'moves' },
    { field: 'averageWinrate', minWidth: 180 },
    { field: 'averageMovesLeft' },
    { field: 'extraMovesUsed', minWidth: 150 },
    { field: 'powerUpsActivated' },
    {
      headerName: 'View Detail',
      cellRendererFramework: buttonViewRenderer,
      cellStyle: { textAlign: 'center' },
      minWidth: 100,
      maxWidth: 100,
    },
  ];

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(initColumn);

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    gridApiRef.current = e.api;
    e.api.sizeColumnsToFit();
  }, []);

  const cloneHandle = async () => {
    if (resultSeleted) {
      formCreate.setFieldsValue({
        ...resultSeleted,
        runtestId: generatePrefixedID(),
        performer: currentUser?.email,
        dateRuntest: moment(),
      });

      // set new config
      setEnvironment(resultSeleted.env);
      setDab(resultSeleted.dab);

      if (resultSeleted?.levelsTestResult?.length) {
        const res = await simulatorService.getRawData(
          resultSeleted?.levelsTestResult[0]?.requestId ?? '',
        );
        if (res.ok && res.data) {
          setConfig(res.data?.configs);
        }
      }

      const levelSeleted = resultSeleted.levelsTestResult?.reduce(
        (all: any, x: LevelsTestResult) => {
          // eslint-disable-next-line prefer-destructuring
          const levelConfig: Record<string, any> = x.levelConfig;
          levelConfig.id = levelConfig.id.toString();
          levelConfig.general.id = levelConfig.general.id.toString();

          all.push(levelConfig);
          return all;
        },
        [],
      );

      setDataSelected(levelSeleted ?? []);
    }
    setActive(SimulatorComponent.CreateComponent);
  };

  useEffect(() => {
    if (Number(boardTabActive) === RuntestStatusClient.ONGOING) {
      setColumnDefs([
        {
          field: 'levelId',
          minWidth: 120,
        },
        { field: 'moves' },
      ]);
    } else {
      setColumnDefs(initColumn);
    }
  }, [boardTabActive]);

  return (
    <>
      {loading ? (
        <Loading fullscreen />
      ) : (
        <>
          <div className="flex mb-6">
            <Typography.Title level={3}>Result Runtest</Typography.Title>
            <div className="ml-auto flex gap-x-3">
              <Button type="primary" icon={<CopyOutlined />} onClick={cloneHandle}>
                Clone
              </Button>
              <Button
                onClick={() => {
                  if (isBackToListRuntestByLevel) {
                    setActive(SimulatorComponent.ListRuntestResultByLevelComponent);
                    setIsBackToListRuntestByLevel(false);
                  } else setActive(SimulatorComponent.BoardComponent);
                }}
              >
                Exit
              </Button>
            </div>
          </div>
          <Form style={{ paddingBottom: 14 }} form={form}>
            <MainForm isViewDetail />
            <Divider orientation="left">Levels Test Results</Divider>
            <ReactResizeDetector
              handleHeight
              render={({ width, height }) => (
                <Row
                  className="items-stretch flex-1 mt-4 overflow-y-auto"
                  style={{ height, width, minHeight: 400 }}
                >
                  <Col
                    flex="0 0 1000px"
                    className="flex ag-theme-alpine bordered flex-col max-h-full"
                  >
                    <AgGridReact
                      defaultColDef={{
                        floatingFilter: true,
                        sortable: true,
                        suppressMenu: true,
                        resizable: true,
                        flex: 1,
                        minWidth: 100,
                      }}
                      sideBar={{
                        toolPanels: [
                          {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                            toolPanelParams: {
                              suppressRowGroups: true,
                              suppressValues: true,
                              suppressPivots: true,
                              suppressPivotMode: true,
                            },
                          },
                          {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel',
                          },
                        ],
                        position: 'right',
                      }}
                      columnDefs={columnDefs}
                      rowData={rowDataViewDetail}
                      rowSelection="multiple"
                      // onSelectionChanged={handleRowSelected}
                      onGridReady={handleGridReady}
                      suppressRowClickSelection
                    />
                  </Col>
                </Row>
              )}
            />
          </Form>
        </>
      )}
    </>
  );
};

export default ViewDetail;
