import React, { FC } from 'react';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';

import EnvironmentSelect from '@lib/components/env-select';
import { useCsPlayerContext } from '@lib/pages/cstool/search-user/components/context';

import { useLeaderBoardContext } from './context';


const LeaderBoardHeader: FC = () => {
  const {
    type,
    setType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    rangeTime,
    setRangetime,
    leaderboardId,
    setLeaderboardId } = useLeaderBoardContext();

  const { environment, setEnvironment } = useCsPlayerContext();

  return (
    <Row>
      <Form
        // layout="inline"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        style={{ width: '100%' }}
      >
        <Row gutter={16}>
          <Col>
            <Form.Item
              name="environment"
              rules={[{ required: true, message: 'PsaId is required!' }]}
            >
              <EnvironmentSelect
                value={environment}
                style={{ width: 200 }}
                onChange={setEnvironment}
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item name="type" rules={[{ required: true, message: 'PsaId is required!' }]}>
              <Select
                value={environment}
                style={{ width: 200 }}
                onChange={setType}
                placeholder="Select type"
                options={[
                  { label: 'Solo Leaderboard', value: 'solo' },
                  { label: 'League Leaderboard', value: 'league' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="rangeTime"
              rules={[{ required: true, message: 'Range time is required!' }]}
            >
              <DatePicker.RangePicker onChange={setRangetime} style={{ width: 250 }} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="leaderboardQuery">
              <Input
                placeholder="SoloLeaderBoardBallonBlitz"
                onChange={(e: any) => {
                  setLeaderboardId(e.target.value);
                }}
                style={{ width: 250 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default LeaderBoardHeader;
