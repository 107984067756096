import React, { FC, useEffect, useState } from 'react';
import { Form, InputNumber, Select } from 'antd';
import { FormInstance } from 'antd/es/form';

import { PlayerMilestoneProgressionDto } from '@lib/services/models';

import { useCsPlayerContext } from '../context';

const MilestoneForm: FC<{ form: FormInstance; obj?: PlayerMilestoneProgressionDto }> = ({
  form,
  obj,
}) => {
  const { milestoneModal } = useCsPlayerContext();

  const [setMaxProgress] = useState<number>(0);
  const { selected } = milestoneModal;

  const handleMilestoneChange = (id?: string) => {
    setMaxProgress(
      selected?.MilestoneLevels.find((item) => item.LevelId === id)?.PointToComplete ?? 0,
    );
  };

  useEffect(() => {
    if (obj) {
      form.setFieldsValue(obj);
    } else {
      form.resetFields();
    }

    // handleMilestoneChange(obj?.currency);
  }, [obj]);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item
        label="Milestone Level"
        name="milestoneLevel"
        rules={[{ required: true, message: 'Please select level!' }]}
      >
        <Select<string>
          placeholder="Select Milestone Level"
          showSearch
          // disabled={!!obj?.ProgressionId}
          onChange={handleMilestoneChange}
        >
          {obj?.MilestoneLevels.map((item) => (
            <Select.Option key={item.LevelId} value={item.LevelId}>
              {item.LevelId}
              {item.PointToComplete >= 0 && (
                <span className="ml-1">(Max point: {item.PointToComplete.toLocaleString()})</span>
              )}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Progress"
        name="progress"
        rules={[{ required: true, message: 'Please input progress!' }]}
      >
        <InputNumber
          type="number"
          min={0}
          style={{ width: '100%' }}
          // max={maxProgress}
          placeholder="Input amount"
        />
      </Form.Item>
    </Form>
  );
};

export default MilestoneForm;
