import React, { FC, useEffect } from 'react';
import { Checkbox, DatePicker, Form, Input, InputNumber } from 'antd';
import { FormInstance } from 'antd/es/form';

import { useSimulatorContext } from '@pages/simulator/context';

import { IResultTable } from '../utils/models';

import ResultTable from './result-table';

export const MAPLEVEL_COLLECTION = 'com.alleylabs.legendblast.blueprint.MapLevelCollection';

type Props = IResultTable & {
  form?: FormInstance<any>;
  isShowResult?: boolean;
};

const Result: FC<Props> = (props) => {
  const {
    form,
    winRate,
    winRate2,
    movesAndExtra,
    percentileMoveRemain,
    moveWin,
    clearLines,
    lineClear,
    percentileMoveUsed,
    target,
    powerUps,
    powerUps2,
    igb,
    isShowResult = false,
  } = props;
  const { fieldsCheck, compare } = useSimulatorContext();

  const styleHighlight = (field: string) => {
    if (!isShowResult)
      return {
        backgroundColor: fieldsCheck?.includes(field) ? '#69c0ff' : 'inherit',
      };
  };

  useEffect(() => {
    const levelId = form?.getFieldValue('levelId');
    if (compare && !levelId) {
      // eslint-disable-next-line no-unused-expressions
      form?.setFieldsValue({ levelId: compare.levelId });
    }
  }, [compare]);

  return (
    <>
      <Form style={{ paddingBottom: 14 }} form={form}>
        <div className="flex gap-x-6" style={{ marginLeft: 59 }}>
          <Form.Item name="levelId" label="Level ID">
            <Input disabled />
          </Form.Item>
        </div>
        <div className="flex gap-x-6" style={{ marginLeft: 33 }}>
          <Form.Item
            name="runtestId"
            label="Runtest ID"
            rules={[
              {
                required: true,
                message: 'Please input your runtestID!',
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="performer"
            label="Performer"
            rules={[
              {
                required: true,
                message: 'Please input your Performer!',
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
        </div>
        <div style={{ marginLeft: 49 }} className="flex gap-x-6">
          <Form.Item name="attempts" label="Attempts">
            <InputNumber disabled style={styleHighlight('attempts')} defaultValue={0} />
          </Form.Item>
          <Form.Item
            name="dateRuntest"
            label="Datetime"
            rules={[
              {
                required: true,
                message: 'Please input your runtestID!',
              },
            ]}
          >
            <DatePicker disabled showTime />
          </Form.Item>
        </div>
        <div className="flex gap-x-6">
          <Form.Item name="useExtraMoves" label="Use Extra Moves" valuePropName="checked">
            <Checkbox disabled style={styleHighlight('useExtraMoves')} />
          </Form.Item>
          <Form.Item name="maxExtraMoves" label="Max Extra Moves">
            <InputNumber disabled defaultValue={0} style={styleHighlight('maxExtraMoves')} />
          </Form.Item>
        </div>
        <div className="flex gap-x-6" style={{ marginLeft: 50 }}>
          <Form.Item
            name="usePGB"
            style={styleHighlight('usePGB')}
            label="Use PGB"
            valuePropName="checked"
          >
            <Checkbox disabled />
          </Form.Item>
          <Form.Item
            name="missile"
            valuePropName="checked"
            style={styleHighlight('_missile')}
            label="Missile"
          >
            <Checkbox disabled />
          </Form.Item>
          <Form.Item
            name="bomb"
            valuePropName="checked"
            style={styleHighlight('_bomb')}
            label="Bomb"
          >
            <Checkbox disabled />
          </Form.Item>
          <Form.Item
            name="cube"
            style={styleHighlight('_cube')}
            label="Cube"
            valuePropName="checked"
          >
            <Checkbox disabled />
          </Form.Item>
        </div>
        <div style={{ marginLeft: 55, maxWidth: 82 }}>
          <Form.Item
            name="useIGB"
            valuePropName="checked"
            style={styleHighlight('useIGB')}
            label="Use IGB"
          >
            <Checkbox disabled />
          </Form.Item>
        </div>
        <div style={{ marginLeft: 16 }}>
          <Form.Item name="botSkillLevel" label="Bot Skill Level">
            <InputNumber disabled defaultValue={0} style={styleHighlight('botSkillLevel')} />
          </Form.Item>
        </div>
        <div style={{ marginLeft: 50 }}>
          <Form.Item name="topOut" label="Top Out" style={styleHighlight('topOut')}>
            <InputNumber disabled defaultValue={0} />
          </Form.Item>
        </div>
        {((fieldsCheck && fieldsCheck.length > 0) || isShowResult) && (
          <ResultTable
            winRate={winRate}
            winRate2={winRate2}
            movesAndExtra={movesAndExtra}
            percentileMoveRemain={percentileMoveRemain}
            moveWin={moveWin}
            clearLines={clearLines}
            percentileMoveUsed={percentileMoveUsed}
            target={target}
            igb={igb}
            powerUps={powerUps}
            powerUps2={powerUps2}
            lineClear={lineClear}
          />
        )}
      </Form>
    </>
  );
};

export default Result;
