import client from '@lib/services/client';
import { BlueprintDeployDto } from '@lib/services/models';

const {
  blueprintDeployHistoryControllerGetAll,
  blueprintDeployControllerGetChangedTypesFromLastDeploy,
} = client.api;

export const paging = client.api.blueprintDeployHistoryControllerList;

export const registerDeploy = (params: BlueprintDeployDto) =>
  client.api.blueprintDeployControllerRegister(params);

export const registerRedeploy = (deployId: number) =>
  client.api.blueprintDeployControllerRegisterRedeploy(deployId);

export const findDeployTaskById = (id: number) =>
  client.api.blueprintDeployHistoryControllerFindById(id.toString());

export const getDeployHistoryList = client.api.blueprintDeployHistoryControllerList;

export const getLastSuccessfulDeploy = (environment?: string, version?: string) =>
  client.api.blueprintDeployHistoryControllerGetLastSucessfulDeploy({
    environment,
    version,
  });

export const getAllDeployHistory = (
  params?: Parameters<typeof blueprintDeployHistoryControllerGetAll>[0],
) => blueprintDeployHistoryControllerGetAll(params);

export const getChangedTypesFromLastDeploy = (
  params: Parameters<typeof blueprintDeployControllerGetChangedTypesFromLastDeploy>[0],
) => blueprintDeployControllerGetChangedTypesFromLastDeploy(params);

export const getDeployedData = (deployId: number) =>
  client.api.blueprintDeployControllerGetBlueprintDeployed(deployId);

export const getBinaryDeployedData = (deployId: number) =>
  client.api.blueprintDeployControllerGetBinaryBlueprintDeployed(deployId);
