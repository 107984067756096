import React, { FC, useEffect } from 'react';
import { Button, Card, Checkbox, Col, Form, Row, Select } from 'antd';
import _ from 'lodash';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { InputNumberItem } from '@pages/map-level/common';
import { useLevelDataConfigContext } from '@pages/map-level/context';
import { TileBlock } from '@pages/map-level/utils/enumeration';
import { useGlobalObjectCollectionContext } from '@providers/object-collection';

const { Item } = Form;

const ClearObjects: FC<{ viewOnly: boolean }> = ({ viewOnly }) => {
  const { tileMap } = useGlobalObjectCollectionContext();
  const { levelData, formTargetAndRewards } = useLevelDataConfigContext();

  const _tileMap = Object.values(_.cloneDeep(tileMap))
    .filter((item) => !item?.id?.includes('.'))
    .map((ele) => {
      switch (Number(ele.id)) {
        case TileBlock.TileI:
          ele.name = 'TileI';
          break;
        case TileBlock.TileJ:
          ele.name = 'TileJ';
          break;
        case TileBlock.TileL:
          ele.name = 'TileL';
          break;
        case TileBlock.TileO:
          ele.name = 'TileO';
          break;
        case TileBlock.TileS:
          ele.name = 'TileS';
          break;
        case TileBlock.TileT:
          ele.name = 'TileT';
          break;
        case TileBlock.TileZ:
          ele.name = 'TileZ';
          break;

        default:
          break;
      }
      return { ...ele };
    });

  useEffect(() => {
    formTargetAndRewards.setFieldsValue({ clearObjects: levelData.targetsAndRewards.clearObjects });
  }, [levelData.targetsAndRewards.clearObjects]);

  return (
    <div className="mr-3">
      <Form.List name={['clearObjects']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Card
                key={field.key}
                tabIndex={index}
                style={{ marginBottom: 12 }}
                extra={
                  !viewOnly && (
                    <MinusCircleOutlined
                      style={{ color: 'red' }}
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  )
                }
                size="small"
                title={index + 1}
              >
                <Row>
                  <Col span={24}>
                    <div className="flex flex-col gap-3">
                      <Item {...field} name={[field.name, 'objectiveId']} label="Object">
                        <Select
                          disabled={viewOnly}
                          defaultValue={tileMap[0]?.id}
                          style={{ maxWidth: 150, textAlign: 'center' }}
                          showSearch
                          filterOption={(input, option) => {
                            return option?.children.toLowerCase().includes(input.toLowerCase());
                          }}
                        >
                          {_tileMap.map((item) => (
                            <Select.Option key={item.id} value={item.id ?? 0}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Item>

                      <InputNumberItem
                        disabled={viewOnly}
                        field={field}
                        label="Number"
                        name="number"
                      />

                      <Item
                        {...field}
                        label="Is Dynamic"
                        name={[field.name, 'isDynamic']}
                        valuePropName="checked"
                      >
                        <Checkbox disabled={viewOnly} />
                      </Item>
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}
            <Row>
              <Col>
                <Item>
                  <Button disabled={viewOnly} type="dashed" onClick={() => add()}>
                    <PlusOutlined /> Add Object
                  </Button>
                </Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default ClearObjects;
