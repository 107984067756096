import React, { FC, useEffect } from 'react';
import { InputNumber, Popover } from 'antd';

import { useLevelDataConfigContext } from '@pages/map-level/context';
import { GridType } from '@pages/map-level/utils/enumeration';
import { useGlobalObjectCollectionContext } from '@providers/object-collection';

import { DEFAULT_TILE } from '../level-data/layout/tile-select';

import { defaultSquareSize, defaultTileSpace } from './grid-layout';

const WrappedDiv: FC<React.HTMLAttributes<HTMLDivElement>> = (props) => <div {...props} />;
export type CellTileProps = {
  x: number;
  y: number;
  rootX?: number;
  rootY?: number;
  hidden: boolean;
  layerNumber: number;
};

const CellTile: FC<CellTileProps> = ({ x, y, layerNumber }) => {
  const { tileMap, tiles: tileArray } = useGlobalObjectCollectionContext();

  const {
    layers,
    tiles,
    setTile,
    isMouseDown,
    setMouseDown,
    startLine,
    maxHeight,
    selectedTile: tileCheck,
    changedRow,
    levelData,
    hiddenLayer0,
    hiddenLayer1,
    hiddenLayer2,
    hiddenLayer3,
  } = useLevelDataConfigContext();

  const content = (durability: number, spawnRate: number) => {
    return (
      <div>
        <p>
          Durability: <InputNumber value={durability} disabled />
        </p>
        <p>
          Spawn Rate: <InputNumber value={spawnRate} disabled />
        </p>
      </div>
    );
  };

  // const layerRealIndex = layerNumber === 4 ? 0 : layerNumber;

  const cell = tiles[layerNumber] && tiles[layerNumber][`${x}_${y}`];
  const selectedTile = tileMap[cell?.tileId] ?? DEFAULT_TILE;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, h] = '1:1'.split(':').map((item) => parseInt(item, 10));
  // const [_, h] = (selectedTile?.size ?? '1:1').split(':').map((item) => parseInt(item, 10));
  const getImage = () => {
    if (tileMap[cell?.tileId]?.haveSegment) {
      const tileSegmentInfo = tileMap[cell?.tileId].segments?.find(
        (seg) => seg.imageName === cell.image,
      );
      return `url(${tileSegmentInfo?.url}`;
    }

    if (tileMap[cell?.tileId]?.haveMetaData) {
      const metaObject = tileMap[cell?.tileId]?.tileMetaData ?? {};

      const tileSegmentInfo = Object.values(metaObject).find(
        (value: any) => value.imageName === cell?.image,
      );
      return `url(${tileSegmentInfo?.url}`;
    }

    return `url(${tileArray.find((tile) => tile.name === cell?.image)?.url}`;
  };

  const [_widthSize, _heightSize] = (selectedTile?.size ? selectedTile?.size : '1:1')
    .split(':')
    .map((item) => defaultSquareSize * parseInt(item, 10));

  useEffect(() => {
    window.addEventListener('mouseup', () => {
      setMouseDown(false);
    });
  }, []);

  if (hiddenLayer0 && layerNumber === 0) return <></>;
  if (hiddenLayer1 && layerNumber === 1) return <></>;
  if (hiddenLayer2 && layerNumber === 2) return <></>;
  if (hiddenLayer3 && layerNumber === 3) return <></>;

  return (
    <WrappedDiv
      hidden={
        changedRow > 0 &&
        y >= maxHeight - changedRow &&
        levelData.general.gridType === GridType.Segment
      }
      className={`absolute cell-tile
      ${startLine === y ? 'highlight-starting-line' : ''} ${
        cell?.isBlock || cell?.isSubBlock ? 'cell-block' : ''
      }  ${cell?.isHidden ? 'cell-hidden' : ''} ${layerNumber <= layers ? '' : 'hidden'}`}
      style={{
        width: _widthSize,
        height: _heightSize,
        bottom: defaultTileSpace * (y - h + 1),
        left: defaultTileSpace * x,
        zIndex: layerNumber + 1,
      }}
      onClick={() => {
        if (tileCheck) {
          setTile(x, y);
        }
      }}
      onMouseDown={() => {
        if (tileCheck) {
          setMouseDown(true);
          setTile(x, y);
        }
      }}
      onMouseUp={() => {
        setMouseDown(false);
      }}
      onMouseEnter={() => {
        if (tileCheck) {
          if (isMouseDown) setTile(x, y);
        }
      }}
    >
      {cell?.durability ? (
        <Popover content={() => content(cell?.durability || -1, cell?.spawnRate || 0)}>
          <div
            style={{
              marginRight: 4,
              marginBottom: 4,
              backgroundImage: getImage(),
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              fontSize: 16,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            className="absolute top-0 left-0 w-full h-full cell-img"
          >
            {cell?.isCenter ? <div className="bg-gray-50 opacity-75 rounded-full">X</div> : ''}
          </div>
        </Popover>
      ) : (
        <div
          style={{
            marginRight: 4,
            marginBottom: 4,
            backgroundImage: getImage(),
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
          className="absolute top-0 left-0 w-full h-full cell-img"
        >
          {cell?.isCenter ? <div className="bg-gray-50 opacity-75 rounded-full">X</div> : ''}
        </div>
      )}
    </WrappedDiv>
  );
};

export default CellTile;
