import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import _ from 'lodash';

import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';
import {
  BlueprintDataFormProps,
  BlueprintDataFormRef,
} from '@lib/pages/blueprint-data/components/form';
import { useGlobalBlueprintProtoContext } from '@lib/providers/blueprint-proto';
import { useGlobalBlueprintDataVersionContext } from '@lib/providers/blueprint-version';

import MapLevelScreen from '@pages/map-level/map-level';
import ChargeBarCollectionProvider from '@providers/charge-bar-collection';
import ObjectCollectionProvider from '@providers/object-collection';

import { DEFAULT_TILE } from './components/level-data/layout/tile-select';
import { LevelDataConfigContextProvider, useLevelDataConfigContext } from './context';

const MapLevelFormInner = forwardRef<BlueprintDataFormRef, BlueprintDataFormProps>((props, ref) => {
  const { data } = props;

  const { selectedKey: version } = useGlobalBlueprintDataVersionContext();
  const { selected: type } = useGlobalBlueprintProtoContext();
  const {
    initForm,
    formGeneral,
    formTetromino,
    getInitialLevel,
    formBlockerSpawn,
    formTargetAndRewards,
    setLevelSelect,
    getDataMapLevelSave,
    setSelectedTile,
  } = useLevelDataConfigContext();
  const {
    list: { fieldSettings },
  } = useBlueprintDataContext();

  const initFormData = async () => {
    formGeneral.resetFields();

    // get ai score
    if (!version || data === null || data === undefined) return;

    formGeneral.setFieldsValue({ ...data });
    setLevelSelect({ ...data });
  };

  useImperativeHandle(ref, () => ({
    onSave: async () => {
      if (!version) return;

      const saveData = getDataMapLevelSave();

      // // save ai score
      const { ...saveLevelData } = saveData;
      setSelectedTile(DEFAULT_TILE);

      return saveLevelData;
    },
    onClear: (isInit?: boolean) => {
      const { tetromino, blocker, targetsAndRewards } = getInitialLevel();

      formGeneral.setFieldsValue({ ...getInitialLevel() });
      formTetromino.setFieldsValue(tetromino);
      formBlockerSpawn.setFieldsValue({ blockerSpawn: blocker });
      formTargetAndRewards.setFieldsValue(targetsAndRewards);

      setSelectedTile(DEFAULT_TILE);
      initForm(isInit);
    },
  }));

  useEffect(() => {
    initFormData();
  }, [type, data, fieldSettings]);
  return (
    <div className="h-full overflow-hidden">
      <MapLevelScreen props />
    </div>
  );
});

const MapLevelForm = forwardRef<BlueprintDataFormRef, BlueprintDataFormProps>((props, ref) => (
  <ChargeBarCollectionProvider>
    <ObjectCollectionProvider>
      <LevelDataConfigContextProvider>
        <MapLevelFormInner {...props} ref={ref} />
      </LevelDataConfigContextProvider>
    </ObjectCollectionProvider>
  </ChargeBarCollectionProvider>
));

export default MapLevelForm;
