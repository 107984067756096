import React, { createContext, FC, useContext, useState } from 'react';

import { useVisible } from '@lib/utils/use-visible';

const useRRuleContextValue = () => {
  const modal = useVisible();
  const [value, setValue] = useState<string>();

  return {
    value,
    setValue,
    modalVisible: modal.visible,
    toggleModal: modal.toggleVisible,
  };
};

export type RRuleContext = ReturnType<typeof useRRuleContextValue>;

const rruleContext = createContext<RRuleContext | null>(null);

export const useRRuleContext = () => {
  const ctx = useContext(rruleContext);
  if (!ctx) {
    throw new Error('useRRuleContext must be used inside RRuleContextProvider');
  }

  return ctx;
};

export const RRuleContextProvider: FC = ({ children }) => {
  const value = useRRuleContextValue();

  return <rruleContext.Provider value={value}>{children}</rruleContext.Provider>;
};

export default RRuleContextProvider;
