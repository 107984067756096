import client from '@services/client';

export const submitAVA = (
  version: string,
  type: string,
  inAppId: string,
  description: string,
  bucketId: number,
  environment: string,
  ids: string,
  bucketName: string,
) => {
  return client.api.avaConnectControllerSubmitAva({
    version,
    type,
    inAppId,
    description,
    bucketId,
    environment,
    ids,
    bucketName,
  });
};
