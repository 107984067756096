import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';

import TileSelect from './tile-select';

export type ObjectiveFormProps = {
  form: FormInstance;
  // obj?: {
  //   aiScore: any;
  // };
};

const TileForm: FC = () => {
  return (
    <div className="relative p-3" style={{ left: 0 }}>
      <Form layout="vertical">
        <Row style={{ marginTop: -5 }} gutter={16}>
          <Col flex="1 1 100%">
            <TileSelect />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TileForm;
