// import 'dayjs/plugin/duration.d';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(duration);

export const DATE_FORMAT = 'YYYY-MM-DD';
export const FULL_DATE_FORMAT = `dddd, ${DATE_FORMAT}`;
export const SHORT_TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT = `${SHORT_TIME_FORMAT}:ss`;

export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const SHORT_DATE_TIME_FORMAT = `${DATE_FORMAT} ${SHORT_TIME_FORMAT}`;
export const FULL_DATE_TIME_FORMAT = `${FULL_DATE_FORMAT} ${TIME_FORMAT}`;

export default dayjs;
