import React, { FC } from 'react';
import { message, Modal } from 'antd';

import { csUserService } from '@lib/services/cstool';
import { Currency } from '@lib/services/models';

import { useCsPlayerContext } from '../context';

import CurrencyForm from './form';

const CurrencyModal: FC = () => {
  const {
    environment,
    player,
    currencyModal,
    loadPlayer,
    playerId,
    formCurrency: form,
  } = useCsPlayerContext();
  const { selected, modalVisible, toggleModal } = currencyModal;

  const title = selected ? 'Edit Currency' : 'Create Currency';

  const handleClose = () => {
    toggleModal(false);
  };

  const handleSubmit = () => {
    if (!environment || !player) return;

    form
      .validateFields()
      .then(
        async (value) => {
          const currency = value as Currency;
          return selected
            ? csUserService.updateT3Currency(playerId, environment, currency)
            : csUserService.createCurrency(playerId, environment, currency);
        },
        (err) => {
          message.error(err.errorFields[0].errors[0]);
        },
      )
      .then(async (res) => {
        if (res && res.ok) {
          message.success(`${title} successfully`);
          toggleModal(false);
          loadPlayer();
        }
      });
  };

  return (
    <Modal title={title} visible={modalVisible} closable onCancel={handleClose} onOk={handleSubmit}>
      <CurrencyForm form={form} obj={selected} />
    </Modal>
  );
};

export default CurrencyModal;
