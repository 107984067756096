import React, { FC } from 'react';
import { IType } from 'protobufjs';

import FieldFormItem from './field';
import { RenderOptions } from './single-field';

export type TypeFieldFormItemProps = {
  options: RenderOptions<IType>;
};

const TypeFieldFormItem: FC<TypeFieldFormItemProps> = ({ options }) => {
  const { type } = options;

  return (
    <>
      {Object.keys(type.fields).map((subKey) => (
        <FieldFormItem
          key={subKey}
          options={{
            ...options,
            type: type.fields[subKey],
            key: subKey,
          }}
        />
      ))}
    </>
  );
};

export default TypeFieldFormItem;
