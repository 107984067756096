import React, { FC, useEffect } from 'react';
import { Switch, SwitchProps } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';

const RevertedSwitch: FC<SwitchProps> = (props) => {
  const { checked, onChange } = props;

  const handleChange: SwitchChangeEventHandler = (value, e) => {
    if (onChange) {
      onChange(!value, e);
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(!!checked, null as any);
    }
  }, [checked]);

  return <Switch {...props} checked={!checked} onChange={handleChange} />;
};

export default RevertedSwitch;
