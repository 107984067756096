import { FilterOrderOptions } from '@lib/services/base/filter';
import { usePaginate } from '@lib/services/base/paginate';
import client from '@lib/services/client';
import { BlueprintProtoUpdateHistory } from '@lib/services/models';

const { blueprintProtoUpdateHistoryControllerGetAll } = client.api;

export const paging = client.api.blueprintProtoUpdateHistoryControllerList;

export const getAll = async (
  params?: Parameters<typeof blueprintProtoUpdateHistoryControllerGetAll>[0],
) => {
  return blueprintProtoUpdateHistoryControllerGetAll(params);
};

export const useGetBlueprintProtoUpdateHistoryList = (order?: FilterOrderOptions) => {
  return usePaginate<BlueprintProtoUpdateHistory>('/api/blueprint-proto-update-history', { order });
};
