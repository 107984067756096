import React, { FC, useEffect } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import _ from 'lodash';

import { useSimulatorContext } from '@pages/simulator/context';
import { SimulatorComponent } from '@pages/simulator/simulator';

import Result from '../level-runtest-result/result';

const Compare: FC = () => {
  const {
    setActive,
    setFieldsCheck,
    compare,
    compare2,
    compareRuntest,
    compareRuntest2,
    formCompare,
    formCompare2,
    isBackToBoard,
  } = useSimulatorContext();

  const keysCompare = [
    'attempts',
    'useExtraMoves',
    'maxExtraMoves',
    'usePGB',
    'missiles',
    'bomb',
    'cube',
    'useIGB',
    'botSkillLevel',
    'topOut',
  ];

  const { resultLevelSeleted } = useSimulatorContext();

  // const handleCompare = (
  //   keys: any[],
  //   _compare: any,
  //   _compare2: any,
  //   _highlightFields: string[],
  // ) => {
  //   keys.forEach((key) => {
  //     if (Array.isArray(_compare[key])) {
  //       const compareArr = _compare[key] as any;
  //       const compareArr2 = _compare2[key] as any;
  //       if (compareArr.length && compareArr2.length)
  //         if (compareArr.length === 1 && compareArr2.length === 1) {
  //           Object.keys(compareArr[0]).forEach((x) => {
  //             if (
  //               compareArr[0][x]?.toString()?.toLocaleLowerCase() !==
  //                 compareArr2[0][x]?.toString()?.toLocaleLowerCase() &&
  //               x !== 'key'
  //             ) {
  //               _highlightFields.push(x);
  //             }
  //           });
  //         } else {
  //           compareArr.forEach((keyCompare: any) => {
  //             Object.keys(keyCompare).forEach((childKeyCompare) => {
  //               if (
  //                 keyCompare[childKeyCompare]?.toString()?.toLocaleLowerCase() !==
  //                   compareArr2[0][childKeyCompare]?.toString()?.toLocaleLowerCase() &&
  //                 childKeyCompare !== 'key'
  //               ) {
  //                 _highlightFields.push(childKeyCompare);
  //               }
  //             });
  //           });
  //         }
  //     } else if (_compare[key] !== _compare2[key]) {
  //       _highlightFields.push(key);
  //     }
  //   });
  // };

  // refunctionally
  function findDifferentFields(_obj1: any, _obj2: any, path = ''): string[] {
    const differentFields: string[] = [];
    function dfs(obj1: any, obj2: any, currentPath: string): void {
      if (obj1 === obj2) {
        return; // Skip if the same reference
      }

      if (typeof obj1 !== typeof obj2) {
        differentFields.push(currentPath);
        return;
      }

      if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) {
        if (obj1.toString() !== obj2.toString()) {
          differentFields.push(currentPath);
        }
        return;
      }

      if (Array.isArray(obj1)) {
        // if (obj1.length !== obj2.length) {
        //   differentFields.push(currentPath);
        //   return;
        // }

        for (let i = 0; i < obj1.length; i += 1) {
          dfs(obj1[i], obj2[i], `${currentPath}[${i}]`);
        }
      } else {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // eslint-disable-next-line no-restricted-syntax
        for (const key of new Set([...keys1, ...keys2])) {
          if (key !== 'key') dfs(obj1[key], obj2[key], `${key}`);
        }
      }
    }

    dfs(_obj1, _obj2, path);
    return differentFields;
  }

  useEffect(() => {
    const compareData = () => {
      let _highlightFields: string[] = [];
      if (compareRuntest && compareRuntest2 && compare && compare2) {
        // handleCompare(keys, compare, compare2, _highlightFields);
        let compareRuntestContent = findDifferentFields(
          _.pick(compareRuntest, keysCompare),
          _.pick(compareRuntest2, keysCompare),
        );

        compareRuntestContent = compareRuntestContent.map((x) => {
          if (x === 'bomb')
            // eslint-disable-next-line no-param-reassign
            x = '_bomb';
          if (x === 'missiles')
            // eslint-disable-next-line no-param-reassign
            x = '_missile';
          if (x === 'cube')
            // eslint-disable-next-line no-param-reassign
            x = '_cube';

          return x;
        });
        _highlightFields = [...compareRuntestContent, ...findDifferentFields(compare, compare2)];
        if (_highlightFields.find((x) => x === 'bomb')?.length) setFieldsCheck(_highlightFields);
      }
    };

    compareData(); // formcompare2 là dữ liệu gốc cần so sánh

    // Clean up effect
    return () => {
      setFieldsCheck([]);
    };
  }, [compareRuntest, compareRuntest2, compare, compare2]);

  // const onCompare = (values: any) => {
  //   setActive(SimulatorComponent.CompareComponent);
  //   // eslint-disable-next-line no-console
  //   console.log('Received values of form: ', values);
  //   setOpenCompare(false);
  // };

  return (
    <>
      <div className="flex mb-3">
        <Typography.Title level={3}>
          List Runtest Result Compare - {resultLevelSeleted?.levelId}
        </Typography.Title>

        <div className="ml-auto flex gap-x-3">
          <Button
            onClick={() => {
              if (isBackToBoard) {
                setActive(SimulatorComponent.BoardComponent);
              } else {
                setActive(SimulatorComponent.ListRuntestResultByLevelComponent);
              }
            }}
          >
            Exit
          </Button>
        </div>
      </div>

      <Row className="overflow-scroll" gutter={32}>
        <Col className="overflow-x-scroll py-2" style={{ border: '1px solid gray' }} span={12}>
          <Result
            form={formCompare}
            winRate={compare?.winRate}
            winRate2={compare.winRate2}
            movesAndExtra={compare.movesAndExtra}
            percentileMoveRemain={compare.percentileMoveRemain}
            moveWin={compare.moveWin}
            clearLines={compare.clearLines}
            percentileMoveUsed={compare.percentileMoveUsed}
            target={compare.target}
            igb={compare.igb}
            powerUps={compare.powerUps}
            powerUps2={compare.powerUps2}
            lineClear={compare.lineClear}
          />
        </Col>

        <Col className="overflow-x-scroll py-2" style={{ border: '1px solid gray' }} span={12}>
          <Result
            form={formCompare2}
            winRate={compare2.winRate}
            winRate2={compare2.winRate2}
            movesAndExtra={compare2.movesAndExtra}
            percentileMoveRemain={compare.percentileMoveRemain}
            moveWin={compare.moveWin}
            clearLines={compare.clearLines}
            percentileMoveUsed={compare.percentileMoveUsed}
            target={compare2.target}
            igb={compare2.igb}
            powerUps={compare2.powerUps}
            powerUps2={compare2.powerUps2}
            lineClear={compare.lineClear}
          />
        </Col>
      </Row>
    </>
  );
};

export default Compare;
