import { usePaginate } from '@lib/services/base/paginate';
import client from '@lib/services/client';
import { BlueprintDataVersion } from '@lib/services/models';

export const useBlueprintDataVersionList = () => {
  return usePaginate<BlueprintDataVersion>('/api/blueprint-data-version');
};

export const create = (data: BlueprintDataVersion) => {
  return client.api.blueprintDataVersionControllerCreate(data);
};

export const update = (data: BlueprintDataVersion) => {
  return client.api.blueprintDataVersionControllerUpdate(data.name, data);
};

export const destroy = (version: string) => {
  return client.api.blueprintDataVersionControllerDeleteById(version);
};

export const toggleReadOnly = (version: string, readOnly: boolean) => {
  return readOnly
    ? client.api.blueprintDataVersionControllerLock(version)
    : client.api.blueprintDataVersionControllerUnLock(version);
};

export const detailInfo = (version: string) => {
  return client.api.blueprintDataVersionControllerDetail(version);
};
