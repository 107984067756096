import { useState } from 'react';

export type UseVisibleState = {
  visible: boolean;
};

export type UseVisibleAction = {
  toggleVisible: (visible?: boolean) => void;
};

export type UseVisible = UseVisibleState & UseVisibleAction;

export const useVisible = (initialState = false): UseVisible => {
  const [visible, setVisible] = useState(initialState);

  const toggleVisible = (show?: boolean) => setVisible((c) => show ?? !c);

  return {
    visible,
    toggleVisible,
  };
};
