import React, { FC } from 'react';

import BlueprintDataProvider from '@lib/pages/blueprint-data/components/context';
import { useDocumentTitle } from '@lib/utils/use-document-title';

import BotPageBody from './components/body';
import BotProvider from './components/context';
import BotModal from './components/modal';

const BotScreen: FC = () => {
  useDocumentTitle('Bot Manager | Bot');
  return (
    <BlueprintDataProvider>
      <BotProvider>
        <BotPageBody />
        <BotModal />
      </BotProvider>
    </BlueprintDataProvider>
  );
};

export default BotScreen;
