import client from '@lib/services/client';
import { Currency } from '@lib/services/models';

import { MassInventoryDto, UpdatePlayerDto } from '../_api';

export const getUser = (id: string, environment: string) => {
  return client.api.csUserControllerGetUser(id, { environment });
};

export const paging = client.api.csUserControllerGetT3Leaderboards;

export const getAllLeaderBoard = (id: string, environment: string) => {
  return client.api.csUserControllerGetAllLeaderBoard(id, { environment });
};

export const getRoomDetail = (
  psaId: number,
  leaderBoardId: string,
  roomId: string,
  environment: string,
) => {
  return client.api.csUserControllerGetRoomDetail(psaId, leaderBoardId, roomId, { environment });
};

export const getMilestoneProgressions = (id: string, environment: string) => {
  return client.api.csUserControllerGetAllLeaderBoard(id, { environment });
};

export const getLeaderBoards = (bucketId: string) => {
  return client.api.blueprintDeployControllerGetLeaderBoards({ bucketId });
};

export const updateUser = (id: string, environment: string, data: UpdatePlayerDto) => {
  return client.api.csUserControllerUpdateUser(id, { environment }, data);
};

export const banUser = (id: string, environment: string, leaderBoardId: string) => {
  return client.api.csUserControllerBanUser(id, { environment, leaderBoardId });
};

export const massInventory = (env: string, body: MassInventoryDto) => {
  return client.api.csUserControllerMassInventory({ environment: env }, body);
};

export const updateNickname = (psaId: number, name: string, environment: string) => {
  return client.api.csUserControllerUpdateNickname(psaId, { name, environment });
};

export const unbanUser = (id: string, environment: string, leaderBoardId: string) => {
  return client.api.csUserControllerUnbanUser(id, { environment, leaderBoardId });
};

export const resetAccount = (id: string, environment: string) => {
  return client.api.csUserControllerResetAccount(id, { environment });
};

export const unlinkSocial = (id: string, environment: string) => {
  return client.api.csUserControllerUnlinkSocial(id, { environment });
};

export const deleteAccount = (id: string, environment: string) => {
  return client.api.csUserControllerDeleteAccount(id, { environment });
};

export const getCurrencies = (userId: string, environment: string) => {
  return client.api.csUserControllerGetCurrencies(userId, { environment });
};

export const removeCurrency = (userId: string, environment: string, currency: string) => {
  return client.api.csUserControllerRemoveCurrency(userId, { environment, currency });
};

export const updateCurrency = (userId: string, environment: string, currency: Currency) => {
  return client.api.csUserControllerUpdateCurrency(userId, { environment }, currency);
};

export const cheatPlayerLevel = (userId: string, environment: string, level: number) => {
  return client.api.csUserControllerCheatPlayerLevel(userId, { environment, level });
};

export const cheatScore = (
  psaId: number,
  leaderBoardId: string,
  roomId: string,
  score: number,
  environment: string,
) => {
  return client.api.csUserControllerCheatScore(psaId, leaderBoardId, roomId, score, {
    environment,
  });
};

export const updateT3Currency = (userId: string, environment: string, currency: Currency) => {
  return client.api.csUserControllerUpdateT3Currency(userId, { environment }, currency);
};

export const scheduleLeaderboard = (
  LeaderboardId: string,
  currentDab: string,
  environment: string,
) => {
  return client.api.csUserControllerScheduleLeaderboard(LeaderboardId, currentDab, {
    environment,
  });
};

export const createCurrency = (userId: string, environment: string, currency: Currency) => {
  return client.api.csUserControllerAddCurrency(userId, { environment }, currency);
};

export const updateMilestoneProgression = (
  userId: string,
  environment: string,
  ProgressionId: string,
  CurrentLevelId: string,
  CurrentLevelPoints: number,
  Type: string,
) => {
  return client.api.csUserControllerUpdateMilestioneProgress(userId, {
    environment,
    ProgressionId,
    CurrentLevelId,
    CurrentLevelPoints,
    Type,
  });
};

export const getBlueprintData = (
  userId: string,
  environment: string,
  type: string,
  variant?: string,
) => {
  return client.api.csUserControllerGetBlueprintData(userId, type, { environment, variant });
};

export const cheatGamePodPlayer = (psaId: string, gamePod: string, environment: string) => {
  return client.api.csUserControllerCheatGamePod(psaId, {gamePod, environment})
}

export const updateLeaderBoard = (leaderBoardId: string, data: any) => { 
  return null;
  // return client.api.csUserControllerCheatGamePod(leaderBoardId, data);
}
