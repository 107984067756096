import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Badge, Button, message, Modal } from 'antd';
import moment from 'moment';
import {
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  PauseOutlined,
  RetweetOutlined,
} from '@ant-design/icons';

import AgGridReact, { ColDef, GridApi, GridReadyEvent } from '@lib/components/ui/ag-grid';

import { RuntestStatusClient, useSimulatorContext } from '@pages/simulator/context';
import { SimulatorComponent } from '@pages/simulator/simulator';
import { ViewDetail } from '@pages/simulator/utils/models';
import { SimulatorV2 as Simulator } from '@services/_api';
import { simulatorService } from '@services/simulator';

import RunningProgressRenderer from '../running-progress';

import RunningMessage from './infiniteScroll';

import './ongoing.scss';

const { confirm } = Modal;

export const taskKey = 'TASK_KEY';

const OnGoing = () => {
  const gridApiRef = useRef<GridApi>();
  const {
    rowDataOngoing,
    setRowDataOngoing,
    setRowDataStopped,
    fetchTaskStatus,
    setBoardTabActive,
    setResultSelected,
    setBackPageLevelRuntestResult,
    setActive,
    setRowDataViewDetail,
  } = useSimulatorContext();
  const [selectedRows, setSelectedRows] = useState<Simulator[]>([]);
  const [open, setOpen] = useState(false);
  const [openStopModal, setOpenStopModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    setLoading(true);
    const res = await simulatorService.getByStatus(RuntestStatusClient.ONGOING);

    if (res.ok && res.data) {
      const task = res.data as any;
      if (task) {
        // sessionStorage.setItem(taskKey, task.id);
        // fetchTaskStatus(Number(task.id), setRowDataOngoing, setLoading);
        setRowDataOngoing(task.task.data);
      }
    }
  };

  const buttonViewRenderer = (props: any) => {
    const handleClick = async () => {
      try {
        const { data } = props;
        setLoading(true);
        setRowDataViewDetail(
          data?.levelsTestResult?.map((item: any) => {
            const result: ViewDetail = {
              rawData: item.rawData,
              requestId: item.requestId,
              levelId: item.levelConfig?.id,
              moves: item.levelConfig?.general.moves,
              averageWinrate: '0',
              averageMovesLeft: ' 0',
              extraMovesUsed: '0',
              powerUpsActivated: '0',
              levelConfig: item?.levelConfig,
            };
            return result;
          }) ?? [],
        );
        setResultSelected(data);
        data.dateRuntest = moment();
        setBackPageLevelRuntestResult(SimulatorComponent.BoardComponent);
        setActive(SimulatorComponent.ViewDetailComponent);
      } catch (error) {
        message.loading('Loading...');
        message.info('Please try again');
      } finally {
        setLoading(false);
      }
    };

    return <Button loading={loading} icon={<EyeOutlined />} onClick={handleClick} />;
  };

  useEffect(() => {
    fetch();
    const interval = setInterval(() => {
      const getTaskKey = sessionStorage.getItem(taskKey);
      if (!getTaskKey) {
        fetch();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   // eslint-disable-next-line no-debugger
  //   debugger;
  //   const gridApi = gridApiRef.current;
  //   setLoadInit(false);
  //   if (gridApi) {
  //     if (loading && loadInit) {
  //       gridApi.showLoadingOverlay();
  //     } else {
  //       gridApi.hideOverlay();
  //     }
  //   }
  // }, [loading]);

  const messageRenderer = (val: any) => {
    const data = val?.data;

    return <RunningMessage message={data.message} />;
  };

  const buttonStopRenderer = (val: any) => {
    const data = val?.data;

    const handleClick = async () => {
      sessionStorage.removeItem(taskKey);

      if (!loading) {
        const res = await simulatorService.stopRuntest(data.runtestId);
        if (res.ok && res.data) {
          setBoardTabActive(RuntestStatusClient.STOP);
          setRowDataOngoing((cur) => cur.filter((x) => x.runtestId !== data.runtestId));
          setRowDataStopped((cur) => [...cur, data]);
          message.success('Stop successfully');

          if (rowDataOngoing.length) {
            fetch();
          }
        }
      } else {
        setTimeout(() => {
          handleClick();
        }, 2000);
      }
    };

    return (
      <Button
        loading={loading}
        icon={data.status === RuntestStatusClient.STOP ? <RetweetOutlined /> : <PauseOutlined />}
        onClick={handleClick}
      />
    );
  };

  const statusOngoing = (val: any) => {
    const data = val?.data;

    if (data.status === RuntestStatusClient.PENDING)
      return <Badge status="warning" text="PENDING" />;
    if (data.status === RuntestStatusClient.ONGOING)
      return <Badge status="processing" text="PROCESSING" />;
    if (data.status === RuntestStatusClient.RUNTEST_RESULT)
      return <Badge status="success" text="SUCCESS" />;
    return <Badge status="error" text="STOP" />;
  };

  const buttonDeleteRenderer = (val: any) => {
    const data = val?.data;

    const handleDelete = async () => {
      const res = await simulatorService.deleteById(data.runtestId);
      if (res.ok)
        setRowDataOngoing((cur) => {
          return [...cur.filter((x) => x.runtestId !== data.runtestId)];
        });
    };

    const showConfirm = () => {
      confirm({
        title: 'Do you want to delete this item?',
        icon: <ExclamationCircleFilled />,
        // content: 'Some descriptions',
        okButtonProps: { danger: true },
        okText: 'Delete',
        onOk() {
          handleDelete();
        },
        onCancel() {
          // eslint-disable-next-line no-console
          console.log('Cancel');
        },
      });
    };

    return <Button danger icon={<DeleteOutlined />} onClick={showConfirm} />;
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'runtestId',
      filter: 'agTextColumnFilter',
      minWidth: 210,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'performer',
      maxWidth: 220,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'dateString',
      minWidth: 170,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'lastUpdated',
      minWidth: 170,
      filter: 'agTextColumnFilter',
      hide: true,
    },
    {
      field: 'listLevel',
      minWidth: 170,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'listRequestId',
      minWidth: 150,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'message',
      headerName: 'Running Message',
      minWidth: 380,
      cellRendererFramework: messageRenderer,
    },
    {
      field: 'status',
      maxWidth: 130,
      cellRendererFramework: statusOngoing,
    },
    {
      field: 'progress',
      headerName: 'Progress',
      maxWidth: 220,
      cellRendererFramework: RunningProgressRenderer,
    },
    {
      headerName: 'View Detail',
      cellRendererFramework: buttonViewRenderer,
      cellStyle: { textAlign: 'center' },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      colId: 'action',
      headerName: 'Stop/Restart',
      cellRendererFramework: buttonStopRenderer,
      cellStyle: { textAlign: 'center' },
      maxWidth: 100,
    },
    {
      colId: 'action',
      headerName: 'Remove/Delete',
      cellRendererFramework: buttonDeleteRenderer,
      cellStyle: { textAlign: 'center' },
      maxWidth: 100,
    },
  ]);

  function handleRowSelected(event: any) {
    setSelectedRows([]);
    setSelectedRows(event.api.getSelectedNodes().map((node: any) => node.data));
  }

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    gridApiRef.current = e.api;
    e.api.sizeColumnsToFit();

    const updatedDefs = gridApiRef?.current?.getColumnDefs().map((colDef: any) => {
      return {
        ...colDef,
      };
    });
    gridApiRef.current.setColumnDefs(updatedDefs);
  }, []);

  const onDelete = async () => {
    setLoading(true);
    // setRowDataResult(
    //   rowDataResult.filter((a) => !selectedRows.find((b) => b.runtestId === a.runtestId)),
    // );
    // eslint-disable-next-line no-restricted-syntax
    for await (const data of selectedRows) {
      const res = await simulatorService.deleteById(data.runtestId);
      if (res.ok)
        setRowDataOngoing((cur) => {
          return [...cur.filter((x) => x.runtestId !== data.runtestId)];
        });
    }
    setSelectedRows([]);

    setOpen(false);
    setLoading(false);
  };

  const onStop = async () => {
    setLoading(true);
    // eslint-disable-next-line no-restricted-syntax
    for await (const data of selectedRows) {
      const res = await simulatorService.stopRuntest(data.runtestId);
      if (res.ok) message.success(`Stop ${selectedRows.length} runtest successfully`);
    }
    setSelectedRows([]);

    setOpen(false);
    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-y-3 grid-result-simulator">
      {/* <div className="flex justify-between items-center"> */}
      <Modal
        title="Confirm"
        visible={open}
        onOk={onDelete}
        onCancel={() => setOpen(false)}
        cancelButtonProps={{ loading }}
        okButtonProps={{ danger: true, loading }}
        okText="Delete"
        cancelText="Cancel"
      >
        Are you sure to remove {selectedRows.length} runtest?
      </Modal>

      <Modal
        title="Confirm"
        visible={openStopModal}
        onOk={onStop}
        onCancel={() => setOpenStopModal(false)}
        cancelButtonProps={{ loading }}
        okButtonProps={{ danger: true, loading }}
        okText="Stop"
        cancelText="Cancel"
      >
        Are you sure to stop {selectedRows.length} runtest?
      </Modal>
      <div className="ml-auto flex gap-x-3">
        {selectedRows.length > 0 && (
          <Button
            type="primary"
            ghost
            icon={<PauseOutlined />}
            onClick={() => setOpenStopModal(true)}
          >
            Stop {selectedRows.length} {selectedRows.length === 1 ? 'runtest' : 'runtests'}
          </Button>
        )}

        {selectedRows.length > 0 && (
          <Button icon={<CloseOutlined />} danger onClick={() => setOpen(true)}>
            Remove {selectedRows.length} {selectedRows.length === 1 ? 'runtest' : 'runtests'}
          </Button>
        )}
        <Button loading={loading} onClick={fetch}>
          Reload
        </Button>
      </div>
      <ReactResizeDetector
        handleHeight
        render={({ height }) => (
          <div className="flex-1 ag-theme-alpine bordered" style={{ height }}>
            <AgGridReact
              defaultColDef={{
                floatingFilter: true,
                sortable: true,
                suppressMenu: true,
                resizable: true,
                flex: 1,
                minWidth: 100,
                wrapText: true,
              }}
              rowHeight={100}
              columnDefs={columnDefs}
              rowData={rowDataOngoing}
              rowSelection="multiple"
              onSelectionChanged={handleRowSelected}
              onGridReady={handleGridReady}
              suppressRowClickSelection // suppress row click selection
              sideBar={{
                toolPanels: [
                  {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                      suppressRowGroups: true,
                      suppressValues: true,
                      suppressPivots: true,
                      suppressPivotMode: true,
                    },
                  },
                  {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                  },
                ],
                position: 'right',
              }}
            />
          </div>
        )}
      />
      {/* </div> */}
    </div>
  );
};

export default OnGoing;
