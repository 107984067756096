import React, { ReactNode } from 'react';
import { Checkbox } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import { IEnum, IField, IType } from 'protobufjs';

import { RefFieldReference } from '@lib/pages/blueprint-data/components/context';
import { ProtoData } from '@lib/providers/blueprint-proto';
import { BlueprintProto, FieldDataType, FieldSettings } from '@lib/services/models';
import { extractBlueprintType, flattenMapField, formatLabel } from '@lib/utils/blueprint-data';
import dayjs, { DATE_FORMAT, SHORT_DATE_TIME_FORMAT, TIME_FORMAT } from '@lib/utils/dayjs';

import { formatBlueprintName } from './format-label';
import { isAnyType } from './is-any-type';

export type DataIndex = Array<string | number>;

type ExtendProps<T> = Omit<T, 'dataIndex'> & {
  field: string;
  inputType: string | RefFieldReference;
  dataIndex?: DataIndex;
  isArray?: boolean;
};

export type ExtentedColumnType<T> = ExtendProps<ColumnType<T>>;
export type ExtentedColumnGroupType<T> = ExtendProps<ColumnGroupType<T>>;

export type AntColumnType<T = {}> = ExtentedColumnType<T> | ExtentedColumnGroupType<T>;

export type GenerateOptions<T> = {
  types: ProtoData;
  paths: DataIndex;
  type: T;
  fieldSettings: Record<string, FieldSettings>;
  key: string;
  label?: ReactNode;
};

export const COLUMN_WIDTH = 180;

export class TableHeaderGenerator {
  private static renderSingleField<T = {}>(
    options: GenerateOptions<IField>,
  ): ExtentedColumnType<T> {
    const { key, paths, label, type: field, fieldSettings } = options;

    const { type } = field;
    const dataIndex = [...paths];
    if (key !== '') {
      dataIndex.push(key);
    }

    const fieldSetting =
      fieldSettings[dataIndex.filter((part) => typeof part !== 'number').join('.')];
    const formatedKey = label ?? fieldSetting?.displayName ?? formatLabel(key);

    const width = Math.max(COLUMN_WIDTH, formatLabel(key).length * 7.5 + 16);
    const base: ExtentedColumnType<T> = {
      field: key,
      inputType: type,
      title: formatedKey,
      dataIndex,
      width,
      ellipsis: true,
      render: (value) => value ?? '-',
    };

    // TODO: custom renderer to each field type
    if (
      fieldSetting?.dataType === FieldDataType.Date ||
      fieldSetting?.dataType === FieldDataType.DateTime
    ) {
      // date
      base.render = (value: number) => {
        return typeof value === 'number'
          ? dayjs(value * 1000)
              .subtract(dayjs().utcOffset(), 'minute')
              .format(
                fieldSetting?.dataType === FieldDataType.DateTime
                  ? SHORT_DATE_TIME_FORMAT
                  : DATE_FORMAT,
              )
          : value ?? '-';
      };
    } else if (fieldSetting?.dataType === FieldDataType.Time) {
      // time
      base.render = (value: number) => {
        return typeof value === 'number'
          ? dayjs(value * 1000)
              .subtract(dayjs().utcOffset(), 'minute')
              .format(TIME_FORMAT)
          : value ?? '-';
      };
    } else if (['int', 'int32', 'int64', 'float', 'uint32'].indexOf(type) > -1) {
      // number
      base.render = (value: number) => {
        return typeof value === 'number' ? value.toLocaleString() : value ?? '-';
      };
      base.align = 'right';
    } else if (type === 'bool') {
      // boolean
      base.render = (value: boolean) => {
        return typeof value === 'boolean' ? <Checkbox checked={value} disabled /> : value ?? '-';
      };
      base.align = 'center';
    }

    return base;
  }

  private static renderEnum<T = {}>(
    options: GenerateOptions<IEnum>,
    repeated?: boolean,
  ): AntColumnType<T> {
    const { paths, type, key, label, fieldSettings } = options;

    const dataIndex = [...paths, key];
    const fieldSetting =
      fieldSettings[dataIndex.filter((part) => typeof part !== 'number').join('.')];
    const formatedKey = label ?? fieldSetting?.displayName ?? formatLabel(key);

    const enumRefType: RefFieldReference = {
      ...fieldSetting?.reference,
      ...type,
    };

    if (repeated) {
      const child = this.renderEnum<T>(options);
      if (child.dataIndex) {
        child.dataIndex.push(0);
      }

      const wrapper: ExtentedColumnGroupType<T> = {
        isArray: true,
        field: key,
        inputType: enumRefType,
        dataIndex: [...paths, key],
        align: 'center',
        title: '',
        children: [child],
      };

      return wrapper;
    }

    return {
      field: key,
      inputType: enumRefType,
      title: formatedKey,
      dataIndex,
      width: COLUMN_WIDTH,
      ellipsis: true,
      render: (value: number | number[]) => {
        return (Array.isArray(value) ? value : [value]).map((item) => type.values[item]).join(', ');
      },
    } as ExtentedColumnType<T>;
  }

  private static renderField<T = {}>(options: GenerateOptions<IField>): AntColumnType<T> {
    const { types, type, paths, key } = options;
    const dataIndex = [...paths, key];

    if ((type as any).keyType) {
      // map
      return this.renderMap<T>(options);
    }

    const repeated = type.rule === 'repeated';

    if (types[type.type]) {
      // ref type
      const refType = types[type.type];

      // enum
      if ((refType as IEnum).values) {
        return this.renderEnum({ ...options, type: refType as IEnum }, repeated);
      }

      if (repeated) {
        // array
        return this.renderArray(options);
      }

      if ((refType as IType).fields) {
        // nested object
        const wrapper = this.renderSingleField(options);
        delete wrapper.dataIndex;
        delete wrapper.width;

        return {
          ...(wrapper as ExtentedColumnGroupType<T>),
          children: this.renderType({
            ...options,
            paths: dataIndex,
            type: refType as IType,
            label: null,
          }),
        };
      }
    }

    // single type
    if (repeated) {
      // array
      return this.renderArray(options);
    }

    return this.renderSingleField(options);
  }

  private static renderNestedArray<T = {}>(
    options: GenerateOptions<IField>,
  ): ExtentedColumnGroupType<T> {
    const { types, type, paths, key } = options;
    const dataIndex = [...paths, key, 0];

    const wrapper = this.renderSingleField(options);
    delete wrapper.dataIndex;
    delete wrapper.width;

    return {
      ...(wrapper as ExtentedColumnGroupType<T>),
      isArray: true,
      dataIndex: [...paths, key],
      children: this.renderType({
        ...options,
        paths: dataIndex,
        type: types[type.type] as IType,
        label: null,
      }),
    };
  }

  private static renderSimpleArray<T = {}>(
    options: GenerateOptions<IField>,
  ): ExtentedColumnGroupType<T> {
    const { paths, key, fieldSettings } = options;
    const dataIndex = [...paths, key, 0];

    const wrapper = this.renderSingleField(options);
    delete wrapper.dataIndex;
    delete wrapper.width;

    const fieldSetting =
      fieldSettings[dataIndex.filter((part) => typeof part !== 'number').join('.')];
    const label = fieldSetting?.displayName ?? formatLabel(key);

    return {
      ...(wrapper as ExtentedColumnGroupType<T>),
      isArray: true,
      dataIndex: [...paths, key],
      align: 'center',
      title: '',
      children: [
        this.renderSingleField<T>({
          ...options,
          paths: dataIndex,
          key: '',
          label,
        }),
      ],
    };
  }

  private static renderArray<T = {}>(options: GenerateOptions<IField>) {
    const { types, type } = options;

    const newType: IField = { ...type };
    newType.rule = undefined;

    return types[type.type]
      ? this.renderNestedArray<T>({ ...options, type: newType })
      : this.renderSimpleArray<T>({ ...options, type: newType });
  }

  private static renderMap<T = {}>(options: GenerateOptions<IField>) {
    const { types, type } = options;

    const mapType = flattenMapField(type, types);
    const mapField: IField = {
      ...type,
      type: `${(type as any).keyType}:${type.type}Flattened`,
      rule: 'repeated',
    };
    delete (mapField as any).keyType;
    types[mapField.type] = mapType;

    return this.renderArray<T>({ ...options, type: mapField });
  }

  private static renderAnyType<T = {}>(options: GenerateOptions<IType>): AntColumnType<T>[] {
    const { paths: dataIndex, fieldSettings, type, types } = options;

    const fieldSetting = fieldSettings[dataIndex.join('.')];

    const refTypes = (fieldSetting?.anyTypes ?? []).reduce<Record<string, any>>((all, item) => {
      all[item] = formatBlueprintName(item) ?? item;
      return all;
    }, {});
    const refTypeName = [...dataIndex, 'Any.type_url'].join('.');
    const refTypeEnum = { ref: true, values: refTypes } as IEnum;
    types[refTypeName] = refTypeEnum;

    const fields: AntColumnType<T>[] = [
      this.renderEnum({
        ...options,
        type: refTypeEnum,
        key: 'type_url',
        label: 'Type',
      }),
      this.renderSingleField({
        ...options,
        type: { type: 'json', id: 2 },
        key: 'value',
        label: 'Value',
      }),
    ];
    if (type.fields.$key) {
      fields.unshift(
        this.renderSingleField({
          ...options,
          type: { type: 'string', id: 0 },
          key: '$key',
        }),
      );
    }

    return fields;
  }

  public static renderType<T = {}>(options: GenerateOptions<IType>) {
    const { type } = options;

    if (isAnyType(type)) {
      return this.renderAnyType<T>(options);
    }

    return Object.keys(type.fields).map((subKey) =>
      this.renderField({
        ...options,
        type: type.fields[subKey],
        key: subKey,
      }),
    ) as AntColumnType<T>[];
  }

  public static renderBlueprintType<T = {}>(blueprint: BlueprintProto, types: ProtoData) {
    const renderType: IType = extractBlueprintType(blueprint, types);

    const fieldSettings = (blueprint?.settings ?? []).reduce<Record<string, FieldSettings>>(
      (all, setting) => {
        all[setting.field] = { ...setting, reference: undefined };
        return all;
      },
      {},
    );

    (blueprint?.idFields ?? []).forEach((field) => {
      let idFieldSetting = fieldSettings[field];
      if (!idFieldSetting) {
        idFieldSetting = {
          field,
        };
        fieldSettings[field] = idFieldSetting;
      }
      idFieldSetting.required = true;
    });

    return this.renderType<T>({ types, paths: ['data'], type: renderType, fieldSettings, key: '' });
  }
}
