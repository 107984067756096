import React, { FC, useEffect, useState } from 'react';
import { Image, ImageProps, Tooltip } from 'antd';
import { FileData } from 'chonky';
import { FileImageTwoTone, LoadingOutlined } from '@ant-design/icons';

import FlexCenter from '@lib/components/ui/flex-center';
import { OdlFile } from '@lib/services/_api';
import { odlService } from '@lib/services/odl';

const centeredLoading = (
  <FlexCenter>
    <LoadingOutlined />
  </FlexCenter>
);

export const generateOdlPreviewImage = (file: FileData, props?: ImageProps) => {
  return (
    <Image
      src={file.thumbnailUrl}
      preview={{ src: file.url }}
      width={128}
      placeholder={centeredLoading}
      onClick={(e) => e.stopPropagation()}
      {...props}
    />
  );
};

const OdlFileGridCellRenderer: FC<{ value?: string }> = ({ value }) => {
  const [file, setFile] = useState<OdlFile>();

  const loadFile = async () => {
    if (!value || !value.startsWith('odl://')) return;

    const odlFileId = value.slice(6);

    const res = await odlService.getFile(odlFileId);
    if (res.ok && res.data) {
      setFile(res.data);
    }
  };

  useEffect(() => {
    loadFile();
  }, [value]);

  if (!value || !value.startsWith('odl://')) {
    return <>{value ?? '-'}</>;
  }

  if (!file) {
    return (
      <>
        <LoadingOutlined className="mr-1" />
        {value}
      </>
    );
  }

  return (
    <Tooltip placement="left" title={generateOdlPreviewImage(file)}>
      <FileImageTwoTone className="mr-1" /> {file.name}
    </Tooltip>
  );
};

export default OdlFileGridCellRenderer;
