import { IType } from 'protobufjs';

const validAnyFields = ['type_url,value', '$key,type_url,value'];

export const isAnyType = (type: IType) => {
  if (!type) return false;
  if (!type.fields || typeof type.fields !== 'object') return false;

  return validAnyFields.includes(Object.keys(type.fields).sort().join(','));
};
