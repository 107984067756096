import React, { FC } from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import moment, { Moment } from 'moment';

type AntDatePickerProps = React.ComponentProps<typeof AntDatePicker['MonthPicker']>;

export type DatePickerProps<T = string> = Omit<AntDatePickerProps, 'onChange' | 'value'> & {
  valueFormat?: string;
  onChange?: (value: T | null) => void;
  value?: T;
  utcTime?: boolean;
};

const getValue = (val: Moment | null, format?: string, utcTime?: boolean) => {
  if (!val) return null;
  let _val = val;
  if (utcTime) {
    _val = _val.add(moment().utcOffset(), 'minute');
  }
  return format ? _val.format(format) : _val.toISOString();
};

const parseValue = (val?: string, format?: string, utcTime?: boolean) => {
  if (!val) return null;
  let _val = moment(val, format);
  if (utcTime) {
    _val = _val.subtract(moment().utcOffset(), 'minute');
  }
  return _val;
};

export const DatePicker: FC<DatePickerProps> = (props) => {
  const { value, onChange, utcTime, valueFormat, ...remainingProps } = props;

  const _onChange: AntDatePickerProps['onChange'] = (val) => {
    if (typeof onChange === 'function') {
      onChange(getValue(val, valueFormat, utcTime));
    }
  };

  const _value = parseValue(value, valueFormat, utcTime);

  return <AntDatePicker {...remainingProps} onChange={_onChange} value={_value} />;
};
