import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Form, message, Modal, Select, Space } from 'antd';
import { Rule } from 'antd/lib/form';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import BucketSelect from '@lib/components/bucket-select';
import DabSelect from '@lib/components/dab-select';
import EnvironmentSelect from '@lib/components/env-select';
import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';
import { csUserService } from '@lib/services/cstool';
import { UserRole } from '@lib/services/models';
import { useAuthorized } from '@lib/utils/authorize';
import { useQuery } from '@lib/utils/use-query';

const BotPageBody: FC = () => {
  const [form] = Form.useForm();

  const authorized = useAuthorized(UserRole.CsEditor);

  // const { gridRef, modal } = useBotContext();
  // const { setSelected, toggleModal } = modal;
  const { environment, setEnvironment } = useBlueprintDataContext();
  const [bucketName, setBucketName] = useState<string>();
  const [leaderBoardName, setLedaderBoardName] = useState<any>();
  const [dabName, setDabName] = useState<string>();

  const handleUpdateAll = () => {
    // setSelected();
    // toggleModal(true);
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to update all progression ${form.getFieldValue('leaderBoard')}`,
      okButtonProps: { danger: true },
      onOk: async () => {
        const leaderBoard = form.getFieldValue('leaderBoard');
        try {
          const res = await csUserService.scheduleLeaderboard(
            leaderBoard,
            dabName ?? '',
            environment ?? '',
          );

          if (res.ok) {
            return message.success(`Schedule Leaderboard successfully !`);
          }
        } catch (error) {
          return message.error(`Schedule Leaderboard failure !`);
        }
      },
    });
  };

  const validationSchema: Record<string, Rule[]> = {
    env: [{ required: true, message: 'Please select environment!' }],
    dabName: [{ required: true, message: 'Please select dab name!' }],
    // bucket: [{ required: true, message: 'Please input bucket!' }],
  };

  const onChangeBucket = async (val: any) => {
    if (val) {
      const res = await csUserService.getLeaderBoards(val);
      if (res.ok && res.data) {
        const nestedLeaderboard = [
          ...Object.values(res.data).reduce((all, cur) => {
            // eslint-disable-next-line no-param-reassign
            all = [
              ...all,
              ...cur.map((x: string) => {
                return {
                  value: x,
                  label: x,
                };
              }),
            ];

            return all;
          }, []),
        ];
        setLedaderBoardName(nestedLeaderboard);
      }

      setBucketName(val);
    }
  };

  return (
    <Card
      className="flex flex-col flex-1 min-h-full"
      bordered={false}
      bodyStyle={{ padding: 16, display: 'flex', flexDirection: 'column' }}
    >
      <div className="flex ">
        <Form layout="vertical" className="mr-auto" form={form}>
          <Space>
            <Form.Item label="Environment" name="environment" rules={validationSchema.env}>
              <EnvironmentSelect
                onChange={setEnvironment}
                value={environment}
                placeholder="Select environment"
                style={{ minWidth: 250 }}
              />
            </Form.Item>
            <Form.Item
              label="Bucket's Leaderboard"
              name="bucketLeaderBoard"
              style={{ minWidth: 350 }}
            >
              <BucketSelect value={bucketName} showSearch allowClear />
            </Form.Item>
            <Form.Item label="Leaderboard" name="leaderBoard" style={{ minWidth: 350 }}>
              <Select
                placeholder="Select leaderboard"
                options={leaderBoardName}
                showSearch
                allowClear
              />
            </Form.Item>
            <Form.Item
              label="Dab name"
              name="dabName"
              rules={validationSchema.dabName}
              style={{ minWidth: 250 }}
            >
              <DabSelect value={dabName} allowClear onChange={setDabName} />
            </Form.Item>
            <Button
              type="primary"
              // icon={<PlusOutlined />}
              disabled={!authorized || !leaderBoardName || !dabName}
              onClick={handleUpdateAll}
            >
              Update All
            </Button>
          </Space>
        </Form>
      </div>
    </Card>
  );
};

export default BotPageBody;
