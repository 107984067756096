import React, { FC, Suspense, useEffect, useState } from 'react';
import { Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';

import Layout from '@lib/components/layout';
import Loading from '@lib/components/ui/loading';
import { useAppContext } from '@lib/providers/app';
import { UserRole } from '@lib/services/_api';
import { useAuthorized } from '@lib/utils/authorize';

import { appRoutes, RouteItem } from './routes';

export const findRoute = (all: RouteItem[], pathname: string): RouteItem | undefined => {
  return all.reduce<RouteItem | undefined>((matched, item) => {
    if (matched) return matched;

    if (item.path === pathname) return item;
    if (item.children) {
      return findRoute(item.children, pathname);
    }
    return matched;
  }, undefined);
};

export const extractRoute = (all: RouteItem[], route: RouteItem) => {
  if (route.component) {
    all.push(route);
  } else if (route.children) {
    route.children.reduce(extractRoute, all);
  }
  return all;
};

const PrivateRoute: FC<RouteProps & { role?: UserRole }> = ({
  role = UserRole.Authenticated,
  ...routeProps
}) => {
  const authorized = useAuthorized(role);
  return authorized ? <Route {...routeProps} /> : null;
};

const AppRoutes: FC = () => {
  const appCtx = useAppContext();
  const routes = appCtx?.routes ?? appRoutes;
  const history = useHistory();

  useEffect(() => {
    const curPath = history.location.pathname;
    if (curPath) {
      history.push(curPath);
    } else {
      history.push('/blueprint/data');
    }
  }, []);

  const allRoutes = routes.reduce<RouteItem[]>(extractRoute, []);
  const fullscreenRoutes = allRoutes.filter(({ fullscreen }) => fullscreen);
  const withLayoutRoutes = allRoutes.filter(({ fullscreen }) => !fullscreen);

  const location = useLocation();
  const doesCurrentRouteHasLayout = () => {
    const currentRoute = findRoute(routes, location.pathname);
    return !currentRoute || withLayoutRoutes.indexOf(currentRoute) > -1;
  };

  const [showLayout, setShowLayout] = useState(doesCurrentRouteHasLayout());

  useEffect(() => {
    setShowLayout(doesCurrentRouteHasLayout());
  }, [location.pathname]);

  return (
    <Switch>
      <Suspense fallback={<Loading fullscreen />}>
        {fullscreenRoutes.map((item) =>
          item.public ? (
            <Route key={item.path} {...item} />
          ) : (
            <PrivateRoute key={item.path} {...item} />
          ),
        )}
        {showLayout && (
          <Layout>
            {withLayoutRoutes.map((item) =>
              item.public ? (
                <Route key={item.path} {...item} />
              ) : (
                <PrivateRoute key={item.path} {...item} />
              ),
            )}
          </Layout>
        )}
      </Suspense>
    </Switch>
  );
};

export default AppRoutes;
