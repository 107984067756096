import React, { FC, useState } from 'react';
import { Divider, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';

import { useSimulatorContext } from '@pages/simulator/context';

import { IGB, IResultTable } from '../utils/models';

export const MAPLEVEL_COLLECTION = 'com.alleylabs.legendblast.blueprint.MapLevelCollection';

const getCellProps = (_params: any) => {
  return {
    className: 'table-header-cell',
  };
};

const ResultTable: FC<Partial<IResultTable>> = (props) => {
  const {
    winRate,
    winRate2,
    movesAndExtra,
    percentileMoveRemain,
    moveWin,
    clearLines,
    percentileMoveUsed,
    target,
    powerUps,
    powerUps2,
    igb,
    lineClear,
  } = props;

  const { fieldsCheck } = useSimulatorContext();

  const renderHighlight = (value: string) => (text: string) => {
    return <div className={fieldsCheck.includes(value) ? 'bg-blue-300' : ''}>{text}</div>;
  };

  const renderColumn = (key: string, title?: JSX.Element | undefined, rest?: any) => {
    const result = {
      title: title ?? key.charAt(0).toUpperCase() + key.slice(1),
      dataIndex: key,
      key,
      align: 'center',
      render: renderHighlight(key),
      ...rest,
    };

    return result;
  };

  const getColumnWinRate2 = () => {
    const result: any = [];

    for (let index = 0; index <= 15; index += 1) {
      if (index === 0) {
        result.push(
          renderColumn(
            'winCount',
            <div key={index}>
              <div>Win Count</div>
              <div>(no extra)</div>
            </div>,
            { width: 120 },
          ),
        );
      } else
        result.push(
          renderColumn(
            `winCount${index}`,
            <div key={index}>
              <div>Win Count</div>
              <div>({index} extra)</div>
            </div>,
            { width: 80 },
          ),
        );
    }
    return result;
  };

  const [columnsIGB] = useState<ColumnsType<{ key: string } & IGB>>([
    renderColumn('key', <></>, { onCell: getCellProps }),
    renderColumn('undo'),
    renderColumn('rocket'),
    renderColumn('hammer'),
    renderColumn('hold'),
  ]);

  const [columnsWinrate1] = useState<any>([
    renderColumn('winRate'),
    renderColumn('totalAttempt'),
    renderColumn(
      'winCountExtra',
      <div>
        <div>Win Count</div>
        <div>(include extra)</div>
      </div>,
    ),
    renderColumn('loseCount'),
  ]);

  const [columnsMoveWin] = useState<any>([
    renderColumn('maxMoveToWin'),
    renderColumn('minMoveToWin'),
    renderColumn('varMoveToWin'),
    renderColumn('stdMoveToWin'),
  ]);

  const [columnsWinrate2] = useState<any>(getColumnWinRate2());

  const [columnsMovesAndExtra] = useState<any>([
    renderColumn(
      'avgMoveLeft',
      <div>
        <div>AVG Move left</div>
        <div>(Win + EM)</div>
      </div>,
    ),
    renderColumn(
      'avgMoveUsed',
      <div>
        <div>AVG Move used</div>
        <div>(Win + EM)</div>
      </div>,
    ),
    renderColumn(
      'maxMoveLeft',
      <div>
        <div>Max Move Left</div>
        <div>(no EM)</div>
      </div>,
    ),
    renderColumn(
      'minMoveLeft',
      <div>
        <div>Min Move Left</div>
        <div>(no EM)</div>
      </div>,
    ),
    renderColumn(
      'varMoveLeft',
      <div>
        <div>Var Move Left</div>
      </div>,
    ),
  ]);

  const [columnsClearLines] = useState<any>([
    renderColumn(
      'avgMovesToClearLines',
      <div>
        <div>AVG Move to</div>
        <div>Clear Lines</div>
      </div>,
    ),
    renderColumn(
      'maxMovesToClearLines',
      <div>
        <div>Max Move to</div>
        <div>Clear Lines</div>
      </div>,
    ),
    renderColumn(
      'avgSimultaneousLinesCleared',
      <div>
        <div>AVG Simultaneous</div>
        <div>Lines Cleared</div>
      </div>,
    ),
    renderColumn(
      'maxSimultaneousLinesCleared',
      <div>
        <div>MAX Simultaneous</div>
        <div>Lines Cleared</div>
      </div>,
    ),
  ]);

  const columnsPercentile = (hasRemain: boolean) => {
    return [
      renderColumn(
        '0',
        <div>
          <div>0% {hasRemain ? 'Move left' : 'Move used'} to Win</div>
        </div>,
      ),
      renderColumn(
        '25',
        <div>
          <div>25% {hasRemain ? 'Move left' : 'Move used'} to Win</div>
        </div>,
      ),
      renderColumn(
        '50',
        <div>
          <div>50% {hasRemain ? 'Move left' : 'Move used'} to Win</div>
        </div>,
      ),
      renderColumn(
        '75',
        <div>
          <div>75% {hasRemain ? 'Move left' : 'Move used'} to Win</div>
        </div>,
      ),
      renderColumn(
        '90',
        <div>
          <div>90% {hasRemain ? 'Move left' : 'Move used'} to Win</div>
        </div>,
      ),
      renderColumn(
        '100',
        <div>
          <div>100% {hasRemain ? 'Move left' : 'Move used'} to Win</div>
        </div>,
      ),
    ];
  };

  const [columnsTarget] = useState<any>([
    renderColumn('key', <></>, { onCell: getCellProps }),
    renderColumn('object1'),
    renderColumn('object2'),
    renderColumn('object3'),
    renderColumn('object4'),
  ]);

  const [columnsPowerUps] = useState<any>([
    renderColumn('key', <></>, { onCell: getCellProps, width: 160 }),
    renderColumn('dynamite'),
    renderColumn('missile'),
    renderColumn('bomb'),
    renderColumn('cube'),
    renderColumn('seeker'),
    renderColumn('miniCube'),
  ]);

  const [columnsPowerUps2] = useState<any>([
    renderColumn('key', <></>, { onCell: getCellProps, width: 160 }),
    renderColumn(
      'dynamiteDynamite',
      <div>
        <div>Dynamite</div>
        <div>Dynamite</div>
      </div>,
      { width: 50 },
    ),
    renderColumn(
      'dynamiteMissile',
      <div>
        <div>Dynamite</div>
        <div>Missile</div>
      </div>,
    ),
    renderColumn(
      'dynamiteBomb',
      <div>
        <div>Dynamite</div>
        <div>Bomb</div>
      </div>,
    ),
    renderColumn(
      'dynamiteCube',
      <div>
        <div>Dynamite</div>
        <div>Cube</div>
      </div>,
    ),
    renderColumn(
      'missileMissile',
      <div>
        <div>Missile</div>
        <div>Missile</div>
      </div>,
    ),
    renderColumn(
      'missileBomb',
      <div>
        <div>Missile</div>
        <div>Bomb</div>
      </div>,
    ),
    renderColumn(
      'missileCube',
      <div>
        <div>Missile</div>
        <div>Cube</div>
      </div>,
    ),
    renderColumn(
      'bombBomb',
      <div>
        <div>Bomb</div>
        <div>Bomb</div>
      </div>,
    ),
    renderColumn(
      'bombCube',
      <div>
        <div>Bomb</div>
        <div>Cube</div>
      </div>,
    ),
    renderColumn(
      'cubeCube',
      <div>
        <div>Cube</div>
        <div>Cube</div>
      </div>,
    ),
  ]);

  const [columnsLineClear] = useState<any>([
    renderColumn('maxRowHeight'),
    renderColumn(
      'maxNoOfTopOuts',
      <div>
        <div>Max no. of</div>
        <div>Top Outs</div>
      </div>,
    ),
    renderColumn('maxCascades'),
    renderColumn('avgCascades'),
  ]);

  return (
    <>
      <Divider orientation="left">Result - Win rate</Divider>
      <div className="flex flex-col gap-y-3 mb-6">
        <Table
          className="bg-red-300"
          columns={columnsWinrate1}
          dataSource={winRate}
          bordered
          pagination={false}
          style={{ width: 450 }}
          size="small"
        />

        <Table
          columns={columnsWinrate2}
          dataSource={winRate2}
          bordered
          pagination={false}
          style={{}}
          size="small"
        />
      </div>

      <Divider orientation="left">Results - Moves & ExtraMoves (EM)</Divider>
      <div className="flex flex-col gap-y-3">
        <Table
          columns={columnsMovesAndExtra}
          dataSource={movesAndExtra}
          bordered
          pagination={false}
          style={{ width: 650 }}
          size="small"
        />

        <Table
          columns={columnsPercentile(true)}
          dataSource={percentileMoveRemain}
          bordered
          pagination={false}
          style={{ width: 1000 }}
          size="small"
        />

        <Table
          className="bg-red-300"
          columns={columnsMoveWin}
          dataSource={moveWin}
          bordered
          pagination={false}
          style={{ width: 450 }}
          size="small"
        />

        <Table
          columns={columnsPercentile(false)}
          dataSource={percentileMoveUsed}
          bordered
          pagination={false}
          style={{ width: 1000 }}
          size="small"
        />

        <Table
          className="bg-red-300"
          columns={columnsClearLines}
          dataSource={clearLines}
          bordered
          pagination={false}
          style={{ width: 550 }}
          size="small"
        />
      </div>

      <Divider orientation="left">Results - Target</Divider>

      <Table
        columns={columnsTarget}
        dataSource={target}
        bordered
        pagination={false}
        style={{ width: 650 }}
        size="small"
      />

      <Divider orientation="left">Results - Power-ups</Divider>

      <div className="flex flex-col gap-y-3 mb-6">
        <Table
          columns={columnsPowerUps}
          dataSource={powerUps}
          bordered
          pagination={false}
          style={{ width: 850 }}
          size="small"
        />

        <Table
          columns={columnsPowerUps2}
          dataSource={powerUps2}
          bordered
          pagination={false}
          style={{}}
          size="small"
        />
      </div>

      <Divider orientation="left">Results - IGB</Divider>

      <Table
        columns={columnsIGB}
        dataSource={igb}
        bordered
        pagination={false}
        style={{ width: 650 }}
        size="small"
      />

      <Divider orientation="left">Results - Line Clear</Divider>

      <Table
        columns={columnsLineClear}
        dataSource={lineClear}
        bordered
        pagination={false}
        style={{ width: 650 }}
        size="small"
      />
    </>
  );
};

export default ResultTable;
