import React, { FC } from 'react';
import { Form, message, Modal } from 'antd';

import { csUserService } from '@lib/services/cstool';
import { leaderBoardService } from '@lib/services/leader-board';
import { T3LeaderBoardDto as T3LeaderBoard } from '@lib/services/models';

import { useCsPlayerContext } from '../../context';

import RoomLeaderBoardForm from './form';

const LeaderBoardModal: FC = () => {
  const { modal, playerLeaderBoards, environment } = useCsPlayerContext();

  const { toggleModal, modalVisible, selected } = modal;

  const [form] = Form.useForm();

  const handleClose = () => {
    toggleModal(false);
  };

  const title = selected ? 'View Room Leader Board' : 'Create Mail Template';

  const handleSubmit = () => {
    form
      .validateFields()
      .then(
        (value) => {
          // delete value.resourceCount;

          return (
            selected &&
            leaderBoardService.update(selected.RoomId, {
              ...selected,
              ...value,
              environment,
            } as T3LeaderBoard)
          );
          // : csMailTemplateService.create(value as MailTemplate);
        },
        (err) => {
          message.error(err.errorFields[0].errors[0]);
        },
      )
      .then(async (res) => {
        if (res && res.ok) {
          message.success(`${title} successfully`);
          // fetch();
          toggleModal(false);
        }
      });
  };

  return (
    <Modal
      title={title}
      visible={modalVisible}
      closable
      onCancel={handleClose}
      onOk={handleSubmit}
      width={600}
    >
      <RoomLeaderBoardForm form={form} obj={selected} />
    </Modal>
  );
};

export default LeaderBoardModal;
