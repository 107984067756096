export type RequestQueryParamsType = Record<string | number, any>;

const formatQueryParam = (query: RequestQueryParamsType, key: string) => {
  let value: any = query[key];
  if (Array.isArray(value)) {
    value = value.join(',');
  } else if (typeof value === 'object') {
    value = JSON.stringify(value);
  }
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
};

const formatQueryParams = (rawQuery?: RequestQueryParamsType): string => {
  const query = rawQuery || {};
  const keys = Object.keys(query).filter((key) => typeof query[key] !== 'undefined');
  return keys.length ? `?${keys.map((key) => formatQueryParam(query, key)).join('&')}` : '';
};

export default formatQueryParams;
