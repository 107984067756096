import '@lib/assets/styles';

import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AuthProvider } from '@lib/providers/auth';
import { AvaConfigProvider } from '@lib/providers/ava-config';
import BlueprintProtoProvider from '@lib/providers/blueprint-proto';
import BlueprintDataVersionProvider from '@lib/providers/blueprint-version';
import { AppConfigProvider } from '@lib/providers/config';
import { SystemHealthProvider } from '@lib/providers/health';
import HttpProvider from '@lib/providers/http';
import AppRoutes from '@lib/routes/router';

const App: FC = () => (
  <RecoilRoot>
    <BrowserRouter>
      <SystemHealthProvider>
        <AppConfigProvider>
          <AuthProvider>
            <HttpProvider>
              <AvaConfigProvider>
                <BlueprintDataVersionProvider>
                  <BlueprintProtoProvider>
                    <AppRoutes />
                  </BlueprintProtoProvider>
                </BlueprintDataVersionProvider>
              </AvaConfigProvider>
            </HttpProvider>
          </AuthProvider>
        </AppConfigProvider>
      </SystemHealthProvider>
    </BrowserRouter>
  </RecoilRoot>
);

export default App;
