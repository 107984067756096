import { LevelsTestResult, SimulatorV2 } from '@services/_api';
import client from '@services/client';

export const getAll = async () => {
  const res = await client.api.simulatorControllerGetAll();

  return res;
};

export const paging = client.api.simulatorControllerGetRuntestPaging;

export const deleteById = async (id: string) => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerDeleteById(id);
};

export const createRuntest = async (bucketIds: number[], params: SimulatorV2 & any) => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerCreateRuntest({ bucketIds }, { ...params });
};

export const getDatafilter = async () => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerGetDataFilter();
};

export const getRawData = async (requestId: string) => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerGetRawData({ requestId });
};

export const calculateData = async (runtestId: string) => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerCalculateData({ runtestId });
};

// export const getConfigsDab = async (bucketId: number[]) => {
//   const res = await client.api.simulatorControllerGetConfigDab({
//     bucketId: JSON.stringify(bucketId),
//   });

//   return res;
// };

export const getMaxId = async () => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerGetMaxId();
};

export const getLevelResult = async () => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerGetLevelResult();
};

export const getByStatus = async (status: number) => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerGetByStatus({ status });
};

export const getDataDetail = async (levelTestResult: LevelsTestResult) => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerGetDateDetail({ ...levelTestResult });
};

export const restartRuntest = async (runtestId: string) => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerRestart({ runtestId });
};

export const stopRuntest = async (runtestId: string) => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerStop({ runtestId });
};

export const deleteLevels = async (levelId: string, runtests: string[]) => {
  // eslint-disable-next-line no-return-await
  return await client.api.simulatorControllerDeleteLevels({ levelId }, runtests);
};
