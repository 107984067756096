import { usePaginate } from '@lib/services/base/paginate';
import client from '@lib/services/client';
import { Environment } from '@lib/services/models';

export const getEnvironments = () => {
  return client.api.environmentControllerGetAll();
};

export const getConfigFields = () => {
  return client.api.environmentControllerGetConfigFields();
};

export const useEnvironmentList = () => {
  return usePaginate<Environment>('/api/environment');
};

export const create = (data: Environment) => {
  return client.api.environmentControllerCreate(data);
};

export const update = (data: Environment) => {
  return client.api.environmentControllerUpdate(data.name, data);
};

export const destroy = (name: string) => {
  return client.api.environmentControllerDeleteById(name);
};
