import React, { ReactNode, useImperativeHandle, useState } from 'react';
import { ServerSideStoreType } from '@ag-grid-enterprise/all-modules';

import { FilterOrderOptions } from '@lib/services/base/filter';
import { PaginateRequestFunc, PagingOptions } from '@lib/services/base/paginate';
import { OrderDirection } from '@lib/services/models';

import AgGridReact, { AgGridReactProps, GridApi, IServerSideDatasource } from '../ui/ag-grid';

export type PagingAgGridProps<F, K> = AgGridReactProps & {
  request: F;
  requestParams?: K;
};

export type PagingAgGridRef = {
  fetch: () => void;
};

const PagingAgGridInner = <
  T,
  F extends PaginateRequestFunc<T> = PaginateRequestFunc<T>,
  K extends PagingOptions<T> = PagingOptions<T>
>(
  props: PagingAgGridProps<F, K> & { children?: ReactNode },
  ref?: React.RefObject<PagingAgGridRef> | ((instance: PagingAgGridRef) => void) | null,
) => {
  const { request, requestParams, onGridReady, ...remainProps } = props;

  const [gridApi, setGridApi] = useState<GridApi>();

  const handleLoading = (loading: boolean) => {
    if (!gridApi) return;
    return loading ? gridApi.showLoadingOverlay() : gridApi.hideOverlay();
  };

  const handleNoData = (noData: boolean) => {
    if (!gridApi) return;
    return noData ? gridApi.showNoRowsOverlay() : gridApi.hideOverlay();
  };

  const datasource: IServerSideDatasource = {
    getRows: async (params) => {
      const {
        success,
        fail,
        request: { startRow, endRow, sortModel },
      } = params;

      handleLoading(true);

      const pagingOptions: PagingOptions<T> = {
        ...requestParams,
        skip: startRow,
        take: endRow - startRow,
        order: (sortModel as any[]).reduce<FilterOrderOptions<T>>(
          (all, item: { colId: Extract<keyof T, string>; sort: string }) => {
            all[item.colId] = item.sort.toUpperCase() as OrderDirection;
            return all;
          },
          requestParams?.order ?? {},
        ),
      };

      const res = await request(pagingOptions);
      const { ok, data } = res;
      if (ok && data) {
        success({ rowData: data.data, rowCount: data.total });
        handleNoData(!data.total);
      } else {
        fail();
      }
      handleLoading(false);
    },
  };

  const handleGridReady: AgGridReactProps['onGridColumnsChanged'] = (e) => {
    const { api } = e;
    api.sizeColumnsToFit();
    api.addEventListener('gridColumnsChanged', () => {
      api.sizeColumnsToFit();
    });

    api.setServerSideDatasource(datasource);

    if (typeof onGridReady === 'function') {
      onGridReady(e);
    }

    setGridApi(api);
  };

  const handleRefetch = () => {
    if (!gridApi) return;
    gridApi.refreshServerSideStore({ purge: true });
  };

  useImperativeHandle(ref, () => ({
    fetch: handleRefetch,
  }));

  return (
    <AgGridReact
      // server size
      rowModelType="serverSide"
      serverSideStoreType={ServerSideStoreType.Partial}
      cacheBlockSize={100}
      maxBlocksInCache={10}
      blockLoadDebounceMillis={100}
      {...remainProps}
      onGridReady={handleGridReady}
    />
  );
};

const PagingAgGridForwarded = React.forwardRef(PagingAgGridInner);

const PagingAgGrid = <
  T,
  F extends PaginateRequestFunc<T> = PaginateRequestFunc<T>,
  K extends PagingOptions<T> = PagingOptions<T>
>({
  actionRef,
  ...rest
}: PagingAgGridProps<F, K> & { actionRef?: React.Ref<PagingAgGridRef> }) => (
  <PagingAgGridForwarded {...rest} ref={actionRef} />
);

export default PagingAgGrid;
