import React, { FC } from 'react';
import { Button, Card, Col, Form, Row, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { InputNumberItem } from '@pages/map-level/common';
import { useGlobalObjectCollectionContext } from '@providers/object-collection';

const { Item, List } = Form;

const Rewards: FC<{ viewOnly: boolean }> = ({ viewOnly }) => {
  const { tileMap } = useGlobalObjectCollectionContext();

  return (
    <div className="w-full">
      <List name="rewards">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Card
                key={field.key}
                tabIndex={index}
                style={{ marginBottom: 12 }}
                extra={
                  true && (
                    <MinusCircleOutlined
                      style={{ color: 'red' }}
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  )
                }
                size="small"
                title={index + 1}
              >
                <Row>
                  <Col span={24}>
                    <div className="flex flex-col gap-3">
                      <Item {...field} name={[field.name, 'objectiveId']} label="Rewards">
                        <Select
                          disabled={viewOnly}
                          defaultValue={tileMap[0]?.id}
                          style={{ maxWidth: 150, textAlign: 'center' }}
                        >
                          {Object.keys(tileMap).map((item) => (
                            <Select.Option key={tileMap[item].id} value={tileMap[item].id ?? 0}>
                              {tileMap[item].name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Item>

                      <InputNumberItem
                        disabled={viewOnly}
                        field={field}
                        label="Amount"
                        name="number"
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}
            <Row>
              <Col>
                <Item>
                  <Button
                    disabled={viewOnly}
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Item>
              </Col>
            </Row>
          </>
        )}
      </List>
    </div>
  );
};

export default Rewards;
