import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';

import { useSimulatorContext } from '@pages/simulator/context';

const RunningProgressRenderer = (props: any) => {
  const [progress, setProgress] = useState(0);
  const { rowDataOngoing } = useSimulatorContext();

  useEffect(() => {
    const data = rowDataOngoing.find((x) => x.runtestId === props.data.runtestId);
    if (data) setProgress(data.progress ?? 0);
  }, [rowDataOngoing, props]);

  return (
    <Progress
      percent={progress}
      strokeColor={{
        '0%': '#108ee9',
        '100%': '#87d068',
      }}
      status="active"
    />
  );
};

export default RunningProgressRenderer;
