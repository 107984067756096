import React, { createContext, FC, RefObject, useContext } from 'react';
import { Button, Input } from 'antd';
import { DeleteOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';

import { BlueprintDataGridRef } from '@lib/components/blueprint-data-grid';
import { AgGridReactProps } from '@lib/components/ui/ag-grid';
import { BlueprintData, VariantSetting } from '@lib/services/models';

export type TableActionRenderOptions = {
  search: {
    onChange: (value?: string) => void;
  };
  variant: {
    isVariant: boolean;
    current?: string;
    variants: VariantSetting[];
    onChange: (value?: string) => void;
  };
  selection: BlueprintData[];
  deleteSelection: () => void | Promise<void>;
  changes: BlueprintData[];
  saveChanges: () => void | Promise<void>;
  cloneVariant?: () => void | Promise<void>;
  isDeleting: boolean;
};

const defaultTableActionRenderer = (options: TableActionRenderOptions) => {
  const {
    search,
    variant,
    selection,
    deleteSelection,
    changes,
    saveChanges,
    cloneVariant,
  } = options;

  return (
    <div className="flex pb-4 bg-white">
      <Input
        style={{ width: 220 }}
        placeholder="Type to search..."
        prefix={<SearchOutlined />}
        onChange={(e) => search.onChange(e.target.value)}
      />
      {variant.isVariant ? (
        <>
          <Button.Group className="ml-2">
            {variant.variants.map((item) => (
              <Button
                key={item.name}
                type={item.key === variant.current ? 'primary' : 'default'}
                onClick={() => variant.onChange(item.key)}
              >
                {item.name}
              </Button>
            ))}
          </Button.Group>
          {cloneVariant && (
            <div className="ml-2">
              <Button type="dashed" onClick={cloneVariant}>
                Copy Variant
              </Button>
            </div>
          )}
        </>
      ) : null}
      <span className="ml-auto">
        {selection.length ? (
          <Button danger icon={<DeleteOutlined />} onClick={deleteSelection}>
            Delete {selection.length} {selection.length === 1 ? 'row' : 'rows'}
          </Button>
        ) : null}
      </span>
      <span className="ml-2">
        {changes.length ? (
          <Button type="primary" icon={<SaveOutlined />} onClick={saveChanges}>
            Save {changes.length} edited {changes.length === 1 ? 'row' : 'rows'}
          </Button>
        ) : null}
      </span>
    </div>
  );
};

export type TableActionRenderer = typeof defaultTableActionRenderer;

export type BlueprintDataConfigContext = {
  singleTypeMode: boolean;
  tableActionRenderer: TableActionRenderer;
  gridOptions?: AgGridReactProps;
  gridRef?: RefObject<BlueprintDataGridRef>;
};

export const blueprintDataDefaultConfigValue: BlueprintDataConfigContext = {
  singleTypeMode: false,
  tableActionRenderer: defaultTableActionRenderer,
};

const blueprintDataConfigContext = createContext<BlueprintDataConfigContext | null>(null);

export const BlueprintDataConfigProvider: FC<{ value?: Partial<BlueprintDataConfigContext> }> = ({
  children,
  value,
}) => {
  const _value = { ...blueprintDataDefaultConfigValue, ...value };

  return (
    <blueprintDataConfigContext.Provider value={_value}>
      {children}
    </blueprintDataConfigContext.Provider>
  );
};

export const useBlueprintDataConfig = () => {
  return useContext(blueprintDataConfigContext) ?? blueprintDataDefaultConfigValue;
};
