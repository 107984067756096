import { cloneDeep } from 'lodash';

import { BlueprintProto } from '@lib/services/_api';

const messageResultAllowedRSC = (key: string, value: any, allowedRSC: string) => {
  if (allowedRSC) {
    return `Field ${key}('${value}') only special characters ${allowedRSC} are allowed.`;
  }
  return `Field ${key}('${value}') only alphanumeric characters allowed.`;
};
/**
 * @param allowedRSC special characters allowed
 * @param bluePrint blueprint from form
 * @param key key to validate
 * @returns message result
 */
const validateString = (allowedRSC: string, bluePrint: Record<string, any>, key: string) => {
  let msg = '';
  let checkAllowed = null;
  const patternStr = `[^a-zA-Z0-9${allowedRSC?.replaceAll('-', '\\-') ?? ''}]`;
  const alphanumericReg = new RegExp(patternStr);

  if (Array.isArray(bluePrint)) {
    checkAllowed = bluePrint[0][key];
  } else checkAllowed = bluePrint[key] ?? '';

  if (Array.isArray(checkAllowed)) {
    checkAllowed.forEach((checkItem) => {
      if (alphanumericReg.test(checkItem)) {
        msg = messageResultAllowedRSC(key, checkItem, allowedRSC);
      }
    });
  } else if (alphanumericReg.test(checkAllowed)) {
    msg = messageResultAllowedRSC(key, checkAllowed, allowedRSC);
  }

  return msg;
};

/**
 *
 * @param value blueprint from form
 * @param keys key to validate
 * @param index index to recursive
 * @param isRSC check has rsc ?
 * @param allowedRSC special characters to check
 * @param countKeys count all keys if has recursive
 * @param message message response
 * @returns message result
 */
const recursive = (
  value: Record<string, any>,
  keys: string[],
  index: number,
  isRSC: boolean,
  allowedRSC: string,
  countKeys: number,
  message?: string,
  repeat?: boolean,
): any => {
  try {
    let messageValidate = '';
    if ((!messageValidate || !message) && index <= countKeys && value) {
      const lastField = keys[countKeys];
      const keyIndex = keys[index];
      const valueChildren: Record<string, any> = value[keyIndex];
      if (index < countKeys) {
        if (Array.isArray(value) && value?.length > 0) {
          for (let j = 0; j < value.length; j += 1) {
            const element = value[j];
            const childElement = element[keyIndex];

            const messageResponse = recursive(
              childElement,
              keys,
              index + 1,
              isRSC,
              allowedRSC,
              countKeys,
              '',
              true,
            );
            if (messageResponse) {
              messageValidate = messageResponse;
              break;
            }
          }
        } else {
          return recursive(valueChildren, keys, index + 1, isRSC, allowedRSC, countKeys, '');
        }
      } else if (index === countKeys && !message) {
        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i += 1) {
            const x = value[i];
            const element = Object.keys(x).find((y: string) => y === lastField);
            if (element && isRSC) {
              if (allowedRSC) {
                messageValidate = validateString(allowedRSC, x, element);
              } else {
                messageValidate = validateString('', x, element);
              }
              if (messageValidate) {
                break;
              }
            }
          }
        } else {
          const element = Object.keys(value).find((y: string) => y === lastField);
          if (element && isRSC) {
            if (allowedRSC) {
              messageValidate = validateString(allowedRSC, value, element);
            } else {
              messageValidate = validateString('', value, element);
            }
          }
        }

        if (!messageValidate && !repeat) {
          return recursive(value, keys, index + 1, isRSC, allowedRSC, countKeys, '');
        }
      }
    }
    if (messageValidate) return messageValidate;
  } catch (error) {
    return error?.toString();
  }
};

/**
 *  @param selectedType this is the type of proto when selected
 * @return true if proto enable RSC
 */
const hasRSC = (selectedType: BlueprintProto | undefined): boolean => {
  if (selectedType?.settings) {
    const { settings } = selectedType;
    for (let i = 0; i < settings.length; i += 1) {
      if (settings[i]?.isRSC) {
        return true;
      }
    }
  }
  return false;
};
/**
 *
 * @param value this is a value to validate
 * @param selectedType this is the type of proto when selected
 * @returns
 */
const validateRSC = (value: Record<string, any>, selectedType: BlueprintProto | undefined) => {
  let messageValidate = null;

  if (selectedType?.settings) {
    const { settings } = selectedType;
    for (let i = 0; i < settings.length; i += 1) {
      const { field, isRSC, allowedRSC } = settings[i];
      let result = null;
      if (field.includes('.')) {
        const keys = field.split('.');
        const index = 0;
        const countKeys = cloneDeep(keys.length) - 1;
        messageValidate = recursive(value, keys, index, !!isRSC, allowedRSC ?? '', countKeys);
      } else {
        if (Array.isArray(value)) {
          result = Object.keys(value[0]).find((x) => x === field);
        } else result = Object.keys(value).find((x) => x === field);
        if (result && isRSC) {
          if (allowedRSC) {
            if (Array.isArray(value)) {
              for (let index = 0; index < value.length; index += 1) {
                const element = value[index];
                messageValidate = validateString(allowedRSC, element, result);
                if (messageValidate) break;
              }
            } else {
              messageValidate = validateString(allowedRSC, value, result);
            }
          } else {
            messageValidate = validateString('', value, result);
          }
        }
      }
      messageValidate = messageValidate === '' ? null : messageValidate;
      if (messageValidate) break;
    }
  }
  return messageValidate;
};
export { hasRSC };
export default validateRSC;
