import { get, set } from 'lodash';
import moment from 'moment';
import { IEnum, IField, IType } from 'protobufjs';

import BooleanCellEditor from '@lib/components/blueprint-data-grid/cell-editors/boolean-cell-editor';
import DateCellEditor from '@lib/components/blueprint-data-grid/cell-editors/date-cell-editor';
import DateTimeCellEditor from '@lib/components/blueprint-data-grid/cell-editors/datetime-cell-editor';
import EnumCellEditor from '@lib/components/blueprint-data-grid/cell-editors/enum-cell-editor';
import NumberCellEditor from '@lib/components/blueprint-data-grid/cell-editors/number-cell-editor';
import TimeCellEditor from '@lib/components/blueprint-data-grid/cell-editors/time-cell-editor';
import OdlFileGridCellRenderer from '@lib/components/odl-file/components/grid-cell';
import { AgColDef, ColDef, ColGroupDef } from '@lib/components/ui/ag-grid';
import { RefFieldSettings } from '@lib/pages/blueprint-data/components/context';
import { extractEnumKeys } from '@lib/pages/blueprint-data/components/enum-select';
import { ProtoData } from '@lib/providers/blueprint-proto';
import { BlueprintData, BlueprintProto, FieldDataType } from '@lib/services/models';
import dayjs, { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from '@lib/utils/dayjs';

import { extractBlueprintType } from './extract-blueprint-type';
import { FlattenedDataType } from './flatten-data';
import { flattenMapField } from './flatten-map-field';
import { formatBlueprintName, formatLabel } from './format-label';
import { isAnyType } from './is-any-type';

export type GenerateOptions<T> = {
  types: ProtoData;
  paths: Array<string | number>;
  type: T;
  fieldSettings: Record<string, RefFieldSettings>;
  key: string;
  label?: string;
};

export type FlattenedBlueprintData = BlueprintData & {
  paths?: string[];
  data: FlattenedDataType;
};

const baseValueFormater = ({ data, value }: any) => {
  if ((data?.paths?.length ?? 1) > 1) {
    return value;
  }

  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value);
  }

  if (typeof value === 'string' && value.indexOf('T') === 10 && value.endsWith('Z')) {
    return value.substring(0, 19).replace('T', ' ');
  }

  return value ?? '-';
};

export class BlueprintGridHeaderGenerator {
  private static renderSingleField(options: GenerateOptions<IField>): ColDef {
    const {
      key,
      paths,
      label,
      type: { type },
      fieldSettings,
    } = options;

    const dataIndex = [...paths];
    if (key) {
      dataIndex.push(key);
    }

    const fieldKey = dataIndex
      .slice(1)
      .filter((item) => typeof item !== 'number')
      .join('.');
    const fieldSetting = fieldSettings[fieldKey];
    const formatedKey = label ?? fieldSetting?.displayName ?? formatLabel(key);
    if (fieldSetting?.reference) {
      return this.renderEnum({ ...options, type: fieldSetting?.reference });
    }

    const minWidth = Math.max(150, formatedKey.length * 7.5 + 24);

    const base: ColDef = {
      headerName: formatedKey,
      field: dataIndex.join('.'),
      colId: dataIndex.join('.'),
      minWidth,
      maxWidth: 400,
      width: minWidth,
      editable: ({ data }) => {
        if (fieldSetting?.editable === false) return false;

        if ((data?.paths?.length ?? 1) > 1) {
          return !!get(data, dataIndex);
        }

        return true;
      },
      valueGetter: ({ data }) => {
        return get(data, dataIndex);
      },
      valueSetter: ({ data, newValue }) => {
        return set(data, dataIndex, newValue);
      },
      valueFormatter: baseValueFormater,
      tooltipValueGetter: ({ valueFormatted }) => valueFormatted,
    };

    if (
      fieldSetting?.dataType === FieldDataType.Date ||
      fieldSetting?.dataType === FieldDataType.DateTime
    ) {
      base.valueFormatter = ({ data, value }) => {
        if (!value) return '-';

        if (typeof value !== 'number') {
          return baseValueFormater({ data, value });
        }

        return dayjs(value * 1000)
          .subtract(dayjs().utcOffset(), 'minute')
          .format(
            fieldSetting?.dataType === FieldDataType.DateTime ? DATE_TIME_FORMAT : DATE_FORMAT,
          );
      };
      base.minWidth = 180;
      base.filter = 'agDateColumnFilter';
      base.cellEditorFramework =
        fieldSetting?.dataType === FieldDataType.DateTime ? DateTimeCellEditor : DateCellEditor;
    } else if (fieldSetting?.dataType === FieldDataType.Time) {
      // time
      base.valueFormatter = ({ data, value }) => {
        if (typeof value !== 'number') {
          return baseValueFormater({ data, value });
        }

        return dayjs(value).subtract(dayjs().utcOffset(), 'minute').format(TIME_FORMAT);
      };
      base.cellEditorFramework = TimeCellEditor;
    } else if (['int', 'int32', 'int64', 'float'].indexOf(type) > -1) {
      // number
      base.valueFormatter = ({ data, value }) => {
        if (typeof value === 'number') {
          return value.toLocaleString();
        }
        if (value && !Number.isNaN(value)) {
          return Number(value).toLocaleString();
        }
        return baseValueFormater({ data, value });
      };
      base.filter = 'agNumberColumnFilter';
      base.headerClass = 'right';
      base.cellEditorFramework = NumberCellEditor;
    } else if (type === 'bool') {
      // boolean
      base.valueFormatter = ({ value }: { value: boolean }) => {
        return value?.toString().toUpperCase() ?? '-';
      };
      base.filter = 'agSetColumnFilter';
      base.filterParams = {
        values: [true, false],
        valueFormatter: base.valueFormatter,
      };
      base.cellEditorFramework = BooleanCellEditor;
    } else {
      if (fieldSetting?.dataType === FieldDataType.OdlFile) {
        base.cellRendererFramework = OdlFileGridCellRenderer;
      }

      base.filter = 'agTextColumnFilter';
    }

    return base;
  }

  private static renderEnum(options: GenerateOptions<IEnum>): ColDef {
    const { paths, key, label, type, fieldSettings } = options;

    const dataIndex = [...paths, key];
    const fieldKey = dataIndex
      .slice(1)
      .filter((item) => typeof item !== 'number')
      .join('.');
    const fieldSetting = fieldSettings[fieldKey];
    const formatedKey = label ?? fieldSetting?.displayName ?? formatLabel(key);

    return {
      headerName: formatedKey,
      field: dataIndex.join('.'),
      minWidth: 150,
      maxWidth: 300,
      width: 150,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: extractEnumKeys(type),
      },
      valueGetter: ({ data }) => {
        return get(data, dataIndex);
      },
      valueFormatter: ({ data, value }) => {
        if ((data?.paths?.length ?? 1) > 1) {
          return value;
        }

        if (value && key === 'type_url') {
          return formatBlueprintName(value) ?? '-';
        }

        return value ?? '-';
      },
      editable: ({ data }) => {
        if (fieldSetting?.editable === false) return false;

        if ((data?.paths?.length ?? 1) > 1) {
          return !!get(data, dataIndex);
        }

        return true;
      },
      cellEditorFramework: EnumCellEditor,
      cellEditorParams: { enum: type },
      tooltipValueGetter: ({ valueFormatted }) => valueFormatted,
    };
  }

  private static renderField(options: GenerateOptions<IField>): AgColDef {
    const { types, type, paths, key } = options;
    const dataIndex = [...paths, key];

    if ((type as any).keyType) {
      // map
      return this.renderMap(options);
    }

    const repeadted = type.rule === 'repeated';

    if (types[type.type]) {
      // ref type
      const refType = types[type.type];

      // enum
      if ((refType as IEnum).values) {
        return this.renderEnum({ ...options, type: refType as IEnum });
      }

      if (repeadted) {
        // array
        return this.renderArray(options);
      }

      if ((refType as IType).fields) {
        // nested object
        const wrapper = this.renderSingleField(options);
        delete wrapper.field;
        delete wrapper.width;

        return {
          ...(wrapper as ColGroupDef),
          children: this.renderType({
            ...options,
            paths: dataIndex,
            type: refType as IType,
            label: undefined,
          }),
        };
      }
    }

    // single type
    if (repeadted) {
      // array
      return this.renderArray(options);
    }

    return this.renderSingleField(options);
  }

  private static renderNestedArray(options: GenerateOptions<IField>): ColGroupDef {
    const { types, type, paths, key } = options;
    const dataIndex = [...paths, key, 0];

    const wrapper = this.renderSingleField(options);
    delete wrapper.field;
    delete wrapper.width;

    return {
      ...(wrapper as ColGroupDef),
      children: this.renderType({
        ...options,
        paths: dataIndex,
        type: types[type.type] as IType,
        label: undefined,
      }),
    };
  }

  private static renderSimpleArray(options: GenerateOptions<IField>) {
    const { paths, key } = options;
    const dataIndex = [...paths, key];
    const FieldType = options.type.type;
    options.type.type = 'string';
    const column = this.renderSingleField({
      ...options,
      paths: dataIndex,
      key: '',
      label: formatLabel(key),
    });

    column.valueGetter = ({ data }) => {
      return get(data, dataIndex);
    };
    column.valueFormatter = ({ value }) =>
      Array.isArray(value) ? value.join(', ') || '-' : value ?? '-';

    // Convert string to array when type list into cell
    column.valueParser = (params) => {
      if (!params.newValue) return null;
      if (!Array.isArray(params.newValue))
        return this.formatArray(FieldType, params.newValue.split(','));
      return params.newValue;
    };

    return column;
  }

  private static formatArray = (type: string, value: any): any[] => {
    if (type === 'int32')
      return value.map((item: any) => {
        const number = parseInt(item, 10);
        if (number || number === 0) return number;
        return item;
      });
    if (type === 'float')
      return value.map((item: any) => {
        const number = parseFloat(item);
        if (number) return number;
        return item;
      });
    return value;
  };

  private static renderArray(options: GenerateOptions<IField>) {
    const { types, type } = options;

    const newType: IField = { ...type };
    newType.rule = undefined;

    return types[type.type]
      ? this.renderNestedArray({ ...options, type: newType })
      : this.renderSimpleArray({ ...options, type: newType });
  }

  private static renderMap(options: GenerateOptions<IField>) {
    const { types, type } = options;

    const mapType = flattenMapField(type, types);
    const mapField: IField = {
      ...type,
      type: `${(type as any).keyType}:${type.type}Flattened`,
      rule: 'repeated',
    };
    delete (mapField as any).keyType;
    types[mapField.type] = mapType;

    return this.renderArray({ ...options, type: mapField });
  }

  private static renderType(options: GenerateOptions<IType>) {
    const { type } = options;

    if (isAnyType(type)) {
      return this.renderAnyType(options);
    }

    return Object.keys(type.fields).map((subKey) =>
      this.renderField({
        ...options,
        type: type.fields[subKey],
        key: subKey,
      }),
    ) as AgColDef[];
  }

  private static renderAnyType(options: GenerateOptions<IType>): AgColDef[] {
    const { paths: dataIndex, fieldSettings, type, types } = options;

    const fieldKey = dataIndex
      .slice(1)
      .filter((item) => typeof item !== 'number')
      .join('.');
    const fieldSetting = fieldSettings[fieldKey];

    const refTypes = (fieldSetting?.anyTypes ?? []).reduce<Record<string, any>>((all, item) => {
      all[item] = formatBlueprintName(item) ?? item;
      return all;
    }, {});
    const refTypeName = [...dataIndex, 'Any.type_url'].join('.');
    const refTypeEnum = { ref: true, values: refTypes } as IEnum;
    types[refTypeName] = refTypeEnum;

    const fields = [
      this.renderEnum({
        ...options,
        type: refTypeEnum,
        key: 'type_url',
      }),
      this.renderSingleField({
        ...options,
        type: { type: 'json', id: 2 },
        key: 'value',
      }),
    ];
    if (type.fields.$key) {
      fields.unshift(
        this.renderSingleField({
          ...options,
          type: { type: 'string', id: 0 },
          key: '$key',
        }),
      );
    }

    return fields;
  }

  public static renderBlueprintType(
    blueprint: BlueprintProto,
    types: ProtoData,
    fieldSettings: Record<string, RefFieldSettings> = {},
  ) {
    const renderType = extractBlueprintType(blueprint, types);

    (blueprint?.idFields ?? []).forEach((field) => {
      let idFieldSetting = fieldSettings[field];
      if (!idFieldSetting) {
        idFieldSetting = {
          field,
        };
        fieldSettings[field] = idFieldSetting;
      }
      idFieldSetting.editable = false;
    });

    return this.renderType({ types, paths: ['data'], type: renderType, key: '', fieldSettings });
  }
}
