import { SystemConfigDto } from '@lib/services/_api';

import { useApi } from '../base/use-api';
import client from '../client';

export const useSystemConfigList = () => useApi(client.api.systemConfigControllerAll);

export const update = async (value: SystemConfigDto) =>
  client.api.systemConfigControllerUpdate(value);

export const bulkUpdate = async (value: SystemConfigDto[]) =>
  client.api.systemConfigControllerBulkUpdate(value);
