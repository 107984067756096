import React, { FC } from 'react';

import CellTile from './cell-tile';

const ColTile: FC<{ x: number; height: number; layer: number }> = ({ x, height, layer }) => {
  return (
    <>
      {Array(height)
        .fill(0)
        .map((_, idx) => {
          return (
            <CellTile
              key={idx}
              x={x}
              y={idx}
              rootX={x}
              rootY={idx}
              hidden={false}
              layerNumber={layer}
            />
          );
        })}
    </>
  );
};

export default ColTile;
