/* eslint-disable prefer-destructuring */
import React, { FC, useRef, useState } from 'react';
import { Switch } from 'antd';

import { useLevelDataConfigContext } from '../context';

import { DEFAULT_TILE } from './level-data/layout/tile-select';

export type SwitchHiddenLayerProps = {
  defaultChecked?: boolean;
  layerNumber: number;
};

const SwitchHiddenLayer: FC<SwitchHiddenLayerProps> = ({ layerNumber, defaultChecked }) => {
  const {
    setHiddenLayer0,
    hiddenLayer0,
    setHiddenLayer1,
    hiddenLayer1,
    setHiddenLayer2,
    hiddenLayer2,
    setHiddenLayer3,
    hiddenLayer3,
    setTiles,
    tiles,
    clearLayer,
  } = useLevelDataConfigContext();

  const hiddenLayer = () => {
    switch (layerNumber) {
      case 0:
        setHiddenLayer0(!hiddenLayer0);
        break;
      case 1:
        setHiddenLayer1(!hiddenLayer1);
        break;
      case 2:
        setHiddenLayer2(!hiddenLayer2);
        break;
      case 3:
        setHiddenLayer3(!hiddenLayer3);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Switch defaultChecked={defaultChecked} onClick={hiddenLayer} />
    </>
  );
};

export default SwitchHiddenLayer;
