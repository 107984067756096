import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { FormListFieldData } from 'antd/es/form/FormList';
import { get, set } from 'lodash';
import { IField } from 'protobufjs';

import { DataIndex } from '@lib/utils/blueprint-data/table-header-generator';
import { useForceUpdate } from '@lib/utils/use-force-update';

import BlueprintDataEditable from '../../../table-editable/table';
import { RenderOptions } from '../single-field';

export type NestedArrayTableProps = {
  options: RenderOptions<IField>;
  fields: FormListFieldData[];
  onRemoveField: (index: number | number[]) => void;
};

const toArray = (data: Record<string, any>) => {
  if (Array.isArray(data)) return data;

  return Object.keys(data).map((key) => data[key]);
};

const NestedArrayTable = forwardRef<any, NestedArrayTableProps>(
  ({ options, fields, onRemoveField }, ref) => {
    const { paths, form, onFieldsChange, key, width } = options;

    const dataIndex = [...paths, key];
    const forceUpdate = useForceUpdate();

    const formDataRef = useRef<Record<string, any>[]>([]);
    const subItemActionRef = useRef<string>();

    const getFormData = () => {
      const formData = form.getFieldsValue();
      const fieldData = get<Record<string, any>[]>(formData, dataIndex, []);
      return fields.map(({ name: fieldKey }) => fieldData[fieldKey] ?? {});
    };

    const handleAddSubItem = (currentDataIndex: DataIndex) => {
      const workingDataIndex = [...currentDataIndex];
      const dataIdx = workingDataIndex.shift() as number;
      const lastSubIdx = workingDataIndex.pop() as number;
      const data = getFormData();
      const dataItem = data[dataIdx];
      const parentNode = get(dataItem, workingDataIndex, []) as Record<string, any>[];

      const isNested =
        Array.isArray(parentNode) && typeof parentNode[0] === 'object' && parentNode[0] !== null;
      const parentNodeArr = toArray(parentNode);
      parentNodeArr.splice(Math.min(lastSubIdx + 1, parentNodeArr.length), 0, isNested ? {} : null);
      set(dataItem, workingDataIndex, parentNodeArr);

      const newData = data
        .slice(0, dataIdx)
        .concat(dataItem)
        .concat(data.slice(dataIdx + 1));

      formDataRef.current = newData;
      subItemActionRef.current = 'add';

      const formData = form.getFieldsValue(true);
      set(formData, dataIndex, newData);
      form.setFieldsValue(formData);

      onFieldsChange([{ name: dataIndex, value: newData }], []);
    };

    const handleDeleteSubItem = (currentDataIndex: DataIndex) => {
      const workingDataIndex = [...currentDataIndex];
      const dataIdx = workingDataIndex.shift() as number;
      const lastSubIdx = workingDataIndex.pop() as number;
      const data = getFormData();
      const dataItem = data[dataIdx];
      const parentNode = get(dataItem, workingDataIndex, []) as Record<string, any>[];
      const parentNodeArr = toArray(parentNode);
      parentNodeArr.splice(Math.min(lastSubIdx, parentNodeArr.length - 1), 1);
      set(dataItem, workingDataIndex, parentNodeArr);

      const newData = data
        .slice(0, dataIdx)
        .concat(dataItem)
        .concat(data.slice(dataIdx + 1));

      formDataRef.current = newData;
      subItemActionRef.current = 'delete';

      const formData = form.getFieldsValue(true);
      set(formData, dataIndex, newData);
      form.setFieldsValue(formData);

      onFieldsChange([{ name: dataIndex, value: newData }], []);
    };

    const handleRemove = (index: number) => {
      onRemoveField(index);
    };

    useEffect(() => {
      formDataRef.current = getFormData();
      if (!subItemActionRef.current) {
        forceUpdate();
      }
      subItemActionRef.current = undefined;
    }, [fields]);

    const tableRef = useRef<any>();

    useImperativeHandle(ref, () => ({
      goToLastPage: () => {
        tableRef.current.goToLastPage();
      },
    }));

    return (
      <BlueprintDataEditable
        ref={tableRef}
        options={{ ...options, paths: dataIndex }}
        width={width}
        dataSource={formDataRef.current}
        onDelete={handleRemove}
        onAddSubItem={handleAddSubItem}
        onDeleteSubItem={handleDeleteSubItem}
        pagination={false}
      />
    );
  },
);

export default NestedArrayTable;
