import client from '@lib/services/client';
import { BotBulkCreateDto } from '@lib/services/models';

import { BotUpdateDto } from '../_api';

export const paging = client.api.botControllerList;

export const addBots = async (bots: BotBulkCreateDto) => {
  return client.api.botControllerAddBots(bots);
};

export const update = async (id: number, bot: BotUpdateDto) => {
  return client.api.botControllerUpdateBot(id, bot);
};

export const destroy = async (id: number) => {
  return client.api.botControllerDeleteById(id.toString() as string);
};

export const getLeaderBoards = async (env: string) => {
  return client.api.botControllerGetLeaderBoards(env);
};

export const deleteAll = async () => {
  return client.api.botControllerDeleteAll();
};

export const updateAllBot = async (env: string, dabName: string, forceUpdate: boolean) => {
  return client.api.botControllerUpdateAllBot(dabName, { forceUpdate, environment: env });
};
