import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Divider, Input, Select, Tooltip } from 'antd';
import { SelectProps } from 'antd/es/select';
import { IEnum } from 'protobufjs';
import { PlusOutlined } from '@ant-design/icons';

import { RefFieldReference } from './context';

export type EnumSelectProps = SelectProps<string> & {
  enum: RefFieldReference;
};

export const extractEnumKeys = (type: IEnum) => {
  let keys = Object.keys(type.values);
  if (!(type as any).ref) {
    keys = keys.filter((key) => Number.isNaN(+key));
  }
  return keys;
};

export const extractEnumValues = (type: IEnum) => {
  return extractEnumKeys(type).map((key) => ({
    key,
    value: Number.isNaN(+type.values[key]) ? type.values[key] : key,
  }));
};

const EnumSelect: FC<EnumSelectProps> = (props) => {
  const { enum: type, value, onChange, ...remainingProps } = props;

  const data = extractEnumValues(type).filter(
    (item) => item.value !== null && item.value !== undefined,
  );
  const [val, setValue] = useState(value);
  const [open, setOpen] = useState(false);
  const inputRef = useRef<Input>(null);

  const handleOpen = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const handleAddInput = () => {
    const _value = inputRef.current?.input.value;
    if (_value) {
      setValue(_value);
      setOpen(false);

      if (onChange) {
        onChange(_value, { value: _value });
      }
    }
  };

  const render = useCallback(
    (menu: ReactNode) => (
      <>
        {menu}
        <Divider style={{ margin: 0 }} />
        <div className="flex p-2">
          <Input placeholder="Input value" ref={inputRef} />
          <Button type="link" icon={<PlusOutlined />} onClick={handleAddInput} />
        </div>
      </>
    ),
    [],
  );

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Select
      showSearch
      {...remainingProps}
      value={val}
      onChange={onChange}
      open={open}
      onDropdownVisibleChange={handleOpen}
      dropdownRender={type.allowInput ? render : undefined}
    >
      {data.map(({ key, value: v }) => (
        <Select.Option key={key} value={key}>
          <Tooltip title={key}>{v}</Tooltip>
        </Select.Option>
      ))}
    </Select>
  );
};

export default EnumSelect;
