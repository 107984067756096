import React, { FC } from 'react';

import BlueprintDataProvider from '@lib/pages/blueprint-data/components/context';
import CsPlayerProvider from '@lib/pages/cstool/search-user/components/context';
import { useDocumentTitle } from '@lib/utils/use-document-title';

import { LeaderBoardProvider } from './component/context';
import LeaderBoardHeader from './component/header';
import LeaderBoard from './component/leader-board';

import './leader-board.scss';

const LeaderBoardScreen: FC = () => {
  useDocumentTitle('CS Tool | LeaderBoard');

  return (
    <BlueprintDataProvider>
      <CsPlayerProvider>
        <LeaderBoardProvider>
          <LeaderBoardHeader />
          <LeaderBoard />
        </LeaderBoardProvider>
      </CsPlayerProvider>
    </BlueprintDataProvider>
  );
};

export default LeaderBoardScreen;
