import { useEffect, useState } from 'react';

import { HttpResponse } from '../_api';

type ApiResponseType<T> = T extends HttpResponse<infer U, any> ? U : unknown;
type ApiErrorType<T> = T extends HttpResponse<any, infer U> ? U : unknown;

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

export type ApiRequestFunc<A extends any[] = any[]> = (
  ...args: A
) => Promise<HttpResponse<any, any>>;

export type UseApi<T, K extends any[] = any[]> = {
  data?: T;
  error?: Error;
  loading: boolean;
  fetch: (...args: K) => void;
};

export const useApi = <F extends ApiRequestFunc, K extends Parameters<F>>(
  fn: F,
  ...args: K
): UseApi<ApiResponseType<AsyncReturnType<F>>, K> => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ApiResponseType<AsyncReturnType<F>>>();
  const [error, setError] = useState<ApiErrorType<AsyncReturnType<F>>>();

  const fetch = async (...params: K) => {
    setLoading(true);
    return fn(...params)
      .then((res) => {
        setData(res.data);
        setError(res.error);
      })
      .catch((err) => {
        setData(undefined);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch(...args);
  }, []);

  return {
    data,
    error,
    loading,
    fetch,
  };
};
