import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { InputNumber } from 'antd';

import { ICellEditorParams } from '@lib/components/ui/ag-grid';

const NumberCellEditor = forwardRef<any, ICellEditorParams>((props, ref) => {
  const [value, setValue] = useState(props.value);
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (refInput?.current) {
        refInput.current.focus();
      }
    });
  }, []);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  return (
    <InputNumber
      bordered={false}
      ref={refInput}
      value={value}
      onChange={setValue}
      style={{ width: '100%' }}
    />
  );
});

export default NumberCellEditor;
