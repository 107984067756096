import React, { FC } from 'react';
import { TabPaneProps, Tabs } from 'antd';

import CurrencyTab from './currency/currency-tab';
import MilestoneTab from './milestone-progression/milestone-tab';
import PlayersLeaderBoardsTab from './user/component/players-leaderboard';
import UserTab from './user/user-tab';
import { useCsPlayerContext } from './context';

const { TabPane } = Tabs;

export type SearchPlayerResultProps = {
  extraTabs?: TabPaneProps[];
};

export enum SearchUserTabs {
  User = '1',
  Currency = '2',
  LeaderBoard = '3',
  Milestone = '4',
}

const SearchPlayerResult: FC = () => {
  const { activeTab, setActiveTab } = useCsPlayerContext();

  return (
    <Tabs
      defaultActiveKey="1"
      activeKey={activeTab}
      onChange={(e: any) => setActiveTab(e)}
      className="flex-1 h-full"
    >
      <TabPane tab="User Info" key={SearchUserTabs.User} style={{ flex: '1 1 100%' }}>
        <UserTab />
      </TabPane>
      <TabPane
        tab="Currency"
        key={SearchUserTabs.Currency}
        className="flex flex-col"
        style={{ flex: '1 1 100%' }}
      >
        <CurrencyTab />
      </TabPane>
      <TabPane
        tab="LeaderBoard"
        key={SearchUserTabs.LeaderBoard}
        className="flex flex-col"
        style={{ flex: '1 1 100%' }}
      >
        <PlayersLeaderBoardsTab />
      </TabPane>
      <TabPane
        tab="Milestone"
        key={SearchUserTabs.Milestone}
        className="flex flex-col"
        style={{ flex: '1 1 100%' }}
      >
        <MilestoneTab />
      </TabPane>
      {/* {extraTabs.map((tabProps, idx) => (
        <TabPane key={`${3 + idx}`} {...tabProps} />
      ))} */}
    </Tabs>
  );
};

export default SearchPlayerResult;
