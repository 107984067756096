import React, { FC } from 'react';
import { Avatar, Dropdown, Menu, message } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import { TOKEN_KEY, useAuth } from '@lib/providers/auth';
import authService from '@lib/services/auth';

const UserDropdown: FC = () => {
  const { currentUser, reset } = useAuth();

  if (!currentUser) {
    return null;
  }

  const handleLogout = async () => {
    const response = await authService.logout();
    if (response.ok) {
      localStorage.removeItem(TOKEN_KEY);
      reset();
    } else {
      message.error({ message: 'Logout failed!' });
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined className="mr-2" />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <Avatar src={currentUser.avatar as any} alt={currentUser.name}>
        {currentUser.name}
      </Avatar>
    </Dropdown>
  );
};

export default UserDropdown;
