/* eslint-disable array-callback-return */
import React, { FC, useEffect, useState } from 'react';
import { Button, Col, InputNumber, message, Popover, Row } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import { useLevelDataConfigContext } from '@pages/map-level/context';
import { TETROMINO_IDS } from '@pages/map-level/utils/constants';
import { TileBlock, TileType } from '@pages/map-level/utils/enumeration';
import { ObjectCollection, useGlobalObjectCollectionContext } from '@providers/object-collection';

export type TileSelectProps = {
  tileId?: string;
};

interface ItemProps {
  id: string;
  className: string;
}

interface ListProps {
  items: ObjectCollection[];
}

export const DEFAULT_TILE: ObjectCollection = { id: '0', name: 'Normal', size: '1:1' };
export const DEFAULT_OVER_SIZE_TILE: ObjectCollection = { id: 'E', name: '', size: '1:1' };
const TileSelect: FC<TileSelectProps> = ({ tileId }) => {
  const {
    tileGlobal,
    setTileGlobal,
    tileBlock,
    setTileBlock,
    layers,
    tileMapFilter,
    selected,
    setSelected,
    setTileSegment,
    tileSegment,
    setTileMeta,
    tileMeta,
  } = useLevelDataConfigContext();
  const { tileMap } = useGlobalObjectCollectionContext();
  const [rotateI, setRotateI] = useState<string>('');
  const [rotateL, setRotateL] = useState<string>('');
  const [rotateJ, setRotateJ] = useState<string>('');
  const [rotateS, setRotateS] = useState<string>('');
  const [rotateZ, setRotateZ] = useState<string>('');
  const [rotateT, setRotateT] = useState<string>('');
  const classNames = [
    {
      id: TileBlock.TileI,
      className: rotateI,
    },
    {
      id: TileBlock.TileL,
      className: rotateL,
    },
    {
      id: TileBlock.TileJ,
      className: rotateJ,
    },
    {
      id: TileBlock.TileS,
      className: rotateS,
    },
    {
      id: TileBlock.TileT,
      className: rotateT,
    },
    {
      id: TileBlock.TileZ,
      className: rotateZ,
    },
  ];

  const contentPopover = (index?: number, spawnValue?: number, direction?: string) => {
    return (
      <div>
        <p hidden={index === 0 || !index}>
          Durability: <InputNumber value={index} />
        </p>

        <p hidden={spawnValue === 0 || !spawnValue}>
          Spawn Rate: <InputNumber value={spawnValue} />
        </p>
        <p hidden={!direction}>Direction: {direction}</p>
      </div>
    );
  };

  const handleSelect = (tile: ObjectCollection, index = 0) => {
    if (
      layers === 4 ||
      layers === 1 ||
      ((layers === 2 || layers === 3) &&
        (Number(tile.id) === TileType.Normal || tileMap[tile.id].layer2))
    ) {
      const currentBlockRotate = classNames.find(
        (x: { id: TileBlock; className: string }) => x.id === Number(tile.id),
      );
      if (currentBlockRotate) {
        if (currentBlockRotate.className.includes('90')) {
          setTileBlock(`${tile.id}.1`);
        } else if (currentBlockRotate.className.includes('180')) {
          setTileBlock(`${tile.id}.2`);
        } else if (currentBlockRotate.className.includes('270')) {
          setTileBlock(`${tile.id}.3`);
        } else {
          setTileBlock(`${tile.id}`);
        }
      }
      setSelected(tile.id);
      setTileGlobal(tile.id);

      if (index !== 0) {
        if (tileMap[tile.id].segments) {
          const segmentTile = tileMap[tile.id].segments?.find(
            (segment) => segment.durability === index,
          );
          setTileSegment(segmentTile);
        }
      }
      if (tileMap[tile.id].haveMetaData && tileMap[tile.id].tileMetaData) {
        const metaObject = tileMap[tile.id]?.tileMetaData?.[index];
        setTileMeta({ ...metaObject, index });
      }

      return tile.id;
    }
    return message.error('Choose only lock or normal tile');
  };

  const tileBlockFilter = Object.values(tileMap).filter((tile) =>
    TETROMINO_IDS.find((x) => tile.id === `${x}`),
  );

  useEffect(() => {
    if (tileId !== undefined) {
      setSelected(tileId);
    } else {
      setSelected('E');
    }
  }, [tileId]);

  const handleRotate = () => {
    if (tileBlock) setTileBlock(tileGlobal);
    if (tileBlock && tileBlock.includes('.')) {
      // dang dc xoay
      const currentTile = tileBlock.split('.');
      const degreeType = Number(currentTile[1]);

      if (degreeType === 1) {
        setTileBlock(`${tileGlobal}.2`);
      }
      if (degreeType === 2) {
        setTileBlock(`${tileGlobal}.3`);
      }
      if (degreeType === 3) {
        setTileBlock(`${tileGlobal}`);
      }

      switch (Number(tileGlobal)) {
        case TileBlock.TileI:
          // if (degreeType === 1) {
          //   setRotateI('tile-i-180');
          // }
          // if (degreeType === 2) {
          //   setRotateI('tile-i-270');
          // }
          if (degreeType === 1) {
            setRotateI('');
            setTileBlock(`${tileGlobal}`);
          }
          break;
        case TileBlock.TileT:
          if (degreeType === 1) {
            // current is 90 degree
            setRotateT('tile-t-180');
            setTileBlock(`${tileGlobal}.2`);
          }
          if (degreeType === 2) {
            // current is 180 degree
            setRotateT('tile-t-270');
            setTileBlock(`${tileGlobal}.3`);
          }
          if (degreeType === 3) {
            // current is 270 degree
            setTileBlock(`${tileGlobal}`);
            setRotateT('');
          }
          break;

        case TileBlock.TileL:
          if (degreeType === 1) {
            // current is 90 degree
            setRotateL('tile-l-180');
            setTileBlock(`${tileGlobal}.2`);
          }
          if (degreeType === 2) {
            // current is 180 degree
            setRotateL('tile-l-270');
            setTileBlock(`${tileGlobal}.3`);
          }
          if (degreeType === 3) {
            // current is 270 degree
            setTileBlock(`${tileGlobal}`);
            setRotateL('');
          }
          break;

        case TileBlock.TileJ:
          if (degreeType === 1) {
            // current is 90 degree
            setRotateJ('tile-j-180');
            setTileBlock(`${tileGlobal}.2`);
          }
          if (degreeType === 2) {
            // current is 180 degree
            setRotateJ('tile-j-270');
            setTileBlock(`${tileGlobal}.3`);
          }
          if (degreeType === 3) {
            // current is 270 degree
            setTileBlock(`${tileGlobal}`);
            setRotateJ('');
          }
          break;

        case TileBlock.TileS:
          if (degreeType === 1) {
            // current is 90 degree
            setRotateS('tile-s-180');
            setTileBlock(`${tileGlobal}.2`);
          }
          if (degreeType === 2) {
            // current is 180 degree
            setRotateS('tile-s-270');
            setTileBlock(`${tileGlobal}.3`);
          }
          if (degreeType === 3) {
            // current is 270 degree
            setTileBlock(`${tileGlobal}`);
            setRotateS('');
          }
          break;

        case TileBlock.TileZ:
          if (degreeType === 1) {
            // current is 90 degree
            setRotateZ('tile-z-180');
            setTileBlock(`${tileGlobal}.2`);
          }
          if (degreeType === 2) {
            // current is 180 degree
            setRotateZ('tile-z-270');
            setTileBlock(`${tileGlobal}.3`);
          }
          if (degreeType === 3) {
            // current is 270 degree
            setTileBlock(`${tileGlobal}`);
            setRotateZ('');
          }
          break;

        default:
          break;
      }
    } else {
      setTileBlock(`${tileGlobal}.1`);
      switch (Number(tileGlobal)) {
        case TileBlock.TileI:
          setRotateI('tile-i-90');
          break;

        case TileBlock.TileT:
          setRotateT('tile-t-90');
          break;

        case TileBlock.TileL:
          setRotateL('tile-l-90');
          break;

        case TileBlock.TileJ:
          setRotateJ('tile-j-90');
          break;

        case TileBlock.TileS:
          setRotateS('tile-s-90');
          break;

        case TileBlock.TileZ:
          setRotateZ('tile-z-90');
          break;

        default:
          break;
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'ArrowRight') {
      handleRotate();
    }
  };

  const Item: React.FC<ItemProps> = ({ id, className }) => {
    return (
      <div
        className={className}
        style={{
          height: '100%',
          width: '100%',
          backgroundImage: `url(${tileMap[id]?.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    );
  };

  const List: React.FC<ListProps> = ({ items }) => {
    return (
      <>
        {items.map((item, index) => (
          <Col
            key={index}
            flex={1}
            onKeyPress={handleKeyDown}
            onKeyDown={handleKeyDown}
            onClick={() => handleSelect(tileMap[item.id])}
          >
            <div
              className={`${
                selected === item.id
                  ? 'tile-block-selected cursor-pointer flex justify-center items-center'
                  : 'cursor-pointer flex justify-center items-center'
              }`}
              style={{ width: 90, height: 70, backgroundColor: '#D9D9D9' }}
            >
              <Item
                key={index}
                id={item.id}
                className={classNames.find((x) => x.id.toString() === item.id)?.className ?? ''}
              />
            </div>
          </Col>
        ))}
      </>
    );
  };

  const ObjectiveItems = () => {
    if (!tileMapFilter) return <></>;
    return (
      <>
        {Object.keys(tileMap).map((item) => {
          if (
            (tileMap[item]?.url !== undefined && !tileMap[item].isBlock) ||
            tileMap[item].haveMetaData
          ) {
            if (tileMap[item].haveSegment)
              return tileMap[item].segments?.map((segment) => (
                <Col
                  flex={1}
                  className="cursor-pointer tile-item"
                  key={`${tileMap[item]?.id}_${segment.durability}`}
                  onClick={() => handleSelect(tileMap[item], segment.durability)}
                >
                  <Popover
                    content={contentPopover(segment.durability, segment.spawn)}
                    title={segment.imageName}
                  >
                    <img
                      alt={`Pic ${tileMap[item]?.id}_${segment.durability}`}
                      className={`${
                        selected === item && tileSegment?.durability === segment.durability
                          ? 'tile-item-selected'
                          : ''
                      }`}
                      style={{ width: 34, height: 34 }}
                      src={segment?.url}
                    />
                  </Popover>
                </Col>
              ));

            if (tileMap[item].haveMetaData && tileMap[item]?.tileMetaData) {
              const metaObject = tileMap[item]?.tileMetaData ?? {};
              return Object.keys(metaObject)?.map((key: any) => (
                <Col
                  flex={1}
                  className="cursor-pointer tile-item"
                  key={`${tileMap[item]?.id}_${key}`}
                  onClick={() => handleSelect(tileMap[item], key)}
                >
                  <Popover
                    title="Arrow"
                    content={contentPopover(0, 0, metaObject?.[key].zipline.ZipLineDirection)}
                  >
                    <img
                      alt={`Pic ${tileMap[item]?.id}_${key}`}
                      className={`${
                        selected === item && metaObject?.[key].imageName === tileMeta.imageName
                          ? 'tile-item-selected'
                          : ''
                      }`}
                      style={{ width: 34, height: 34 }}
                      src={metaObject[key].url}
                    />
                  </Popover>
                </Col>
              ));
            }

            return (
              <Col
                flex={1}
                className="cursor-pointer tile-item"
                key={tileMap[item]?.id}
                onClick={() => handleSelect(tileMap[item])}
              >
                <img
                  alt={`Pic ${tileMap[item]?.id}`}
                  className={`${selected === item ? 'tile-item-selected' : ''}`}
                  style={{ width: 34, height: 34 }}
                  src={tileMap[item]?.url}
                />
              </Col>
            );
          }
        })}
      </>
    );
  };

  const disableRotate = (id: number) => {
    if (!id) return true;
    return TileBlock.TileI > id || TileBlock.TileZ < id || id === TileBlock.TileO;
  };

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <span>Object List</span>
        <Button
          type="primary"
          disabled={disableRotate(Number(selected))}
          onClick={handleRotate}
          icon={<SyncOutlined />}
        />
      </div>

      <Row
        className="p-6 grid-cols-12"
        gutter={32}
        style={{
          display: 'grid',
          width: '100%',
          marginLeft: '0 !important',
          rowGap: 12,
          backgroundColor: '#F2F2F2',
        }}
      >
        <ObjectiveItems />
      </Row>

      <Row
        className="p-3 mb-6"
        gutter={32}
        style={{
          width: '100%',
          marginLeft: '0 !important',
          rowGap: 12,
          backgroundColor: '#F2F2F2',
        }}
      >
        <List items={tileBlockFilter} />
      </Row>
    </>
  );
};

export default TileSelect;
