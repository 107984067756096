import {
  BlueprintDataDiffArray,
  BlueprintDataDiffDeleted,
  BlueprintDataDiffEdit,
  BlueprintDataDiffNew,
  UserRole,
} from './_api';

export type UserRoles = {
  [k in UserRole]?: string[];
};

export type BlueprintDataDiffItem =
  | BlueprintDataDiffNew
  | BlueprintDataDiffEdit
  | BlueprintDataDiffDeleted
  | BlueprintDataDiffArray;

export * from './_api';
