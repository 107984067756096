import React, { FC } from 'react';

import BlueprintDataProvider from '@lib/pages/blueprint-data/components/context';
import BlueprintDataVersionProvider from '@lib/providers/blueprint-version';
import { useDocumentTitle } from '@lib/utils/use-document-title';

import { EventCalendarProvider } from './components/context';
import EventCalendarForm from './components/form';
import EventCalendarModal from './components/modal';

const EventCalendarScreen: FC = () => {
  useDocumentTitle('Event Calendar Management');

  return (
    <BlueprintDataProvider>
      <BlueprintDataVersionProvider>
        <EventCalendarProvider>
          <EventCalendarForm />
          <EventCalendarModal />
        </EventCalendarProvider>
      </BlueprintDataVersionProvider>
    </BlueprintDataProvider>
  );
};

export default EventCalendarScreen;
