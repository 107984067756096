import React, { FC } from 'react';
import { DatePicker, TimePicker } from 'antd';
import moment, { Moment } from 'moment';

import { DatePickerProps } from './date-picker';

type AntDatePickerProps = React.ComponentProps<typeof DatePicker['MonthPicker']>;
type AntTimePickerProps = React.ComponentProps<typeof DatePicker['TimePicker']>;

type TimestampPickerProps = {
  onChange?: (value: number | null) => void;
  value?: number;
  useSeconds?: boolean;
  utcTime?: boolean;
};

export type TimestampDatePickerProps = Omit<DatePickerProps, 'onChange' | 'value'> &
  TimestampPickerProps;

const getValue = (val: Moment | null, useSeconds?: boolean, utcTime?: boolean) => {
  if (!val) return null;
  let _val = val.valueOf();
  if (utcTime) {
    _val = moment(_val).add(moment().utcOffset(), 'minute').valueOf();
  }
  if (useSeconds) {
    _val = Math.ceil(_val / 1000);
  }
  return _val;
};

const parseValue = (val?: any, useSeconds?: boolean, utcTime?: boolean) => {
  if (!val) return null;
  let _val = val;
  if (typeof val === 'string') _val = new Date(val).getTime() / 1000;
  if (useSeconds) {
    _val *= 1000;
  }
  if (utcTime) {
    _val = moment(_val).subtract(moment().utcOffset(), 'minute').valueOf();
  }
  return moment(_val);
};

export const TimestampDatePicker: FC<TimestampDatePickerProps> = (props) => {
  const { value, onChange, useSeconds, utcTime, showTime, ...remainingProps } = props;

  const _onChange: AntDatePickerProps['onChange'] = (val) => {
    if (typeof onChange === 'function') {
      onChange(getValue(val, useSeconds, utcTime));
    }
  };

  const _value = parseValue(value, useSeconds, utcTime);

  return <DatePicker showTime={showTime} {...remainingProps} onChange={_onChange} value={_value} />;
};

export type TimestampTimePickerProps = Omit<AntTimePickerProps, 'onChange' | 'value'> &
  TimestampPickerProps;

export const TimestampTimePicker: FC<TimestampDatePickerProps> = (props) => {
  const { value, onChange, useSeconds, utcTime, ...remainingProps } = props;

  const _onChange: AntTimePickerProps['onChange'] = (val) => {
    if (typeof onChange === 'function') {
      onChange(getValue(val, useSeconds, utcTime));
    }
  };

  const _value = parseValue(value, useSeconds, utcTime);

  return <TimePicker {...remainingProps} onChange={_onChange} value={_value} />;
};

export default TimestampDatePicker;
