import React, { FC } from 'react';
import { Form, InputNumber, Select } from 'antd';

import { ObjectCollection } from '@providers/object-collection';

const { Item } = Form;

type InputNumberItemProps = {
  field: any;
  label: string;
  name: string;
  disabled?: boolean;
};

type SelectTypeProps = {
  tileMap: Record<string, ObjectCollection>;
  disabled?: boolean;
};

const InputNumberItem: FC<InputNumberItemProps> = ({ field, label, name, disabled }) => (
  <Item label={label} {...field} name={[field.name, name]}>
    <InputNumber disabled={disabled} defaultValue={0} style={{ width: 150, textAlign: 'center' }} />
  </Item>
);

const SelectTile: FC<SelectTypeProps> = ({ tileMap, disabled }) => (
  <Select
    disabled={disabled}
    defaultValue={tileMap[0]?.id}
    style={{ maxWidth: 150, textAlign: 'center' }}
  >
    {Object.keys(tileMap).map((item) => (
      <Select.Option key={tileMap[item].id} value={tileMap[item].id ?? 0}>
        {tileMap[item].name}
      </Select.Option>
    ))}
  </Select>

  // <Select defaultValue={tileMap[0].id} style={{ width: 150, textAlign: 'center' }}>
  //   {Object.keys(tileMap).map((item) => (
  //     <Select.Option key={tileMap[item].id} value={tileMap[item].id ?? 0}>
  //       {tileMap[item].name}
  //     </Select.Option>
  //   ))}
  // </Select>
);

export { InputNumberItem, SelectTile };
