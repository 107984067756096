import React, { FC } from 'react';
import { Provider } from 'use-http';

import { interceptRequest, interceptResponse } from '@lib/services/base/interceptors';

const HttpProvider: FC = ({ children }) => (
  <Provider options={{ interceptors: { request: interceptRequest, response: interceptResponse } }}>
    {children}
  </Provider>
);

export default HttpProvider;
