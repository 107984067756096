import React, { FC, useEffect } from 'react';
import { Divider, Select, Tooltip, Typography } from 'antd';
import { SelectProps } from 'antd/es/select';
import { ArrowLeftOutlined, FileImageTwoTone, FolderTwoTone } from '@ant-design/icons';

import {
  OdlFileBrowserContext,
  useOdlFileBrowserContext,
  useOdlFileBrowserValue,
} from './components/context';
import { generateOdlPreviewImage } from './components/grid-cell';

const OdlFileSelectInner: FC<SelectProps<string>> = (props) => {
  const { value } = props;
  const { setFolderPrefix, folderChain, loading, files } = useOdlFileBrowserContext();

  const folders = files.filter((item) => item.isDir);
  const options = files.filter((item) => !item.isDir);

  useEffect(() => {
    if (value && value.startsWith('odl://')) {
      const parts = value.slice(6).split('/');
      parts.pop();
      parts.push('');
      setFolderPrefix(parts.join('/'));
    } else {
      setFolderPrefix('/');
    }
  }, [value]);

  return (
    <Select
      placeholder="Select file"
      showSearch
      optionFilterProp="value"
      allowClear
      {...props}
      loading={loading}
      dropdownRender={(menu) => (
        <div>
          {folderChain.length > 1 && (
            <div className="flex-none block cursor-pointer" style={{ padding: '5px 12px' }}>
              <ArrowLeftOutlined className="mr-1" />
              {folderChain.map((item, idx) => (
                <span key={item.id}>
                  {idx > 0 && ' / '}
                  {idx < folderChain.length - 1 ? (
                    <Typography.Link onClick={() => setFolderPrefix(item.id)}>
                      {item.name}
                    </Typography.Link>
                  ) : (
                    item.name
                  )}
                </span>
              ))}
            </div>
          )}
          {folders.map((folder) => (
            <Typography.Link
              key={folder.id}
              className="flex-none block cursor-pointer"
              style={{ padding: '5px 12px' }}
              onClick={() => setFolderPrefix(folder.id)}
            >
              <FolderTwoTone /> {folder.name}
            </Typography.Link>
          ))}
          <Divider style={{ margin: '4px 0' }} />
          {menu}
        </div>
      )}
    >
      {options.map((item) => (
        <Select.Option key={item.id} value={`odl://${item.id}`}>
          <Tooltip placement="left" title={generateOdlPreviewImage(item)}>
            <FileImageTwoTone className="mr-1" />
            {item.name}
          </Tooltip>
        </Select.Option>
      ))}
    </Select>
  );
};

const OdlFileSelect: FC<SelectProps<string>> = (props) => {
  const value = useOdlFileBrowserValue();

  return (
    <OdlFileBrowserContext.Provider value={value}>
      <OdlFileSelectInner {...props} />
    </OdlFileBrowserContext.Provider>
  );
};

export default OdlFileSelect;
