import React, { FC, useEffect, useRef } from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/es/input';
import { CloseCircleOutlined } from '@ant-design/icons';

import { useRRuleContext } from './context';

const RRuleInput: FC<InputProps> = (props) => {
  const { value, onChange } = props;

  const { toggleModal, modalVisible, value: rrule, setValue } = useRRuleContext();

  const modalToggledByMe = useRef(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const handleEdit = () => {
    setValue(value ? `RRULE:${value}` : (value as string));
    toggleModal(true);
    modalToggledByMe.current = true;
  };

  const handleClear = () => {
    if (onChange) {
      onChange({ target: { value: undefined } } as any);
    }
  };

  useEffect(() => {
    if (modalToggledByMe.current && onChange) {
      onChange({ target: { value: rrule?.replace('RRULE:', '') } } as any);
    }
  }, [rrule]);

  useEffect(() => {
    if (!modalVisible) {
      modalToggledByMe.current = false;
    }
  }, [modalVisible]);

  return (
    <Input
      {...props}
      onClick={handleEdit}
      onChange={handleChange}
      suffix={<CloseCircleOutlined onClick={handleClear} title="Clear" />}
      readOnly
    />
  );
};

export default RRuleInput;
