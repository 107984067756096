import { set } from 'lodash';

export type FlattenedDataType = Record<string, any> & {
  $keys: Array<(string | number)[]>;
};

const _flattenData = <T extends Record<string, any>>(
  data: T,
  transformed: FlattenedDataType[],
  row = 0,
  paths: Array<string | number> = [],
): number => {
  if (!data) return 1;
  return Object.keys(data).reduce((rowCount, key) => {
    const childPaths = [...paths, key];

    if (Array.isArray(data[key])) {
      const maxCount = Math.max(
        rowCount,
        (data[key] as any[]).reduce((count, item, idx) => {
          const insertRow = row + count;
          while (transformed.length <= insertRow) {
            transformed.push({ $keys: [] });
          }

          const setPath = childPaths.map((part) => (typeof part === 'number' ? 0 : part));
          set(transformed[insertRow], [...setPath, 0], item);
          transformed[insertRow].$keys.push([...childPaths, idx]);

          return count + _flattenData(item, transformed, insertRow, [...childPaths, idx]);
        }, 0),
      );

      return maxCount;
    }

    if (typeof data[key] === 'object') {
      return Math.max(rowCount, _flattenData(data[key], transformed, row, childPaths));
    }

    return rowCount;
  }, 1);
};

export const flattenData = <T extends Record<string, any>>(
  data: T,
  transformed: FlattenedDataType[],
): void => {
  _flattenData(data, transformed);
  if (!transformed.length) {
    transformed.push({ $keys: [] });
  }
};
