import React, { FC } from 'react';

import BaseApp from '@lib/components/app';
import { AppProvider } from '@lib/providers/app';

import { transformBlueprint } from '@pages/blueprint/utils/blueprint-transform';
import MapLevelForm from '@pages/map-level/map-level-form';
import { routes } from '@routes/routes';

const App: FC = () => {
  return (
    <AppProvider
      value={{
        routes,
        docs: {
          userGuidePath: '/user-guide/',
          devGuidePath: '/docs/',
        },
        blueprint: {
          transformProto: transformBlueprint,
          formComponents: {
            'com.alleylabs.legendblast.blueprint.MapLevelCollection': MapLevelForm,
          },
        },
      }}
    >
      <BaseApp />
    </AppProvider>
  );
};

export default App;
