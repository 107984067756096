import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import moment from 'moment';

import { useGlobalBlueprintDataVersionContext } from '@lib/providers/blueprint-version';
import taskQueueService from '@lib/services/task-queue';

import { taskKey } from '@pages/simulator/component/runtest-ongoing/ongoing';
import { MAPLEVEL_COLLECTION } from '@pages/simulator/create-runtest/create-runtest';
import { SimulatorComponent } from '@pages/simulator/simulator';
import { CompareData } from '@pages/simulator/utils/initData';
import { IResultTable, ViewDetail } from '@pages/simulator/utils/models';
import { BlueprintData, SimulatorV2 as Simulator } from '@services/_api';
import { blueprintDataService } from '@services/blueprint';
import { simulatorService } from '@services/simulator';

export enum RuntestStatusClient {
  RUNTEST_RESULT = 1,
  ONGOING = 2,
  DRAFT = 3,
  STOP = 4,
  PENDING = 5,
}

export type SimulatorAdvance = Simulator &
  Partial<{
    listLevel: string | undefined;
  }>;

type ResultLevel = {
  levelId: string;
  runtestIds: string;
  runtests: Simulator[];
};

export type DataFilter = {
  runtestIds: string[];
  performers: string[];
  tags: string[];
};

const useSimulatorContextValue = () => {
  const [fieldsCheck, setFieldsCheck] = useState<string[]>([]);
  const [boardTabActive, setBoardTabActive] = useState<number>(RuntestStatusClient.RUNTEST_RESULT);
  const [active, setActive] = useState<number>(SimulatorComponent.BoardComponent);
  const [isBackToListRuntestByLevel, setIsBackToListRuntestByLevel] = useState<boolean>(false);
  const [isBackToBoard, setIsBackToBoard] = useState<boolean>(false);
  const [backPageLevelRuntestResult, setBackPageLevelRuntestResult] = useState<number>(
    SimulatorComponent.ViewDetailComponent,
  ); // recognize the back page when go to Level Runtest Result Page
  const [blueprintData, setBlueprintData] = useState<BlueprintData[]>([]);
  const [dataFilter, setDataFilter] = useState<DataFilter>();
  const [dataSelected, setDataSelected] = useState<BlueprintData[]>([]);
  const [viewDetailSelected, setViewDetailSelected] = useState<any>();
  const [compareRuntest, setCompareRuntest] = useState<Simulator>();
  const [compareRuntest2, setCompareRuntest2] = useState<Simulator>();
  const [compare, setCompare] = useState<IResultTable & Partial<Simulator>>(CompareData);
  const [compare2, setCompare2] = useState<IResultTable & Partial<Simulator>>(CompareData);

  const [rowDataResult, setRowDataResult] = useState<SimulatorAdvance[]>([]);
  const [rowDataLevelId, setRowDataLevelId] = useState<ResultLevel[]>([]);
  const [rowDataDraft, setRowDataDraft] = useState<Simulator[]>([]);
  const [rowDataViewDetail, setRowDataViewDetail] = useState<ViewDetail[]>([]);
  const [rowDataOngoing, setRowDataOngoing] = useState<Simulator[]>([]);
  const [rowDataStopped, setRowDataStopped] = useState<Simulator[]>([]);
  const [resultSeleted, setResultSelected] = useState<Simulator>();
  const [resultLevelSeleted, setResultLevelSelected] = useState<ResultLevel>();
  const [resultActive, setResultActive] = useState(1);
  const [loading, setLoading] = useState(false);
  const [calculated, setCalculated] = useState(true);
  const [dab, setDab] = useState<string>();
  const [config, setConfig] = useState<any>();
  const [bucketIds, setBucketIds] = useState<number[]>([]);

  const {
    selectedKey: version,
    setSelected: setVersion,
    loading: versionLoading,
  } = useGlobalBlueprintDataVersionContext();

  // form
  const [formCreate] = Form.useForm();
  const [formCompare] = Form.useForm();
  const [formCompare2] = Form.useForm();

  const fetch = async () => {
    if (!version) return;
    const res = await blueprintDataService.getData(version, MAPLEVEL_COLLECTION);
    const { ok, data } = res;
    if (ok && data) {
      // changeLogsRef.current = data;
      setBlueprintData(data);
    }
  };

  const fetchTaskStatus = async (
    taskId: number | undefined,
    funC: (value: React.SetStateAction<Simulator[]>) => void,
    _setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (taskId) {
      const res = await taskQueueService.findTaskById(taskId);
      if (res.ok) {
        if (res.data?.status === 'DEPLOYING') {
          setTimeout(() => {
            fetchTaskStatus(taskId, funC, _setLoading);
          }, 5000);
        } else if (res.data?.status === 'SUCCESS') {
          const data = res.data.returnValue as Simulator[];
          if (data && data.length) {
            // eslint-disable-next-line no-unused-expressions
            data
              ?.sort(
                (a: Simulator, b: Simulator) =>
                  Date.parse(b.dateRuntest) - Date.parse(a.dateRuntest),
              )
              .map((x: SimulatorAdvance & any) => {
                x.listLevel = x.levelsTestResult
                  ?.map((entry: any) => entry.levelConfig?.id ?? '')
                  .join(',');
                x.dateRuntest = moment(x.dateRuntest);
                x.lastUpdated = moment(x.lastUpdated).format('DD/MM/YYYY HH:mm');
                x.dateString = moment(x.dateRuntest).format('DD/MM/YYYY HH:mm');
                x.listRequestId = x.levelsTestResult
                  ?.map((entry: any) => entry.requestId?.split('-')[0] ?? '')
                  .join(',');
                return x;
              });

            sessionStorage.removeItem(taskKey);
            funC(data as Simulator[]);
          } else {
            funC([]);
          }
          _setLoading(false);
        } else if (res.data?.status === 'FAIL') {
          sessionStorage.removeItem(taskKey);
          message.error(res.data.returnValue?.toString(), 15);
          _setLoading(false);
        }
      }
    }
  };

  const fetchRuntest = async () => {
    try {
      setLoading(true);
      const res = await simulatorService.getLevelResult();
      if (res.ok && res.data) {
        const levelData = (await res.data) as Simulator[];
        if (levelData) {
          let listLevelByReuntest = [];
          listLevelByReuntest = levelData.reduce<ResultLevel[]>(
            (all: ResultLevel[], x: Simulator) => {
              if (x.levelsTestResult?.length) {
                // eslint-disable-next-line no-unused-expressions
                x.levelsTestResult?.forEach((entry: any) => {
                  let rl: ResultLevel = {
                    levelId: '',
                    runtestIds: '',
                    runtests: [],
                  };

                  const { levelConfig } = entry;
                  const _id = `LevelConfig_${levelConfig?.id}`;
                  if (all.find((a) => a.levelId === _id)) {
                    const curLevelRuntest = all.find((a) => a.levelId === _id);
                    if (curLevelRuntest) {
                      curLevelRuntest.runtestIds =
                        curLevelRuntest?.runtestIds.concat(',', x.runtestId) ?? '';
                      curLevelRuntest.runtests.push(x);
                    }
                  } else {
                    rl = {
                      levelId: _id ?? '',
                      runtestIds: x.runtestId,
                      runtests: [],
                    };

                    rl.runtests.push(x);
                    all.push(rl);
                  }
                });
              }
              return all;
            },
            [],
          );
          if (listLevelByReuntest.length) {
            setRowDataLevelId(listLevelByReuntest);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      message.error('Can not fetch');
    }
  };

  const onCompare = async (values: any) => {
    let _compareRuntest1: Simulator | undefined;
    let _compareRuntest2: Simulator | undefined;

    const resCompare = await simulatorService.calculateData(values?.RuntestId1 ?? '');
    if (resCompare.ok && resCompare.data) {
      _compareRuntest1 = resCompare.data;
    }

    const resCompare2 = await simulatorService.calculateData(values?.RuntestId2 ?? '');
    if (resCompare2.ok && resCompare2.data) {
      _compareRuntest2 = resCompare2.data;
    }

    if (_compareRuntest1 && _compareRuntest2) {
      const _compare = _compareRuntest1?.levelsTestResult?.find((x: any) => x.resultTable)
        ?.resultTable as IResultTable;
      const _compare2 = _compareRuntest2?.levelsTestResult?.find((x: any) => x.resultTable)
        ?.resultTable as IResultTable;
      _compareRuntest1.dateRuntest = moment(_compareRuntest1?.dateRuntest) as any;
      _compareRuntest2.dateRuntest = moment(_compareRuntest2?.dateRuntest) as any;
      formCompare.setFieldsValue({
        ..._compareRuntest1,
        levelId: resultLevelSeleted?.levelId,
      });
      formCompare2.setFieldsValue({
        ..._compareRuntest2,
        levelId: resultLevelSeleted?.levelId,
      });
      setCompareRuntest(_compareRuntest1);
      setCompareRuntest2(_compareRuntest2);
      setCompare(_compare);
      setCompare2(_compare2);
      setActive(SimulatorComponent.CompareComponent);
    }
  };

  const loadDataFilter = async () => {
    const res = await simulatorService.getDatafilter();
    if (res.ok && res.data) {
      setDataFilter(res.data);
    }
  };

  useEffect(() => {
    fetch();
    fetchRuntest();
    loadDataFilter();

    // return () => {
    //   fetch();
    //   fetchRuntest();
    //   loadDataFilter();
    // };
  }, [version]);

  return {
    formCreate,
    calculated,
    setCalculated,
    loading,
    // fetchRuntest,
    dab,
    setDab,
    config,
    setConfig,
    compare,
    compare2,
    setCompare,
    setCompare2,
    fieldsCheck,
    setFieldsCheck,
    active,
    setActive,
    boardTabActive,
    setBoardTabActive,
    rowDataResult,
    setRowDataResult,
    fetchTaskStatus,
    rowDataDraf: rowDataDraft,
    setRowDataDraft,
    rowDataLevelId,
    setRowDataLevelId,
    rowDataOngoing,
    setRowDataOngoing,
    blueprintData,
    setDataSelected,
    dataSelected,
    resultSeleted,
    setResultSelected,
    rowDataViewDetail,
    setRowDataViewDetail,
    isBackToListRuntestByLevel,
    setIsBackToListRuntestByLevel,
    backPageLevelRuntestResult,
    setBackPageLevelRuntestResult,
    viewDetailSelected,
    setViewDetailSelected,
    resultLevelSeleted,
    setResultLevelSelected,
    onCompare,
    setResultActive,
    resultActive,
    formCompare,
    formCompare2,
    compareRuntest,
    compareRuntest2,
    setRowDataStopped,
    rowDataStopped,
    dataFilter,
    setIsBackToBoard,
    isBackToBoard,
    bucketIds,
    setBucketIds,
    fetch,
    version
  };
};

export type SimulatorContext = ReturnType<typeof useSimulatorContextValue>;
const levelDataConfigContext = createContext<SimulatorContext | null>(null);

export const SimulatorContextProvider: FC = ({ children }) => {
  const value = useSimulatorContextValue();

  return (
    <levelDataConfigContext.Provider value={value}>{children}</levelDataConfigContext.Provider>
  );
};

export const useSimulatorContext = () => {
  const ctx = useContext(levelDataConfigContext);
  if (!ctx) {
    throw new Error('useSimulatorContext must be used inside SimulatorContextProvider');
  }

  return ctx;
};
