import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Button, Col, Input, Row } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';

import AgGridReact, { ColDef, GridApi, GridReadyEvent } from '@lib/components/ui/ag-grid';
import { Currency, T3CurrencyDto, UserRole } from '@lib/services/models';
import { useAuthorized } from '@lib/utils/authorize';

import { useCsPlayerContext } from '../context';

import CurrencyListAction from './list-action';
import CurrencyModal from './modal';

const columnDefs: ColDef[] = [
  {
    headerName: 'Name',
    field: 'name',
  },
  {
    headerName: 'Type',
    field: 'type',
  },
  {
    headerName: 'Value',
    field: 'value',
    minWidth: 200,
    maxWidth: 200,
    valueFormatter: ({ value }) => value?.toLocaleString() ?? 0,
  },
  {
    headerName: '',
    field: '',
    colId: 'action',
    minWidth: 85,
    maxWidth: 85,
    cellRendererFramework: CurrencyListAction,
  },
];

const CurrencyTab: FC = () => {
  const authorized = useAuthorized(UserRole.CsEditor);

  const { player, currencyModal } = useCsPlayerContext();
  const { setSelected, toggleModal } = currencyModal;

  // const [resources, setResources] = useState<T3CurrencyDto[]>([]);
  const { currencies } = useCsPlayerContext();
  const gridApiRef = useRef<GridApi>();

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    gridApiRef.current = e.api;
    e.api.sizeColumnsToFit();
  }, []);

  const handleSearch = useCallback((term?: string) => {
    if (gridApiRef.current) {
      gridApiRef.current.setQuickFilter(term);
    }
  }, []);

  const handleNew = () => {
    setSelected(undefined);
    toggleModal(true);
  };

  // useEffect(() => {
  //   setResources(player?.resources ?? []);
  // }, [player]);

  return (
    <>
      <Row className="flex mb-4">
        <Col flex="0 0 220px">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Type to search..."
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
      </Row>
      <ReactResizeDetector
        handleHeight
        render={({ height }) => (
          <div className="flex-auto ag-theme-alpine bordered" style={{ height }}>
            <AgGridReact
              columnDefs={columnDefs}
              rowData={currencies}
              onGridReady={handleGridReady}
            />
          </div>
        )}
      />

      <CurrencyModal />
    </>
  );
};

export default CurrencyTab;
