import React, { FC, useEffect } from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { FormListFieldData } from 'antd/lib/form/FormList';

import { T3LeaderBoard } from '@services/_api';

const ScoreFormItem: FC<{ field: FormListFieldData }> = ({ field }) => {
  return (
    <Row gutter={8} className="flex-auto">
      <Col flex="1">
        <Form.Item
          {...field}
          name={[field.name, 'PsaId']}
          fieldKey={[field.fieldKey, 'PsaId']}
          rules={[{ required: true, message: 'Please select currency!' }]}
          style={{ margin: 0 }}
        >
          <Input placeholder="PsaId" readOnly />
        </Form.Item>
      </Col>
      <Col flex="1">
        <Form.Item
          {...field}
          name={[field.name, 'Score']}
          fieldKey={[field.fieldKey, 'Score']}
          rules={[{ required: true, message: 'Please input amount!' }]}
          style={{ margin: 0 }}
        >
          <InputNumber placeholder="Input amount" style={{ width: '100%' }} />
        </Form.Item>
      </Col>
    </Row>
  );
};

const RoomLeaderBoardForm: FC<{ form: FormInstance; obj?: T3LeaderBoard }> = ({ form, obj }) => {
  useEffect(() => {
    if (obj) {
      form.setFieldsValue({ ...obj });
    } else {
      form.resetFields();
    }
  }, [obj]);

  return (
    <div className="form-container">
      <Form form={form} labelCol={{ flex: '0 0 110px' }} layout="horizontal">
        <Row>
          {/* <Col flex="0 0 110px" className="ant-form-item-label">
          <label className="ant-form-item-required" htmlFor="rewards">
            Rewards
            <input type="hidden" />
          </label>
        </Col> */}
          <Col flex="1">
            <Form.List name={['RoomDetailInfo']}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <div key={field.key} className="flex mb-2">
                      <ScoreFormItem field={field} />
                      {/* <Button
                        danger
                        type="text"
                        icon={<DeleteOutlined />}
                        className="flex-initial ml-auto"
                        onClick={() => {
                          form.setFieldsValue({ resourceCount: fields.length - 1 });
                          remove(field.name);
                        }}
                      /> */}
                    </div>
                  ))}

                  {/* <Button
                    type="dashed"
                    onClick={() => {
                      form.setFieldsValue({ resourceCount: fields.length + 1 });
                      add();
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Bot
                  </Button> */}
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RoomLeaderBoardForm;
