import React, { FC } from 'react';
import { Card } from 'antd';

import BlueprintDataProvider from '@lib/pages/blueprint-data/components/context';
import { AuthProvider } from '@lib/providers/auth';
import BlueprintProtoProvider from '@lib/providers/blueprint-proto';

import { LevelDataConfigContextProvider } from '@pages/map-level/context';
import { SimulatorContextProvider, useSimulatorContext } from '@pages/simulator/context';
import ChargeBarCollectionProvider from '@providers/charge-bar-collection';
import ObjectCollectionProvider from '@providers/object-collection';

import ListRuntestResultByLevel from './component/runtest-result/list-runtest-result-by-level';
import Board from './board';
import Compare from './compare';
import CreateRuntest from './create-runtest';
import LevelRuntestResult from './level-runtest-result';
import ViewDetail from './view-detail';

import './simulator.scss';

export enum SimulatorComponent {
  BoardComponent = 1,
  CreateComponent = 2,
  RuntestResultComponent = 3,
  ListRuntestResultByLevelComponent = 4,
  LevelRuntestResultComponent = 5,
  CompareComponent = 6,
  ViewDetailComponent = 7,
}

const Component: FC = () => {
  const { active } = useSimulatorContext();
  switch (active) {
    case SimulatorComponent.BoardComponent:
      return <Board />;
    case SimulatorComponent.CreateComponent:
      return <CreateRuntest />;
    case SimulatorComponent.ViewDetailComponent:
      return <ViewDetail />;
    case SimulatorComponent.ListRuntestResultByLevelComponent:
      return <ListRuntestResultByLevel />;
    case SimulatorComponent.LevelRuntestResultComponent:
      return <LevelRuntestResult />;
    case SimulatorComponent.CompareComponent:
      return <Compare />;

    default:
      return null;
  }
};

const Simulator: FC = () => {
  return (
    <AuthProvider>
      <BlueprintProtoProvider>
        <BlueprintDataProvider>
          <SimulatorContextProvider>
            <ObjectCollectionProvider>
              <ChargeBarCollectionProvider>
                <LevelDataConfigContextProvider>
                  <Card
                    className="flex flex-col flex-1 min-h-full"
                    bordered={false}
                    bodyStyle={{
                      padding: 16,
                      display: 'flex',
                      flexDirection: 'column',
                      flex: '1 1 100%',
                    }}
                  >
                    <div className="relative flex-1">
                      <div className="absolute top-0 left-0 z-10 flex flex-col w-full h-full bg-white">
                        <Component />
                      </div>
                    </div>
                  </Card>
                </LevelDataConfigContextProvider>
              </ChargeBarCollectionProvider>
            </ObjectCollectionProvider>
          </SimulatorContextProvider>
        </BlueprintDataProvider>
      </BlueprintProtoProvider>
    </AuthProvider>
  );
};

export default Simulator;
