import React, { FC, useCallback, useRef } from 'react';
import { Col, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import AgGridReact, { ColDef, GridApi, GridReadyEvent } from '@lib/components/ui/ag-grid';

import { useCsPlayerContext } from '../../context';

import PlayerLeaderBoardListAction from './list-action';
import LeaderBoardModal from './modal';

import './style.scss';

const columnDefs: ColDef[] = [
  {
    headerName: 'Name',
    field: 'LeaderboardId',
  },
  {
    headerName: 'RoomId',
    field: 'RoomId',
    maxWidth: 200,
  },
  {
    headerName: 'Joined Player',
    field: 'CurrentJoinedPlayerCount',
    maxWidth: 200,
  },
  {
    minWidth: 350,
    headerName: 'Action',
    // field: 'IsBanned',
    colId: 'action',
    sortable: true,
    cellRendererFramework: PlayerLeaderBoardListAction,
  },
];

const PlayersLeaderBoardsTab: FC = () => {
  const { playerLeaderBoards } = useCsPlayerContext();
  const gridApiRef = useRef<GridApi>();

  const handleSearch = useCallback((term?: string) => {
    if (gridApiRef.current) {
      gridApiRef.current.setQuickFilter(term);
    }
  }, []);

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    gridApiRef.current = e.api;
    e.api.sizeColumnsToFit();
  }, []);

  return (
    <>
      <Row className="flex mb-4">
        <Col flex="0 0 220px">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Type to search..."
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
      </Row>
      <div className="grid-leaderboard flex-auto ag-theme-alpine bordered">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={playerLeaderBoards}
          onGridReady={handleGridReady}
        />
      </div>
      <LeaderBoardModal />
    </>
  );
};

export default PlayersLeaderBoardsTab;
