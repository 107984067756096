import React, { FC, useState } from 'react';
import { Button, Form, message, Modal, Select, Typography } from 'antd';

import { RuntestStatusClient, useSimulatorContext } from '@pages/simulator/context';
import { SimulatorComponent } from '@pages/simulator/simulator';
import { SimulatorV2 as Simulator } from '@services/_api';

import ListRuntestForm from './list-runtest-form';

interface CollectionCompareFormProps {
  open: boolean;
  onCompare: (values: any) => void;
  onCancel: () => void;
}

export const CompareForm: React.FC<CollectionCompareFormProps> = ({
  open,
  onCompare,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const { resultLevelSeleted, rowDataLevelId, setResultLevelSelected } = useSimulatorContext();
  const [idSelected, setIdSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const onChange = (e: string) => {
    setIdSelected(e);
  };

  const onChangeLevelId = (e: string) => {
    const resultLevelSelected = rowDataLevelId.find((x) => x.levelId === e);
    setResultLevelSelected(resultLevelSelected);
  };

  return (
    <Modal
      visible={open}
      title="Compare Runtest Result"
      okText="Compare"
      cancelText="Cancel"
      confirmLoading={loading}
      cancelButtonProps={{ loading }}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            setLoading(true);
            await onCompare(values);
            setLoading(false);
          })
          .catch((info) => {
            // eslint-disable-next-line no-console
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        initialValues={{ levelId: resultLevelSeleted?.levelId }}
      >
        <Form.Item
          name="levelId"
          label="Level Id"
          rules={[{ required: true, message: 'Please select one level!' }]}
        >
          <Select
            disabled={loading}
            showSearch
            allowClear
            onChange={onChangeLevelId}
            options={rowDataLevelId
              ?.filter((x) => x.runtests.length > 1)
              .map((x) => {
                return { value: x.levelId, label: x.levelId };
              })}
          />
        </Form.Item>
        <Form.Item
          name="RuntestId1"
          label="Runtest ID 1"
          rules={[{ required: true, message: 'Please select one runtest!' }]}
        >
          <Select
            disabled={loading}
            onChange={onChange}
            options={resultLevelSeleted?.runtests.map((x) => {
              return { value: x.runtestId, label: x.runtestId };
            })}
          />
        </Form.Item>
        <Form.Item
          name="RuntestId2"
          label="Runtest ID 2"
          rules={[{ required: true, message: 'Please select one runtest!' }]}
        >
          <Select
            disabled={loading}
            options={resultLevelSeleted?.runtests
              .filter((item) => item.runtestId !== idSelected)
              .map((x) => {
                return { value: x.runtestId, label: x.runtestId };
              })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ListRuntestResultByLevel: FC = () => {
  const {
    setActive,
    setBoardTabActive,
    setRowDataResult,
    rowDataResult,
    resultLevelSeleted,
    onCompare,
  } = useSimulatorContext();

  const [selectedRows, setSelectedRows] = useState<Simulator[]>([]);
  const [open, setOpen] = useState(false);
  const [openCompare, setOpenCompare] = useState(false);

  const [loading, setLoading] = useState(false);

  const onDelete = () => {
    setLoading(true);
    setTimeout(() => {
      setRowDataResult(
        rowDataResult.filter((a) => !selectedRows.find((b) => b.runtestId === a.runtestId)),
      );
      setOpen(false);
      setLoading(false);
      message.success('Delete successfully');
    }, 3000);
  };

  const compare = async (values: any) => {
    await onCompare(values);
    setOpenCompare(false);
  };

  return (
    <>
      <CompareForm
        open={openCompare}
        onCompare={compare}
        onCancel={() => {
          setOpenCompare(false);
        }}
      />
      <div className="flex mb-3">
        <Typography.Title level={3}>
          List Runtest Result - {resultLevelSeleted?.levelId}
        </Typography.Title>

        <div className="ml-auto flex gap-x-3">
          <Button type="primary" onClick={() => setOpenCompare(true)}>
            Compare Result
          </Button>
          <Button
            onClick={() => {
              setActive(SimulatorComponent.BoardComponent);
              setBoardTabActive(RuntestStatusClient.RUNTEST_RESULT);
            }}
          >
            Exit
          </Button>
        </div>
      </div>

      <div className="flex justify-end mb-3 items-center">
        <Modal
          title="Confirm"
          visible={open}
          onOk={onDelete}
          onCancel={() => setOpen(false)}
          cancelButtonProps={{ loading }}
          okButtonProps={{ danger: true, loading }}
          okText="Clear"
          cancelText="Cancel"
        >
          Are you sure to remove {selectedRows.length === 1 ? 'this' : `${selectedRows.length}`}{' '}
          runtest?
        </Modal>
        {selectedRows.length > 0 && (
          <Button danger onClick={() => setOpen(true)}>
            Delete {selectedRows.length} {selectedRows.length === 1 ? 'row' : 'rows'}
          </Button>
        )}
      </div>

      <ListRuntestForm
        setOpen={setOpen}
        rawData={resultLevelSeleted?.runtests ?? []}
        setSelectedRows={setSelectedRows}
        isBackToListRuntestByLevel
        levelId={resultLevelSeleted?.levelId}
      />
    </>
  );
};

export default ListRuntestResultByLevel;
