import React, { FC } from 'react';
import { Button } from 'antd';

import { useLevelDataConfigContext } from '../context';

import { DEFAULT_TILE } from './level-data/layout/tile-select';

export type ButtonLayerProps = {
  layerNumber: number;
  curLayerNumber: number;
  onClickLayerButton?: (layerNumber: number) => void;
};

const ButtonLayer: FC<ButtonLayerProps> = ({ layerNumber, onClickLayerButton, curLayerNumber }) => {
  const { setTileGlobal, setTileBlock, setSelected } = useLevelDataConfigContext();

  const handleClick = (layer: number) => {
    if (onClickLayerButton) {
      setTileGlobal(DEFAULT_TILE.id);
      setSelected(DEFAULT_TILE.id);
      setTileBlock('');
      onClickLayerButton(layer);
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          handleClick(layerNumber);
        }}
        type={curLayerNumber === layerNumber ? 'primary' : 'default'}
      >
        {layerNumber === 4 ? 0 : layerNumber}
      </Button>
    </>
  );
};

export default ButtonLayer;
