import React, { FC, useState } from 'react';
import { Button, message, Modal } from 'antd';

import { useSimulatorContext } from '@pages/simulator/context';
import { SimulatorV2 as Simulator } from '@services/_api';
import { simulatorService } from '@services/simulator';

import ByRuntestId from './runtest-result/by-runtest-id';

const Draft: FC = () => {
  const { setRowDataDraft } = useSimulatorContext();
  const [selectedRows, setSelectedRows] = useState<Simulator[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    // eslint-disable-next-line no-restricted-syntax
    for await (const data of selectedRows) {
      const res = await simulatorService.deleteById(data.runtestId);
      if (res.ok) {
        message.success('Delete successfully');
        setRowDataDraft((cur) => {
          return [...cur.filter((x) => x.runtestId !== data.runtestId)];
        });
      }
    }
    setSelectedRows([]);

    setOpen(false);
    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-y-3 grid-result-simulator">
      <div className="flex justify-between items-center">
        <Modal
          title="Confirm"
          visible={open}
          onOk={onDelete}
          onCancel={() => setOpen(false)}
          cancelButtonProps={{ loading }}
          okButtonProps={{ danger: true, loading }}
          okText="Clear"
          cancelText="Cancel"
        >
          Are you sure to remove {selectedRows.length} runtest?
        </Modal>
        <div className="ml-auto flex gap-x-3">
          {selectedRows.length > 0 && (
            <Button
              danger
              onClick={() => {
                setOpen(true);
              }}
            >
              Delete {selectedRows.length} {selectedRows.length === 1 ? 'row' : 'rows'}
            </Button>
          )}
          {/* <Button loading={loadingFetch} onClick={() => fetchRuntest()}>
            Reload
          </Button> */}
        </div>
      </div>
      <ByRuntestId setOpen={setOpen} setSelectedRows={setSelectedRows} hasDraft />
    </div>
  );
};

export default Draft;
