import { IField, IType } from 'protobufjs';

const simpleTypes = ['int16', 'int32', 'int64', 'float', 'bool', 'string', 'uint32', 'bytes'];

export const isSimpleType = (field: IField) => simpleTypes.includes(field.type);

export const isMapType = (field: IField) => !!(field as any).keyType;

export const isArrayType = (field: IField) => field.rule === 'repeated';

export const isSingleRecordType = (type: IType) => {
  const rawFields = Object.keys(type.fields);
  if (rawFields.length === 1) {
    const field = type.fields[rawFields[0]];

    // simple type, including array and map of simple type
    if (isSimpleType(field)) return true;

    if (field.rule === 'repeated' || (field as any).keyType) {
      // map or array
      return false;
    }
  }

  return true;
};
