import React, { FC, useEffect } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { LockOutlined } from '@ant-design/icons';

import { useGlobalBlueprintDataVersionContext } from '@lib/providers/blueprint-version';

export type BlueprintDataVersionSelectProps = SelectProps<string> & {
  type?: 'base' | 'working';
};

// const _baseVersion = 'base';

export const BlueprintDataVersionSelect: FC<BlueprintDataVersionSelectProps> = ({
  type,
  ...props
}) => {
  const { versions: data, loading } = useGlobalBlueprintDataVersionContext();

  const filteredData = type
    ? data.filter((item) => (type === 'base' ? item.isBaseVersion : !item.isBaseVersion))
    : data;

  return (
    <Select placeholder="Select Blueprint data version" showSearch {...props} loading={loading}>
      {filteredData.map(({ name, baseVersion, isReadOnly }) => {
        return (
          <Select.Option key={name} value={name}>
            {isReadOnly ? <LockOutlined className="mr-1" /> : null}
            {name}
            {baseVersion && <span className="ml-1">(Based on {baseVersion})</span>}
          </Select.Option>
        );
      })}
    </Select>
  );
};

const BlueprintDataVersionSelectGlobal: FC<BlueprintDataVersionSelectProps> = (props) => {
  const { selectedKey, setSelected } = useGlobalBlueprintDataVersionContext();

  // useEffect(() => {
  //   setSelected(_baseVersion);
  // }, []);

  return <BlueprintDataVersionSelect {...props} value={selectedKey} onChange={setSelected} />;
};

export default BlueprintDataVersionSelectGlobal;
