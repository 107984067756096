import React, { FC } from 'react';
import { Modal, Row } from 'antd';
import { IField, IType } from 'protobufjs';

import { formatBlueprintName } from '@lib/utils/blueprint-data';

import { useBlueprintDataContext } from '../../../context';
import { RenderOptions } from '../single-field';
import TypeFieldFormItem from '../type-field';

export type AnyFormProps = {
  options: RenderOptions<IField>;
};

const AnyForm: FC<AnyFormProps> = ({ options }) => {
  const { type, types } = options;

  if (!types[type.type]) return null;

  return <TypeFieldFormItem options={{ ...options, type: types[type.type] as IType }} />;
};

const AnyModal: FC = () => {
  const { anyModal } = useBlueprintDataContext();
  const { modalVisible, toggleModal, selected } = anyModal;

  const handleClose = () => {
    toggleModal(false);
  };

  return (
    <Modal
      title={`Input ${formatBlueprintName(selected?.type?.type ?? 'Any')}`}
      visible={modalVisible && !!selected}
      onCancel={handleClose}
      onOk={handleClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      width="60%"
    >
      {selected ? (
        <Row gutter={8} className="ant-form-vertical">
          <AnyForm options={selected} />
        </Row>
      ) : null}
    </Modal>
  );
};

export default AnyModal;
