import React from 'react';
import { Layout as AntdLayout, Space } from 'antd';

import HeaderBreadCrumb from './breadcrumb';
import DocsDropdown from './docs-dropdown';
import UserDropdown from './user-dropdown';

const HeaderContent = () => {
  return (
    <AntdLayout.Header
      className="flex justify-center shadow-sm"
      style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #f0f0f0' }}
    >
      <div className="flex items-center h-full">
        <HeaderBreadCrumb />
      </div>
      <div className="flex items-center h-full ml-auto">
        <Space size="large">
          <DocsDropdown />
          <UserDropdown />
        </Space>
      </div>
    </AntdLayout.Header>
  );
};

export default HeaderContent;
