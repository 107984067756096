import React, { FC } from 'react';
import { Card, Col, Row } from 'antd';
import { IField } from 'protobufjs';

import { formatLabel } from '@lib/utils/blueprint-data';

import EnumFieldFormItem from '../enum-field';
import { RenderOptions } from '../single-field';
import { generateAnyEnum } from '../utils/generate-any-enum';

import JsonFormField from './json-field';

export type AnyFieldFormItemProps = {
  options: RenderOptions<IField>;
};

const AnyFieldFormItem: FC<AnyFieldFormItemProps> = ({ options }) => {
  const { paths, key, fieldSettings, types } = options;

  const fieldName = [...paths, key];
  const dataKey = fieldName.join('.');
  const formatedKey = formatLabel(key);

  const fieldSetting = fieldSettings[dataKey];

  const refTypeName = [...fieldName, 'Any.type_url'].join('.');
  const refTypeEnum = generateAnyEnum(fieldSetting?.anyTypes ?? []);
  types[refTypeName] = refTypeEnum;

  return (
    <Col flex="0 0 100%" key={dataKey} className="mb-4">
      <Card size="small">
        <div className="font-bold ant-form-item-label">{formatedKey}</div>
        <Row gutter={8}>
          <EnumFieldFormItem
            options={{
              ...options,
              type: refTypeEnum,
              paths: fieldName,
              key: 'type_url',
              label: 'Type',
            }}
          />
          <JsonFormField options={{ ...options, paths: fieldName, key: 'value' }} />
        </Row>
      </Card>
    </Col>
  );
};

export default AnyFieldFormItem;
