import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Col, Divider, Form, message, Modal, Row, Typography } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { GridReadyEvent } from '@ag-grid-community/core';

import BlueprintDataGrid, { BlueprintDataGridRef } from '@lib/components/blueprint-data-grid';
import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';
import { useAvaConfigContext } from '@lib/providers/ava-config';
import { useGlobalBlueprintProtoContext } from '@lib/providers/blueprint-proto';

import { RuntestStatusClient, useSimulatorContext } from '@pages/simulator/context';
import { BlueprintData, SimulatorV2 as Simulator } from '@services/_api';
import { simulatorService } from '@services/simulator';

import { SimulatorComponent } from '../simulator';

import MainForm from './main-form';

export const MAPLEVEL_COLLECTION = 'com.alleylabs.legendblast.blueprint.MapLevelCollection';
export const SIMULATOR_CONFIG_KEY = 'SIMULATOR_CONFIG';

const CreateRuntest: FC = () => {
  const [confirm, setConfirm] = useState(false);
  const [countSelected, setCountSelected] = useState(0);
  const [disable, setDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const { loading } = useAvaConfigContext();

  const gridRef = useRef<BlueprintDataGridRef>(null);
  const { environment } = useBlueprintDataContext();

  const { protos } = useGlobalBlueprintProtoContext();
  const {
    setBoardTabActive,
    setActive,
    blueprintData,
    dataSelected,
    setDataSelected,
    resultSeleted,
    formCreate: form,
    rowDataOngoing,
    setRowDataOngoing,
    dab,
    bucketIds,
    version
  } = useSimulatorContext();

  const handleGridSelectionChange = (objs: BlueprintData[]) => {
    setCountSelected(objs.length);
    setDataSelected(objs);
    // if (gridRef?.current) {
    //   // eslint-disable-next-line no-unused-expressions
    //   gridRef?.current.gridApi.setPinnedTopRowData(objs);
    // }
  };

  const handleGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();

    if (dataSelected.length && event.api) {
      event.api.forEachNode((node) => {
        if (!dataSelected.length || dataSelected.map((x: any) => x.id).includes(node.data.objId)) {
          node.setSelected(true, false);
        }
      });
      // event.api.setPinnedTopRowData(dataSelected);
    }
  };

  const handleCreateRuntest = async (hasDraft?: boolean) => {
    try {
      setLoadingCreate(true);
      if ((confirm && dataSelected && dataSelected?.length > 0) || hasDraft) {
        // if (_.isEmpty(config)) {
        //   return message.error('Not enough config to runtest');
        // }
        const dataForm = form.getFieldsValue();
        const res = await simulatorService.createRuntest(bucketIds, {
          ...dataForm,
          levelsTestResult: dataSelected.map((item) => {
            const result = {
              requestId: '',
              levelConfig: item.data,
            };
            return result;
          }),
          // configs: config,
          bucketIds,
          env: environment,
          dab,
          status: hasDraft ? RuntestStatusClient.DRAFT : RuntestStatusClient.ONGOING,
          version
        } as Simulator);
        if (res.ok) {
          if (hasDraft) {
            message.success('Save draft successfully');
            setBoardTabActive(RuntestStatusClient.DRAFT);
          } else {
            if (res.ok) {
              const { data } = res as any;
              // data.listLevel = data.levelsTestResult
              //   ?.map((entry: any) => entry.levelConfig?.id ?? '')
              //   .join(',');
              data.dateString = moment(data.dateRuntest).format('DD/MM/YYYY HH:mm');
              data.lastUpdated = moment(data.lastUpdated).format('DD/MM/YYYY HH:mm');
              rowDataOngoing.push(data);
              setRowDataOngoing(rowDataOngoing);
            }
            setBoardTabActive(RuntestStatusClient.ONGOING);
            message.success('Create runtest successfully');
          }
          setActive(SimulatorComponent.BoardComponent);
        }
      } else {
        setOpen(true);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setLoadingCreate(false);
    }
  };

  const onConfirm = () => {
    if (dataSelected && dataSelected?.length > 0) {
      setConfirm(true);
      setDisable(true);
    }
  };

  useEffect(() => {
    if (dataSelected && dataSelected?.length) {
      setConfirm(false);
      setDisable(false);
    }
  }, [dataSelected]);

  return (
    <>
      <Modal
        onCancel={() => setOpen(false)}
        title="You haven't selected the levels to test!"
        visible={open}
        onOk={() => setOpen(false)}
        cancelButtonProps={{ hidden: true }}
        okText="Confirm"
      >
        <div>
          <p>Please tick on checkbox to select the level</p>
          <p>After that, click to Confirm your choice</p>
        </div>
      </Modal>
      <div className="flex mb-6">
        <Typography.Title level={3}>
          {resultSeleted ? 'Result Runtest' : 'Create A Runtest'}
        </Typography.Title>
        <div className="ml-auto flex gap-x-3">
          <Button
            type="primary"
            loading={loadingCreate || loading}
            onClick={() => handleCreateRuntest()}
          >
            Run it
          </Button>
          <Button
            loading={loadingCreate || loading}
            type="primary"
            ghost
            onClick={() => handleCreateRuntest(true)}
          >
            Save as Draft
          </Button>
          <Button
            loading={loadingCreate || loading}
            onClick={() => {
              setActive(SimulatorComponent.BoardComponent);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
      <Form style={{ paddingBottom: 14 }} form={form}>
        <MainForm />
        <Divider orientation="left">Pick Levels</Divider>
        <div className="flex w-full">
          <Row
            className="items-stretch gap-3 flex-1 mt-4 overflow-y-auto"
            style={{
              minHeight: 700,
              maxHeight: 700,
            }}
          >
            <Col flex={3} className="flex flex-col max-h-full min-h-full">
              <div className="flex justify-between items-center">
                {countSelected > 0 ? (
                  <h5>{countSelected === 1 ? '1 row' : `${countSelected} rows`} selected</h5>
                ) : null}

                <Button
                  className="ml-auto mb-3"
                  onClick={onConfirm}
                  disabled={disable}
                  type="primary"
                >
                  Confirm
                </Button>
              </div>
              <BlueprintDataGrid
                ref={gridRef}
                onGridReady={handleGridReady}
                type={protos.find((item) => item.name === MAPLEVEL_COLLECTION)}
                data={blueprintData}
                rowSelection="multiple"
                action={{
                  onSelectionChanged: handleGridSelectionChange,
                }}
              />
            </Col>
            {dataSelected?.length > 0 && (
              <Col
                flex={2}
                className="flex flex-col max-h-full min-h-full overflow-y-auto"
                style={{ marginTop: '44px', height: 'calc(100% - 44px)' }}
              >
                {dataSelected.map((x) => {
                  return <div>LevelConfig_{x?.data?.id}</div>;
                })}
              </Col>
            )}
          </Row>
        </div>
      </Form>
    </>
  );
};

export default CreateRuntest;
