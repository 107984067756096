import React, { FC } from 'react';
import { Card, Col, Form, Row } from 'antd';
import { IType } from 'protobufjs';

import RevertedSwitch from '@lib/components/ui/reverted-switch';
import { formatLabel } from '@lib/utils/blueprint-data';

import { RenderOptions } from './single-field';
import TypeFieldFormItem from './type-field';

export type ObjectFormItemProps = {
  options: RenderOptions<IType>;
};

const ObjectFormItem: FC<ObjectFormItemProps> = ({ options }) => {
  const { paths, key, form } = options;

  const fieldName = [...paths, key];
  const dataKey = fieldName.join('.');

  return (
    <Col flex="0 0 100%" key={dataKey} className="mb-4">
      <Card size="small">
        <div className="font-bold ant-form-item-label">
          {formatLabel(key)}
          <span className="ml-2">
            <Form.Item name={[...fieldName, '$deleted']} valuePropName="checked" noStyle>
              <RevertedSwitch size="small" />
            </Form.Item>
          </span>
        </div>
        {form.getFieldValue([...fieldName, '$deleted']) !== true && (
          <Row gutter={8}>
            <TypeFieldFormItem options={{ ...options, paths: fieldName }} />
          </Row>
        )}
      </Card>
    </Col>
  );
};

export default ObjectFormItem;
