import React, { createContext, FC, useContext } from 'react';

import { UseApi } from '@lib/services/base/use-api';
import { SystemConfig } from '@lib/services/models';
import { useSystemConfigList } from '@lib/services/system-config';
import { UseModal, useModal } from '@lib/utils/use-modal';

export type SystemConfigContext = {
  list: UseApi<SystemConfig[]>;
  modal: UseModal<SystemConfig>;
};

export enum DefaultSystemConfigKeys {
  JWT_SECRET = 'JWT_SECRET',
  APP_ICON = 'APP_ICON',
  GOOGLE_CLIENT_ID = 'GOOGLE_CLIENT_ID',
  GOOGLE_ALLOWED_MAIL_DOMAINS = 'GOOGLE_ALLOWED_MAIL_DOMAINS',
  SLACK_WEB_HOOK_URL = 'SLACK_WEB_HOOK_URL',
  SLACK_SUCCESS_EMOJI = 'SLACK_SUCCESS_EMOJI',
  SLACK_FAILURE_EMOJI = 'SLACK_FAILURE_EMOJI',
  GITLAB_API_URL = 'GITLAB_API_URL',
  GITLAB_API_TOKEN = 'GITLAB_API_TOKEN',
  GITHUB_API_URL = 'GITHUB_API_URL',
  GITHUB_API_TOKEN = 'GITHUB_API_TOKEN',
  DEPLOY_TIMEOUT = 'DEPLOY_TIMEOUT',
  BLUEPRINT_HISTORY_SIZE = 'BLUEPRINT_HISTORY_SIZE',
  ODL_JENKINS_JOB_URL = 'ODL_JENKINS_JOB_URL',
  ODL_S3_URL = 'ODL_S3_URL',
  ODL_BUNDLE_PREFIX = 'ODL_BUNDLE_PREFIX',
  ODL_SUPPORT_IMAGE_SIZE = 'ODL_SUPPORT_IMAGE_SIZE',
  IS_RSC = 'IS_RSC',
  RESET_RSC = 'RESET_RSC',
}

const SystemConfigContext = createContext<SystemConfigContext | null>(null);

export const SystemConfigProvider: FC = ({ children }) => {
  const list = useSystemConfigList();
  const modal = useModal<SystemConfig>();

  const value = {
    list,
    modal,
  };

  return <SystemConfigContext.Provider value={value}>{children}</SystemConfigContext.Provider>;
};

export const useSystemConfigContext = () => {
  const ctx = useContext(SystemConfigContext);
  if (!ctx) {
    throw new Error('useSystemConfigContext must be used inside SystemConfigProvider');
  }

  return ctx;
};

export default SystemConfigProvider;
