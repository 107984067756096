import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Form, InputNumber, message, Modal, Row, Tooltip } from 'antd';
import {
  ExclamationCircleOutlined,
  LockOutlined,
  UnlockOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import { csUserService } from '@lib/services/cstool';
import { T3LeaderBoardDto, UserRole } from '@lib/services/models';
import { useAuthorized } from '@lib/utils/authorize';

import { useCsPlayerContext } from '../../context';

const PlayerLeaderBoardListAction: FC<{
  data: T3LeaderBoardDto;
}> = ({ data: leaderboard }) => {
  const history = useHistory();
  const authorized = useAuthorized(UserRole.CsEditor);
  const [open, setOpen] = useState<boolean>(false);
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);

  const {
    environment,
    playerId,
    banPlayer,
    unbanPlayer,
    setPlayerLeaderBoards: setLeaderBoards,
    playerLeaderBoards,
    modal,
  } = useCsPlayerContext();

  const { toggleModal, setSelected } = modal;

  const handleLeaderBoardChange = (id?: string) => {
    if (!id) return;

    setLeaderBoards(
      playerLeaderBoards.map((item) => {
        if (item.LeaderboardId === id) item.IsBanned = !item.IsBanned;

        return item;
      }),
    );
  };

  const handleBanAccount = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to ban player ${playerId} in lead board ${leaderboard.LeaderboardId}`,
      okButtonProps: { danger: true },
      onOk: async () => {
        const res = await banPlayer(leaderboard.LeaderboardId);
        if (res) {
          message.success(`Ban ${playerId} successfully`);
          handleLeaderBoardChange(leaderboard.LeaderboardId);
        }
      },
    });
  };

  const handleViewLeaderBoard = () => {
    if (leaderboard.RoomDetailInfo?.length === 0) {
      Modal.error({
        title: 'Error',
        content: 'Room Leader Board is Empty!',
      });
      return;
    }

    setSelected(leaderboard as T3LeaderBoardDto);
    toggleModal(true);
  };

  const handleUnBanAccount = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to unban player ${playerId} in leader board ${leaderboard.LeaderboardId}?`,
      okButtonProps: { danger: true },
      onOk: async () => {
        const res = await unbanPlayer(leaderboard.LeaderboardId);
        if (res) {
          message.success(`UnBan ${playerId} successfully`);
          handleLeaderBoardChange(leaderboard.LeaderboardId);
        }
      },
    });
  };

  const [form] = Form.useForm();
  const [formSchedule] = Form.useForm();

  const handleCheatScore = async (values: any) => {
    if (!environment) {
      return message.error('Please select environment !');
    }
    const res = await csUserService.cheatScore(
      Number(playerId),
      leaderboard.LeaderboardId,
      leaderboard.RoomId,
      values.score,
      environment ?? '',
    );

    if (res.ok) {
      // fetchData(dataForm.PsaId, dataForm.LeaderboardId, dataForm.RoomId, dataForm.environment);
      setOpenSchedule(false);
      return message.success(`Cheat player's score successfully !`);
    }
    return message.error(res.error ?? `Cheat player's score failure !`);
  };

  // const handleSchedule = async (values: any) => {
  //   if (!values.currentDab) {
  //     return message.error('Please select dab !');
  //   }
  //   const res = await csUserService.scheduleLeaderboard(
  //     leaderboard.LeaderboardId,
  //     values.currentDab,
  //     environment ?? '',
  //   );

  //   if (res.ok) {
  //     // fetchData(dataForm.PsaId, dataForm.LeaderboardId, dataForm.RoomId, dataForm.environment);
  //     setOpenSchedule(false);
  //     return message.success(`Schedule Leaderboard successfully !`);
  //   }
  //   return message.error(res.error ?? `Schedule Leaderboard failure !`);
  // };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  // const handleOpenChangeSchedule = (newOpen: boolean) => {
  //   setOpenSchedule(newOpen);
  // };

  const FormUpdateProgress = () => {
    return (
      <>
        <Form onFinish={handleCheatScore} layout="inline" form={form}>
          <Form.Item name="score" label="Score">
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };

  // const FormSchedule = () => {
  //   return (
  //     <>
  //       <Form onFinish={handleSchedule} layout="inline" form={formSchedule}>
  //         <Form.Item name="currentDab" label="Current Dab">
  //           <DabSelect showSearch allowClear style={{ minWidth: 300 }} />
  //         </Form.Item>
  //         <Form.Item>
  //           <Button type="primary" htmlType="submit">
  //             Submit
  //           </Button>
  //         </Form.Item>
  //       </Form>
  //     </>
  //   );
  // };

  return (
    <Form layout="vertical" form={form}>
      <Row>
        <Col>
          <div className="flex gap-x-1 justify-items-start items-center">
            <Tooltip title="For View Detail Of LeaderBoard">
              <Button
                htmlType="submit"
                hidden={!environment || !playerId || !authorized}
                // loading={loading}
                onClick={handleViewLeaderBoard}
                icon={<UnorderedListOutlined />}
                type="primary"
              >
                View LeaderBoard
              </Button>
            </Tooltip>
            {/* <Popover
              content={FormSchedule}
              trigger="click"
              visible={openSchedule}
              title="Schedule Leaderboard"
              onVisibleChange={handleOpenChangeSchedule}
            >
              <Button htmlType="submit" icon={<ScheduleOutlined />} type="primary">
                Schedule
              </Button>
            </Popover> */}
            {/* <Popover
              content={FormUpdateProgress}
              trigger="click"
              visible={open}
              title="Cheat Score"
              onVisibleChange={handleOpenChange}
            >
              <Button htmlType="submit" icon={<SwapOutlined />} type="primary">
                Cheat Score
              </Button>
            </Popover> */}
            <Tooltip title="For Banning Account In LeaderBoard">
              <Button
                htmlType="submit"
                hidden={!environment || !playerId || leaderboard?.IsBanned || !authorized}
                // loading={loading}
                onClick={handleBanAccount}
                icon={<LockOutlined />}
                danger
                style={{ width: '100px' }}
              >
                Ban
              </Button>
            </Tooltip>

            <Tooltip title="For UnBanning Account In LeaderBoard">
              <Button
                htmlType="submit"
                hidden={!environment || !playerId || !leaderboard?.IsBanned || !authorized}
                // loading={loading}
                onClick={handleUnBanAccount}
                icon={<UnlockOutlined />}
                type="primary"
                style={{ width: '100px' }}
              >
                Unban
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default PlayerLeaderBoardListAction;
