import React, { ReactNode } from 'react';
import Location from 'react-app-location';
import { RouteProps } from 'react-router-dom';
import * as yup from 'yup';
import {
  AccountBookOutlined,
  AlertOutlined,
  ApiOutlined,
  BranchesOutlined,
  BuildOutlined,
  CloudUploadOutlined,
  CodeOutlined,
  ControlOutlined,
  CrownOutlined,
  DatabaseOutlined,
  DeploymentUnitOutlined,
  DownloadOutlined,
  DropboxOutlined,
  FieldTimeOutlined,
  FolderOpenOutlined,
  ImportOutlined,
  NumberOutlined,
  OrderedListOutlined,
  ProjectOutlined,
  RobotOutlined,
  SafetyCertificateOutlined,
  ScheduleOutlined,
  SearchOutlined,
  SettingOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';

import UserScreen from '@lib/pages/admin/user/lazy';
import ApiTokenScreen from '@lib/pages/api-token/lazy';
import BlueprintDataHistoryScreen from '@lib/pages/blueprint-data-history/lazy';
import BlueprintDataImportScreen from '@lib/pages/blueprint-data/import/lazy';
import BlueprintDataScreen from '@lib/pages/blueprint-data/lazy';
import BlueprintStructScreen from '@lib/pages/blueprint-struct/lazy';
import BlueprintDataVersionScreen from '@lib/pages/blueprint-version/lazy';
import BotScreen from '@lib/pages/bot-manage/bot';
import CsToolMassInventory from '@lib/pages/cstool/mass-inventory/lazy';
import ScheduleScreen from '@lib/pages/cstool/schedule';
import CsToolSearchUserScreen from '@lib/pages/cstool/search-user/lazy';
import BlueprintDeployHistoryScreen from '@lib/pages/deploy-blueprint/history/lazy';
import DeployBlueprintScreen from '@lib/pages/deploy-blueprint/lazy';
import EnvironmentScreen from '@lib/pages/environment/lazy';
import AssetConfigScreen from '@lib/pages/game-config/asset-config/lazy';
import ConfigBlueprintVersionScreen from '@lib/pages/game-config/blueprint-version/components/lazy';
import GitLabScreen from '@lib/pages/gitlab/lazy';
import HealthScreen from '@lib/pages/health/lazy';
import MaintenanceScreen from '@lib/pages/health/maintenance/lazy';
import LoginScreen from '@lib/pages/login/lazy';
import BlueprintDataMoveChangesScreen from '@lib/pages/move-changes/lazy';
import OdlFileBrowserScreen from '@lib/pages/odl/file-browser/lazy';
import OdlBuildHistoryScreen from '@lib/pages/odl/odl-build/history/lazy';
import OdlBuildScreen from '@lib/pages/odl/odl-build/lazy';
import SystemConfigScreen from '@lib/pages/system-config/lazy';
import { UserRole } from '@lib/services/models';

export type RouteItem = Omit<RouteProps, 'path'> & {
  name: string;
  title: string;
  icon?: ReactNode;
  path?: string;
  children?: RouteItem[];
  public?: boolean;
  role?: UserRole;
  hideInMenu?: boolean;
  fullscreen?: boolean;
};

export const HealthLocation = new Location('/health');
export const MaintenanceLocation = new Location('/maintenance');
export const LoginLocation = new Location('/login', null, {
  next: yup.string(),
});
export const UserLocation = new Location('/user');
export const ApiTokenLocation = new Location('/token');
export const UpdateBlueprintProtoLocation = new Location('/blueprint/proto/update');
export const BlueprintProtoLocation = new Location('/blueprint/proto');
export const BlueprintVersionLocation = new Location('/blueprint/version');
export const BlueprintDataLocation = new Location('/blueprint/data', null, {
  version: yup.string(),
  type: yup.string(),
});
export const BlueprintDataImportLocation = new Location('/blueprint/data/import', null, {
  version: yup.string(),
});
export const BlueprintDataHistoryLocation = new Location('/blueprint/data/history', null, {
  version: yup.string(),
  type: yup.string(),
  deployId: yup.number().positive().integer(),
});
export const BlueprintDataMoveChangesLocation = new Location('/blueprint/data/move-changes', null, {
  version: yup.string(),
  type: yup.string(),
  compareVersion: yup.string(),
});
export const DeployBlueprintLocation = new Location('/');
export const DeployBlueprintHistoryLocation = new Location('/deploy-blueprint/history');
export const SystemConfigLocation = new Location('/config');
export const EnvConfigLocation = new Location('/environment');
export const GameVersionConfigLocation = new Location('/game-config/version');
export const AssetConfigLocation = new Location('/game-config/asset');
export const CsSearchUserLocation = new Location('/cstools/search-users');
export const CsMassInventoryLocation = new Location('/cstools/mass-inventory');
export const CsLeaderBoardLocation = new Location('/leader-board');
export const CsMailTemplateLocation = new Location('/cstools/mail-template');
export const ScheduleLeaderBoardLocation = new Location('/cstools/schedule');
export const BotLocation = new Location('/bot-manage/bot');
// export const CsMailTemplateLocation = new Location('/cstools/mail-template');
export const CsInboxLocation = new Location('/cstools/inbox');
export const CsInboxV2Location = new Location('/cstools/inbox/v2');
export const AuditLogLocation = new Location('/cstools/audit-log');
export const CsInboxHistoryLocation = new Location('/cstools/inbox/history');
export const OdlLocation = new Location('/odl');
export const OdlBuildLocation = new Location('/odl/build');
export const OdlBuildHistoryLocation = new Location('/odl/build/history');

export const loginRoute: RouteItem = {
  name: 'login',
  title: 'Login',
  component: LoginScreen,
  path: LoginLocation.path,
  exact: true,
  hideInMenu: true,
  fullscreen: true,
  public: true,
};

export const appRoutes: RouteItem[] = [
  loginRoute,
  {
    name: 'system',
    title: 'System',
    icon: <ControlOutlined />,
    children: [
      {
        name: 'health',
        title: 'Health',
        icon: <AlertOutlined />,
        component: HealthScreen,
        path: HealthLocation.path,
        exact: true,
        role: UserRole.System,
      },
      {
        name: 'maintenance',
        title: 'Maintenance',
        component: MaintenanceScreen,
        path: MaintenanceLocation.path,
        exact: true,
        hideInMenu: true,
        fullscreen: true,
        public: true,
      },
      {
        name: 'systemConfig',
        title: 'System Config',
        icon: <ApiOutlined />,
        component: SystemConfigScreen,
        path: SystemConfigLocation.path,
        exact: true,
        role: UserRole.System,
      },
      {
        name: 'token',
        title: 'API Token',
        icon: <SafetyCertificateOutlined />,
        component: ApiTokenScreen,
        path: ApiTokenLocation.path,
        exact: true,
        role: UserRole.Member,
      },
    ],
  },
  {
    name: 'admin',
    title: 'Admin',
    icon: <SettingOutlined />,
    children: [
      {
        name: 'user',
        title: 'User',
        icon: <UserOutlined />,
        component: UserScreen,
        path: UserLocation.path,
        exact: true,
        role: UserRole.System,
      },
      {
        name: 'envConfig',
        title: 'Environment',
        icon: <DeploymentUnitOutlined />,
        component: EnvironmentScreen,
        path: EnvConfigLocation.path,
        exact: true,
        role: UserRole.System,
      },
    ],
  },
  {
    name: 'blueprint',
    title: 'Blueprint',
    icon: <ProjectOutlined />,
    children: [
      {
        name: 'updateBlueprintProto',
        title: 'Update Proto',
        icon: <DownloadOutlined />,
        component: GitLabScreen,
        path: UpdateBlueprintProtoLocation.path,
        exact: true,
        role: UserRole.ProtoAdmin,
      },
      {
        name: 'blueprintProto',
        title: 'Proto Config',
        icon: <UnorderedListOutlined />,
        component: BlueprintStructScreen,
        path: BlueprintProtoLocation.path,
        exact: true,
        role: UserRole.Member,
      },
      {
        name: 'blueprintDataVersion',
        title: 'Version',
        icon: <NumberOutlined />,
        component: BlueprintDataVersionScreen,
        path: BlueprintVersionLocation.path,
        exact: true,
        role: UserRole.Member,
      },
      {
        name: 'blueprintData',
        title: 'Blueprint Data',
        icon: <DatabaseOutlined />,
        component: BlueprintDataScreen,
        path: BlueprintDataLocation.path,
        exact: true,
        role: UserRole.Member,
      },
      {
        name: 'blueprintImport',
        title: 'Import',
        icon: <ImportOutlined />,
        component: BlueprintDataImportScreen,
        path: BlueprintDataImportLocation.path,
        exact: true,
        role: UserRole.DataEditor,
      },
      {
        name: 'moveChanges',
        title: 'Move Changes',
        icon: <BranchesOutlined />,
        component: BlueprintDataMoveChangesScreen,
        path: BlueprintDataMoveChangesLocation.path,
        exact: true,
        role: UserRole.DataAdmin,
      },
      {
        name: 'blueprintDataHistory',
        title: 'Blueprint Data History',
        icon: <FieldTimeOutlined />,
        component: BlueprintDataHistoryScreen,
        path: BlueprintDataHistoryLocation.path,
        exact: true,
        hideInMenu: true,
        role: UserRole.Member,
      },
    ],
  },
  {
    name: 'deployBlueprint',
    title: 'Deploy Blueprint',
    icon: <CloudUploadOutlined />,
    component: DeployBlueprintScreen,
    path: DeployBlueprintLocation.path,
    exact: true,
    role: UserRole.Deployer,
  },
  {
    name: 'deployBlueprintHistory',
    title: 'Deploy History',
    icon: <FieldTimeOutlined />,
    component: BlueprintDeployHistoryScreen,
    path: DeployBlueprintHistoryLocation.path,
    exact: true,
    hideInMenu: true,
    role: UserRole.Member,
  },
  {
    name: 'gameConfig',
    title: 'Game Config',
    icon: <CodeOutlined />,
    children: [
      {
        name: 'blueprintVersion',
        title: 'Blueprint Version',
        icon: <OrderedListOutlined />,
        component: ConfigBlueprintVersionScreen,
        path: GameVersionConfigLocation.path,
        exact: true,
        role: UserRole.Deployer,
      },
      {
        name: 'assetConfig',
        title: 'Asset Config',
        icon: <DropboxOutlined />,
        component: AssetConfigScreen,
        path: AssetConfigLocation.path,
        exact: true,
        role: UserRole.Deployer,
      },
    ],
  },
  {
    name: 'odl',
    title: 'ODL',
    icon: <BuildOutlined />,
    children: [
      {
        name: 'odlFile',
        title: 'File Manager',
        icon: <FolderOpenOutlined />,
        component: OdlFileBrowserScreen,
        path: OdlLocation.path,
        exact: true,
        role: UserRole.OdlEditor,
      },
      {
        name: 'odlBuild',
        title: 'Build ODL',
        icon: <DeploymentUnitOutlined />,
        component: OdlBuildScreen,
        path: OdlBuildLocation.path,
        exact: true,
        role: UserRole.OdlEditor,
      },
      {
        name: 'odlBuildHistory',
        title: 'Build History',
        icon: <FieldTimeOutlined />,
        component: OdlBuildHistoryScreen,
        path: OdlBuildHistoryLocation.path,
        exact: true,
        // hideInMenu: true,
        role: UserRole.OdlEditor,
      },
    ],
  },
  {
    name: 'csTool',
    title: 'CS Tool',
    icon: <ToolOutlined />,
    children: [
      {
        name: 'searchUser',
        title: 'Search User',
        icon: <SearchOutlined />,
        component: CsToolSearchUserScreen,
        path: CsSearchUserLocation.path,
        exact: true,
        role: UserRole.CsViewer,
      },
      {
        name: 'massInventory',
        title: 'Mass Inventory',
        icon: <AccountBookOutlined />,
        component: CsToolMassInventory,
        path: CsMassInventoryLocation.path,
        exact: true,
        role: UserRole.CsViewer,
      },
      {
        name: 'bot',
        title: 'Admin Bot',
        icon: <RobotOutlined />,
        component: BotScreen,
        path: BotLocation.path,
        exact: true,
        role: UserRole.CsViewer,
      },
      {
        name: 'scheduleLeaderBoard',
        title: 'Schedule LeaderBoard',
        icon: <ScheduleOutlined />,
        component: ScheduleScreen,
        path: ScheduleLeaderBoardLocation.path,
        exact: true,
        role: UserRole.CsViewer,
      },
    ],
  },
];
