import React, {
  createContext,
  Dispatch,
  FC,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Bluebird from 'bluebird';
import { get, uniq } from 'lodash';
import { IEnum, IField } from 'protobufjs';

import { BlueprintDataGridRef } from '@lib/components/blueprint-data-grid';
import { DefaultSystemConfigKeys } from '@lib/pages/system-config/components/context';
import { useGlobalBlueprintProtoContext } from '@lib/providers/blueprint-proto';
import { useGlobalBlueprintDataVersionContext } from '@lib/providers/blueprint-version';
import { UseApi } from '@lib/services/base/use-api';
import { blueprintDataService } from '@lib/services/blueprint';
import {
  BlueprintData,
  BlueprintDataChangeLog,
  BlueprintDataState,
  BlueprintProto,
  FieldReference,
  FieldSettings,
} from '@lib/services/models';
import { useSystemConfigList } from '@lib/services/system-config';
import { formatBlueprintName } from '@lib/utils/blueprint-data';
import validateRSC from '@lib/utils/blueprint-data/restrist-special-character';
import { mapReduce } from '@lib/utils/map-reduce';
import { UseModal, useModal } from '@lib/utils/use-modal';

import { RenderOptions } from './form/form-generator/single-field';
import { useBlueprintDataConfig } from './config-context';
import { BlueprintDataFormRef } from './form';

export type RefFieldReference = IEnum &
  Partial<FieldReference> & { ref?: boolean; refValues?: any[] };

export type RefFieldSettings = Omit<FieldSettings, 'reference'> & {
  reference?: RefFieldReference;
  isIdField?: boolean;
};

export type BlueprintDataListContext = UseApi<BlueprintData[]> & {
  selection: BlueprintData[] | undefined;
  setSelection: Dispatch<SetStateAction<BlueprintData[] | undefined>>;
  variant?: string;
  setVariant: (variant?: string) => void;
  fieldSettings: MutableRefObject<Record<string, RefFieldSettings>>;
  data: BlueprintData[];
  changeLogs: MutableRefObject<BlueprintDataChangeLog[]>;
  storeData: (...data: BlueprintData[]) => ReturnType<typeof blueprintDataService.storeData>;
  deleteData: typeof blueprintDataService.deleteMultiple;
  deleteDataAsync: typeof blueprintDataService.deleteMultipleAsync;
  checkAllowedRSC: (value: Record<string, any>) => string | null;
  updateData: (objIds: string[], changes: BlueprintDataChangeLog[]) => void;
};

export type BlueprintDataContext = {
  gridRef: RefObject<BlueprintDataGridRef>;
  list: BlueprintDataListContext;
  modal: UseModal<BlueprintData>;
  anyModal: UseModal<RenderOptions<IField>>;
  formRef: React.RefObject<BlueprintDataFormRef>;
  tags: string[];
  setTags: (value: string[]) => void;
  isRSCProps?: boolean;
  openModalPull?: string;
  setOpenModalPull: (value: string) => void;
  environment?: string;
  setEnvironment: (value: string) => void;
  bucketName?: string;
  setBucketName: (value: string) => void;
  bucketId: number;
  setBucketId: (value: number) => void;
  levelNames: string[];
  setLevelNames: (value: string[]) => void;
};

export const transformFieldSettings = (
  blueprintType?: BlueprintProto,
  refData: Record<string, BlueprintData[]> = {},
) => {
  const settings = (blueprintType?.settings ?? []).reduce<Record<string, RefFieldSettings>>(
    (all, setting) => {
      all[setting.field] = { ...setting, reference: undefined };

      if (setting.reference?.valueField) {
        const { type: protoName, valueField, displayField = valueField } = setting.reference;
        if (refData[protoName]) {
          if (valueField.includes('.')) {
            const firstReferKey = valueField.slice(0, valueField.lastIndexOf('.'));
            const lastReferKey = valueField.slice(
              valueField.lastIndexOf('.') + 1,
              valueField.length,
            );

            const values: Record<string, any> = {};
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < refData[protoName].length; j++) {
              const arrayValue = refData[protoName][j].data[firstReferKey];
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < arrayValue.length; i++) {
                values[arrayValue[i][lastReferKey]] = arrayValue[i][lastReferKey];
              }
            }

            const refValues = [];
            // eslint-disable-next-line no-plusplus
            for (let j = 0; j < refData[protoName].length; j++) {
              const arrayValue = refData[protoName][j].data[firstReferKey];
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < arrayValue.length; i++) {
                refValues.push({ [lastReferKey]: arrayValue[i][lastReferKey] });
              }
            }

            all[setting.field].reference = { ...setting.reference, values, refValues, ref: true };
          } else {
            const values = refData[protoName]
              .map((item) => item.data)
              .reduce<Record<string, any>>((allValues, item) => {
                allValues[get(item, valueField)] = get(item, displayField);
                return allValues;
              }, {});
            const refValues = refData[protoName].map((item) => item.data);

            all[setting.field].reference = { ...setting.reference, values, refValues, ref: true };
          }
        }
      }

      if (setting.anyTypes) {
        const refTypes = setting.anyTypes.reduce<Record<string, any>>((types, item) => {
          types[item] = formatBlueprintName(item) ?? item;
          return types;
        }, {});
        all[setting.field].reference = { values: refTypes, ref: true };
      }

      return all;
    },
    {},
  );

  (blueprintType?.idFields ?? []).forEach((field) => {
    let idFieldSetting = settings[field];
    if (!idFieldSetting) {
      idFieldSetting = {
        field,
      };
      settings[field] = idFieldSetting;
    }
    idFieldSetting.required = true;
    // idFieldSetting.editable = false;
    idFieldSetting.isIdField = true;
  });

  return settings;
};

const useBlueprintDataContextValue = (): BlueprintDataContext => {
  const { selectedKey: type, selected: selectedType } = useGlobalBlueprintProtoContext();
  const { selectedKey: version } = useGlobalBlueprintDataVersionContext();
  const { gridRef: ref } = useBlueprintDataConfig();

  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState<string>();
  const [blueprintData, setBlueprintData] = useState<BlueprintData[]>([]);
  const [selection, setSelection] = useState<BlueprintData[] | undefined>();
  const changeLogs = useRef<BlueprintDataChangeLog[]>([]);
  const fieldSettings = useRef<Record<string, RefFieldSettings>>({});
  const [tags, setTags] = useState<string[]>([]);

  const modal = useModal<BlueprintData>();
  const anyModal = useModal<RenderOptions<IField>>();

  const formRef = useRef<BlueprintDataFormRef>(null);
  const [openModalPull, setOpenModalPull] = useState<string | undefined>('');
  const [environment, setEnvironment] = useState<string>();
  const [bucketName, setBucketName] = useState<string>();
  const [bucketId, setBucketId] = useState<number>(0);
  const [levelNames, setLevelNames] = useState<string[]>([]);
  const gridRef = ref ?? useRef<BlueprintDataGridRef>(null);

  // Get systemConfig
  const list = useSystemConfigList();
  const rscConfig = list.data?.find((item) => item.key === DefaultSystemConfigKeys.IS_RSC);
  const isRSCProps = !!rscConfig?.value;

  const setSelected = (obj?: BlueprintData) => {
    setTags(obj?.tags ?? []);
    modal.setSelected(obj);
  };

  /**
   *
   * @param value this is a value to validate
   * @returns
   */
  const checkAllowedRSC = (value: Record<string, any>) => validateRSC(value, selectedType);

  const appendChangeLogs = (changes: BlueprintDataChangeLog[]) => {
    const changeMap = mapReduce(changes, 'objId');
    changeLogs.current = changeLogs.current
      .filter((item) => !changeMap[item.objId])
      .concat(changes as any[]);
  };

  const storeData = async (...objs: BlueprintData[]) => {
    if (!version) throw new Error('Version is required!');

    const res = await blueprintDataService.storeBatchData(version, objs);
    const { ok, data } = res;
    if (ok && data) {
      setBlueprintData((current) => {
        const newData = [...current];
        const changes: BlueprintDataChangeLog[] = [];

        data.forEach(({ data: saved, change }) => {
          const idx = newData.findIndex((item) => item.objId === saved.objId);
          if (idx < 0) {
            newData.unshift(saved);
          } else {
            newData.splice(idx, 1, saved);
          }

          if (change) {
            changes.push(change);
          }
        });

        appendChangeLogs(changes);

        return newData;
      });
    }
    return res;
  };

  const updateData = (objIds: string[], changes: BlueprintDataChangeLog[]) => {
    appendChangeLogs(changes);

    setBlueprintData((current) => {
      return objIds.reduce(
        (all, objId) => {
          const idx = all.findIndex((item) => item.objId === objId);
          return idx < 0
            ? all
            : all
                .slice(0, idx)
                .concat({ ...all[idx], state: BlueprintDataState.D })
                .concat(all.slice(idx + 1));
        },
        [...current],
      );
    });
  };

  const deleteData = async (_version: string, _type: string, objIds: string[]) => {
    const res = await blueprintDataService.deleteMultiple(_version, _type, objIds);
    const { ok, data: changes } = res;

    if (ok && changes) {
      updateData(objIds, changes);
    }

    return res;
  };

  const deleteDataAsync = async (_version: string, _type: string, objIds: string[]) =>
    blueprintDataService.deleteMultipleAsync(_version, _type, objIds);

  const loadRefData = async () => {
    if (!version || !selectedType) {
      return {};
    }

    const refTypes = (selectedType.settings ?? []).reduce<string[]>((all, item) => {
      if (item.reference?.valueField) {
        all.push(item.reference.type);
      }
      return all;
    }, []);

    const _refData = await Bluebird.reduce<string, Record<string, BlueprintData[]>>(
      uniq(refTypes),
      async (all, _type) => {
        const res = await blueprintDataService.getData('base', _type);
        all[_type] = res.data ?? [];
        return all;
      },
      {},
    );

    return _refData;
  };

  const updateFieldSettings = async () => {
    const refData = await loadRefData();
    fieldSettings.current = transformFieldSettings(selectedType, refData);
  };

  const fetch = async () => {
    if (!type || !version) {
      if (blueprintData.length) {
        setBlueprintData([]);
      }
      return;
    }

    setLoading(true);
    const res = await blueprintDataService.getDataAndCompare(version, type, {
      variant: variant === '$$all' ? undefined : variant,
    });
    const { ok, data } = res;
    if (ok && data) {
      await updateFieldSettings();
      changeLogs.current = data.changeLogs ?? [];
      setBlueprintData(data.data);
    } else {
      changeLogs.current = [];
      setBlueprintData([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    fetch();
  }, [variant]);

  useEffect(() => {
    setVariant(undefined);
    fetch();
  }, [type, version]);

  return {
    list: {
      selection,
      setSelection,
      loading,
      variant,
      setVariant,
      data: blueprintData,
      fieldSettings,
      changeLogs,
      fetch,
      storeData,
      deleteData,
      deleteDataAsync,
      checkAllowedRSC,
      updateData,
    },
    modal: {
      ...modal,
      setSelected,
    },
    gridRef,
    anyModal,
    formRef,
    tags,
    setTags,
    isRSCProps,
    openModalPull,
    setOpenModalPull,
    environment,
    setEnvironment,
    bucketName,
    setBucketName,
    bucketId,
    setBucketId,
    levelNames,
    setLevelNames,
  };
};

const blueprintDataContext = createContext<BlueprintDataContext | null>(null);
const { Provider } = blueprintDataContext;

export const BlueprintDataProvider: FC = ({ children }) => {
  const value = useBlueprintDataContextValue();

  return <Provider value={value}>{children}</Provider>;
};

export const useBlueprintDataContext = () => {
  const ctx = useContext(blueprintDataContext);
  if (!ctx) {
    throw new Error('useBlueprintDataContext must be used inside BlueprintDataProvider');
  }

  return ctx;
};

export default BlueprintDataProvider;
