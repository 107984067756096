import { IResultTable } from './models';

const CompareData: IResultTable = {
  winRate: [
    {
      winRate: 0,
      totalAttempt: 0,
      winCountExtra: 0,
      loseCount: 0,
    },
  ],
  winRate2: [
    {
      winCount: 0,
      winCount1: 0,
      winCount2: 0,
      winCount3: 0,
      winCount4: 0,
      winCount5: 0,
      winCount6: 0,
      winCount7: 0,
      winCount8: 0,
      winCount9: 0,
      winCount10: 0,
      winCount11: 0,
      winCount12: 0,
      winCount13: 0,
      winCount14: 0,
      winCount15: 0,
    },
  ],
  movesAndExtra: [{ avgMoveLeft: 0, avgMoveUsed: 0, maxMoveLeft: 0, minMoveLeft: 0 }],
  percentileMoveRemain: [
    {
      0: 0,
      25: 0,
      50: 0,
      70: 0,
      90: 0,
      100: 0,
    },
  ],
  moveWin: [
    {
      maxMoveWin: 0,
      minMoveWin: 0,
      varMoveWin: 0,
      stdMoveWin: 0,
    },
  ],
  clearLines: [
    {
      avgMovesToClearLines: 0,
      maxMovesToClearLines: 0,
      maxSimultaneousLinesCleared: 0,
      avgSimultaneousLinesCleared: 0,
    },
  ],
  percentileMoveUsed: [
    {
      0: 0,
      25: 0,
      50: 0,
      70: 0,
      90: 0,
      100: 0,
    },
  ],
  target: [],
  powerUps: [
    {
      key: '',
      dynamite: 0,
      missiles: 0,
      bomb: 0,
      cube: 0,
    },
  ],
  powerUps2: [],
  igb: [],
  lineClear: [],
};

export { CompareData };
