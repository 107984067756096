import React, { FC, useState } from 'react';
import { Button, Divider, message, Modal, Select } from 'antd';

import { useSimulatorContext } from '@pages/simulator/context';
import { SimulatorV2 as Simulator } from '@services/_api';
import { simulatorService } from '@services/simulator';

import ByLevelId from './by-level-id';
import ByRuntestId from './by-runtest-id';

const options = [
  {
    value: 1,
    label: 'By Runtest ID',
  },
  {
    value: 2,
    label: 'By Level ID',
  },
];

export const SelectFilter: FC<{ dataFilter: any; funC: any; placeH: string }> = ({
  dataFilter,
  funC,
  placeH,
}) => {
  let width = 0;
  if (placeH.includes('RuntestId')) width = 230;
  else if (placeH.includes('Performer')) width = 190;
  else if (placeH.includes('Tags')) width = 230;

  return (
    <Select
      allowClear
      showSearch
      placeholder={`Filter by ${placeH}`}
      style={{ width }}
      options={dataFilter}
      onChange={(e: any) => funC(e)}
    />
  );
};

const Result: FC = () => {
  const {
    rowDataResult,
    setRowDataResult,
    dataFilter,
    setRowDataLevelId,
    setResultActive,
    resultActive,
  } = useSimulatorContext();
  const [selectedRows, setSelectedRows] = useState<Simulator[]>([]);
  const [selectedRowsLevel, setSelectedRowsLevel] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [openLevel, setOpenLevel] = useState(false);
  const [loading, setLoading] = useState(false);

  const [runtestId, setRuntestId] = useState<string>('');
  const [performer, setPerformers] = useState<string>('');
  const [tags, setTags] = useState<string>('');

  const onDelete = async () => {
    try {
      setLoading(true);

      // eslint-disable-next-line no-restricted-syntax
      for await (const selected of selectedRows) {
        await simulatorService.deleteById(selected.runtestId);
      }

      setRowDataResult(
        rowDataResult.filter((a) => !selectedRows.find((b) => b.runtestId === a.runtestId)),
      );
      setOpen(false);
      setLoading(false);
      setSelectedRows([]);
      message.success('Delete successfully');
    } catch (error) {
      message.error(error);
    }
  };

  const onDeleteLevels = async () => {
    try {
      setLoading(true);

      // eslint-disable-next-line no-restricted-syntax
      for await (const selected of selectedRowsLevel) {
        await simulatorService.deleteLevels(selected.levelId, selected.runtestIds.split(','));
      }

      setRowDataLevelId((cur) =>
        cur.filter((a) => !selectedRowsLevel.find((b: any) => b.levelId === a.levelId)),
      );
      setOpenLevel(false);
      setLoading(false);
      setSelectedRowsLevel([]);
      message.success('Delete successfully');
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <div className="flex flex-col gap-y-3 grid-result-simulator">
      <div className="flex justify-start items-center">
        <Modal
          title="Confirm"
          visible={open}
          onOk={onDelete}
          onCancel={() => setOpen(false)}
          cancelButtonProps={{ loading }}
          okButtonProps={{ danger: true, loading }}
          okText="Delete"
          cancelText="Cancel"
        >
          Are you sure to remove {selectedRows.length === 1 ? 'this' : `${selectedRows.length}`}{' '}
          runtest?
        </Modal>
        <Modal
          title="Confirm"
          visible={openLevel}
          onOk={onDeleteLevels}
          onCancel={() => setOpenLevel(false)}
          cancelButtonProps={{ loading }}
          okButtonProps={{ danger: true, loading }}
          okText="Delete"
          cancelText="Cancel"
        >
          Are you sure to remove{' '}
          {selectedRowsLevel.length === 1 ? 'this' : `${selectedRowsLevel.length}`} level of
          runtest?
        </Modal>
        <Select
          style={{ width: 200 }}
          value={resultActive}
          defaultValue={1}
          options={options}
          onChange={(e) => setResultActive(e)}
        />
        <Divider type="vertical" />
        {resultActive === options[0].value && (
          <div className="flex gap-x-1">
            <SelectFilter
              dataFilter={dataFilter?.runtestIds.map((x: any) => {
                return { value: x.runtestId, label: x.runtestId };
              })}
              funC={setRuntestId}
              placeH="RuntestId"
            />
            <SelectFilter
              dataFilter={dataFilter?.performers.map((x: any) => {
                return { value: x.performer, label: x.performer };
              })}
              funC={setPerformers}
              placeH="Performer"
            />
            <SelectFilter
              dataFilter={dataFilter?.tags.map((x: any) => {
                return { value: x.tags, label: x.tags };
              })}
              funC={setTags}
              placeH="Tags"
            />
          </div>
        )}

        <div className="ml-auto">
          {selectedRows.length > 0 && (
            <Button className="mr-3" danger onClick={() => setOpen(true)}>
              Delete {selectedRows.length} {selectedRows.length === 1 ? 'row' : 'rows'}
            </Button>
          )}
          {selectedRowsLevel.length > 0 && (
            <Button className="mr-3" danger onClick={() => setOpenLevel(true)}>
              Delete {selectedRowsLevel.length} {selectedRowsLevel.length === 1 ? 'row' : 'rows'}
            </Button>
          )}
          {/* <Button loading={loadingFetch} onClick={() => fetchRuntest()}>
            Reload
          </Button> */}
        </div>
      </div>
      {resultActive === 1 ? (
        <ByRuntestId
          setOpen={setOpen}
          setSelectedRows={setSelectedRows}
          dataFilter={{ performer, runtestId, tags }}
        />
      ) : null}

      {/* by level id */}
      {resultActive === options[1].value ? (
        <ByLevelId setOpenLevel={setOpenLevel} setSelectedRows={setSelectedRowsLevel} />
      ) : null}
    </div>
  );
};

export default Result;
