import React, { useState } from 'react';
import { Layout as AntdLayout, Typography } from 'antd';

import { useAppContext } from '@lib/providers/app';
import { useAppConfig } from '@lib/providers/config';
import version from '@lib/version.json';

import Menu from './menu';

const Sider = () => {
  const appCtx = useAppContext();
  const config = useAppConfig();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <AntdLayout.Sider
      className="relative shadow-sm"
      style={{ backgroundColor: '#ffffff', borderRight: '1px solid #f0f0f0' }}
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-col justify-center" style={{ height: 64 }}>
          <img
            src={config.appIcon || `${process.env.PUBLIC_URL}/logo.png`}
            alt="Gear Inc"
            style={{ marginRight: collapsed ? undefined : 118 }}
            className={`${collapsed ? 'h-6' : 'h-10'} mx-auto`}
          />
        </div>
        <div className="flex-1 overflow-x-hidden overflow-y-auto">
          <Menu />
        </div>
        <div className="pt-2 mt-auto text-center" style={{ width: collapsed ? 80 : 200 }}>
          <Typography.Link
            href={`${process.env.PUBLIC_URL}/docs/docs/release-notes/`}
            target="_blank"
          >
            {appCtx?.version
              ? `v${appCtx?.version} (base v${version.version})`
              : `v${version.version}`}
          </Typography.Link>
        </div>
      </div>
    </AntdLayout.Sider>
  );
};

export default Sider;
