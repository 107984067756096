import { groupBy } from 'lodash';

import {
  BlueprintDataChangeLog,
  BlueprintDataDiffEdit,
  BlueprintDataDiffItem,
  BlueprintDataState,
} from '@lib/services/models';

export const changeStateToDataState = (change: BlueprintDataDiffItem): BlueprintDataState => {
  if (change.kind === 'A') {
    return BlueprintDataState.U;
  }
  if (change.kind === 'N') {
    return BlueprintDataState.U;
  }
  if (change.kind === 'D') {
    return BlueprintDataState.U;
  }
  if (change.kind === 'E' && !change.path) {
    const editChange = change as BlueprintDataDiffEdit;
    if (editChange.lhs === null && editChange.rhs !== null) {
      return BlueprintDataState.A;
    }
    if (editChange.lhs !== null && editChange.rhs === null) {
      return BlueprintDataState.D;
    }
  }

  return BlueprintDataState.U;
};

export const calculateChangeStates = (changeLogs: BlueprintDataChangeLog[]) => {
  const changeLogsByObj = groupBy(changeLogs ?? [], 'objId');
  return Object.keys(changeLogsByObj).reduce<Record<string, BlueprintDataState>>((all, objId) => {
    const [firstChange] = changeLogsByObj[objId][0].data;
    const [lastChange] = changeLogsByObj[objId][0].data.reverse();

    if (lastChange && changeStateToDataState(lastChange) === BlueprintDataState.D) {
      all[objId] = BlueprintDataState.D;
    } else if (firstChange) {
      all[objId] = changeStateToDataState(firstChange);
    }

    return all;
  }, {});
};
