import React, { FC, useCallback, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Button } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';

import AgGridReact, { ColDef, GridApi, GridReadyEvent } from '@lib/components/ui/ag-grid';

import { useSimulatorContext } from '@pages/simulator/context';
import { SimulatorComponent } from '@pages/simulator/simulator';

type ByLevelIdProps = {
  setOpenLevel: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRows: React.Dispatch<React.SetStateAction<any>>;
};

const ByLevelId: FC<ByLevelIdProps> = ({ setOpenLevel, setSelectedRows }) => {
  const gridApiRef = useRef<GridApi>();
  const { rowDataLevelId, setActive, setResultLevelSelected } = useSimulatorContext();

  const buttonViewRenderer = (props: any) => {
    const handleClick = () => {
      setResultLevelSelected(props.data);
      setActive(SimulatorComponent.ListRuntestResultByLevelComponent);
    };

    return <Button icon={<EyeOutlined />} onClick={handleClick} />;
  };

  const buttonDeleteRenderer = (props: any) => {
    const handleClick = () => {
      setSelectedRows([{ ...props.data }]);
      setOpenLevel(true);
    };

    return <Button danger icon={<DeleteOutlined />} onClick={handleClick} />;
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'levelId',
      filter: 'agTextColumnFilter',
      maxWidth: 220,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    { field: 'runtestIds', filter: 'agTextColumnFilter' },
    // { field: 'botSkillLevel', maxWidth: 200, filter: 'agTextColumnFilter' },
    {
      headerName: 'View Detail',
      cellRendererFramework: buttonViewRenderer,
      cellStyle: { textAlign: 'center' },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      headerName: 'Remove/Delete',
      cellRendererFramework: buttonDeleteRenderer,
      cellStyle: { textAlign: 'center' },
      minWidth: 100,
      maxWidth: 100,
    },
  ]);

  function handleRowSelected(event: any) {
    setSelectedRows([]);
    setSelectedRows(event.api.getSelectedNodes().map((node: any) => node.data));
  }

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    gridApiRef.current = e.api;
    e.api.sizeColumnsToFit();
  }, []);

  return (
    <ReactResizeDetector
      handleHeight
      render={({ height }) => (
        <div className="grid-result-simulator">
          <div className="flex-1 ag-theme-alpine bordered" style={{ height }}>
            <AgGridReact
              defaultColDef={{
                floatingFilter: true,
                sortable: true,
                suppressMenu: true,
                resizable: true,
                flex: 1,
                minWidth: 100,
              }}
              columnDefs={columnDefs}
              rowData={rowDataLevelId}
              rowSelection="multiple"
              onSelectionChanged={handleRowSelected}
              onGridReady={handleGridReady}
              suppressRowClickSelection // suppress row click selection
              sideBar={{
                toolPanels: [
                  {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                      suppressRowGroups: true,
                      suppressValues: true,
                      suppressPivots: true,
                      suppressPivotMode: true,
                    },
                  },
                  {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                  },
                ],
                position: 'right',
              }}
            />
          </div>
        </div>
      )}
    />
  );
};

export default ByLevelId;
