import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Breadcrumb, Typography } from 'antd';
import { HomeOutlined, RightOutlined } from '@ant-design/icons';

import { useAppContext } from '@lib/providers/app';
import { appRoutes, RouteItem } from '@lib/routes/routes';

const getBreadcrumbItems = (routes: RouteItem[], pathname: string) => {
  const breadcrumbItems: RouteItem[] = [];

  routes.forEach((item) => {
    if (item.path === pathname) {
      breadcrumbItems.push(item);
    } else if (item.children) {
      const childBreadcrumbItems = getBreadcrumbItems(item.children, pathname);
      if (childBreadcrumbItems.length) {
        breadcrumbItems.push(item, ...childBreadcrumbItems);
      }
    }
  });

  return breadcrumbItems;
};

const HeaderBreadCrumb: FC = () => {
  const location = useLocation();

  const appCtx = useAppContext();
  const routes = appCtx?.routes ?? appRoutes;

  const [breadcrumbItems, setBreadcrumbItems] = useState<RouteItem[]>([]);

  useEffect(() => {
    setBreadcrumbItems(getBreadcrumbItems(routes, location.pathname));
  }, [location.pathname]);

  return (
    <Breadcrumb separator={<RightOutlined />}>
      <Breadcrumb.Item>
        <HomeOutlined />
      </Breadcrumb.Item>
      {breadcrumbItems.map((item, idx) => (
        <Breadcrumb.Item key={item.name} className="inline-block">
          {idx < breadcrumbItems.length - 1 ? (
            <>
              {item.icon} {item.title}
            </>
          ) : (
            <Typography.Title level={4} style={{ margin: 0 }}>
              {item.icon} {item.title}
            </Typography.Title>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default HeaderBreadCrumb;
