import React, { FC } from 'react';
import { Button, Col, Form, Row } from 'antd';

import BlueprintDataVersionSelectGlobal from '@lib/components/blueprint-data-version-select';
import BucketSelect from '@lib/components/bucket-select';
import EnvironmentSelect from '@lib/components/env-select';
import { avaConnectService } from '@lib/services/blueprint/ava';

import { useEventCalendarContext } from './context';

const EventCalendarForm: FC = () => {
  // const [loading, setLoading] = useState(true);
  // const [allRoles, setRoles] = useState<Role[]>([]);

  const {
    environment,
    setEnvironment,
    bucketName,
    setBucketName,
    version,
    loading,
    setLoading,
    fetch,
    bucketId,
  } = useEventCalendarContext();

  const [form] = Form.useForm();

  const getBucketEventData = async () => {
    if (!version) return;
    setLoading(true);

    const res = await avaConnectService.pullBucketEventData(bucketId, version, true);
    if (res.ok && res.data) {
      setLoading(false);
      fetch();
    }
  };

  return (
    <Form layout="vertical" form={form}>
      <Row gutter={16}>
        <Col flex="0 0 220px">
          <Form.Item label="Environment" name="environment">
            <EnvironmentSelect style={{ width: 200 }} onChange={setEnvironment} />
          </Form.Item>
        </Col>
        <Col flex="0 0 250px">
          <Form.Item label="Version">
            <BlueprintDataVersionSelectGlobal />
          </Form.Item>
        </Col>
        <Col flex="0 0 250px">
          <Form.Item name="bucketId" label="Bucket" rules={[{ required: true }]}>
            <BucketSelect />
          </Form.Item>
        </Col>
        <Col flex="0 0 250px">
          <Form.Item>
            <Button
              type="primary"
              style={{ marginTop: 30 }}
              loading={loading}
              onClick={getBucketEventData}
              disabled={!environment || !version || !bucketName}
            >
              Pull Events Data
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EventCalendarForm;
