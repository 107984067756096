import client from '@lib/services/client';
import { BlueprintProto, CloneBlueprintProtoDto } from '@lib/services/models';

export const getAll = (updateId?: number) =>
  client.api.blueprintProtoControllerGetAll({ updateId });

export const update = (data: BlueprintProto, updateId?: number) => {
  return client.api.blueprintProtoControllerUpdate(data.name, data, { updateId });
};

export const clone = (data: CloneBlueprintProtoDto, updateId?: number) => {
  return client.api.blueprintProtoControllerCloneBlueprint(data, { updateId });
};
