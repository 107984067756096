import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { useHistory } from 'react-router-dom';
import { Button, message } from 'antd';
import moment from 'moment';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';

import { PagingAgGridRef } from '@lib/components/paging-grid/paging-grid';
import AgGridReact, { ColDef, GridApi, GridReadyEvent } from '@lib/components/ui/ag-grid';
import Loading from '@lib/components/ui/loading';
import { PagingOptions } from '@lib/services/base/paginate';

import { useLevelDataConfigContext } from '@pages/map-level/context';
import { RuntestStatusClient, useSimulatorContext } from '@pages/simulator/context';
import { SimulatorComponent } from '@pages/simulator/simulator';
import { IResultTable, ViewDetail } from '@pages/simulator/utils/models';
import { LevelsTestResult, RuntestStatus, SimulatorV2 as Simulator } from '@services/_api';
import { simulatorService } from '@services/simulator';

type DataFilterSingle = {
  runtestId: string;
  performer: string;
  tags: string;
};

type Props = {
  setSelectedRows: React.Dispatch<React.SetStateAction<Simulator[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isBackToListRuntestByLevel?: boolean;
  hasDraft?: boolean;
  rawData?: Simulator[];
  levelId?: string;
  dataFilter?: DataFilterSingle;
};

const ListRuntestForm: FC<Props> = ({
  setSelectedRows,
  setOpen,
  isBackToListRuntestByLevel,
  hasDraft,
  rawData,
  levelId,
  dataFilter,
}) => {
  const history = useHistory();
  const gridRef = useRef<PagingAgGridRef>(null);
  const gridApiRef = useRef<GridApi>();
  const performer = dataFilter?.performer;
  const runtestId = dataFilter?.runtestId;
  const tag = dataFilter?.tags;
  const pagingOptionsRef = useRef<PagingOptions<Simulator & { tagString: string }>['where']>({});

  const [loading, setLoading] = useState(false);

  const {
    setActive,
    setResultSelected,
    setIsBackToListRuntestByLevel,
    setRowDataViewDetail,
    formCreate,
    setDataSelected,
    setCompare,
    setCalculated,
    setBackPageLevelRuntestResult,
    boardTabActive,
  } = useSimulatorContext();

  const { setLevelSelect } = useLevelDataConfigContext();

  const buttonViewRenderer = (props: any) => {
    const handleClick = async () => {
      try {
        const { data } = props;
        let calculationData = data;
        let curCalculated = true;
        if (!hasDraft) {
          setLoading(true);
          for (let index = 0; index < data.levelsTestResult.length; index += 1) {
            const element = data.levelsTestResult[index];
            if (!element?.hasOwnProperty('resultTable')) {
              setCalculated(false);
              curCalculated = false;
              break;
            }
          }

          if (!curCalculated) {
            const res = await simulatorService.calculateData(data?.runtestId ?? '');
            if (res.ok && res.data) {
              calculationData = res.data;
            }
            calculationData.dateRuntest = moment(calculationData?.dateRuntest);
          }

          calculationData.dateRuntest = moment(calculationData?.dateRuntest);
          setResultSelected(calculationData);

          setRowDataViewDetail(
            calculationData?.levelsTestResult?.map((item: any) => {
              const result: ViewDetail = {
                rawData: item.rawData,
                requestId: item.requestId,
                levelId: item.levelConfig?.id,
                moves: item.levelConfig?.general.moves,
                averageWinrate: item?.averageWinrate ?? 0,
                averageMovesLeft: item?.averageMovesLeft ?? 0,
                extraMovesUsed: item?.extraMovesUsed ?? 0,
                powerUpsActivated: item?.powerUpsActivated ?? 0,
                resultTable: item?.resultTable ?? 0,
                levelConfig: item?.levelConfig,
              };
              return result;
            }) ?? [],
          );

          if (!isBackToListRuntestByLevel) {
            setActive(SimulatorComponent.ViewDetailComponent);
          } else {
            setIsBackToListRuntestByLevel(true);
            const compare: IResultTable = {
              ...(calculationData?.levelsTestResult?.find((x: any) =>
                levelId?.includes(x.levelConfig.id),
              )?.resultTable as IResultTable),
              levelId,
            };

            const levelConfig = calculationData?.levelsTestResult?.find((x: any) =>
              levelId?.includes(x.levelConfig.id),
            ).levelConfig;

            if (levelConfig) setLevelSelect(levelConfig);
            setCompare(compare);
            setBackPageLevelRuntestResult(SimulatorComponent.ViewDetailComponent);
            setActive(SimulatorComponent.LevelRuntestResultComponent);
          }
        } else {
          setResultSelected(data);
          data.dateRuntest = moment();
          formCreate.setFieldsValue(data);
          const levelSeleted = data.levelsTestResult
            ?.map((x: LevelsTestResult) => x.levelConfig)
            .map((y: any) => {
              y.id = y.id.toString();
              y.general = y.general.toString();

              return y;
            });

          setDataSelected(levelSeleted);
          setActive(SimulatorComponent.CreateComponent);
        }
      } catch (error) {
        message.error('Calculate data error please check again !');
      } finally {
        setLoading(false);
      }
    };

    return <Button icon={<EyeOutlined />} onClick={handleClick} />;
  };

  const buttonDeleteRenderer = (props: any) => {
    const handleClick = () => {
      // eslint-disable-next-line no-console
      console.log(`Button clicked for row ${props.data}`);
      setSelectedRows([{ ...props.data }]);
      setOpen(true);
    };

    return <Button danger icon={<DeleteOutlined />} onClick={handleClick} />;
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'runtestId',
      // filter: 'agTextColumnFilter',
      minWidth: 100,
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
    },
    { field: 'performer' },

    { field: 'dateString', minWidth: 180 },
    { field: 'attempts' },
    { field: 'tags' },
    { field: 'listLevel', minWidth: 150 },
    { field: 'botSkillLevel' },
    {
      headerName: 'View Detail',
      cellRendererFramework: buttonViewRenderer,
      cellStyle: { textAlign: 'center' },
      minWidth: 100,
      maxWidth: 100,
    },
    {
      headerName: 'Remove/Delete',
      cellRendererFramework: buttonDeleteRenderer,
      cellStyle: { textAlign: 'center' },
      minWidth: 100,
      maxWidth: 100,
    },
  ]);

  function handleRowSelected(event: any) {
    setSelectedRows([]);
    setSelectedRows(event.api.getSelectedNodes().map((node: any) => node.data));
  }

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    gridApiRef.current = e.api;
    e.api.sizeColumnsToFit();
  }, []);

  const updateQuery = () => {
    const queries = [
      runtestId && `runtestId=${runtestId}`,
      performer && `performer=${performer}`,
      tag?.length && `tag=${tag.length && tag[0]}`,
    ]
      .filter(Boolean)
      .join('&');
    history.push({
      search: `?${queries}`,
    });
  };

  useEffect(() => {
    if (pagingOptionsRef.current) {
      if (runtestId) pagingOptionsRef.current.runtestId = runtestId;
      if (performer) pagingOptionsRef.current.performer = performer;

      if (tag) pagingOptionsRef.current.tagString = tag;
      else pagingOptionsRef.current.tagString = undefined;

      if (hasDraft || Number(boardTabActive) === RuntestStatusClient.DRAFT) {
        pagingOptionsRef.current.status = RuntestStatus.DRAFT;
      } else {
        pagingOptionsRef.current.status = RuntestStatus.RUNTESTRESULT;
      }
    }
    if (gridRef.current) {
      gridRef.current.fetch();
    }
    updateQuery();
  }, [performer, runtestId, tag, boardTabActive]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ReactResizeDetector
          handleHeight
          render={({ height }) => (
            <div className="flex-1 ag-theme-alpine bordered" style={{ height }}>
              <AgGridReact
                defaultColDef={{
                  floatingFilter: true,
                  sortable: true,
                  suppressMenu: true,
                  resizable: true,
                  flex: 1,
                  minWidth: 100,
                }}
                columnDefs={columnDefs}
                rowData={rawData?.sort(
                  (a: Simulator, b: Simulator) =>
                    Date.parse(b.dateRuntest) - Date.parse(a.dateRuntest),
                )}
                rowSelection="multiple"
                onSelectionChanged={handleRowSelected}
                onGridReady={handleGridReady}
                suppressRowClickSelection // suppress row click selection
                pagination
                sideBar={{
                  toolPanels: [
                    {
                      id: 'columns',
                      labelDefault: 'Columns',
                      labelKey: 'columns',
                      iconKey: 'columns',
                      toolPanel: 'agColumnsToolPanel',
                      toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                      },
                    },
                    {
                      id: 'filters',
                      labelDefault: 'Filters',
                      labelKey: 'filters',
                      iconKey: 'filter',
                      toolPanel: 'agFiltersToolPanel',
                    },
                  ],
                  position: 'right',
                }}
              />
            </div>
          )}
        />
      )}
    </>
  );
};

export default ListRuntestForm;
