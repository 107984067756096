import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { IEnum, IType } from 'protobufjs';

import { blueprintProtoService } from '@lib/services/blueprint';
import { BlueprintProto } from '@lib/services/models';

import { useAppContext } from './app';
import { useAuth } from './auth';
import { useGlobalBlueprintDataVersionContext } from './blueprint-version';

export type BlueprintProtoContext = {
  protos: BlueprintProto[];
  protoMap: ProtoData;
  loading: boolean;
  selected?: BlueprintProto;
  selectedKey?: string;
  setSelected: (key?: string) => BlueprintProto | undefined;
  fetch: () => Promise<void>;
};

export type ProtoData = {
  [key: string]: IType | IEnum;
};

const blueprintProtoContext = createContext<BlueprintProtoContext | null>(null);
const { Provider } = blueprintProtoContext;

export const BlueprintProtoProvider: FC = ({ children }) => {
  const appCtx = useAppContext();
  const { currentUser } = useAuth();

  const { selected: version } = useGlobalBlueprintDataVersionContext();

  const [loading, setLoading] = useState(true);
  const [protoUpdateId, setProtoUpdateId] = useState(version?.protoUpdateId ?? undefined);
  const [data, setData] = useState<BlueprintProto[]>([]);
  const [selected, _setSelected] = useState<BlueprintProto>();

  const setSelected = (name?: string) => {
    const proto = data.find(
      (item) => (name && item.name.includes(name)) || item.shortName === name,
    );
    _setSelected(proto);
    return proto;
  };

  const fetch = async () => {
    const res = await blueprintProtoService.getAll(protoUpdateId);
    const blueprints = (res.data ?? []).map((item) => {
      if (appCtx?.blueprint?.transformProto) {
        item.proto = appCtx.blueprint.transformProto(item.name, item);
      }

      return item;
    });

    setData(blueprints);
    setLoading(false);
  };

  useEffect(() => {
    setProtoUpdateId(version?.protoUpdateId ?? undefined);
  }, [version]);

  useEffect(() => {
    if (!currentUser) return;
    fetch();
  }, [protoUpdateId, currentUser]);

  useEffect(() => {
    setSelected(selected?.name);
  }, [data]);

  const protoMap = data.reduce<ProtoData>((all, item) => {
    // if (!item.enabled) return all;

    all[item.name] = item.proto as IType;

    const shortName = item.name.split('.');
    all[shortName[shortName.length - 1]] = item.proto as IType;

    return all;
  }, {});

  return (
    <Provider
      value={{
        protos: data,
        protoMap,
        loading,
        selected,
        selectedKey: selected?.name,
        setSelected,
        fetch,
      }}
    >
      {children}
    </Provider>
  );
};

export const useGlobalBlueprintProtoContext = () => {
  const ctx = useContext(blueprintProtoContext);
  if (!ctx) {
    throw new Error('useBlueprintProtoContext must be used inside BlueprintProtoProvider');
  }

  return ctx;
};

export default BlueprintProtoProvider;
