import { IEnum } from 'protobufjs';

import { formatBlueprintName } from '@lib/utils/blueprint-data';

export const generateAnyEnum = (types: string[]) => {
  const refTypes = types.reduce<Record<string, any>>((all, item) => {
    all[item] = formatBlueprintName(item) ?? item;
    return all;
  }, {});

  return { ref: true, values: refTypes } as IEnum;
};
