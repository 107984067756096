import React, { FC } from 'react';
import { Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { IType } from 'protobufjs';

import { ProtoData } from '@lib/providers/blueprint-version';
import { BlueprintProto } from '@lib/services/_api';
import { extractBlueprintType } from '@lib/utils/blueprint-data';
import { DataIndex } from '@lib/utils/blueprint-data/table-header-generator';

import { RefFieldSettings } from '../../context';

import AnyModal from './any/form';
import { OnFieldsChangeCallback } from './single-field';
import TypeFieldFormItem from './type-field';

export type FormGeneratorProps = {
  blueprint: BlueprintProto;
  types: ProtoData;
  form: FormInstance;
  onFieldsChange: OnFieldsChangeCallback;
  fieldSettings: Record<string, RefFieldSettings>;
  width: number;
  editing?: boolean;
  paths?: DataIndex;
};

const FormGenerator: FC<FormGeneratorProps> = ({
  blueprint,
  types,
  form,
  onFieldsChange,
  fieldSettings,
  width,
  editing,
  paths = [],
}) => {
  const type: IType = extractBlueprintType(blueprint, types);

  return (
    <Row gutter={8}>
      <TypeFieldFormItem
        options={{
          types,
          type,
          paths,
          key: '',
          form,
          onFieldsChange,
          fieldSettings,
          width,
          editing,
        }}
      />
      <AnyModal />
    </Row>
  );
};

export default FormGenerator;
