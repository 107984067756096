import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { ICellEditorParams } from '@lib/components/ui/ag-grid';
import { TimestampDatePicker } from '@lib/components/ui/date-picker';

const DateTimeCellEditor = forwardRef<any, ICellEditorParams>((props, ref) => {
  const [value, setValue] = useState(props.value);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  return (
    <TimestampDatePicker
      bordered={false}
      showTime
      autoFocus
      style={{ width: '100%' }}
      useSeconds
      utcTime
      value={value}
      onChange={setValue}
    />
  );
});

export default DateTimeCellEditor;
