import { cloneDeep } from 'lodash';
import { IType } from 'protobufjs';

import { TransformBlueprintCallback } from '@lib/providers/app';

export const transformBlueprint: TransformBlueprintCallback = (type, blueprint) => {
  if (blueprint.type === 'com.alleylabs.legendblast.blueprint.ObjectProto') {
    // edit proto
    const proto = cloneDeep(blueprint.proto) as IType;
    proto.fields.isDraw = {
      type: 'bool',
      id: 10,
    };

    return proto;
  }

  return blueprint.proto as any;
};
