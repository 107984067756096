import React, { FC } from 'react';
import { Form, message, Modal } from 'antd';

import { csUserService } from '@lib/services/cstool';

import { useCsPlayerContext } from '../context';

import MilestoneForm from './form';

const MilestoneModal: FC = () => {
  const { environment, player, milestoneModal, loadPlayer, playerId } = useCsPlayerContext();
  const { selected, modalVisible, toggleModal } = milestoneModal;

  const [form] = Form.useForm();

  const title = selected ? 'Edit Milestone Progress' : 'Create Milestone';

  const handleClose = () => {
    form.resetFields();
    toggleModal(false);
  };

  const handleSubmit = () => {
    if (!environment || !player) return;

    form
      .validateFields()
      .then(
        (value) => {
          const ProgressionId = selected?.ProgressionId ?? '';

          return csUserService.updateMilestoneProgression(
            playerId,
            environment,
            ProgressionId,
            value?.milestoneLevel,
            value?.progress,
            selected?.Type ?? '',
          );
        },
        (err) => {
          message.error(err.errorFields[0].errors[0]);
        },
      )
      .then(async (res) => {
        if (res && res.ok) {
          message.success(`${title} successfully`);
          toggleModal(false);
          loadPlayer();
        }
      });
  };

  return (
    <Modal title={title} visible={modalVisible} closable onCancel={handleClose} onOk={handleSubmit}>
      <MilestoneForm form={form} obj={selected} />
    </Modal>
  );
};

export default MilestoneModal;
