import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Menu as AntdMenu } from 'antd';

import { useAppContext } from '@lib/providers/app';
import { useAuth } from '@lib/providers/auth';
import { appRoutes, RouteItem } from '@lib/routes/routes';
import { authorized } from '@lib/utils/authorize';

const useMenu = () => {
  const appCtx = useAppContext();
  const { userRoles } = useAuth();

  const routes = appCtx?.routes ?? appRoutes;

  return routes.reduce<RouteItem[]>(function extractMenu(all, item) {
    if (item.hideInMenu) {
      return all;
    }

    if (item.children) {
      const children = item.children.reduce(extractMenu, []);
      if (children.length) {
        all.push({
          ...item,
          children: item.children.reduce(extractMenu, []),
        });
      }
    } else if (item.path && userRoles && (!item.role || authorized(userRoles, item.role))) {
      all.push(item);
    }

    return all;
  }, []);
};

const getMenuMap = (menu: RouteItem[]) =>
  menu.reduce<Record<string, string>>(function extractMenu(all, item) {
    if (item.children) {
      item.children.reduce(extractMenu, all);
    } else {
      all[item.path ?? ''] = item.name;
    }

    return all;
  }, {});

const Menu: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const menu = useMenu();
  const menuMap = getMenuMap(menu);

  const onClick = (path: string | undefined) => {
    const curPath = history.location.pathname;
    if (path === '/simulator' && curPath.includes('simulator')) history.go(0);
  };

  useEffect(() => {
    setSelectedKeys([menuMap[location.pathname]].filter(Boolean));
  }, [location]);

  return (
    <AntdMenu mode="inline" selectedKeys={selectedKeys}>
      {menu.map((item) =>
        item.children ? (
          <AntdMenu.SubMenu key={item.name} title={item.title} icon={item.icon}>
            {item.children.map((child) => (
              <AntdMenu.Item key={child.name} icon={child.icon}>
                {child.path && <Link to={child.path}>{child.title}</Link>}
              </AntdMenu.Item>
            ))}
          </AntdMenu.SubMenu>
        ) : (
          <AntdMenu.Item key={item.name} icon={item.icon}>
            {item.path && (
              <Link onClick={() => onClick(item.path)} to={item.path}>
                {item.title}
              </Link>
            )}
          </AntdMenu.Item>
        ),
      )}
    </AntdMenu>
  );
};

export default Menu;
