import React, { FC } from 'react';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import { FormInstance } from 'antd/es/form';

import { useLevelDataConfigContext } from '@pages/map-level/context';

const { Item } = Form;

export type TetrominoSpawnProps = {
  form: FormInstance;
  viewOnly: boolean;
};

const { TextArea } = Input;
const TetrominoSpawn: FC<TetrominoSpawnProps> = ({ form, viewOnly }) => {
  const { levelData, onChangeTetromino, tetrominoInit } = useLevelDataConfigContext();

  return (
    <Form
      form={form}
      layout="inline"
      className="justify-center"
      initialValues={levelData?.tetromino ?? tetrominoInit}
    >
      <Row gutter={32}>
        <Col span={8} style={{ alignContent: 'space-around' }}>
          <div className="flex flex-col gap-3 items-end">
            {Object.keys(tetrominoInit as Record<string, any>)?.map((key) => {
              if (
                key !== 'sequence' &&
                key !== 'permutation' &&
                key !== 'orientation' &&
                key !== 'inventoryCapacity' &&
                key !== 'specialTetromino' &&
                key !== 'inventoryFilling'
                // tetrominos.find((tetro) => tetro.shortName === key)
              ) {
                return (
                  <Item label={key.charAt(0).toLocaleUpperCase() + key.slice(1)} name={key}>
                    <InputNumber
                      disabled={viewOnly}
                      min={0}
                      onChange={(value) => onChangeTetromino(value, key)}
                      style={{ maxWidth: 150, textAlign: 'center' }}
                      // defaultValue={tetrominoInit ? tetrominoInit[key] : 0}
                    />
                  </Item>
                );
              }
            })}

            {/* {Object.keys(tetrominoInit ?? {})?.map((key) => {
              if (!Object.keys(levelData.tetromino).includes(key.toLowerCase())) {
                return (
                  <Item label={key.charAt(0).toLocaleUpperCase() + key.slice(1)} name={key}>
                    <InputNumber
                      disabled={viewOnly}
                      min={0}
                      onChange={(value) => onChangeTetromino(value, key)}
                      style={{ maxWidth: 150, textAlign: 'center' }}
                    />
                  </Item>
                );
              }
            })} */}
          </div>
        </Col>
        <Col span={8}>
          <Row className="gap-3">
            <div style={{ width: '200px' }}>Sequence</div>
            <Item name="sequence">
              <TextArea disabled={viewOnly} style={{ minHeight: 150 }} />
            </Item>
          </Row>
          {/* <Divider orientation="left" /> */}
          <Row className="gap-3">
            <div style={{ width: '200px' }}>Inventory Tetromino</div>
            <Item name="specialTetromino">
              <TextArea disabled={viewOnly} style={{ minHeight: 150 }} />
            </Item>
          </Row>
          <Row className="gap-3">
            <div style={{ width: '200px' }}>Inventory Capacity</div>
            <Item name="inventoryCapacity">
              <InputNumber disabled={viewOnly} />
            </Item>
          </Row>
          <Row className="gap-3">
            <div style={{ width: '200px' }}>Inventory Filling</div>
            <Item name="inventoryFilling">
              <InputNumber disabled={viewOnly} />
            </Item>
          </Row>
        </Col>
        <Col span={8}>
          <div className="gap-3">
            <div>Default Bag Rotation</div>
            <Item name="orientation">
              <TextArea disabled={viewOnly} style={{ minHeight: 150 }} />
            </Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default TetrominoSpawn;
