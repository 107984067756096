import path from 'path';

import React, { createContext, FC, useContext, useState } from 'react';
import { FileData } from 'chonky';

import { odlService } from '@lib/services/odl';
import { useModal } from '@lib/utils/use-modal';

export const useOdlFileBrowserValue = () => {
  const modal = useModal<string>();

  const [loading, setLoading] = useState(false);
  const [folderPrefix, _setFolderPrefix] = useState('/');
  const [files, setFiles] = useState<FileData[]>([]);

  const folderChain = React.useMemo(() => {
    let chain: FileData[];
    if (folderPrefix === '/') {
      chain = [
        {
          id: '/',
          name: 'Root',
          isDir: true,
        },
      ];
    } else {
      let currentPrefix = '';
      chain = `/${folderPrefix}`
        .replace(/\/*$/, '')
        .split('/')
        .map(
          (prefixPart, idx): FileData => {
            currentPrefix = currentPrefix ? path.join(currentPrefix, prefixPart) : prefixPart;
            return {
              id: `${currentPrefix}/`,
              name: idx ? prefixPart : 'Root',
              isDir: true,
            };
          },
        );
    }

    return chain;
  }, [folderPrefix]);

  const loadS3Objects = async (prefix = '/') => {
    setLoading(true);
    const res = await odlService.getAllFilesByFolder(prefix || '/');
    if (res.ok) {
      const _files = (res.data ?? []).map<FileData>((item) => {
        return { ...item, modDate: item.updatedAt };
      });

      setFiles(_files);
    }
    setLoading(false);
  };

  const loadUnbuildS3Objects = async () => {
    setLoading(true);
    const res = await odlService.getAllUnbuildFiles();
    if (res.ok) {
      const _files = (res.data ?? []).map<FileData>((item) => {
        return {
          ...item,
          modDate: item.updatedAt,
          name: `${item.folder === '/' ? '' : item.folder}${item.name}`,
        };
      });

      setFiles(_files);
    }
    setLoading(false);
  };

  const addFile = (file: FileData) => {
    setFiles((current) => [...current, file]);
  };

  const removeFile = (fileIds: string[]) => {
    setFiles((current) => current.filter((item) => !fileIds.includes(item.id)));
  };

  const setFolderPrefix = (prefix = '/') => {
    _setFolderPrefix(prefix);
    loadS3Objects(prefix);
  };

  const getSupportImageSize = async () => {
    const res = await odlService.getSupportImageSize();
    if (res.ok) {
      return res.data.supportImageSize;
    }
  };

  return {
    folderPrefix,
    setFolderPrefix,
    folderChain,
    loading,
    files,
    loadS3Objects,
    loadUnbuildS3Objects,
    addFile,
    removeFile,
    modal,
    getSupportImageSize,
  };
};

export type OdlFileBrowserContext = ReturnType<typeof useOdlFileBrowserValue>;

export const OdlFileBrowserContext = createContext<OdlFileBrowserContext | null>(null);

export const OdlFileBrowserProvider: FC = ({ children }) => {
  const value = useOdlFileBrowserValue();

  return <OdlFileBrowserContext.Provider value={value}>{children}</OdlFileBrowserContext.Provider>;
};

export const useOdlFileBrowserContext = () => {
  const ctx = useContext(OdlFileBrowserContext);
  if (!ctx) {
    throw new Error('useOdlUploadContext must be used inside OdlUploadContext');
  }

  return ctx;
};

export default OdlFileBrowserProvider;
