import client from '@lib/services/client';

import { InboxDto, InboxVersion } from '../models';

export const paging = client.api.csInboxHistoryControllerList;

export const registerTask = (version: InboxVersion, params: InboxDto) =>
  client.api.csInboxControllerRegister(version, params, {
    headers: { 'Content-Type': undefined } as any,
  });

export const redeployTask = (id: number) => client.api.csInboxControllerRedeploy(id);

export const getMessageProto = (version: InboxVersion) =>
  client.api.csInboxControllerGetMessageProto(version);
