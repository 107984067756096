import { UserRoleMapping } from '../_api';
import client from '../client';

export type LoginResponseDto = {
  token: string;
};

export const login = (googleTokenId: string) => client.api.authControllerLogin({ googleTokenId });

export const logout = () => client.api.authControllerLogout();

export const me = () => client.api.authControllerMe();

export const getRoles = () => client.api.authControllerAllRoles();

export const getUserRoles = (email: string) => client.api.authControllerGetRoles(email);

export const setUserRoles = (email: string, roles: UserRoleMapping[]) =>
  client.api.authControllerSetRoles(email, roles);
