import React, { forwardRef, useEffect, useState } from 'react';
import { Select } from 'antd';
import { RefSelectProps, SelectProps } from 'antd/es/select';

import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';
import { BucketInfo, useAvaConfigContext } from '@lib/providers/ava-config';
import { avaConnectService } from '@lib/services/blueprint/ava';

export function removeDuplicateObjects(array: any[], property: string) {
  const uniqueIds: any[] = [];

  const unique = array.filter((element) => {
    const isDuplicate = uniqueIds.includes(element[property]);

    if (!isDuplicate) {
      uniqueIds.push(element[property]);

      return true;
    }

    return false;
  });

  return unique;
}

const BucketSelect = forwardRef<RefSelectProps, SelectProps<string>>((props, ref) => {
  const [buckets, setBuckets] = useState<any[]>([]);
  const {
    environment,
    setBucketName,
    bucketId,
    setBucketId,
    levelNames,
    setLevelNames,
    bucketName,
    openModalPull,
  } = useBlueprintDataContext();
  const { avaConfig, loading } = useAvaConfigContext();

  useEffect(() => {
    setBuckets(avaConfig[(environment ?? 'trunk').toLowerCase()]?.buckets);
  }, [environment]);

  const setBucketInfo = async (value: any, label: any) => {
    setBucketId(value);
    setBucketName(label.label);

    if (openModalPull === 'MapLevelCollection') {
      const res = await avaConnectService.getConfigByBucketSearch(value, 'LevelConfig');

      if (res && res.ok) {
        setLevelNames(res.data);
      }
    }
  };

  return (
    <Select
      placeholder="Select bucket"
      allowClear
      showSearch
      {...props}
      loading={loading}
      ref={ref}
      filterOption={
        (inputValue, option) =>
          option?.key?.toString().toLowerCase().includes(inputValue.toLowerCase()) ?? false
        // eslint-disable-next-line react/jsx-curly-newline
      }
      options={buckets?.map((bucket: BucketInfo) => {
        return {
          value: bucket.id,
          label: bucket.inAppId,
          key: bucket.inAppId,
          title: bucket.inAppId,
        };
      })}
      onChange={setBucketInfo}
      value={bucketName}
    />
  );
});

export default BucketSelect;
