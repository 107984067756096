import React, { FC } from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { Currency, UserRole } from '@lib/services/models';
import { useAuthorized } from '@lib/utils/authorize';

import { useCsPlayerContext } from '../context';

const CurrencyListAction: FC<{
  data: Currency & any;
}> = ({ data: currency }) => {
  const authorized = useAuthorized(UserRole.CsEditor);

  const { player, formCurrency, currencyModal } = useCsPlayerContext();
  const { toggleModal, setSelected } = currencyModal;

  const handleEdit = () => {
    toggleModal(true);
    setSelected(currency);
    formCurrency.setFieldsValue({ currency: currency.name });
  };

  return (
    <Button.Group>
      <Button
        type="primary"
        icon={<EditOutlined />}
        disabled={!authorized || !player /*  || player.source !== PlayerDataSource.Redis */}
        onClick={handleEdit}
      />
    </Button.Group>
  );
};

export default CurrencyListAction;
