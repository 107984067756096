import React, { FC, ReactNode } from 'react';
import { Col, Form, Input, InputNumber, Switch } from 'antd';
import { FormInstance, FormItemProps, FormProps, Rule } from 'antd/es/form';
import { FormListFieldData } from 'antd/es/form/FormList';
import { IField, IType } from 'protobufjs';
import { rrulestr } from 'rrule';
import { FileImageTwoTone } from '@ant-design/icons';

import OdlFileSelect from '@lib/components/odl-file/file-select';
import { TimestampDatePicker, TimestampTimePicker } from '@lib/components/ui/date-picker';
import InputWithValueAsTitle from '@lib/components/ui/input-with-value-as-title';
import { ProtoData } from '@lib/providers/blueprint-version';
import { FieldDataType } from '@lib/services/_api';
import { formatLabel } from '@lib/utils/blueprint-data';

import { RefFieldSettings } from '../../context';
import RRuleInput from '../../rrule/input';

import EnumFieldFormItem from './enum-field';

export type OnFieldsChangeCallback = NonNullable<FormProps['onFieldsChange']>;

export type RenderOptions<T = IType> = {
  types: ProtoData;
  paths: Array<number | string>;
  type: T;
  key: string;
  label?: ReactNode;
  field?: FormListFieldData;
  form: FormInstance;
  onFieldsChange: OnFieldsChangeCallback;
  fieldSettings: Record<string, RefFieldSettings>;
  width: number;
  editing?: boolean;
};

export type SingleFieldFormItemProps = {
  options: RenderOptions<IField>;
};

type RenderFormItemOptions = FormItemProps & {
  width?: number;
};

const validateRRule = async (rule: Rule, value?: string) => {
  if (!value) return;

  return new Promise((resolve, reject) => {
    try {
      rrulestr(value);
      resolve(undefined);
    } catch (e) {
      reject(e);
    }
  });
};

const SingleFieldFormItem: FC<SingleFieldFormItemProps> = ({ options }) => {
  const {
    paths,
    type: { type },
    key,
    label,
    field,
    fieldSettings,
    editing,
  } = options;

  const fieldName = field ? [field.name] : [...paths];
  const fieldKey = field ? [field.fieldKey] : [...paths];
  if (!field) {
    fieldName.push(key);
    fieldKey.push(key);
  }

  const dataKey = fieldName.join('.');
  const truthDataKey = field ? key : dataKey;

  const fieldSetting = fieldSettings[truthDataKey];
  const required = key === '$key' || fieldSetting?.required;
  const editable = fieldSetting?.editable ?? true;

  if (fieldSetting?.reference) {
    return <EnumFieldFormItem options={{ ...options, type: fieldSetting.reference }} />;
  }

  const formatedKey = fieldSetting?.displayName ?? formatLabel(key);

  const rules: Rule[] = [];
  if (required) {
    rules.push({ required: true, message: `${formatedKey} is required` });
  }

  if (['int', 'int32', 'int64', 'float', 'uint32'].indexOf(type) > -1) {
    if (['int', 'int32', 'int64', 'uint32'].indexOf(type) > -1) {
      rules.push({ type: 'integer' });
    } else {
      rules.push({ type: 'number' });
    }
  }

  const renderFormItem = (children: ReactNode, _options: RenderFormItemOptions = {}) => {
    const { width: _width = 220, label: _label } = _options;
    const width = Math.max(_width, formatedKey.length * 7.5);

    return (
      <Col flex={`0 0 ${width}px`} key={dataKey}>
        <Form.Item
          {...field}
          name={fieldName}
          fieldKey={fieldKey}
          label={_label ?? label ?? formatedKey}
          rules={rules}
          valuePropName={type === 'bool' ? 'checked' : undefined}
        >
          {children}
        </Form.Item>
      </Col>
    );
  };

  if (
    fieldSetting?.dataType === FieldDataType.Date ||
    fieldSetting?.dataType === FieldDataType.DateTime
  ) {
    return renderFormItem(
      <TimestampDatePicker
        showTime={fieldSetting?.dataType === FieldDataType.DateTime}
        placeholder={`Select ${formatedKey}`}
        style={{ width: '100%' }}
        disabled={editing && !editable}
        useSeconds
        utcTime
      />,
    );
  }
  if (fieldSetting?.dataType === FieldDataType.Time) {
    return renderFormItem(
      <TimestampTimePicker
        placeholder={`Select ${formatedKey}`}
        style={{ width: '100%' }}
        disabled={editing && !editable}
        useSeconds
        utcTime
      />,
    );
  }
  if (['int', 'int32', 'int64', 'float', 'uint32'].indexOf(type) > -1) {
    return renderFormItem(
      <InputNumber
        placeholder={`Input ${formatedKey}`}
        style={{ width: '100%' }}
        disabled={editing && !editable}
      />,
    );
  }
  if (type === 'bool') {
    return renderFormItem(<Switch disabled={editing && !editable} defaultChecked={false} />);
  }
  if (fieldSetting?.dataType === FieldDataType.Textarea) {
    return renderFormItem(
      <Input.TextArea
        placeholder={`Input ${formatedKey}`}
        disabled={editing && !editable}
        rows={3}
        style={{ width: '100%' }}
      />,
      { width: 440 },
    );
  }
  if (fieldSetting?.dataType === FieldDataType.Rrule) {
    rules.push({ message: 'Invalid rrule string', validator: validateRRule });
    return renderFormItem(
      <RRuleInput
        placeholder={`Select ${formatedKey}`}
        disabled={editing && !editable}
        style={{ width: '100%' }}
      />,
      { width: 440 },
    );
  }
  if (fieldSetting?.dataType === FieldDataType.OdlFile) {
    return renderFormItem(
      <OdlFileSelect
        placeholder={`Select ${formatedKey}`}
        disabled={editing && !editable}
        style={{ width: '100%' }}
      />,
      {
        label: (
          <>
            <FileImageTwoTone className="mr-1" />
            {label ?? formatedKey}
          </>
        ),
      },
    );
  }

  return renderFormItem(
    <InputWithValueAsTitle placeholder={`Input ${formatedKey}`} disabled={editing && !editable} />,
  );
};

export default SingleFieldFormItem;
