import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { useHistory } from 'react-router-dom';
import { Card } from 'antd';
import moment from 'moment';

import { PagingAgGridRef } from '@lib/components/paging-grid';
import PagingAgGrid from '@lib/components/paging-grid/paging-grid';
import { ColDef, ServerSideStoreType } from '@lib/components/ui/ag-grid';
import { useCsPlayerContext } from '@lib/pages/cstool/search-user/components/context';
import { PagingOptions } from '@lib/services/base/paginate';
import { useAuthorized } from '@lib/utils/authorize';
import { useQuery } from '@lib/utils/use-query';

import { T3LeaderBoard, UserRole } from '@services/_api';
import { leaderBoardService } from '@services/leader-board';

import { useLeaderBoardContext } from './context';
import LeaderBoardFormListAction from './list-action';
import LeaderBoardModal from './modal';

const columnDefs: ColDef[] = [
  {
    headerName: 'LeaderboardId',
    field: 'LeaderboardId',
    minWidth: 300,
  },
  {
    headerName: 'RoomSize',
    field: 'RoomSize',
    maxWidth: 150,
  },
  {
    headerName: 'RoomId',
    field: 'RoomId',
    maxWidth: 150,
  },
  {
    headerName: 'CurrentJoinedPlayerCount',
    field: 'CurrentJoinedPlayerCount',
  },
  {
    headerName: 'RoomStartDate',
    field: 'RoomStartDate',
    cellRendererFramework: (val: any) => {
      return <>{moment(val.data.RoomStartDate).format('DD/MM/YYYY HH:MM')}</>;
    },
  },
  {
    headerName: 'RoomEndDate',
    field: 'RoomEndDate',
    cellRendererFramework: (val: any) => {
      return <>{moment(val.data.RoomEndDate).format('DD/MM/YYYY HH:MM')}</>;
    },
  },
  {
    minWidth: 300,
    headerName: 'Action',
    // field: 'action',
    colId: 'action',
    sortable: true,
    cellRendererFramework: LeaderBoardFormListAction,
  },
];

const LeaderBoard: FC = () => {
  const history = useHistory();
  const query = useQuery();

  const authorized = useAuthorized(UserRole.Deployer);
  const systemRole = useAuthorized(UserRole.System);

  const gridRef = useRef<PagingAgGridRef>(null);

  const pagingOptionsRef = useRef<PagingOptions<any>['where']>({});

  const { environment, setEnvironment } = useCsPlayerContext();

  const {
    type,
    setType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    rangeTime,
    setRangetime,
    leaderboardId,
    setLeaderboardId,
  } = useLeaderBoardContext();

  const updateQuery = () => {
    const queries = [
      environment && `environment=${environment}`,
      type && `type=${type}`,
      rangeTime && `startDate=${rangeTime[0].valueOf()}`,
      rangeTime && `endDate=${rangeTime[1].valueOf()}`,
      leaderboardId && `query=${leaderboardId}`,
    ]
      .filter(Boolean)
      .join('&');
    history.push({
      search: `?${queries}`,
    });
  };

  useEffect(() => {
    if (pagingOptionsRef.current) {
      if (environment) pagingOptionsRef.current.environment = environment;
      else delete pagingOptionsRef.current.environment;

      if (type) pagingOptionsRef.current.type = type;
      else delete pagingOptionsRef.current.type;

      if (rangeTime) {
        pagingOptionsRef.current.startDate = rangeTime[0].valueOf();
        pagingOptionsRef.current.endDate = rangeTime[1].valueOf();
      } else {
        delete pagingOptionsRef.current.startDate;
        delete pagingOptionsRef.current.endDate;
      }

      if (leaderboardId) pagingOptionsRef.current.leaderboardId = leaderboardId;
      else delete pagingOptionsRef.current.leaderboardId;
    } else {
      pagingOptionsRef.current = {};
    }
    if (gridRef.current) {
      gridRef.current.fetch();
    }
    updateQuery();
  }, [environment, type, rangeTime, leaderboardId]);

  return (
    <Card
      className="flex flex-col flex-1 min-h-full"
      bordered={false}
      bodyStyle={{ padding: 16, display: 'flex', flexDirection: 'column', flex: '1 1 100%' }}
    >
      <ReactResizeDetector
        handleHeight
        render={({ height }) => (
          <div className="flex-auto ag-theme-alpine bordered" style={{ height }}>
            <PagingAgGrid<T3LeaderBoard>
              request={leaderBoardService.paging}
              requestParams={{ where: pagingOptionsRef.current }}
              serverSideStoreType={ServerSideStoreType.Partial}
              actionRef={gridRef}
              defaultColDef={{
                sortable: true,
                tooltipValueGetter: ({ valueFormatted }) => valueFormatted,
              }}
              columnDefs={[...columnDefs]}
              enableBrowserTooltips
              pagination
              paginationPageSize={20}
            />
          </div>
        )}
      />
      <LeaderBoardModal />
    </Card>
  );
};

export default LeaderBoard;
