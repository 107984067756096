import React, { FC } from 'react';
import { Empty } from 'antd';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import {
  AgGridReact as AgGrid,
  AgGridReactProps as BaseAgGridReactProps,
} from '@ag-grid-community/react';
import { AllEnterpriseModules } from '@ag-grid-enterprise/all-modules';
import { LicenseManager } from '@ag-grid-enterprise/core';

import Loading from '../loading';

import './ag-grid.scss';

LicenseManager.setLicenseKey('NDEwMjMzMzIwMDAwMA==4776ae9eddc069aad222a64b09b9e834');

export * from '@ag-grid-community/all-modules';
export * from '@ag-grid-community/core';
export * from '@ag-grid-community/react';

export type AgColDef = ColDef | ColGroupDef;

export type AgGridReactProps = Omit<BaseAgGridReactProps, 'modules'>;

export const AgGridReact: FC<AgGridReactProps> = (props) => {
  const { defaultColDef, ...otherProps } = props;

  return (
    <AgGrid
      defaultColDef={{
        sortable: true,
        suppressMenu: true,
        resizable: true,
        ...defaultColDef,
      }}
      noRowsOverlayComponentFramework={() => <Empty description="No data" />}
      loadingOverlayComponentFramework={() => <Loading />}
      modules={[...AllCommunityModules, ...AllEnterpriseModules]}
      {...otherProps}
    />
  );
};

export default AgGridReact;
