import React, { createContext, FC, useContext, useState } from 'react';
import { Form, message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';
import { csUserService } from '@lib/services/cstool';
import {
  Currency,
  PlayerMilestoneProgressionDto,
  T3CurrencyDto,
  T3LeaderBoardDto,
} from '@lib/services/models';
import { useModal } from '@lib/utils/use-modal';

import { SearchUserTabs } from './search-result';

export type T3Player = {
  level?: number;
  lastSessionDate?: string;
  totalSpent?: number;
  lastCountry?: string;
  leaderboardIds?: string[];
  accountDisable?: boolean;
  name?: string;
  pod?: string;
};

export type PlayerLeaderBoarDto = {
  LeaderboardId: string;
  RoomId: string;
  banned: boolean;
  RoomDetailInfo: RoomDetailDto[];
  CurrentJoinedPlayerCount: number;
};

export type RoomDetailDto = {
  PsaId: number;
  Score: number;
  LastUpdate: number;
};

const useCsUserContextValue = () => {
  const [formLeaderBoard] = Form.useForm();
  const [formCurrency] = Form.useForm();
  const { environment, setEnvironment } = useBlueprintDataContext();
  const [playerId, setPlayerId] = useState<string>('');
  const [player, setPlayer] = useState<T3Player>();
  const [activeTab, setActiveTab] = useState<SearchUserTabs>(SearchUserTabs.User);
  const [currencies, setCurrencies] = useState<T3CurrencyDto[]>([]);
  const [milestones, setMilestones] = useState<PlayerMilestoneProgressionDto[]>([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [frameUrl, setFrameUrl] = useState<string>();

  const [milestone, setMilestone] = useState<PlayerMilestoneProgressionDto>();
  const [playerLeaderBoards, setPlayerLeaderBoards] = useState<T3LeaderBoardDto[]>([]);
  const [roomDetail, setRoomDetail] = useState<any>([]);

  const currencyModal = useModal<Currency>();

  const modal = useModal<T3LeaderBoardDto>();

  const milestoneModal = useModal<PlayerMilestoneProgressionDto>();
  const s3URL = 'https://tool-t3-dev.s3.ap-southeast-1.amazonaws.com/';
  const loadLeaderBoards = async (ids: string[]) => {
    if (!playerId || !environment) return;

    const res = await csUserService.getAllLeaderBoard(playerId, environment);
    const { ok, data } = res;
    if (ok) {
      if (!data) {
        return message.info(`Not found any leader board with ID ${playerId}`);
      }

      // TODO: Following backend api
      const leaderBoardArr: T3LeaderBoardDto[] = [];
      // eslint-disable-next-line no-unused-expressions
      data?.forEach((item: T3LeaderBoardDto) => {
        // let banned = false;
        // if (ids.includes(item.LeaderboardId)) banned = true;
        leaderBoardArr.push({
          LeaderboardId: item.LeaderboardId,
          RoomId: item.RoomId,
          RoomDetailInfo: item.RoomDetailInfo,
          IsBanned: item?.IsBanned,
          CurrentJoinedPlayerCount: item.CurrentJoinedPlayerCount,
          RoomSize: 0,
          environment: '',
          RoomEndDate: '',
          RoomStartDate: '',
          Type: '',
        });
      });

      setPlayerLeaderBoards(leaderBoardArr);
      // return loadCurrencies;
    }
  };

  const loadPlayer = async () => {
    if (!playerId || !environment) return;

    try {
      const res = await csUserService.getUser(playerId, environment);
      const { ok, data } = res;
      if (ok) {
        if (!data) {
          message.info(`Not found any user with ID ${playerId}`);
        }

        setImageUrl(`${s3URL}Avatar/${data?.avatarName.split('/')[2]}.png` ?? '');

        setFrameUrl(`${s3URL}Frame/${data?.frameName.split('/')[2]}.png` ?? '');

        setPlayer({
          level: data?.playerInfo.level,
          totalSpent: data?.finance.totalSpent,
          lastCountry: data?.geo.lastCountry,
          accountDisable: data?.restrictions.accountDisable,
          name: data?.name,
          pod: data?.playerInfo.pod,
        });

        setCurrencies(data?.balances ?? []);

        setMilestones(data?.milestoneProgressions ?? []);

        loadLeaderBoards(data?.restrictions?.banState?.soloLeaderboardIds ?? []);
      } else {
        message.info(`Not found any user with ID ${playerId}`);
      }
    } catch (error) {
      message.info(error?.message ?? `Not found any user with ID ${playerId}`);
    }

    //  else {
    //   message.error(`Error: ${res.error.message}`);
    // }
  };

  const banPlayer = async (name: string) => {
    if (!playerId || !environment) return;

    const res = await csUserService.banUser(playerId, environment, name);
    const { ok } = res;
    if (ok) {
      setPlayer({ ...player });

      return ok;
    }
  };

  const unbanPlayer = async (name: string) => {
    if (!playerId || !environment) return;

    const res = await csUserService.unbanUser(playerId, environment, name);
    const { ok } = res;
    if (ok) {
      setPlayer({ ...player });

      return ok;
    }
  };

  const resetAccount = async () => {
    if (!playerId || !environment) return;

    const res = await csUserService.resetAccount(playerId, environment);
    const { ok } = res;
    if (ok) {
      setPlayer({ ...player });

      return ok;
    }
  };

  const unlinkSocial = async () => {
    if (!playerId || !environment) return;

    const res = await csUserService.unlinkSocial(playerId, environment);
    const { ok } = res;
    if (ok) {
      setPlayer({ ...player });

      return ok;
    }
  };

  const deleteAccount = async () => {
    if (!playerId || !environment) return;

    const res = await csUserService.deleteAccount(playerId, environment);
    const { ok } = res;
    if (ok) {
      // setPlayer({ ...player });
      loadPlayer();

      return ok;
    }
  };

  const cheatPlayerLevel = async () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to cheat level of ${playerId} in ${environment}`,
      okButtonProps: { danger: true },
      onOk: async () => {
        if (!playerId || !environment) return;

        const res = await csUserService.cheatPlayerLevel(playerId, environment, player?.level ?? 0);
        const { ok } = res;
        if (ok) {
          // setPlayer({ ...player });
          loadPlayer();
          message.success(`Cheat Level ${playerId} successfully`);

          return ok;
        }
      },
    });
  };

  const cheatGameModePlayer = async () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to cheat game pod of ${playerId} in ${environment}`,
      okButtonProps: { danger: true },
      onOk: async () => {
        if (!playerId || !environment || !player?.pod) return;

        const res = await csUserService.cheatGamePodPlayer(
          playerId,
          player?.pod as string,
          environment,
        );
        const { ok } = res;
        if (ok) {
          // setPlayer({ ...player });
          loadPlayer();
          message.success(`Cheat game pod ${playerId} successfully`);

          return ok;
        }
      },
    });
  };

  return {
    formLeaderBoard,
    environment,
    setEnvironment,
    setRoomDetail,
    roomDetail,
    playerId,
    setPlayerId,
    player,
    loadPlayer,
    setPlayer,
    currencyModal,
    currencies,
    banPlayer,
    unbanPlayer,
    playerLeaderBoards,
    setPlayerLeaderBoards,
    resetAccount,
    unlinkSocial,
    deleteAccount,
    cheatPlayerLevel,
    milestones,
    milestoneModal,
    setMilestone,
    milestone,
    activeTab,
    setActiveTab,
    imageUrl,
    setImageUrl,
    frameUrl,
    setFrameUrl,
    formCurrency,
    cheatGameModePlayer,
    modal,
  };
};

export type CsUserContext = ReturnType<typeof useCsUserContextValue>;

const csPlayerContext = createContext<CsUserContext | null>(null);

export const CsPlayerProvider: FC = ({ children }) => {
  const value = useCsUserContextValue();

  return <csPlayerContext.Provider value={value}>{children}</csPlayerContext.Provider>;
};

export const useCsPlayerContext = () => {
  const ctx = useContext(csPlayerContext);
  if (!ctx) {
    throw new Error('useCsPlayerContext must be used inside CsPlayerProvider');
  }

  return ctx;
};

export default CsPlayerProvider;
