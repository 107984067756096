import React, { useRef } from 'react';

import { Message } from '@services/_api';

const RunningMessage: React.FC<{ message: Message[] }> = ({ message }) => {
  const messageRef = useRef<any>(null);

  React.useEffect(() => {
    const elem = document.getElementById('scrollableDiv');
    if (elem) elem.scrollTop = elem.scrollHeight;
  }, [message]);

  return (
    <div
      id="scrollableDiv"
      style={{
        padding: '3px 0',
        lineHeight: '15px',
        height: '100px',
        overflow: 'auto',
      }}
    >
      {message &&
        message.length &&
        message?.map((x) => (
          <div
            style={{
              color:
                x.message?.toLocaleLowerCase()?.includes('error') ||
                x.message?.toLocaleLowerCase()?.includes('timeout')
                  ? 'red'
                  : 'green',
            }}
            dangerouslySetInnerHTML={{ __html: x.message }}
          />
        ))}
      <div ref={messageRef} />
    </div>
  );
};

export default RunningMessage;
