import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { UserRole } from '@lib/services/models';
import { useAuthorized } from '@lib/utils/authorize';

import { T3LeaderBoard } from '@services/_api';

import { useLeaderBoardContext } from './context';

const LeaderBoardFormListAction: FC<{ data: T3LeaderBoard }> = ({ data }) => {
  const authorized = useAuthorized(UserRole.CsEditor);

  const { modal } = useLeaderBoardContext();
  // const { fetch } = list;
  const { toggleModal, setSelected } = modal;

  const handleViewRoomDetail = () => {
    if (!data.RoomDetailInfo || data.RoomDetailInfo.length === 0) {
      Modal.error({
        title: 'Error',
        content: 'Room Leader Board is Empty!',
      });
      return;
    }

    setSelected(data);
    toggleModal(true);
  };

  // const handleDelete = () => {
  //   Modal.confirm({
  //     icon: <ExclamationCircleOutlined />,
  //     content: `Delete mail template [${data.templateId}]?`,
  //     okButtonProps: { danger: true },
  //     onOk: async () => {
  //       fetch();
  //       await csMailTemplateService.destroy(data.id as number);
  //       message.success('Deleted successfully');
  //     },
  //   });
  // };

  return (
    <Button.Group>
      <Button
        type="primary"
        icon={<EditOutlined />}
        disabled={!authorized}
        onClick={handleViewRoomDetail}
      />
      {/* <Button danger icon={<DeleteOutlined />} disabled={!authorized} onClick={handleDelete} /> */}
    </Button.Group>
  );
};

export default LeaderBoardFormListAction;
