import client from '@lib/services/client';

export const paging = () => {
  return client.api.avaConnectControllerList({ where: { isPushing: true } });
};

export const getDataAVA = () => {
  return client.api.avaConnectControllerGetEnv();
};

export const getConfigByBucketSearch = (bucketId: number, searchType: string) => {
  return client.api.avaConnectControllerGetConfigByBucketSearch({
    bucketId,
    searchType,
  });
};

export const pullDataFromAVA = (
  inAppIds: string,
  bucketId: number,
  bucketName: string,
  version: string,
  environment: string,
  type: string,
  upsert: boolean,
  data?: string[],
) => {
  return client.api.avaConnectControllerPullDataFromAva(
    {
      inAppIds,
      bucketId,
      bucketName,
      version,
      environment,
      type,
      upsert,
    },
    data ?? [],
  );
};

export const pullBucketEventData = (bucketId: number, version: string, upsert: boolean) => {
  return client.api.avaConnectControllerGetBucketEventData({
    bucketId,
    version,
    upsert,
  });
};

export const submitAVA = (
  version: string,
  type: string,
  inAppId: string,
  description: string,
  bucketId: number,
  environment: string,
  ids?: string,
  levelConfig?: string,
  bucketName?: string,
) => {
  return client.api.avaConnectControllerSubmitAva({
    version,
    type,
    inAppId,
    description,
    bucketId,
    environment,
    ids,
    levelConfig,
    bucketName,
  });
};
