import React, { createContext, FC, useContext, useRef, useState } from 'react';

import { PagingAgGridRef } from '@lib/components/paging-grid';
import { Bot } from '@lib/services/models';
import { useModal } from '@lib/utils/use-modal';

const useBotContextValue = () => {
  const [environment, setEnvironment] = useState<string>();
  const [dabName, setDabName] = useState<string>();

  const modal = useModal<Bot>();

  const gridRef = useRef<PagingAgGridRef>(null);

  return {
    environment,
    setEnvironment,
    modal,
    gridRef,
    dabName,
    setDabName,
  };
};

export type BotContext = ReturnType<typeof useBotContextValue>;

const botContext = createContext<BotContext | null>(null);

export const BotProvider: FC = ({ children }) => {
  const value = useBotContextValue();
  return <botContext.Provider value={value}>{children}</botContext.Provider>;
};

export const useBotContext = () => {
  const ctx = useContext(botContext);
  if (!ctx) {
    throw new Error('useBotContext must be used inside BotProvider');
  }

  return ctx;
};

export default BotProvider;
