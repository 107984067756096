import { message } from 'antd';

import { TOKEN_KEY } from '@lib/providers/auth';
import { LoginLocation } from '@lib/routes/routes';
import { errorMessages, interceptRequest } from '@lib/services/base/interceptors';

import { Api, HttpResponse } from './_api';

const interceptResponse = async <T, E>({ response }: { response: HttpResponse<T, E> }) => {
  const { ok, error, statusText } = response;
  if (!ok) {
    const errMsg =
      (error as any)?.message ??
      // (error as any)?.error ??
      errorMessages[response.status] ??
      statusText;
    message.error(errMsg);
  }

  if (response.status === 401 && window.location.pathname !== LoginLocation.path) {
    localStorage.removeItem(TOKEN_KEY);
    window.location.href = LoginLocation.path;
  }

  return response;
};

const client = new Api({
  baseUrl: process.env.REACT_APP_API_ENDPOINT || '',
  interceptors: { request: interceptRequest, response: interceptResponse },
});

export default client;
