import { message } from 'antd';
import { Res as HttpRes } from 'use-http';

import { TOKEN_KEY } from '@lib/providers/auth';
import { LoginLocation } from '@lib/routes/routes';

export type Res<T> = HttpRes<T> & {
  error?: Error;
};

export const interceptRequest = ({ options = {} }: { options: RequestInit }): RequestInit => {
  const token = localStorage.getItem(TOKEN_KEY);
  const headers: Record<string, string> = token ? { authorization: `Bearer ${token}` } : {};

  return {
    ...options,
    headers: {
      ...headers,
      ...(options.headers ?? {}),
    },
  };
};

export const errorMessages: Record<number, string> = {
  401: 'Please relogin to perform this action',
};

export const interceptResponse = async <T = any>({ response }: { response: Res<T> }) => {
  let result;
  if (response.headers.get('content-type')?.includes('application/json')) {
    result = await response.json();
  }

  if (response.ok) {
    response.data = result;
  } else {
    const errMsg =
      result?.error ?? result?.message ?? errorMessages[response.status] ?? response.statusText;
    const error = new Error(errMsg);
    response.error = error;

    message.error(result?.message ?? response.statusText);
  }

  if (response.status === 401 && window.location.pathname !== LoginLocation.path) {
    localStorage.removeItem(TOKEN_KEY);
    const next = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
    window.location.href = `${LoginLocation.path}?next=${next}`;
  }

  return response;
};
