import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Badge, Button, message, Modal } from 'antd';
import moment from 'moment';
import {
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  PauseOutlined,
  RetweetOutlined,
} from '@ant-design/icons';

import AgGridReact, { ColDef, GridApi, GridReadyEvent } from '@lib/components/ui/ag-grid';

import { RuntestStatusClient, useSimulatorContext } from '@pages/simulator/context';
import { SimulatorV2 as Simulator } from '@services/_api';
import { simulatorService } from '@services/simulator';

import RunningProgressRenderer from '../running-progress';

import RunningMessage from './infiniteScroll';

import './ongoing.scss';

const { confirm } = Modal;

const Stopped = () => {
  const gridApiRef = useRef<GridApi>();
  const {
    rowDataStopped,
    setRowDataStopped,
    setBoardTabActive,
    fetchTaskStatus,
    setRowDataOngoing,
    rowDataOngoing,
    boardTabActive,
  } = useSimulatorContext();
  const [selectedRows, setSelectedRows] = useState<Simulator[]>([]);
  const [open, setOpen] = useState(false);
  const [openStopModal, setOpenStopModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    setLoading(true);

    const res = await simulatorService.getByStatus(RuntestStatusClient.STOP);

    if (res.ok && res.data) {
      const task = (await res.data) as any;
      if (task) {
        // fetchTaskStatus(Number(task?.id), setRowDataStopped, setLoading);
        setRowDataStopped(task.task.data);
      }
    }
  };

  useEffect(() => {
    if (boardTabActive === RuntestStatusClient.STOP) {
      fetch();
    }
  }, [boardTabActive]);

  useEffect(() => {
    fetch();
  }, []);

  const messageRenderer = (val: any) => {
    const data = val?.data;

    return <RunningMessage message={data.message} />;
  };

  const buttonStopRenderer = (val: any) => {
    const value = val?.data;

    const handleClick = async () => {
      const res = await simulatorService.restartRuntest(value.runtestId);
      if (res.ok) {
        setRowDataStopped((cur) => cur.filter((x) => x.runtestId !== value.runtestId));
        const { data } = res as any;

        setRowDataOngoing((cur) => [...cur, data]);

        data.dateString = moment(data.dateRuntest).format('DD/MM/YYYY HH:mm');
        data.lastUpdated = moment(data.lastUpdated).format('DD/MM/YYYY HH:mm');
        if (!rowDataOngoing.find((x) => x.runtestId === data.runtestId)) rowDataOngoing.push(data);
        setBoardTabActive(RuntestStatusClient.ONGOING);
        message.success('Restart successfully');
      }
    };

    return (
      <Button
        icon={value.status === RuntestStatusClient.STOP ? <RetweetOutlined /> : <PauseOutlined />}
        onClick={handleClick}
      />
    );
  };

  const statusOngoing = (val: any) => {
    const data = val?.data;

    if (data.status === RuntestStatusClient.STOP) return <Badge status="error" text="STOP" />;
    return <Badge status="error" text="ERROR" />;
  };

  const buttonDeleteRenderer = (val: any) => {
    const data = val?.data;

    const handleDelete = async () => {
      const res = await simulatorService.deleteById(data.runtestId);
      if (res.ok)
        setRowDataStopped((cur) => {
          return [...cur.filter((x) => x.runtestId !== data.runtestId)];
        });
    };

    const showConfirm = () => {
      confirm({
        title: 'Do you want to delete this item?',
        icon: <ExclamationCircleFilled />,
        // content: 'Some descriptions',
        okButtonProps: { danger: true },
        okText: 'Delete',
        onOk() {
          handleDelete();
        },
        onCancel() {
          // eslint-disable-next-line no-console
          console.log('Cancel');
        },
      });
    };

    return <Button danger icon={<DeleteOutlined />} onClick={showConfirm} />;
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'runtestId',
      filter: 'agTextColumnFilter',
      minWidth: 210,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'performer',
      maxWidth: 220,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'dateString',
      minWidth: 170,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'lastUpdated',
      minWidth: 170,
      filter: 'agTextColumnFilter',
      hide: true,
    },
    {
      field: 'listLevel',
      minWidth: 170,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'message',
      headerName: 'Running Message',
      minWidth: 380,
      cellRendererFramework: messageRenderer,
      // valueGetter: (params) => params.getValue('message')?.message,
    },
    {
      field: 'status',
      maxWidth: 130,
      cellRendererFramework: statusOngoing,
    },
    {
      field: 'progress',
      headerName: 'Progress',
      maxWidth: 220,
      // cellRendererFramework: RunningProgressRenderer,
    },
    {
      colId: 'action',
      headerName: 'Stop/Restart',
      cellRendererFramework: buttonStopRenderer,
      cellStyle: { textAlign: 'center' },
      maxWidth: 100,
    },
    {
      colId: 'action',
      headerName: 'Remove/Delete',
      cellRendererFramework: buttonDeleteRenderer,
      cellStyle: { textAlign: 'center' },
      maxWidth: 100,
    },
  ]);

  function handleRowSelected(event: any) {
    setSelectedRows([]);
    setSelectedRows(event.api.getSelectedNodes().map((node: any) => node.data));
  }

  const handleGridReady = useCallback((e: GridReadyEvent) => {
    gridApiRef.current = e.api;
    e.api.sizeColumnsToFit();

    const updatedDefs = gridApiRef?.current?.getColumnDefs().map((colDef: any) => {
      return {
        ...colDef,
      };
    });
    gridApiRef.current.setColumnDefs(updatedDefs);
  }, []);

  const onDelete = async () => {
    setLoading(true);
    // setRowDataResult(
    //   rowDataResult.filter((a) => !selectedRows.find((b) => b.runtestId === a.runtestId)),
    // );
    // eslint-disable-next-line no-restricted-syntax
    for await (const data of selectedRows) {
      const res = await simulatorService.deleteById(data.runtestId);
      if (res.ok)
        setRowDataStopped((cur) => {
          return [...cur.filter((x) => x.runtestId !== data.runtestId)];
        });
    }
    setSelectedRows([]);

    setOpen(false);
    setLoading(false);
  };

  const onStop = async () => {
    setLoading(true);
    // eslint-disable-next-line no-restricted-syntax
    for await (const data of selectedRows) {
      const res = await simulatorService.stopRuntest(data.runtestId);
      if (res.ok) message.success(`Stop ${selectedRows.length} runtest successfully`);
    }
    setSelectedRows([]);

    setOpen(false);
    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-y-3 grid-result-simulator">
      {/* <div className="flex justify-between items-center"> */}
      <Modal
        title="Confirm"
        visible={open}
        onOk={onDelete}
        onCancel={() => setOpen(false)}
        cancelButtonProps={{ loading }}
        okButtonProps={{ danger: true, loading }}
        okText="Delete"
        cancelText="Cancel"
      >
        Are you sure to remove {selectedRows.length} runtest?
      </Modal>

      <Modal
        title="Confirm"
        visible={openStopModal}
        onOk={onStop}
        onCancel={() => setOpenStopModal(false)}
        cancelButtonProps={{ loading }}
        okButtonProps={{ danger: true, loading }}
        okText="Stop"
        cancelText="Cancel"
      >
        Are you sure to stop {selectedRows.length} runtest?
      </Modal>
      <div className="ml-auto flex gap-x-3">
        {selectedRows.length > 0 && (
          <Button
            type="primary"
            ghost
            icon={<PauseOutlined />}
            onClick={() => setOpenStopModal(true)}
          >
            Stop {selectedRows.length} {selectedRows.length === 1 ? 'runtest' : 'runtests'}
          </Button>
        )}

        {selectedRows.length > 0 && (
          <Button icon={<CloseOutlined />} danger onClick={() => setOpen(true)}>
            Remove {selectedRows.length} {selectedRows.length === 1 ? 'runtest' : 'runtests'}
          </Button>
        )}
        <Button loading={loading} onClick={fetch}>
          Reload
        </Button>
      </div>
      <ReactResizeDetector
        handleHeight
        render={({ height }) => (
          <div className="flex-1 ag-theme-alpine bordered" style={{ height }}>
            <AgGridReact
              defaultColDef={{
                floatingFilter: true,
                sortable: true,
                suppressMenu: true,
                resizable: true,
                flex: 1,
                minWidth: 100,
                wrapText: true,
              }}
              rowHeight={100}
              columnDefs={columnDefs}
              rowData={rowDataStopped}
              rowSelection="multiple"
              onSelectionChanged={handleRowSelected}
              onGridReady={handleGridReady}
              suppressRowClickSelection // suppress row click selection
              sideBar={{
                toolPanels: [
                  {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                      suppressRowGroups: true,
                      suppressValues: true,
                      suppressPivots: true,
                      suppressPivotMode: true,
                    },
                  },
                  {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                  },
                ],
                position: 'right',
              }}
            />
          </div>
        )}
      />
      {/* </div> */}
    </div>
  );
};

export default Stopped;
