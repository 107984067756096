import React, { forwardRef, useEffect, useState } from 'react';
import { Select } from 'antd';
import { RefSelectProps, SelectProps } from 'antd/es/select';

import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';
import { DabInfo, useAvaConfigContext } from '@lib/providers/ava-config';

export function removeDuplicateObjects(array: any[], property: string) {
  const uniqueIds: any[] = [];

  const unique = array.filter((element) => {
    const isDuplicate = uniqueIds.includes(element[property]);

    if (!isDuplicate) {
      uniqueIds.push(element[property]);

      return true;
    }

    return false;
  });

  return unique;
}

const DabSelect = forwardRef<RefSelectProps, SelectProps<string>>((props, ref) => {
  const [dabs, setDabs] = useState<any[]>([]);
  const { environment } = useBlueprintDataContext();
  const { avaConfig, loading } = useAvaConfigContext();

  useEffect(() => {
    setDabs(avaConfig[(environment ?? 'trunk').toLowerCase()]?.dabs);
  }, [environment]);

  return (
    <Select
      placeholder="Select dab"
      {...props}
      loading={loading}
      ref={ref}
      options={dabs?.map((dab: DabInfo) => {
        return { value: dab.inAppId, label: dab.inAppId, key: dab.inAppId, title: dab.inAppId };
      })}
    />
  );
});

export default DabSelect;
