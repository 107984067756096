import { FilterWhereOptions } from '@lib/services/base/filter';

import { OdlFile } from '@services/_api';

import client from '../client';

export const getFile = (id: string) => client.api.odlControllerFindOne({ where: { id } });

export const getAllFiles = (where?: FilterWhereOptions<OdlFile>) =>
  client.api.odlControllerGetAll({ where });

export const getAllFilesByFolder = (prefix = '/') =>
  client.api.odlControllerGetAllFilesByFolder({ prefix });

export const getAllUnbuildFiles = () => client.api.odlControllerGetAllUnbuildFiles();

export const getAllFilesOfBuild = (buildId: number) =>
  client.api.odlControllerGetAllFilesOfBuild(buildId);

export const createFolder = (filename: string, prefix = '/') =>
  client.api.odlControllerCreateFolder({ filename, prefix });

export const signGetUrl = (filename: string, prefix = '/') =>
  client.api.odlControllerSignGetUrl({ filename, prefix });

export const uploadFile = (file: File, prefix = '/') =>
  client.api.odlControllerUploadFile(
    { file },
    { prefix },
    {
      headers: { 'Content-Type': undefined } as any,
    },
  );

export const deleteFile = (filenames: string[]) =>
  client.api.odlControllerDeleteFile({ ids: filenames });

export const getSupportImageSize = () => client.api.odlControllerGetSupportImageSize();
