import React, { createContext, FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { MaintenanceLocation } from '@lib/routes/routes';
import client from '@lib/services/client';
import { HealthDto } from '@lib/services/models';
import { suspensePromise } from '@lib/utils/suspense-promise';

export type SystemHealth = HealthDto;

const healthReq = suspensePromise(client.api.healthControllerCheck());

const healthContext = createContext<SystemHealth | null>(null);

export const SystemHealthProvider: FC = ({ children }) => {
  const res = healthReq.read();
  const history = useHistory();
  const location = useLocation();

  const [healthData, setHealthData] = useState(res?.data);
  const pullRef = useRef<NodeJS.Timeout>();

  const pullHealthStatus = async () => {
    const _res = await client.api.healthControllerCheck();
    if (_res.ok) {
      setHealthData(_res.data);
    }

    pullRef.current = setTimeout(pullHealthStatus, 5000);
  };

  useEffect(() => {
    pullRef.current = setTimeout(pullHealthStatus, 5000);

    return () => {
      clearTimeout(pullRef.current as any);
    };
  }, []);

  useEffect(() => {
    if (healthData?.maintenance && location.pathname !== MaintenanceLocation.path) {
      history.push({
        pathname: MaintenanceLocation.path,
      });
    } else if (
      healthData?.maintenance === false &&
      location.pathname === MaintenanceLocation.path
    ) {
      history.push({
        pathname: '/',
      });
    }
  }, [healthData]);

  return <healthContext.Provider value={healthData ?? null}>{children}</healthContext.Provider>;
};

export const useSystemHealth = () => {
  const ctx = useContext(healthContext);
  if (!ctx) {
    throw new Error('useSystemHealth must be used inside SystemHealthProvider');
  }

  return ctx;
};
