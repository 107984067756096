import React, { forwardRef, useEffect, useState } from 'react';
import { Select } from 'antd';
import { RefSelectProps, SelectProps } from 'antd/es/select';

import environmentService from '@lib/services/environment';

const EnvironmentSelect = forwardRef<RefSelectProps, SelectProps<string>>((props, ref) => {
  const [environments, setEnvironments] = useState<string[]>([]);

  const loadEnvironment = async () => {
    const res = await environmentService.getEnvironments();
    if (res.ok) {
      const envs = (res.data ?? []).map(({ name }) => name);
      setEnvironments(envs);
    }
  };

  useEffect(() => {
    loadEnvironment();
  }, []);

  return (
    <Select placeholder="Select environment" {...props} ref={ref}>
      {environments.map((env) => (
        <Select.Option key={env} value={env}>
          {env.toUpperCase()}
        </Select.Option>
      ))}
    </Select>
  );
});

export default EnvironmentSelect;
