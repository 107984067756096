import React, { FC } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';

import { useEventCalendarContext } from './context';

const EventCalendarModal: FC = () => {
  const { eventData, loading } = useEventCalendarContext();

  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      weekends
      events={eventData}
      loading={() => loading}
    />
  );
};

export default EventCalendarModal;
