import React, { FC } from 'react';
import { IEnum, IField, IType } from 'protobufjs';

import AnyFieldFormItem from './any';
import ArrayFormItem from './array';
import EnumFieldFormItem from './enum-field';
import MapFieldFormItem from './map-field';
import ObjectFormItem from './object-field';
import SingleFieldFormItem, { RenderOptions } from './single-field';

export type FieldFormItemProps = {
  options: RenderOptions<IField>;
};

const FieldFormItem: FC<FieldFormItemProps> = ({ options }) => {
  const { types, type } = options;

  if ((type as any).keyType) {
    return <MapFieldFormItem options={options} />;
  }

  const repeated = type.rule === 'repeated';

  if (type.type === 'google.protobuf.Any') {
    return repeated ? <ArrayFormItem options={options} /> : <AnyFieldFormItem options={options} />;
  }

  if (types[type.type]) {
    // ref type
    const refType = types[type.type];

    if ((refType as IEnum).values) {
      // enum
      return repeated ? (
        <ArrayFormItem options={options} />
      ) : (
        <EnumFieldFormItem options={{ ...options, type: refType as IEnum }} />
      );
    }

    if (repeated) {
      return <ArrayFormItem options={options} />;
    }

    return <ObjectFormItem options={{ ...options, type: refType as IType }} />;
  }

  // single type
  return repeated ? <ArrayFormItem options={options} /> : <SingleFieldFormItem options={options} />;
};

export default FieldFormItem;
