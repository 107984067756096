import { IEnum, IField, IType } from 'protobufjs';

import { ProtoData } from '@lib/providers/blueprint-version';

import { isAnyType } from './is-any-type';
import { isArrayType, isMapType, isSimpleType } from './is-simple-type';

export type IdField = {
  key: string;
  field: IField;
};

export const getTypeIdField = (type: IType, types: ProtoData, keyType?: string): IdField | null => {
  if (isAnyType(type)) {
    return null;
  }

  const { fields } = type;
  if ('$key' in fields && (!keyType || keyType === fields.$key.type)) {
    return { key: '$key', field: fields.$key };
  }
  if ('id' in fields && (!keyType || keyType === fields.id.type)) {
    return { key: 'id', field: fields.id };
  }

  return (
    Object.keys(fields)
      .map((key) => ({ key, ...fields[key] }))
      // sort field by id
      .sort((a, b) => a.id - b.id)
      // only string or number can be id
      .reduce<IdField | null>((current, field) => {
        if (current) return current;
        if (isArrayType(field) || isMapType(field)) return current;

        if (isSimpleType(field) && (!keyType || keyType === field.type))
          return { key: field.key, field };

        const subType = types[field.type] as IType;
        if (((subType as unknown) as IEnum)?.values) {
          return { key: field.key, field };
        }
        if (subType?.fields && field.rule !== 'repeated' && !(field as any).keyType) {
          const subId = getTypeIdField(subType as IType, types, keyType);
          if (subId)
            return {
              key: [field.key, subId].join('.'),
              field: subId.field,
            };
        }

        return current;
      }, null)
  );
};
