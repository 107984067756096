import React from 'react';
import ReactDOM from 'react-dom';

import Loading from '@lib/components/ui/loading';

import App from '@components/app';

ReactDOM.render(
  <React.Suspense fallback={<Loading />}>
    <App />
  </React.Suspense>,
  document.getElementById('root'),
);
