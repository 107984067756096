import React, { FC } from 'react';
import { Collapse, Form } from 'antd';
import { FormInstance } from 'antd/es/form';

import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';
import FormGenerator from '@lib/pages/blueprint-data/components/form/form-generator';
import { useGlobalBlueprintProtoContext } from '@lib/providers/blueprint-proto';

import { useLevelDataConfigContext } from '@pages/map-level/context';
import { BlueprintProto } from '@services/_api';

export type ChargeBarConfigsProps = {
  form: FormInstance;
  width: any;
};

const ChargeBarConfigs: FC<ChargeBarConfigsProps> = ({ form, width }) => {
  const { levelData } = useLevelDataConfigContext();

  const { protoMap, protos } = useGlobalBlueprintProtoContext();

  const handleFieldChanges = () => {
    // empty
  };

  const {
    list: { fieldSettings },
  } = useBlueprintDataContext();

  // useEffect(() => {
  //   form.setFieldsValue({
  //     objectiveId: '0',
  //     rewardId: '0',
  //   });
  // }, []);

  // useEffect(() => {
  //   setTargetType(levelData?.targetsAndRewards?.type ?? TargetType.None);
  // }, [levelData?.chargeBarConfig?.type]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFieldsChange={handleFieldChanges}
      className="blueprint-data-form"
    >
      <FormGenerator
        blueprint={
          protos.find(
            (proto) => proto.name === 'com.alleylabs.legendblast.blueprint.ChargeBarListProto',
          ) as BlueprintProto
        }
        types={protoMap}
        form={form}
        onFieldsChange={handleFieldChanges}
        fieldSettings={fieldSettings.current}
        width={width}
        editing
      />
    </Form>
  );

  // <FormGenerator
  //   blueprint={
  //     protos.find(
  //       (proto) => proto.name === 'com.alleylabs.legendblast.blueprint.ChargeBarListProto',
  //     ) as BlueprintProto
  //   }
  //   types={protoMap}
  //   form={form}
  //   onFieldsChange={handleFieldChanges}
  //   fieldSettings={fieldSettings.current}
  //   width={width}
  //   editing
  // />

  // </Form>
};

export default ChargeBarConfigs;
