import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Badge } from 'antd';
import { debounce } from 'lodash';
import { ColGroupDef, ColumnApi } from '@ag-grid-enterprise/all-modules';
import { blue, green, grey, red } from '@ant-design/colors';

import AgGridReact, {
  AgColDef,
  AgGridReactProps,
  CellClassParams,
  ColDef,
  GridApi,
} from '@lib/components/ui/ag-grid';
import { RefFieldSettings } from '@lib/pages/blueprint-data/components/context';
import { useAppContext } from '@lib/providers/app';
import { useGlobalBlueprintProtoContext } from '@lib/providers/blueprint-proto';
import {
  BlueprintData,
  BlueprintDataChangeLog,
  BlueprintDataDiffArray,
  BlueprintDataDiffEdit,
  BlueprintDataDiffItem,
  BlueprintDataState,
  BlueprintProto,
} from '@lib/services/models';
import {
  BlueprintGridHeaderGenerator,
  calculateChangeStates,
  DataIndex,
  findActualDataIndex,
  flattenData,
  FlattenedBlueprintData,
  FlattenedDataType,
  parseDataIndex,
} from '@lib/utils/blueprint-data';

import BlueprintDataGridAction, {
  BlueprintDataGridActionProps,
} from './blueprint-data-grid-action';
import BlueprintDataGridRowCount from './blueprint-data-grid-count';

const GRID_COLUMN_LOCAL_STORAGE_KEY = 'GridColumns';

export type BlueprintDataGridAction = Partial<Omit<BlueprintDataGridActionProps, 'data'>> & {
  onSelectionChanged?: (selection: BlueprintData[]) => void;
};

export type BlueprintDataGridRef = {
  gridApi: GridApi;
  columnApi: ColumnApi;
};

export type BlueprintDataGridProps = AgGridReactProps & {
  loading?: boolean;
  data: BlueprintData[];
  fieldSettings?: Record<string, RefFieldSettings>;
  type?: BlueprintProto;
  changeLogs?: BlueprintDataChangeLog[];
  action?: BlueprintDataGridAction;
  cellRendererCheckbox?: Function;
};

const handleFlattenData = (data: BlueprintData[]) => {
  return data.reduce<FlattenedBlueprintData[]>((all, item, idx) => {
    const transformed = [] as FlattenedDataType[];
    flattenData(item.data, transformed);
    all.push(
      {
        ...item,
        data: { ...item.data, $id: idx.toString(), $keys: [] },
        paths: [(item.objId as unknown) as string],
      },
      ...transformed.slice(1).map((row, subIdx) => ({
        ...item,
        data: { ...row, $id: `${idx.toString()}.${subIdx.toString()}` },
        paths: [(item.objId as unknown) as string, subIdx.toString()],
      })),
    );
    return all;
  }, []);
};

const BlueprintDataGrid = forwardRef<BlueprintDataGridRef, BlueprintDataGridProps>((props, ref) => {
  const {
    loading,
    data,
    fieldSettings,
    type,
    changeLogs = [],
    action = {},
    onGridReady,
    cellRendererCheckbox,
    ...gridProps
  } = props;
  const { onSelectionChanged } = action;
  const selectable = !!onSelectionChanged;

  const appCtx = useAppContext();
  const { protoMap } = useGlobalBlueprintProtoContext();

  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();

  const onSelectionChangedRef = useRef(onSelectionChanged);
  const changePathMapRef = useRef<Record<string, BlueprintDataDiffItem>>({});
  const changeStateMapRef = useRef<Record<string, BlueprintDataState>>({});
  const handleColumnVisibleRef = useRef(() => {
    if (!type || !columnApi) return;

    const columns = columnApi
      .getAllDisplayedColumns()
      .map((item) => `${item.getColDef().field}:${item.getActualWidth()}`)
      .filter((field) => field && field.startsWith('data'));

    const storageKey = `${GRID_COLUMN_LOCAL_STORAGE_KEY}:${type.name}`;
    localStorage.setItem(storageKey, columns.join(','));
  });

  const stateColumn: ColDef = {
    headerName: '',
    colId: 'state',
    field: 'state',
    maxWidth: 32,
    minWidth: 32,
    pinned: 'left',
    lockPosition: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    cellRendererFramework: ({
      value,
      data: obj,
    }: {
      value: BlueprintDataState;
      data: BlueprintData;
    }) => {
      const state =
        value === BlueprintDataState.D ? value : changeStateMapRef.current[obj?.objId ?? ''];

      if (state === BlueprintDataState.A) {
        return <Badge color={green.primary} />;
      }

      if (state === BlueprintDataState.U) {
        return <Badge color={blue.primary} />;
      }

      if (state === BlueprintDataState.D) {
        return <Badge color={red.primary} />;
      }

      return <Badge color={grey.primary} />;
    },
  };

  const updateGridVisibleCallbacks = () => {
    if (!gridApi) return;

    gridApi.removeEventListener('columnVisible', handleColumnVisibleRef.current);
    gridApi.removeEventListener('columnMoved', handleColumnVisibleRef.current);
    gridApi.removeEventListener('columnResized', handleColumnVisibleRef.current);
    handleColumnVisibleRef.current = () => {
      if (!type || !columnApi) return;

      const columns = columnApi
        .getAllDisplayedColumns()
        .map((item) => `${item.getColDef().field}:${item.getActualWidth()}`)
        .filter((field) => field && field.startsWith('data'));

      const storageKey = `${GRID_COLUMN_LOCAL_STORAGE_KEY}:${type.name}`;
      localStorage.setItem(storageKey, columns.join(','));
    };
    gridApi.addEventListener('columnVisible', handleColumnVisibleRef.current);
    gridApi.addEventListener('columnMoved', handleColumnVisibleRef.current);
    gridApi.addEventListener('columnResized', handleColumnVisibleRef.current);
  };

  const handleGridReady: AgGridReactProps['onGridReady'] = (e) => {
    const handleColumnChanged = debounce(
      () => {
        e.api.sizeColumnsToFit();
      },
      100,
      { leading: false, trailing: true },
    );

    const handleSelectionChange = (selection: any[] = []) => {
      if (onSelectionChangedRef.current) {
        onSelectionChangedRef.current(selection);
      }
    };

    e.api.addEventListener('displayedColumnsChanged', handleColumnChanged);
    e.api.addEventListener('selectionChanged', () =>
      handleSelectionChange(e.api.getSelectedRows()),
    );

    setGridApi(e.api);
    setColumnApi(e.columnApi);
    updateGridVisibleCallbacks();

    if (onGridReady) {
      onGridReady(e);
    }
  };

  const getCellClassFromChange = (change: BlueprintDataDiffItem) => {
    if (change.kind === 'N') {
      return 'bg-green-100';
    }
    if (change.kind === 'D') {
      return 'bg-red-100';
    }
    if (change.kind === 'E' && !change.path) {
      const edit = change as BlueprintDataDiffEdit;
      if (edit.lhs === null && edit.rhs) {
        return 'bg-green-100';
      }
      if (edit.lhs && edit.rhs === null) {
        return 'bg-red-100';
      }
    }

    return 'bg-blue-100';
  };

  const getCellClass = (params: CellClassParams): string => {
    const { colDef } = params;
    if (!colDef) return '';

    const obj = params.data as FlattenedBlueprintData;

    const { current: changeLogMap } = changePathMapRef;

    // not data columns
    if (!colDef.field || colDef.field.indexOf('data') !== 0) return '';

    // not update
    const state = changeStateMapRef.current[obj?.objId ?? ''];
    if (state !== BlueprintDataState.U) return '';

    // root change
    if (changeLogMap[obj.objId ?? '']) return '';

    const currentDataIndex = parseDataIndex(colDef.field ?? '');

    let dataIndex = currentDataIndex.slice(1);
    if (dataIndex.length > 1) {
      const actualDataIndex = findActualDataIndex(dataIndex.slice(0, -1), obj.data);
      if (!actualDataIndex) return '';

      dataIndex = [...actualDataIndex, dataIndex[dataIndex.length - 1]];
    } else if ((obj.paths?.length ?? 1) > 1) {
      return '';
    }

    const changePath = [obj.objId, ...dataIndex];
    while (changePath.length > 1) {
      if (changeLogMap[changePath.join('.')]) {
        return getCellClassFromChange(changeLogMap[changePath.join('.')]);
      }
      changePath.pop();
    }

    return '';
  };

  const getRowNodeId = (obj: FlattenedBlueprintData) => {
    const path = obj.paths ?? [];
    const objPath = [obj.version, obj.type, ...path];
    return objPath.join('.');
  };

  const updateRowCount = () => {
    if (!gridApi) return;

    const statusBarComponent = gridApi.getStatusPanel('treeRowCountComponentKey');
    const componentInstance = (statusBarComponent as any).getFrameworkComponentInstance();
    componentInstance.setCount(data.length);

    if (!data.length) {
      gridApi.showNoRowsOverlay();
    } else {
      gridApi.hideOverlay();
    }
  };

  const updateChangeLogRef = () => {
    changeStateMapRef.current = calculateChangeStates(changeLogs);

    changePathMapRef.current = changeLogs.reduce<Record<string, BlueprintDataDiffItem>>(
      (all, changeLog) => {
        changeLog.data.forEach((change) => {
          const path = [changeLog.objId, ...(change.path ?? [])] as DataIndex;

          if (change.kind === 'A') {
            const arrChange = change as BlueprintDataDiffArray;
            path.push(arrChange.index);
          }

          all[path.join('.')] = (change as any).item ?? change;
        });

        return all;
      },
      {},
    );

    if (gridApi) {
      gridApi.redrawRows();
      gridApi.onFilterChanged();
    }
  };

  useEffect(() => {
    onSelectionChangedRef.current = onSelectionChanged;
  }, [onSelectionChanged]);

  useEffect(updateGridVisibleCallbacks, [gridApi, type]);

  useEffect(() => {
    if (gridApi) {
      if (loading) {
        gridApi.showLoadingOverlay();
      } else if (!data.length) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!gridApi || !type) return;

    const variants = type.variants ?? [];
    const _columnDefs: AgColDef[] = [stateColumn];

    if (type.isVariant) {
      _columnDefs.push({
        headerName: 'Variant',
        field: 'variant',
        minWidth: 100,
        maxWidth: 100,
        pinned: 'left',
        filter: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        valueGetter: ({ data: obj }) => {
          if (!obj) return;
          return variants.find((item) => item.key === obj.variant || (!item.key && !obj.variant))
            ?.name;
        },
      });
    }

    let dataColumns = BlueprintGridHeaderGenerator.renderBlueprintType(
      type,
      protoMap,
      fieldSettings,
    );

    const storageKey = `${GRID_COLUMN_LOCAL_STORAGE_KEY}:${type.name}`;
    const storageVal = localStorage.getItem(storageKey);
    if (storageVal) {
      const storageColumns: string[] = [];
      const storageColumnWidthMap = storageVal
        .split(',')
        .reduce<Record<string, number>>((all, column) => {
          const [field, width = '150'] = column.split(':');
          if (width !== '150') {
            all[field] = parseInt(width, 10);
          }
          storageColumns.push(field);
          return all;
        }, {});

      const columnSorter = (colA: ColDef, colB: ColDef) => {
        const idxA = storageColumns.indexOf(colA.field ?? '');
        const idxB = storageColumns.indexOf(colB.field ?? '');
        if (idxA >= 0 && idxB >= 0) return idxA - idxB;
        return 0;
      };

      dataColumns = dataColumns
        .sort(columnSorter)
        .map(function applyStorageSetting(column: ColDef) {
          const colGroup = column as ColGroupDef;
          if (colGroup.children) {
            colGroup.children = colGroup.children.sort(columnSorter).map(applyStorageSetting);
          }

          if (column.field) {
            column.hide = !storageColumns.includes(column.field ?? '');
            if (storageColumnWidthMap[column.field]) {
              column.width = storageColumnWidthMap[column.field];
              column.suppressSizeToFit = true;
            }
          }

          return column;
        });
    }

    if (appCtx?.blueprint?.onGridColumnUpdate) {
      dataColumns = appCtx.blueprint.onGridColumnUpdate(type.name, dataColumns);
    }

    const { onDelete, onEdit, extraActions } = action ?? {};
    const width = [onDelete, onEdit, ...(extraActions ?? [])].filter(Boolean).length * 32;

    _columnDefs.push(
      ...dataColumns,
      {
        headerName: 'Tags',
        field: 'tags',
        colId: `tags-${Date.now()}`,
        maxWidth: 250,
        minWidth: 100,
        filter: true,
      },
      {
        colId: 'action',
        headerName: '',
        cellRendererFramework: BlueprintDataGridAction,
        cellRendererParams: action,
        sortable: false,
        minWidth: 22 + width,
        maxWidth: 22 + width,
        headerClass: 'right',
        pinned: 'right',
        lockPosition: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
    );

    gridApi.setColumnDefs(_columnDefs);
  }, [gridApi, type, action, data]);

  const flattenedData = useMemo(() => {
    updateChangeLogRef();
    updateRowCount();
    return handleFlattenData(data);
  }, [data]);

  useImperativeHandle(ref, () => ({
    gridApi: gridApi as GridApi,
    columnApi: columnApi as ColumnApi,
  }));
  return (
    <ReactResizeDetector
      handleHeight
      render={({ height }) => (
        <div className="flex-auto ag-theme-alpine bordered" style={{ height }}>
          <AgGridReact
            {...gridProps}
            onGridReady={handleGridReady}
            defaultColDef={{
              floatingFilter: true,
              sortable: true,
              suppressMenu: true,
              resizable: true,
              cellClass: getCellClass,
            }}
            autoGroupColumnDef={{
              headerName: '',
              minWidth: selectable ? 70 : 40,
              maxWidth: selectable ? 70 : 40,
              checkboxSelection: selectable,
              headerCheckboxSelection: selectable,
              headerCheckboxSelectionFilteredOnly: true,
              cellRenderer: 'agGroupCellRenderer' /*  */,
              cellRendererParams: cellRendererCheckbox ?? {
                suppressCount: true,
              },
              valueGetter: () => '',
              pinned: 'left',
              lockPosition: true,
            }}
            enableBrowserTooltips
            rowData={flattenedData}
            immutableData
            getRowNodeId={getRowNodeId}
            getContextMenuItems={() => []}
            sideBar={{
              toolPanels: [
                {
                  id: 'columns',
                  labelDefault: 'Columns',
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',
                  toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                  },
                },
                {
                  id: 'filters',
                  labelDefault: 'Filters',
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
                },
              ],
              position: 'right',
            }}
            treeData
            getDataPath={(obj: FlattenedBlueprintData) => obj.paths ?? []}
            debounceVerticalScrollbar
            rowSelection={selectable ? 'multiple' : undefined}
            suppressRowClickSelection
            isRowSelectable={(node) => {
              return node.data?.paths?.length === 1;
            }}
            frameworkComponents={{
              treeRowCountComponent: BlueprintDataGridRowCount,
            }}
            statusBar={{
              statusPanels: [
                {
                  statusPanel: 'treeRowCountComponent',
                  key: 'treeRowCountComponentKey',
                  align: 'left',
                },
              ],
            }}
          />
        </div>
      )}
    />
  );
});

export default BlueprintDataGrid;
