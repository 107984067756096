import React, { createContext, FC, useContext, useEffect, useState } from 'react';

import { useAuth } from '@lib/providers/auth';
import { useGlobalBlueprintDataVersionContext } from '@lib/providers/blueprint-version';
import { blueprintDataService } from '@lib/services/blueprint';

import {
  LevelModel,
  MapLevelCollectionContext,
  MapLevelProto,
} from '@pages/map-level/utils/models';

const levelCollectionContext = createContext<MapLevelCollectionContext | null>(null);
const { Provider } = levelCollectionContext;

const getInitialEnemyLevelModel = () => {
  const dataModel: LevelModel[] = [];
  return dataModel;
};

export const LevelCollectionProvider: FC = ({ children }) => {
  const { currentUser } = useAuth();

  const { selectedKey: version } = useGlobalBlueprintDataVersionContext();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MapLevelProto[]>([]);
  const [mapLevelModel, setMapLevelModel] = useState(getInitialEnemyLevelModel());
  const mapLevel = data.reduce<Record<string, MapLevelProto>>((all, item) => {
    all[item.id] = item;
    return all;
  }, {});

  const setLevelModelDataArr = (levelModel: LevelModel[]) => {
    setMapLevelModel(levelModel);
  };

  const setLevelModelData = (levelModel: LevelModel) => {
    setMapLevelModel((current) => {
      return [
        ...current.slice(0, parseInt(levelModel.level, 10)),
        levelModel,
        ...current.slice(parseInt(levelModel.level, 10)),
      ];
    });
  };

  const fetch = async () => {
    if (!version) return;

    const res = await blueprintDataService.getData(
      version,
      'com.alleylabs.legendblast.blueprint.MapLevelCollection',
    );
    if (res.ok && res.data) {
      const rawData = res.data.map((item) => item.data);
      const levelModelArr = Array<LevelModel>();
      setData(rawData as MapLevelProto[]);
      (rawData as MapLevelProto[]).forEach((value, index) => {
        levelModelArr[index] = {
          key: value.id,
          level: value.id,
          background: value.background,
        };
      });
      setLevelModelDataArr(levelModelArr);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!currentUser || !version) return;
    fetch();
  }, [currentUser, version]);

  return (
    <Provider
      value={{
        mapLevelCollection: mapLevel,
        loading,
        mapLevelModel,
        setLevelModelData,
        fetch,
      }}
    >
      {children}
    </Provider>
  );
};

export const useGlobalMapLevelCollectionContext = () => {
  const ctx = useContext(levelCollectionContext);
  if (!ctx) {
    throw new Error(
      'useGlobalMapLevelCollectionContext must be used inside LevelCollectionProvider',
    );
  }

  return ctx;
};

export default LevelCollectionProvider;
