import React, { forwardRef, Key, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, PaginationProps, Popconfirm, Space, Table, TablePaginationConfig } from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import { IField, IType } from 'protobufjs';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import {
  findActualDataIndex,
  flattenData,
  FlattenedDataType,
  formatLabel,
} from '@lib/utils/blueprint-data';
import {
  DataIndex,
  ExtentedColumnGroupType,
  ExtentedColumnType,
  TableHeaderGenerator,
} from '@lib/utils/blueprint-data/table-header-generator';

import { RenderOptions } from '../form/form-generator/single-field';

import EditableCell, { EditableCellProps } from './cell';

export type EditableDataType = FlattenedDataType & {
  $id: string;
  $dataIndex: Array<number | string>;
};

export type BlueprintDataEditableProps = Omit<
  TableProps<EditableDataType>,
  'dataSource' | 'columns' | 'rowSelection'
> & {
  options: RenderOptions<IField>;
  width?: number;
  dataSource: Record<string, any>[];
  onAddSubItem: (dataIndex: DataIndex) => void;
  onDeleteSubItem: (dataIndex: DataIndex) => void;
  onDelete: (index: number) => void;
};

const flattenTableData = (dataSource: Record<string, any>[]) => {
  return dataSource.reduce<EditableDataType[]>((all, item, idx) => {
    const flattened = [] as FlattenedDataType[];
    flattenData(item, flattened);
    all.push(
      ...flattened.map<EditableDataType>((subItem, subIdx) => ({
        ...subItem,
        $id: subIdx ? `${idx}.${subItem.$keys.toString()}` : idx.toString(),
        $dataIndex: [idx],
      })),
    );
    return all;
  }, []);
};

const getChildDataIndex = (
  column: ExtentedColumnGroupType<EditableDataType>,
): DataIndex | undefined => {
  const child = column.children.find((item: any) => !!item.dataIndex) as ExtentedColumnType<
    EditableDataType
  >;
  if (child) return child.dataIndex;

  return column.children
    .filter((item: any) => item.children)
    .reduce<DataIndex | undefined>((all, item) => {
      if (all) return all;

      return getChildDataIndex(item as ExtentedColumnGroupType<EditableDataType>);
    }, undefined);
};

const BlueprintDataEditable = forwardRef<any, BlueprintDataEditableProps>(
  ({ options, width, dataSource, onAddSubItem, onDeleteSubItem, onDelete, ...restProps }, ref) => {
    const { type: field, types: protoMap, fieldSettings } = options;
    const type = protoMap[field.type] as IType;

    const [columns, setColumns] = useState<ColumnType<EditableDataType>[]>([]);
    const [data, setData] = useState<EditableDataType[]>([]);
    const selected = useRef<EditableDataType>();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize] = useState<number>(10);

    useImperativeHandle(ref, () => ({
      goToLastPage: () => {
        const pageCount = Math.ceil(data?.length / pageSize);

        if (data?.length % pageSize === 0) {
          setCurrentPage(pageCount + 1);
        } else {
          setCurrentPage(pageCount);
        }
      },
    }));

    const onChange: PaginationProps['onChange'] = (page) => {
      setCurrentPage(page);
    };

    const paginationOptions = () => {
      let DEFAULT_PAGE_SIZE = 10;

      if (options.key === 'NewbieOnlineRewardsItemConfigs') {
        DEFAULT_PAGE_SIZE = 100;
      }

      const pagination: TablePaginationConfig = {
        current: currentPage,
        size: 'default',
        position: ['bottomCenter'],
        total: data?.length,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
        onChange,
      };

      if (data?.length > DEFAULT_PAGE_SIZE) {
        return pagination;
      }

      return false;
    };

    const handleDelete = (idx: number) => {
      selected.current = undefined;
      onDelete(idx);
    };

    const generateTableColumns = () => {
      const generatedColumns = TableHeaderGenerator.renderType<EditableDataType>({
        types: protoMap,
        type,
        fieldSettings: fieldSettings as any,
        paths: [],
        key: '',
      });

      const columnsWidth = generatedColumns.reduce(function countColumn(
        all,
        col: ColumnType<any>,
      ): number {
        const groupCol = col as ExtentedColumnGroupType<any>;
        return groupCol.children?.length
          ? groupCol.children.reduce(countColumn, all)
          : all + (col.width as number);
      },
      0);

      generatedColumns.forEach(function extendOnCell(col: ExtentedColumnType<EditableDataType>) {
        const groupCol = col as ExtentedColumnGroupType<EditableDataType>;
        if (groupCol.children?.length) {
          groupCol.children.forEach((child) =>
            extendOnCell(child as ExtentedColumnType<EditableDataType>),
          );

          const childDataIndex = getChildDataIndex(groupCol) ?? [];
          const lastNumberIndex = [...childDataIndex]
            .reverse()
            .findIndex((item) => typeof item === 'number');
          const ignoredIndex = lastNumberIndex <= 0 ? undefined : -lastNumberIndex;

          if (col.isArray) {
            let addDataIndex: DataIndex = [];
            let removeDataIndex: DataIndex = [];
            if (selected.current) {
              const _selected = selected.current;

              addDataIndex = _selected.$dataIndex.concat(
                findActualDataIndex(childDataIndex.slice(0, ignoredIndex), _selected, false) ?? [],
              );
              removeDataIndex = _selected.$dataIndex.concat(
                findActualDataIndex(childDataIndex.slice(0, ignoredIndex), _selected) ?? [],
              );
            }
            const addable = !!selected.current;
            const removable = !!selected.current && removeDataIndex.length;

            groupCol.title = (
              <Space>
                {groupCol.title}
                <Button
                  type="link"
                  size="small"
                  icon={<PlusOutlined />}
                  style={{ fontWeight: 'inherit' }}
                  disabled={!addable}
                  onClick={() => onAddSubItem(addDataIndex)}
                />
                <Popconfirm
                  title="Delete this item?"
                  disabled={!removable}
                  onConfirm={() => onDeleteSubItem(removeDataIndex)}
                  okButtonProps={{ danger: true }}
                >
                  <Button
                    type="link"
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                    style={{ fontWeight: 'inherit' }}
                    title="Delete"
                    disabled={!removable}
                  />
                </Popconfirm>
              </Space>
            );
          }
        } else {
          if (col.field === '$key') {
            col.title = (
              <>
                <span className="mr-1 text-red-500" style={{ fontWeight: 400 }}>
                  *
                </span>
                {col.title}
              </>
            );
          }

          col.onCell = (record): EditableCellProps => {
            let editable = true;
            const colDataIndex = col.dataIndex ?? [];
            const lastNumberIdx = colDataIndex.reduceRight<number>(
              (all, item, idx) => Math.max(all, typeof item === 'number' ? idx : -1),
              -1,
            );

            let actualDataIndex = findActualDataIndex(
              colDataIndex.slice(0, lastNumberIdx < 0 ? undefined : lastNumberIdx + 1),
              record,
            );
            if (actualDataIndex) {
              if (lastNumberIdx > -1 && lastNumberIdx < colDataIndex.length - 1) {
                actualDataIndex.push(...colDataIndex.slice(lastNumberIdx + 1));
              }
            } else {
              actualDataIndex = colDataIndex;
              editable = record.$id.indexOf('.') < 0 ? lastNumberIdx < 0 : false;
            }

            const refField = (col.dataIndex ?? [])
              .slice(0, col.field === 'type_url' ? -1 : undefined)
              .filter((part) => typeof part === 'string')
              .join('.');

            return {
              options: {
                ...options,
                type: field,
              },
              editable,
              inputType: fieldSettings[refField]?.reference ?? col.inputType,
              dataIndex: record.$dataIndex.concat(actualDataIndex ?? []),
              title: formatLabel(col.field),
            };
          };
          col.align = 'center';
        }
      });

      const actionCol: ColumnType<EditableDataType> = {
        key: 'action',
        render: (_, record) => (
          <Popconfirm
            title="Delete this item?"
            disabled={record.$id.indexOf('.') > -1}
            onConfirm={() => handleDelete(record.$dataIndex.pop() as number)}
            okButtonProps={{ danger: true }}
            placement="left"
          >
            <Button
              danger
              size="small"
              icon={<DeleteOutlined />}
              disabled={record.$id.indexOf('.') > -1}
            />
          </Popconfirm>
        ),
        fixed: 'right',
        width: 65,
      };

      const spaceCol: ColumnType<EditableDataType> = {
        key: 'space',
        width: 960,
      };

      if (width && columnsWidth < width - 112) {
        spaceCol.width = width - 112 - columnsWidth;
      } else {
        spaceCol.width = 0;
      }

      setColumns([...generatedColumns, spaceCol, actionCol]);
    };

    const handleSelectionChange = (keys: Key[], selectedRows: EditableDataType[]) => {
      [selected.current] = selectedRows;
      generateTableColumns();
    };

    useEffect(() => {
      setData(() => flattenTableData(dataSource));
    }, [dataSource]);

    useEffect(() => {
      generateTableColumns();
    }, [type, width, fieldSettings]);

    const availHeight = window.innerHeight - 200;
    const tableHeight = Math.floor((availHeight * 0.6) / 42) * 42;

    return (
      <Table<EditableDataType>
        {...restProps}
        size="small"
        bordered
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={columns}
        rowKey="$id"
        dataSource={data}
        scroll={{ y: tableHeight, x: 'max-content' }}
        rowSelection={{
          type: 'radio',
          fixed: true,
          columnWidth: 50,
          onChange: handleSelectionChange,
          selectedRowKeys: [selected.current?.$id ?? ''].filter(Boolean),
        }}
        pagination={paginationOptions()}
      />
    );
  },
);

export default BlueprintDataEditable;
