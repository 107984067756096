import { FlattenedDataType } from './flatten-data';

export type DataIndex = Array<string | number>;

export const zeroifyDataIndex = (key: DataIndex): DataIndex =>
  key.map((part) => (typeof part === 'number' ? 0 : part));

export const parseDataIndex = (dataIndex: string): DataIndex =>
  dataIndex.split('.').map((item) => (/^[\d]+$/.test(item) ? parseInt(item, 10) : item));

export const findLongestCommonPath = (...dataIndexes: DataIndex[]) => {
  return dataIndexes.slice(1).reduce<DataIndex>((aDataIndex, bDataIndex) => {
    const result: DataIndex = [];

    let idx = 0;
    while (
      idx < aDataIndex.length &&
      idx < bDataIndex.length &&
      aDataIndex[idx] === bDataIndex[idx]
    ) {
      result.push(aDataIndex[idx]);
      idx += 1;
    }

    return result;
  }, dataIndexes[0]);
};

export const findActualDataIndex = (
  currentDataIndex: DataIndex,
  record: FlattenedDataType,
  exactMatch = true,
) => {
  let actualDataIndex = [...currentDataIndex];

  if (exactMatch) {
    const colDataIndex = currentDataIndex.join('.');
    const replaceDataIndex = record.$keys.findIndex(
      (key) => zeroifyDataIndex(key).join('.') === colDataIndex,
    );
    if (replaceDataIndex > -1) {
      actualDataIndex = [...record.$keys[replaceDataIndex]];
    } else {
      return;
    }
  } else {
    const longestCommonPath = record.$keys.reduce((matched, key, idx) => {
      const commonPath = findLongestCommonPath(zeroifyDataIndex(key), currentDataIndex);
      return commonPath.length > matched.length
        ? record.$keys[idx].slice(0, commonPath.length)
        : matched;
    }, []);

    return longestCommonPath.concat(currentDataIndex.slice(longestCommonPath.length));
  }

  return actualDataIndex;
};
