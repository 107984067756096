import { IField, IType } from 'protobufjs';

import { ProtoData } from '@lib/providers/blueprint-proto';

import { getTypeIdField } from './get-type-id-field';

export const flattenMapField = (field: IField, types: ProtoData) => {
  const keyField: IField = { id: 0, type: (field as any).keyType };

  let newType: IType | undefined;

  if (types[field.type]) {
    // nested
    const refType = types[field.type] as IType;
    if (refType.fields) {
      // type
      newType = {
        fields: {
          ...(refType as IType).fields,
        },
      };

      const idField = getTypeIdField(refType, types, keyField.type);
      if (!idField) {
        newType.fields = {
          $key: keyField,
          ...(refType as IType).fields,
        };
      }
    }
  }

  if (!newType) {
    // single type
    newType = {
      fields: {
        $key: keyField,
        $val: { id: 1, type: field.type },
      },
    };
  }

  return newType;
};
