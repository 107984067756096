import React, { FC, forwardRef, useImperativeHandle, useState } from 'react';

import { ColumnApi, GridApi } from '@lib/components/ui/ag-grid';

export type BlueprintDataGridRowCountRef = {
  setCount: (count: number) => void;
};

export type BlueprintDataGridRowCountProps = {
  api: GridApi;
  columnApi: ColumnApi;
};

const BlueprintDataGridRowCount: FC<BlueprintDataGridRowCountProps> = forwardRef<
  BlueprintDataGridRowCountRef,
  BlueprintDataGridRowCountProps
>((props, ref) => {
  const [rowCount, setRowCount] = useState(0);

  useImperativeHandle(ref, () => ({
    setCount: setRowCount,
  }));

  return (
    <div className="ag-status-name-value">
      <span>Total Rows: </span>
      <span className="ag-status-name-value-value">{rowCount}</span>
    </div>
  );
});

export default BlueprintDataGridRowCount;
