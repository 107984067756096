import React, { FC } from 'react';
import { Form, message, Modal } from 'antd';

import { botService } from '@lib/services/bot-manage';
import { BotBulkCreateDto, BotUpdateDto } from '@lib/services/models';

import { useBotContext } from './context';
import BotForm from './form';

const BotModal: FC = () => {
  const { gridRef, modal } = useBotContext();
  const { toggleModal, modalVisible, selected } = modal;

  const [form] = Form.useForm();

  const handleClose = () => {
    toggleModal(false);
  };

  const title = selected ? 'Edit Bot' : 'Create Bot';

  const handleSubmit = () => {
    form
      .validateFields()
      .then(
        (value) => {
          return selected
            ? botService.update(value.id, value as BotUpdateDto)
            : (botService.addBots(value as BotBulkCreateDto) as any);
        },
        (err) => {
          message.error(err.errorFields[0].errors[0]);
        },
      )
      .then(async (res) => {
        if (res && res.ok) {
          message.success(`${title} successfully`);
          toggleModal(false);

          if (gridRef.current) {
            gridRef.current.fetch();
          }
          form.resetFields();
        }
      });
  };

  return (
    <Modal
      title={title}
      visible={modalVisible}
      closable
      onCancel={handleClose}
      onOk={handleSubmit}
      width={600}
    >
      <BotForm form={form} obj={selected} />
    </Modal>
  );
};

export default BotModal;
