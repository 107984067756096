import { IField, IType } from 'protobufjs';

import { ProtoData } from '@lib/providers/blueprint-proto';
import { BlueprintProto } from '@lib/services/models';

import { flattenMapField } from './flatten-map-field';

export const extractBlueprintType = (blueprint: BlueprintProto, types: ProtoData) => {
  const renderType: IType = (types[blueprint.type] ?? blueprint.proto) as IType;

  if (Object.keys(renderType.fields).length === 1) {
    const field = Object.values(renderType.fields)[0];

    if ((field as any).keyType && !blueprint.isSingle) {
      return flattenMapField(field, types);
    }
    if (field.rule === 'repeated' && blueprint.isSingle) {
      return renderType;
    }
    if (types[field.type]) {
      return types[field.type] as IType;
    }

    const key = Object.keys(renderType.fields)[0];
    return {
      fields: { [key]: { ...field, rule: undefined } },
    };
  }

  return renderType;
};

const _getFieldProto = (fieldPath: string[], type: IType, types: ProtoData): IField | undefined => {
  const field = fieldPath.shift();

  if (!field || !type.fields[field]) return;

  const fieldProto = type.fields[field];

  if (!fieldPath.length) return fieldProto;

  // map
  if ((fieldProto as any).keyType) {
    const mapType = flattenMapField(fieldProto, types);
    const mapField: IField = {
      ...fieldProto,
      type: `${(fieldProto as any).keyType}:${fieldProto.type}Flattened`,
      rule: 'repeated',
    };
    delete (mapField as any).keyType;
    types[mapField.type] = mapType;

    return _getFieldProto(fieldPath, mapType, types);
  }

  return _getFieldProto(fieldPath, types[fieldProto.type] as IType, types);
};

export const getFieldProto = (field: string, type: IType, types: ProtoData) => {
  return _getFieldProto(field.split('.'), type, types);
};
