import React, { FC } from 'react';
import { Layout as AntdLayout } from 'antd';

import Header from './header/header';
import Sider from './sider/sider';

import './layout.css';

const Layout: FC = ({ children }) => (
  <AntdLayout className="h-screen">
    <Sider />
    <AntdLayout className="min-h-full">
      <Header />
      <AntdLayout.Content className="relative overflow-auto bg-white">
        {children}
      </AntdLayout.Content>
    </AntdLayout>
  </AntdLayout>
);

export default Layout;
