import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Switch } from 'antd';

import { ICellEditorParams, ICellEditorReactComp } from '@lib/components/ui/ag-grid';

const BooleanCellEditor = forwardRef<ICellEditorReactComp, ICellEditorParams>((props, ref) => {
  const [value, setValue] = useState(!!props.value);
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (refInput?.current) {
        refInput.current.focus();
      }
    });
  }, []);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  return (
    <div className="text-center">
      <Switch checked={value} onChange={setValue} />
    </div>
  );
});

export default BooleanCellEditor;
