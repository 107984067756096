import React, { createContext, FC, useContext } from 'react';

import client from '@lib/services/client';
import { ClientConfigResponseDto } from '@lib/services/models';
import { suspensePromise } from '@lib/utils/suspense-promise';

export type AppConfig = ClientConfigResponseDto;

const configData = suspensePromise(client.api.systemConfigControllerGetPublicConfig());

const configContext = createContext<AppConfig | null>(null);

export const AppConfigProvider: FC = ({ children }) => {
  const res = configData.read();

  return <configContext.Provider value={res?.data ?? null}>{children}</configContext.Provider>;
};

export const useAppConfig = () => {
  const ctx = useContext(configContext);
  if (!ctx) {
    throw new Error('useAppConfig must be used inside AppConfigProvider');
  }

  return ctx;
};
