import React, { FC, useMemo } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import { RowNode } from '@ag-grid-community/core';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { BlueprintData } from '@lib/services/models';
import { FlattenedBlueprintData } from '@lib/utils/blueprint-data';

export type ExtraActionProps = Omit<ButtonProps, 'onClick'> & {
  onClick: (obj: BlueprintData) => void | Promise<void>;
};

export type BlueprintDataGridActionProps = {
  node: RowNode;
  data: FlattenedBlueprintData;
  onDelete?: (obj: BlueprintData) => void;
  onEdit?: (obj: BlueprintData) => void;
  isDisabled?: (obj: BlueprintData) => boolean;
  extraActions?: ExtraActionProps[];
};

const BlueprintDataGridAction: FC<BlueprintDataGridActionProps> = (props) => {
  const { node, isDisabled, onDelete, onEdit, extraActions = [] } = props;

  const disabled = useMemo(() => (isDisabled ? isDisabled(node.data) : false), [
    node.data,
    isDisabled,
  ]);

  return (
    <Button.Group>
      {onEdit ? (
        <Button
          key="edit"
          type="primary"
          icon={<EditOutlined />}
          title="Edit"
          onClick={() => {
            setTimeout(() => onEdit(node.data));
          }}
          disabled={disabled}
        />
      ) : null}
      {onDelete ? (
        <Button
          key="delete"
          danger
          icon={<DeleteOutlined />}
          title="Delete"
          onClick={() => onDelete(node.data)}
          disabled={disabled}
        />
      ) : null}
      {extraActions.map(({ onClick, ...actionProps }, idx) => (
        <Button key={idx} {...actionProps} onClick={() => onClick(node.data)} />
      ))}
    </Button.Group>
  );
};

export default BlueprintDataGridAction;
