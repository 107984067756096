const re = /([A-Za-z]?)([a-z]+)/g;
/*
  matches example: "oneTwoThree"
  ["one", "o", "ne"]
  ["Two", "T", "wo"]
  ["Three", "T", "hree"]
*/

export const formatLabel = (label: string) => {
  if (label === '$key') return 'Key';
  if (label === '$val') return 'Value';
  if (label === 'type_url') return 'Type';

  const output = [];

  let match = re.exec(label);
  while (match) {
    output.push([match[1].toUpperCase(), match[2]].join(''));
    match = re.exec(label);
  }

  return output.join(' ');
};

export const formatBlueprintName = (name: string) => name.split('.').pop();
