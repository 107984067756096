/* eslint-disable no-nested-ternary */
import React, { FC, useEffect } from 'react';

import { useLevelDataConfigContext } from '@pages/map-level/context';
import { ObjectCollection } from '@providers/object-collection';

import { DEFAULT_TILE } from '../level-data/layout/tile-select';

import ColTile from './col-tile';

import './style.css';

export const defaultTileSpace = 30;
export const defaultSquareSize = 28;

export type GridLayoutProps = {
  layer?: number;
  tile?: ObjectCollection;
  viewOnly?: boolean;
};

const GridLayout: FC<GridLayoutProps> = ({ layer = 1, tile = DEFAULT_TILE, viewOnly }) => {
  const {
    setLayer,
    setSelectedTile,
    tiles,
    maxWidth: width,
    maxHeight: height,
    hiddenLayer0,
    hiddenLayer1,
    hiddenLayer2,
    hiddenLayer3,
  } = useLevelDataConfigContext();

  useEffect(() => {
    if (!viewOnly) setLayer(layer);
    // setSelectedTile(tile);
  }, []);

  useEffect(() => {
    if (!viewOnly) setLayer(layer);
  }, [layer]);

  useEffect(() => {
    if (!viewOnly) setSelectedTile(tile);
  }, [tile]);

  useEffect(() => {
    if (!viewOnly) setLayer(layer);
  }, [tiles]);

  return (
    <div className="relative tile-layout" style={{ height: height * defaultTileSpace }}>
      {[0, 0, 0, 0].map((_, idx) => {
        return Array(width)
          .fill(0)
          .map((_row, idxColumn) => (
            <ColTile key={`${idx}_${idxColumn}`} x={idxColumn} height={height} layer={idx + 1} />
          ));
      })}
    </div>
  );
};

const GridLayoutWrapper: FC<GridLayoutProps> = (props) => {
  return <GridLayout {...props} />;
};

export default GridLayoutWrapper;
