import React, { useState } from 'react';

import { TOKEN_KEY } from '@lib/providers/auth';

const useLocalStorageToken = (initialValue?: string) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(TOKEN_KEY);
      // Return data or if none return initialValue
      return item || initialValue;
    } catch (error) {
      // If error also return initialValue
      console.warn(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value?: string) => {
    try {
      // Save state
      setStoredValue(value);
      // Save to local storage
      if (typeof window !== 'undefined') {
        if (value) window.localStorage.setItem(TOKEN_KEY, value);
        else window.localStorage.removeItem(TOKEN_KEY);
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.warn(error);
    }
  };
  return [storedValue, setValue] as const;
};

export default useLocalStorageToken;
