import React, { createContext, FC, useContext, useEffect, useState } from 'react';

import { useAuth } from '@lib/providers/auth';
import { useGlobalBlueprintDataVersionContext } from '@lib/providers/blueprint-version';
import { blueprintDataService } from '@lib/services/blueprint';

import { BlueprintData } from '@services/_api';
import { odlService } from '@services/odl';

export type ChargeBarSlot = {
  ChargedPowerUp: number;
  IsHidden: boolean;
  IsMaxLevelAfterTriggered: boolean;
  CanChargedPowerUpRecycle: boolean;
};

export type ChargeBarLevel = {
  $type: string;
  Levels: any;
};

export type ChargeBarCollection = {
  EnableChargeBarSystem: boolean;
  ChargeBarLevelsList: ChargeBarLevel[];
  ChargeBarSlots: ChargeBarSlot[];
};

export type ChargeBarCollectionContext = {
  chargebarTemplate: Record<string, ChargeBarCollection>;
  loading: boolean;
  fetch: () => Promise<void>;
};

const chargeBarCollectionContext = createContext<ChargeBarCollectionContext | null>(null);
const { Provider } = chargeBarCollectionContext;

export const ChargeBarCollectionProvider: FC = ({ children }) => {
  const { currentUser } = useAuth();

  const { selectedKey: version } = useGlobalBlueprintDataVersionContext();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Record<string, ChargeBarCollection>>({});

  const fetch = async () => {
    if (!version) return;
    const res = await blueprintDataService.getData(
      version,
      'com.alleylabs.legendblast.blueprint.Template_ChargeBarBlueprint',
    );

    if (res.ok && res.data) {
      const rawData = res?.data?.reduce((acc: any, item: BlueprintData) => {
        if (item.state !== 'D') {
          acc[String(item.data.$key)] = {
            ...item.data,
          };
        }
        return acc;
      }, []);

      setData(rawData);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!currentUser || !version) return;
    fetch();
  }, [currentUser, version]);

  return (
    <Provider
      value={{
        chargebarTemplate: data,
        loading,
        fetch,
      }}
    >
      {children}
    </Provider>
  );
};

export const useGlobalChargeBarCollectionContext = () => {
  const ctx = useContext(chargeBarCollectionContext);
  if (!ctx) {
    throw new Error(
      'useGlobalChargeBarCollectionContext must be used inside ChargeBarCollectionProvider',
    );
  }

  return ctx;
};

export default ChargeBarCollectionProvider;
