import React, { FC } from 'react';
import { IField } from 'protobufjs';

import { flattenMapField } from '@lib/utils/blueprint-data';

import ArrayFormItem from './array';
import { RenderOptions } from './single-field';

export type MapFieldFormItemProps = {
  options: RenderOptions<IField>;
};

const MapFieldFormItem: FC<MapFieldFormItemProps> = ({ options }) => {
  const { types, type } = options;

  const mapType = flattenMapField(type, types);
  const mapField: IField = {
    ...type,
    type: `${(type as any).keyType}:${type.type}Flattened`,
    rule: 'repeated',
  };
  delete (mapField as any).keyType;
  types[mapField.type] = mapType;

  return <ArrayFormItem options={{ ...options, type: mapField }} />;
};

export default MapFieldFormItem;
