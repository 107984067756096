import client from '@lib/services/client';
import {
  BlueprintData,
  BlueprintDataFormat,
  BlueprintDataMoveTypeChangesDto,
} from '@lib/services/models';

import { BlueprintDataImportDto } from '../_api';

export type BlueprintImportOptions = {
  preview?: boolean;
  upsert?: boolean;
  reserveOrder?: boolean;
  overrideAllData?: boolean;
};

export const getData = (
  version: string,
  type: string,
  query?: { variant?: string; deployId?: number },
) => {
  return client.api.blueprintDataControllerGetAllData(version, type, query);
};

export const getDataAndCompare = (
  version: string,
  type: string,
  query?: {
    variant?: string;
    deployId?: number;
    compareDeployId?: number;
    compareVersion?: string;
  },
) => {
  return client.api.blueprintDataControllerGetDataAndCompare(version, type, query);
};

export const getDataAndCompareWithLastDeploy = (
  environment: string,
  version: string,
  type: string,
  variant?: string,
) => {
  return client.api.blueprintDataControllerGetAllDataAndCompareWithLastDeploy(version, type, {
    environment,
    variant,
  });
};

export const getChangedTypesOfVersions = (
  version: string,
  query?: {
    variant?: string;
    deployId?: number;
    compareDeployId?: number;
    compareVersion?: string;
  },
) => {
  return client.api.blueprintDataControllerGetChangedTypesOfVersions(version, query);
};

export const storeData = (obj: BlueprintData) => client.api.blueprintDataControllerStoreData(obj);

export const storeBatchData = (version: string, objs: BlueprintData[]) =>
  client.api.blueprintDataControllerStoreBatchData(version, { data: objs });

export const deleteData = (version: string, type: string, objId: string) =>
  client.api.blueprintDataControllerDeleteData(version, type, objId);

export const deleteMultiple = (version: string, type: string, objIds: string[]) =>
  client.api.blueprintDataControllerDeleteMultipleData(version, type, { objIds });

export const deleteMultipleAsync = (version: string, type: string, objIds: string[]) =>
  client.api.blueprintDataControllerDeleteMultipleDataAsync(version, type, { objIds });

export const importData = (body: BlueprintDataImportDto) => {
  if (!body.options) {
    delete body.options;
  } else {
    body.options = JSON.stringify(body.options) as any;
  }

  return client.api.blueprintDataImportControllerImportFromExcel(body, {
    headers: { 'Content-Type': undefined } as any,
  });
};
export const importDataAsync = (body: BlueprintDataImportDto) => {
  if (!body.options) {
    delete body.options;
  } else {
    body.optionsBin = new Blob([JSON.stringify(body.options)], { type: 'application/json' }) as any;
  }

  return client.api.blueprintDataImportControllerImportFromExcelAsync(body, {
    headers: { 'Content-Type': undefined } as any,
  });
};

export const exportData = (
  version: string,
  type?: string,
  deployId?: number,
  selectedIds?: string[],
  format = BlueprintDataFormat.Xlsx,
) => {
  return client.api.blueprintDataImportControllerExportToExcel(version, {
    type,
    deployId,
    selectedIds,
    format,
  });
};

export const exportJson = (version: string, type?: string, deployId?: number, ids?: string) => {
  return client.api.blueprintDataImportControllerExportToJson(version, { type, deployId, ids });
};

export const moveChanges = (
  version: string,
  type: string,
  toVersion: string,
  objIds?: string[],
) => {
  return client.api.blueprintDataControllerMoveChangesToVersion({
    version,
    type,
    toVersion,
    objIds,
  });
};

export const moveMultipleChanges = (
  version: string,
  toVersion: string,
  changes: BlueprintDataMoveTypeChangesDto[],
) => {
  return client.api.blueprintDataControllerMoveMultipleChangesToVersion({
    version,
    toVersion,
    changes,
  });
};

export const moveMultipleChangesAsync = (
  version: string,
  toVersion: string,
  changes: BlueprintDataMoveTypeChangesDto[],
  overrideExistingData?: boolean,
) => {
  return client.api.blueprintDataControllerMoveMultipleChangesToVersionAsync({
    version,
    toVersion,
    changes,
    overrideExistingData,
  });
};

export const revertData = (version: string, type: string, objId: string, lastChangedAt: string) => {
  return client.api.blueprintDataControllerRevertData(version, type, objId, {
    lastChangedAt,
  });
};

export const cloneData = (version: string, toVersion: string) => {
  return client.api.blueprintDataControllerCloneData(version, toVersion);
};

export const cloneVariant = (
  version: string,
  type: string,
  fromVariant: string,
  toVariant: string,
  overrideAllData?: boolean,
) => {
  return client.api.blueprintDataControllerCloneVariant({
    version,
    type,
    from: fromVariant,
    to: toVariant,
    overrideAllData,
  });
};

export const getBlueprintData = (version: string, type: string, objId: string) => {
  return client.api.blueprintDataControllerGetData(version, type, objId);
};
